<template>
  <!-- 节点右键菜单 -->
  <div
    id="elementMenuList"
    ref="elementMenuList"
    class="element-menu-list"
    v-show="showElementMenuList"
    v-clickoutside="outsideCloseIconCompoenntAccout"
    :style="{ left: newMenuX + 'px', top: newMenuY + 'px' }"
  >
    <input
      type="file"
      accept="image/*"
      @change="uploadFile"
      class="hiddenImgInput"
    />

    <template v-if="MindMenuType == 'mind'">
      <div
        v-for="(item, index) in showElementMenuListData"
        :key="index"
        class="element-menu-children-list"
      >
        <div
          v-for="(each, count) in item"
          :key="count"
          @click="checkMenuElement(each.name)"
        >
          <!-- 修改形状 -->
          <div
            v-if="each.name == strings.Mind_Edit_Node_Menu_Modify_Shape"
            class="each-menu"
          >
            <div
              class="checkMindShape checkMindShape-shape"
              :style="{ left: muneShapeLeft + 'px', top: muneShapeTop + 'px' }"
            >
              <!-- style="visibility: visible;" -->
              <ComplieChildMenuForm
                ref="shapeMenuBox"
                :isShowExplain="isExplain"
                :shapeBorderType="shapeBorderType"
                :reigthMenu="true"
              />
            </div>
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img
              v-if="mapEditDarkMode"
              src="../../../assets/img/canvasMenu/darkModeIcon/menuTriangeWhite.svg"
              class="each-icon-triange"
              alt=""
            />
            <img
              v-else
              src="../../../assets/img/canvasMenu/menuTriange.png"
              class="each-icon-triange"
              alt=""
            />
          </div>
          <!-- 快速样式 -->
          <div
            v-else-if="each.name == strings.Mind_Edit_Node_Menu_Quick_Style"
            class="each-menu"
          >
            <div
              class="checkMindShape checkMindShape-style"
              :style="{ left: muneStyleLeft + 'px', top: muneStyleTop + 'px' }"
            >
              <ComplieChildMenuNodeQuickStyle />
            </div>
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img
              v-if="mapEditDarkMode"
              src="../../../assets/img/canvasMenu/darkModeIcon/menuTriangeWhite.svg"
              class="each-icon-triange"
              alt=""
            />
            <img
              v-else
              src="../../../assets/img/canvasMenu/menuTriange.png"
              class="each-icon-triange"
              alt=""
            />
          </div>
          <!-- 插入二级菜单 -->
          <div
            v-else-if="each.name == strings.Mind_Edit_Insert"
            class="each-menu" >
            <div
              class="checkMindShape"
              :style="{
                left: muneInsertLeft + 'px',
                top: muneInsertTop + 'px',
              }"
            >
              <ComplieChildMenuNodeInsertContent
                ref="insertMenuBox"
                @clickMenuElement="checkMenuElement"
              />
            </div>
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img
              v-if="mapEditDarkMode"
              src="../../../assets/img/canvasMenu/darkModeIcon/menuTriangeWhite.svg"
              class="each-icon-triange"
              alt=""
            />
            <img
              v-else
              src="../../../assets/img/canvasMenu/menuTriange.png"
              class="each-icon-triange"
              alt=""
            />
          </div>
          <!-- 序号 -->
          <div
            v-else-if="each.name == strings.Mind_Edit_Order_Label"
            class="each-menu" >
            <div
              class="checkMindShape"
              :style="{
                left: muneInsertLeft + 'px',
                top: muneInsertTop + 'px',
              }"
            >
              <ComplieChildMenuNodeOrderContent
                ref="orderMenuBox"
                @clickMenuElement="checkMenuElement"
              />
            </div>
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img
              v-if="mapEditDarkMode"
              src="../../../assets/img/canvasMenu/darkModeIcon/menuTriangeWhite.svg"
              class="each-icon-triange"
              alt=""
            />
            <img
              v-else
              src="../../../assets/img/canvasMenu/menuTriange.png"
              class="each-icon-triange"
              alt=""
            />
          </div>

          <!-- 显示层级 -->
          <div
            v-else-if="each.name == strings.Mind_Edit_Open_Hidde_Title"
            class="each-menu" >
            <div
              class="checkMindShape"
              :style="{
                left: muneInsertLeft + 'px',
                top: muneInsertTop + 'px',
              }"
            >
              <ComplieChildMenuNodeOpenHidde
                ref="openHiddeMenuBox"
                @clickMenuElement="checkMenuElement"
              />
            </div>
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img
              v-if="mapEditDarkMode"
              src="../../../assets/img/canvasMenu/darkModeIcon/menuTriangeWhite.svg"
              class="each-icon-triange"
              alt=""
            />
            <img
              v-else
              src="../../../assets/img/canvasMenu/menuTriange.png"
              class="each-icon-triange"
              alt=""
            />
          </div>

          <div v-else-if="each.show" class="each-menu">
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img
              v-if="each.needMember && !isMember()"
              src="../../../assets/img/user/icon/vip.svg"
              class="need-member"
              alt="vip"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="MindMenuType == 'chart'">
      <div
        v-for="(item, index) in showChartDataMenu"
        :key="index"
        class="element-menu-children-list"
      >
        <div
          v-for="each in item"
          :key="each.id"
          @click="checkMenuElement(each.name)"
        >
          <div v-if="each.show" class="each-menu">
            <img
              v-if="mapEditDarkMode"
              :src="each.iconWhite"
              class="each-icon"
              :alt="each.name"
            />
            <img
              v-else
              :src="each.iconUrl"
              class="each-icon"
              :alt="each.name"
            />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import Template from "../../../views/template/Template.vue";
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuNodeQuickStyle from "../ComplieRightMenuChild/ComplieChildMenuNodeQuickStyle";
import ComplieChildMenuNodeInsertContent from "../ComplieRightMenuChild/ComplieChildMenuNodeInsertContent";
import ComplieChildMenuNodeOrderContent from "../ComplieRightMenuChild/ComplieChildMenuNodeOrderContent";
import ComplieChildMenuNodeOpenHidde from "../ComplieRightMenuChild/ComplieChildMenuNodeOpenHidde";
import { postBaseGlobalConfig } from "../../../common/netWork/networkcache";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import ShapeTypeImg from "../tools/ShapeTypeImg";

export default {
  name: "ComplieLeftContentMenu",
  props: [
    "showElementMenuList",
    "newMenuY",
    "newMenuX",
    "MindMenuType",
    "hiddenMindButtonArray",
    "fullHeight",
    "fullWidth",
    "isExplain",
  ],
  data() {
    return {
      showElementMenuListData: [
        [
          {
            id: 1,
            name: strings.Global_Edit,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuEdit.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 34,
            name: strings.Mind_Edit_Node_Menu_Ai_Label,
            iconUrl: require("../../../assets/img/canvasMenu/ai_menu_icon.svg"), 
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/ai_menu_icon.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 2,
            name: strings.Mind_Edit_Node_Menu_Copy_Style,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu2.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuCopy.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 3,
            name: strings.Mind_Edit_Node_Menu_Sticking_Style,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu8.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuSticking.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 31,
            name: strings.Mind_Edit_Node_Menu_Quick_Style,
            iconUrl: require("../../../assets/img/canvasMenu/quick_style_1.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuQuickStyle_1.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 4,
            name: strings.Mind_Edit_Node_Menu_Modify_Shape,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu3.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuModifyShape.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 32,
            name: strings.Mind_Node_To_Card_Label,
            iconUrl: require("../../../assets/img/canvasMenu/card_menu_icon.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/card_menu_icon.svg"),
            type: "mind",
            show: true,
          },
          
          // {
          //   id: 32,
          //   name: strings.Global_Edit_Task,
          //   iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1_task.svg"),
          //   type: "mind",
          //   show: true
          // },
          // {
          //   id: 33,
          //   name: strings.Mind_Edit_Latex,
          //   iconUrl: require("../../../assets/img/canvasMenu/editLatex.svg"),
          //   type: "mind",
          //   show: true,
          // },
          // {
          //   id: 30,
          //   name: strings.Mind_Edit_Subscript,
          //   iconUrl: require("../../../assets/img/canvasMenu/subScript.svg"),
          //   type: "mind",
          //   show: true,
          // },
        ],
        [
          // {
          //   id: 5,
          //   name: strings.Mind_Edit_Insert_Remarks,
          //   iconUrl: require("../../../assets/img/canvasMenu/canvasMenu4.svg"),
          //   type: "mind",
          //   show: true,
          // },
          // {
          //   id: 6,
          //   name: strings.Mind_Edit_Insert_Link,
          //   iconUrl: require("../../../assets/img/canvasMenu/canvasMenu5.svg"),
          //   type: "mind",
          //   show: true,
          // },
          {
            id: strings.Mind_Edit_Subtopic,
            name: strings.Mind_Edit_Subtopic,
            iconUrl: require("../../../assets/img/mindNewEditMenuIocn/son.svg"),
            iconWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/son.svg"),
            type: "mind",
            show: true,
          },
          {
            id: strings.Mind_Edit_Bottom_Subtopic,
            name: strings.Mind_Edit_Bottom_Subtopic,
            iconUrl: require("../../../assets/img/mindNewEditMenuIocn/bottomSon.svg"),
            iconWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/bottomSon.svg"),
            type: "mind",
            show: true,
          },
          {
            id: strings.Mind_Edit_Top_Subtopic,
            name: strings.Mind_Edit_Top_Subtopic,
            iconUrl: require("../../../assets/img/mindNewEditMenuIocn/topSon.svg"),
            iconWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/topSon.svg"),
            type: "mind",
            show: true,
          },
          {
            id: strings.Mind_Edit_Insert,
            name: strings.Mind_Edit_Insert,
            iconUrl: require("../../../assets/img/canvasMenu/editInsert.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/editInsertShape.svg"),
            type: "mind",
            show: true,
          },
          {
            id: strings.Mind_Edit_Order_Label,
            name: strings.Mind_Edit_Order_Label,
            iconUrl: require("../../../assets/img/canvasMenu/editorder.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/editorder.svg"),
            type: "mind",
            show: true,
          },
          {
            id: strings.Mind_Edit_Open_Hidde_Title,
            name: strings.Mind_Edit_Open_Hidde_Title,
            iconUrl: require("../../../assets/img/canvasMenu/quick_style.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuQuickStyle.svg"),
            type: "mind",
            show: true,
          },
          {
            id: strings.Mind_Edit_Checkbox_Title,
            name: strings.Mind_Edit_Checkbox_Title,
            iconUrl: require("../../../assets/img/canvasMenu/todo.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/todo.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 33,
            name: strings.Mind_Edit_Left_Menu_Save_Picture, //保存图片
            iconUrl: require("../../../assets/img/canvasMenu/saveImg.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/savePictureWhite.svg"),
            type: "mind",
            show: true,
          },
          // {
          //   id: 7,
          //   name: strings.Mind_Usinghelp_9, //插入图片
          //   iconUrl: require("../../../assets/img/canvasMenu/canvasMenu6.svg"),
          //   type: "mind",
          //   show: true,
          //   uniqueID: "InsertPicture",
          //   needMember: false,
          // },
          // {
          //   id: 8,
          //   name: strings.Mind_Edit_Insert_Video,
          //   iconUrl: require("../../../assets/img/canvasMenu/add_video_af.svg"),
          //   type: "mind",
          //   show: true,
          // },
          // {
          //   id: 18,
          //   name: strings.Mind_add_Resource,
          //   iconUrl: require("../../../assets/img/canvasMenu/resourceIcon.svg"),
          //   type: "mind",
          //   show: true,
          // },
          {
            id: 15,
            name: strings.Mind_Edit_Node_Delete_View_Video,
            iconUrl: require("../../../assets/img/canvasMenu/delete_video_af.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/deleteVideoWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 11,
            name: strings.Mind_Edit_Node_Menu_View_Picture,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu6.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/viewPictureWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 18,
            name: strings.Mind_Edit_Node_Menu_Save_Picture,
            iconUrl: require("../../../assets/img/canvasMenu/saveImg.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/savePictureWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 9,
            name: strings.Mind_Edit_Node_Menu_Delete_Picture,
            iconUrl: require("../../../assets/img/canvasMenu/delete_Image_af.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/deleteImageWhite.svg"),
            type: "mind",
            show: true,
          },
          // {
          //   id: 13,
          //   name: strings.Mind_Add_Background_Image, //插入背景图
          //   iconUrl: require("../../../assets/img/canvasMenu/add-bg.svg"),
          //   type: "mind",
          //   show: true,
          // },

          {
            id: 14,
            name: strings.Mind_Delete_Background_Image,
            iconUrl: require("../../../assets/img/canvasMenu/deleteBgImage.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/deleteBgImageWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 16,
            name: strings.Mind_Association_Map,
            iconUrl: require("../../../assets/img/canvasMenu/association_map.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/associationMapWhite.svg"),
            type: "mind",
            needMember: false,
            show: true,
          },
          {
            id: 17,
            name: strings.Mind_Association_Node,//连接线
            iconUrl: require("../../../assets/img/canvasMenu/association_node.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/associationNodeWhite.svg"),
            type: "mind",
            show: true,
          },
        ],
        [
          {
            id: 10,
            name: strings.Mind_Edit_Node_Menu_Copy,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu7.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/menuCopyWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 11,
            name: strings.Mind_Edit_Node_Menu_Paste,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu10_paste.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/menuPasteWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 13,
            name: strings.Mind_Node_Overlay_Paste,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu10_paste.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/menuPasteWhite.svg"),
            type: "mind",
            show: true,
          },
          {
            id: 12,
            name: strings.Mind_Mindmap_Deleted_Forever,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/deletedForeverWhite.svg"),
            type: "mind",
            show: true,
          },
          //  {
          //   id: 19,
          //   name: strings.Mind_Mindmap_Only_Deleted_Forever,
          //   iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
          //   iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/deletedForeverWhite.svg"),
          //   type: "mind",
          //   show: true,
          // },
        ],
      ], // 菜单里面的数据
      showChartDataMenu: [
        [
          {
            id: 1,
            name: strings.Global_Edit,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/canvasMenuEdit.svg"),
            type: "chart",
            show: true,
          },
        ],
        [
          {
            id: 12,
            name: strings.Mind_Mindmap_Deleted_Forever,
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
            iconWhite: require("../../../assets/img/canvasMenu/darkModeIcon/deletedForeverWhite.svg"),
            type: "chart",
            show: true,
          },
        ],
      ],
      strings: strings,
      muneShapeLeft: 160,
      muneShapeTop: 0,
      muneStyleLeft: 160,
      muneStyleTop: 0,
      muneInsertLeft: 160,
      muneInsertTop: 0,
      isShowExplain: false,
      shapeBorderType:{},
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => !state.mapEditIsDardarkMode.darkMode,
    }),
  },
  created() {
    this.insertPicturesIsNeedMembers();
  },
  mounted() {
    this.getMenuLocation();
    this.setDardarkMode();
  },
  components: {
    ComplieChildMenuForm,
    ComplieChildMenuNodeQuickStyle,
    ComplieChildMenuNodeInsertContent,
    ComplieChildMenuNodeOrderContent,
    ComplieChildMenuNodeOpenHidde,
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl"]),

    getMenuLocation() {
      if (this.fullWidth - this.newMenuX > 268) {
        this.muneLeft = 160;
      }
    },

    getString(i) {
      return getString(i);
    },
    outsideCloseIconCompoenntAccout() {
      let obj = { name: "colse" };
      this.$emit("checkMenuElement", obj);
    },
    checkMenuElement(name) {
      let obj = {};
      obj.name = name;
      this.$emit("checkMenuElement", obj);
    },
    uploadFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        let userImage = res.result;
        this.showCropperImgUrl({ img: userImage, type: "uploadImg" });
      };
      reader.readAsDataURL(file);
      if (file < 1024 * 1024 * 3) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
        );
        return false;
      } else {
        this.showCropperImg(true);
        this.$el.querySelector(".hiddenImgInput").value = null;
      }
    },
    //插入图片是否需要会员
    insertPicturesIsNeedMembers() {
      postBaseGlobalConfig({}, (res) => {
        if (res) {
          this.showElementMenuListData.forEach((item) => {
            let obj = item.find((sitem) => sitem.uniqueID == "InsertPicture");
            if (obj) {
              obj.needMember = !res.uploadImageFree;
            }
          });
          // this.showElementMenuListData[1][3].needMember = !res.uploadImageFree
        }
      });
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        const white = "#fff";
        let boxDom = this.$refs.elementMenuList;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (!dardarkMode.darkMode) {
            boxDom.style.color = "#fff";//dardarkMode.fontColor;
            boxDom.style.background = "#3E3E3E"//dardarkMode.bgColor; 
            document.documentElement.style.setProperty(
              "--rightClickMenuHoverColor",
              "#fff"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuBgHover",
              "#4C4C4C"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuLineColor",
              "#666666"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuBorderColor",
              "#1C1C1C"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuInsetBorderColor",
              "#6e6e6e"
            );
          } else {
            boxDom.style.color = "#000";
            boxDom.style.background = white;
            document.documentElement.style.setProperty(
              "--rightClickMenuHoverColor",
              "#000"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuBgHover",
              "#e0e0e0"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuLineColor",
              "#ddd8d8"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuBorderColor",
              "#ffffff"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuInsetBorderColor",
              "#c9c9c9"
            );
          }
        }
      });
    },
  },
  watch: {
    showElementMenuList(newVal, old) {
      // console.log('改变了菜单状态')
      if(newVal){
        let node = EditMindmapVM.getSelectedNode();
        if(node == null || node == "" || node == undefined){
          return;
        }
        let shapeTypeList = [];
            if(node.value.type == MindElementType.EXPLAIN){
              shapeTypeList = new ShapeTypeImg().explainLayoutStyleList;
            }else{
              shapeTypeList = new ShapeTypeImg().layoutStyleList;
            }
            let shapeTypeListCount = shapeTypeList.length;
            for(var i = 0; i < shapeTypeListCount; i++){
              let shapeType = shapeTypeList[i]
              if(node.value.mindElementShape == shapeType.nodeType){
                this.borderStyleLayout = this.mapEditDarkMode?shapeType.layoutIconWhite:shapeType.layoutIcon;
                this.shapeBorderType = shapeType;
              }
            }
      }else{
        this.shapeBorderType = {};
      }
    },
    hiddenMindButtonArray: {
      handler(newVal) {
        //展示全部按钮        
        for (let j = 0; j < this.showElementMenuListData.length; j++) {
          for (let k = 0; k < this.showElementMenuListData[j].length; k++) {
            this.showElementMenuListData[j][k].show = true;
          }
        }
        if (newVal != null && newVal.length != 0) {
          //隐藏部分按钮
          for (let index = 0; index < newVal.length; index++) {
            for (let j = 0; j < this.showElementMenuListData.length; j++) {              
              for (let k = 0; k < this.showElementMenuListData[j].length; k++) {
                if (this.showElementMenuListData[j][k].name == newVal[index]) {
                  this.showElementMenuListData[j][k].show = false;
                }
                if (this.showElementMenuListData[j][k].id == strings.Mind_Edit_Checkbox_Title) {
                  let node = EditMindmapVM.getSelectedNode();
                  if (node == null || node.value == null || node.value.checkBoxContent == null) {
                    this.showElementMenuListData[j][k].name = strings.Mind_Edit_Checkbox_Title
                  } else {
                    this.showElementMenuListData[j][k].name = strings.Mind_Edit_Remove_Checkbox_Title
                  }
                }
              }
            }
          }
        }
      },
      deep: true,
    },
    newMenuX: {
      handler(newVal) {
        let root = document.getElementById("canvas-father")
        let scrollLeft = root.scrollLeft;
        newVal = newVal - scrollLeft;
        let offsetRightSite = this.fullWidth - newVal;
        if (this.fullWidth - newVal > 428) {
          this.muneShapeLeft = 158;
        } else {
          this.muneShapeLeft = -225;
        }
        if (this.fullWidth - newVal > 358) {
          this.muneStyleLeft = 228;
        } else {
          this.muneStyleLeft = -126;
        }
        let rightAntInsertWidth = 292; //右键菜单和插入菜单的宽度
        if (offsetRightSite > rightAntInsertWidth) {
          this.muneInsertLeft = 228;
        } else {
          this.muneInsertLeft = -60;
        }
      },
      deep: true,
    },
    newMenuY: {
      handler(newVal) {
        let screenHeight = this.fullHeight; //屏幕高度
        this.muneStyleTop = 0;
        this.$nextTick(() => {
          let root = document.getElementById("canvas-father")
          let scrollTop = root.scrollTop;
          newVal = newVal - scrollTop
          let shapeOffsetTop = 118; //形状菜单距离右键菜单顶部的距离
          let shapeMenuHeight = 0; //形状菜单的高度
          let shapeMenuDom = this.$refs.shapeMenuBox;
          if (shapeMenuDom) {
            if (shapeMenuDom[0]) {
              shapeMenuHeight = shapeMenuDom[0].$el.offsetHeight;
            }
          }
          if (screenHeight - (shapeOffsetTop + newVal) >= shapeMenuHeight) {
            this.muneShapeTop = 0;
          } else {
            this.muneShapeTop =
              screenHeight - (shapeOffsetTop + newVal + shapeMenuHeight);
          }
          let insertOffsetTop = 154; //插入菜单距离右键菜单顶部的距离
          let insertMenuHeight = 0; //右键菜单的高度
          let insertMenuDom = this.$refs.insertMenuBox;
          if (insertMenuDom) {
            if (insertMenuDom[0]) {
              insertMenuHeight = insertMenuDom[0].$el.offsetHeight;
            }
          }
         
          if (screenHeight - (insertOffsetTop + newVal) >= insertMenuHeight) {
            this.muneInsertTop = 0;
          } else {
            this.muneInsertTop =
              screenHeight - (insertOffsetTop + newVal + insertMenuHeight) + 0;
          }
        });
      },
      deep: true,
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>

<style lang='less' scoped>
/* 元素菜单列表开始 */
.element-menu-list {
  // width: 150px;
  // height: 300px;
  position: absolute;
  left: 200px;
  top: 300px;
  z-index: 999;
  background-color: #fff;
  user-select: "none";
  box-shadow: 0px 3px 10px rgba(80, 80, 80, 0.16);
  border: 1px solid var(--rightClickMenuBorderColor);
  box-shadow: 0px 0px 0px 1px var(--rightClickMenuInsetBorderColor) inset;
  padding: 5px;
  border-radius: 6px;
  .hiddenImgInput {
    display: none;
  }

  .element-menu-children-list {
    border-bottom: 1px solid var(--rightClickMenuLineColor);
    .each-menu {
      display: flex;
      align-items: center;
      width: 160px;
      height: 28px;
      cursor: pointer;
      position: relative;
      border-radius: 4px;
      .checkMindShape {
        // display: none;
        visibility: hidden;
        position: absolute;
        background-color: #fff;
        border-radius: 6px;
        transition-delay:.2s;
      }

      .each-icon {
        width: 17px;
        height: 17px;
        display: block;
        margin-left: 20px;
      }
      .each-icon-name {
        margin-left: 11px;
        font-size: 13px;
        white-space: nowrap;
        // font-weight: 600;
      }
      .each-icon-triange {
        // width: 12px;
        // height: 12px;
        display: block;
        margin-left: 140px;
        margin-top: 3px;
        position: absolute;
      }
      .need-member {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: var(--rightClickMenuBgHover);
        color: var(--rightClickMenuHoverColor);
      }
      &:hover .checkMindShape {
        // display: block;
        transition-delay:.1s;
        visibility: visible;
        z-index: 999;
      }
    }

    &:last-child {
      border-bottom: transparent;
    }
  }

  .off-chart {
    border-bottom: 1px solid #eee;
  }
}
/* 元素菜单列表结束 */
</style>