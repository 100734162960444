import Point from "../../../viewmodel/core/base/Point";

import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import LineOrientation from "../../../viewmodel/datatype/LineOrientation";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";

import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType"
import LinePath from "../../../viewmodel/core/base/LinePath";
import imageToStyle from "../../../utils/ImageToolkit";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import Config from "../../../core/core/calcule/Config";
import MindElementFullType from "../../../viewmodel/datatype/MindElementFullType";


/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/30
 * Copyright(c) 2020 mindyushu.com
 */

class MindNodeBackgroundView {
    constructor(data, backgroundGTag, clipPathGraph, 
        mindBackgroundImage, mindBackgroundImageChild, mindBackgroundImageClipPathGraph,
        isAnimation, duration, mindTextSizeChangedDelegate, context) {
        this.data = data; //这里是mindElementData
        this.fullColor = ""
        this.shadowRadius = new UiUtil().dip2px(4);
        this.shadowTopOffset = new UiUtil().dip2px(1);
        this.backgroundGTag = backgroundGTag;
        this.backgroundDom;
        this.backgroundLine;
        this.backgroundLine2;
        this.orderElement = null;
        this.pathDomList = [];
        this.clipPathGraph = clipPathGraph;
        this.mindBackgroundImage = mindBackgroundImage;
        this.mindBackgroundImageChild = mindBackgroundImageChild;
        this.mindBackgroundImageClipPathGraph = mindBackgroundImageClipPathGraph;
        this.isAnimation = isAnimation == undefined || isAnimation == null ? false : isAnimation;
        this.duration = duration == undefined || duration == null ? 1 : duration;
        this.animationTimes = 1
        this.UiUtil = new UiUtil();
        this.Util = Util;
        this.mindTextSizeChangedDelegate = mindTextSizeChangedDelegate; //vue的this
        this.context = context; //整个节点的g标签。
        this.defsTag; //defs标签
        this.count = 0;
        this.lastMindElementShape = null;
        this.lastWidth = -1;
        this.lastHeight = -1;
        this.lastLayout = null;
        this.lastBackgroundColor = -99;
        this.lastBackgroundColorAlpha = 1.0
        this.lastBorderColor = -99;
        this.lastBorderWidth = -99;
        this.lastBackgroundUrl = -99;
        this.lastBorderDot = null;
        this.lastBackgroundUrl = -99;
        this.lastBackgroundFullType = -10;
        this.borderThicken = false;
        this.isNodeShadow = false;
        this.onDraw()
    }

    setAnimation(isAnimation, duration, times) {
        this.isAnimation = isAnimation
        this.duration = duration
        this.animationTimes = times
        this.onDraw()
        if (this.animationTimes == 1) {
            this.isAnimation = false
            this.duration = 0
        }
    }


    setData(data) {
        this.data = data;
    }
    /**
    * 重写这个方法
    */

    onDraw() {
        if (this.data == null || this.context == null) {
            return;
        }

        if (this.lastMindElementShape == this.data.mindElementShape && 
            this.lastWidth == this.data.width &&
            this.lastHeight == this.data.height &&
            this.lastLayout == this.data.layout &&
            this.lastBackgroundColor == this.data.backgroundColor &&
            this.lastBackgroundColorAlpha == this.data.lastBackgroundColorAlpha &&
            this.lastBorderColor == this.data.borderColor &&
            this.lastBorderWidth == this.data.borderWidth &&
            this.lastBackgroundUrl == this.data.backgroundUrl &&
            this.lastBorderDot == this.data.borderDot &&
            this.lastBackgroundFullType == this.data.backgroundFullType &&
            this.borderThicken == this.data.borderThicken &&
            this.isNodeShadow == this.data.isShadow) {
            return;
        }
        this.lastMindElementShape = this.data.mindElementShape;
        this.lastWidth = this.data.width;
        this.lastHeight = this.data.height;
        this.lastLayout = this.data.layout;
        this.lastBackgroundColor = this.data.backgroundColor;
        this.lastBackgroundColorAlpha = this.data.backgroundColorAlpha;
        this.lastBorderColor = this.data.borderColor;
        this.lastBorderWidth = this.data.borderWidth;
        this.lastBackgroundUrl = this.data.backgroundUrl;
        this.lastBorderDot = this.data.borderDot
        this.lastBackgroundFullType = this.data.backgroundFullType
        this.borderThicken = this.data.borderThicken
        this.isNodeShadow = this.data.isShadow

        this.backgroundGTag.style.width = this.data.width + 'px';
        this.backgroundGTag.style.height = this.data.height + 'px';

        //画线之前先清空一线之前的画横线的样式。
        if (this.backgroundLine != null) {
            this.backgroundLine.parentNode.removeChild(this.backgroundLine);
            this.backgroundLine = null
        }
        if (this.backgroundLine2 != null) {
            this.backgroundLine2.parentNode.removeChild(this.backgroundLine2);
            this.backgroundLine2 = null
        }
        if (this.backgroundDom != null) {
            this.backgroundDom.parentNode.removeChild(this.backgroundDom);
            this.backgroundDom = null
        }
        if (this.orderElement != null && this.orderElement.parentNode != null) {
            this.orderElement.parentNode.removeChild(this.orderElement);
            this.orderElement = null
        }
        if (this.mindBackgroundImageClipPathGraph != null) {
            this.mindBackgroundImageClipPathGraph.setAttribute("fill", Colors.getUiColor(Colors.clear));
        }
        if (this.data.isFreeTreeNode && 
            (this.data.mindElementShape == MindElementShapeType.Underline || this.data.mindElementShape == MindElementShapeType.Two_Underline)) {
            return
        }
        if (this.data.layout == NodeLayoutType.LAYOUT_RADIATE || 
            this.data.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            this.data.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
            return
        }
        this.fullColor = (this.getBackgorundColor());

        this.backgroundDom = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.backgroundGTag.appendChild(this.backgroundDom)

        this.backgroundLine = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.backgroundGTag.appendChild(this.backgroundLine)

        this.backgroundLine2 = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.backgroundGTag.appendChild(this.backgroundLine2)

        if (this.pathDomList.length != 0) {
            for (let index = 0; index < this.pathDomList.length; index++) {
                if (this.pathDomList[index] != null) {
                    this.pathDomList[index].parentNode.removeChild(this.pathDomList[index]);
                }
            }
            this.pathDomList = []
        }

        for (let count = 0; count < 3; count++) {
            this.pathDomList[count] = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.backgroundGTag.appendChild(this.pathDomList[count])
        }
        
        if (this.data.isShadow) {
            if (this.data.mindElementShape == MindElementShapeType.Underline || this.data.mindElementShape == MindElementShapeType.Two_Underline) {
                this.backgroundGTag.setAttribute('filter', 'url(#linemapLineShadow)');
            } else {
                this.backgroundGTag.setAttribute('filter', 'url(#editmapNodeShadow)');
            }
        } else {
            this.backgroundGTag.setAttribute('filter', '');
        }

        if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            this.setCorner();
        } else {
            switch (this.data.mindElementShape) {
                case MindElementShapeType.Corner:
                    this.setCorner();
                    break;
                case MindElementShapeType.Card:
                    this.setCard();
                    break;
                case MindElementShapeType.CornerShadow:
                    this.setCornerShadow();
                    break;
                case MindElementShapeType.Corner_Right: //新添加
                    this.setCornerRight();
                    break;
                case MindElementShapeType.Semicircle:
                    this.setSemicircle();
                    break;
                case MindElementShapeType.Diamond:
                    this.setDiamond();
                    break;
                case MindElementShapeType.Cloud:
                    this.setCloud();
                    break;
                case MindElementShapeType.Underline:
                    this.setUnderline();
                    break;
                case MindElementShapeType.Two_Underline:
                    this.setTwoUnderline();
                    break;
                case MindElementShapeType.Circular:
                    this.setCircular();
                    break;
                case MindElementShapeType.Book:
                    this.setBook()    
                    break;
                case MindElementShapeType.Circular_Right_Top:  //新添加
                    this.setCircularRightTop();
                    break;
                case MindElementShapeType.Ring: //新添加
                    this.setRing();
                    break;
                case MindElementShapeType.Ring2: //新添加
                    this.setRing2();
                    break;
                case MindElementShapeType.Ring2_UP:  //新添加
                    this.setRing2Up();
                    break;
                case MindElementShapeType.Ring3: //新添加
                    this.setRing3();
                    break;
                case MindElementShapeType.Hexagon:
                    this.setHexagon();
                    break;
                case MindElementShapeType.Left_Right_Three_Side:
                    this.setLeftAndRightThreeSide();
                    break;
                case MindElementShapeType.Left_Straight_Right_Arrow:
                    this.setLeftStraightRightArrow();
                    break;
                case MindElementShapeType.Right_Straight_Left_Arrow:
                    this.setRightStraightLeftArrow();
                    break;
                case MindElementShapeType.LeftArrow:
                    this.setLeftArrow();
                    break;
                case MindElementShapeType.RightArrow:
                    this.setRightArrow();
                    break;
    
                case MindElementShapeType.LeftArrow2: //新添加
                    this.setLeftArrow2();
                    break;
                case MindElementShapeType.RightArrow2: //新添加
                    this.setRightArrow2();
                    break;
                case MindElementShapeType.Three_Circular_Cloud: //新添加
                    this.setThreeCircularCloud();
                    break;
                case MindElementShapeType.Ellipse: //新添加
                    this.setEllipse();
                    break;
                case MindElementShapeType.Ellipse_3D: //新添加
                    this.setEllipse3D();
                    break;
                case MindElementShapeType.Cube: //新添加
                    this.setCube();
                    break;
                case MindElementShapeType.Form: //新添加
                    this.setForm();
                    break;
                case MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle:
                    this.setLeftRightCornerTopBottomSemicircle();
                    break;
                case MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner:
                    this.setLeftRightCornerTopBottomCorner();
                    break;
                case MindElementShapeType.Triangle:
                    this.setTriangle();
                    break;
                case MindElementShapeType.InvertedTriangle:
                    this.setInvertedTriangle();
                    break;
                case MindElementShapeType.RightTriangle:
                    this.setRightTriangle();
                    break;
                case MindElementShapeType.LeftTriangle:
                    this.setLeftTriangle();
                    break;
                case MindElementShapeType.CircularAndRight:
                    this.setCircularAndRight();
                    break;
                case MindElementShapeType.Oblique_corner:
                    this.setObliqueCorner();//60度夹角矩形
                    break;
                case MindElementShapeType.Large_braces:
                    this.setLargeBraces();//大括号
                    break;
                case MindElementShapeType.Middle_brackets:
                    this.setMiddleBraces();//中括号
                    break;
                case MindElementShapeType.Brackets:
                    this.setBraces();//括弧（）
                    break;
                case MindElementShapeType.Flower_Brackets:
                    this.flowerBrackets();//括弧（）
                    break;                    
                case MindElementShapeType.Angle_braces:
                    this.setAngleBraces();//夹角括号
                    break;
                case MindElementShapeType.Heart:
                    this.setHeartShape();//心
                    break;
                case MindElementShapeType.Gemstone:
                    this.setGemstoneShape();//宝石
                    break;
                case MindElementShapeType.Star:
                    this.setStarShape();//五角星
                    break;
                case MindElementShapeType.Octagonal:
                    this.setOctagonalShape();//八边形
                    break;
                case MindElementShapeType.Regular_hexagon:
                    this.setRegularHexagonShape();//正六边形
                    break;
                case MindElementShapeType.Shield:
                    this.setShieldShape();//盾牌
                    break;
                default:
                    this.setCorner();
            }
        }
        
        if (this.backgroundLine != null && this.backgroundLine.parentNode != null) {
            if (!this.isValidPathDom(this.backgroundLine)) {
                this.backgroundLine.parentNode.removeChild(this.backgroundLine);
                this.backgroundLine = null
            }
        } else {
            this.backgroundLine = null
        }
        if (this.backgroundLine2 != null && this.backgroundLine2.parentNode != null) {
            if (!this.isValidPathDom(this.backgroundLine2)) {
                this.backgroundLine2.parentNode.removeChild(this.backgroundLine2);
                this.backgroundLine2 = null
            }
        } else {
            this.backgroundLine2 = null
        }
        if (this.backgroundDom != null && this.backgroundDom.parentNode != null) {
            if (!this.isValidPathDom(this.backgroundDom)) {
                this.backgroundDom.parentNode.removeChild(this.backgroundDom);
                this.backgroundDom = null
            }
            
        }
        if (this.orderElement != null && this.orderElement.parentNode != null) {
            if (!this.isValidPathDom(this.orderElement)) {
                this.orderElement.parentNode.removeChild(this.orderElement);
                this.orderElement = null
            }
        } else {
            this.orderElement = null
        }
        if (this.pathDomList.length != 0) {
            let list = []
            for (let index = 0; index < this.pathDomList.length; index++) {
                if (this.pathDomList[index] != null) {
                    let path = this.pathDomList[index].getAttribute("d")
                    if (path == null || path == "") {
                        this.pathDomList[index].parentNode.removeChild(this.pathDomList[index]);
                    } else {
                        list.push(this.pathDomList[index])
                    }
                }
            }
            this.pathDomList = list
        }
    }

    isValidPathDom(dom) {
        if (dom == null) {
            return false
        }
        let path = dom.getAttribute("d")
        if (path == null || path == "") {
            return false
        }

        let fill = dom.getAttribute("fill")
        let strokeWidth = dom.getAttribute("stroke-width")
        let stroke = dom.getAttribute("stroke")
        if (!this.data.isShadow && (fill == null || fill == "" || fill == Colors.getUiColor(Colors.clear))) {
            if (stroke == null || stroke == "" || stroke == Colors.getUiColor(Colors.clear) ||
                strokeWidth == null || strokeWidth == "" || strokeWidth == "0" || strokeWidth == 0) {
                return false
            }
        }
        return true
    }

    createdBackgroundImage() {
        if (this.mindBackgroundImage == null) {
            this.mindBackgroundImage = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject");
            this.context.insertBefore(this.mindBackgroundImage, this.backgroundGTag)
            this.mindBackgroundImageChild = document.createElement('div');
            this.mindBackgroundImage.appendChild(this.mindBackgroundImageChild);
            this.mindBackgroundImage.setAttribute("clip-path", "url(#backgrundClip" + this.data.id + ")");
        }
    }

    removeBackgroundImage() {
        if (this.mindBackgroundImageChild != null && this.mindBackgroundImageChild.parentNode != null) {
            this.mindBackgroundImageChild.parentNode.removeChild(this.mindBackgroundImageChild)
            this.mindBackgroundImageChild = null
        }
        if (this.mindBackgroundImage != null && this.mindBackgroundImage.parentNode != null) {
            this.mindBackgroundImage.parentNode.removeChild(this.mindBackgroundImage)
            this.mindBackgroundImage = null
        }
    }

    isAddBackgroundImage() {
        let bgUrl = this.data.backgroundUrl
        if (this.data.type != MindElementType.BAOWEI_VIEW &&
            this.data.type != MindElementType.CONTENT_GENERALIZATION &&
            this.data.type != MindElementType.NODE_CONNECT_LINE &&
            this.data.type != MindElementType.LEFTBRACELINE && bgUrl != null && bgUrl != "") {
                return true
        }
        return false
    }

    drawBgImage() {
        let bgUrl = this.data.backgroundUrl
        let isAddBackgroundImage = this.isAddBackgroundImage();
        if (isAddBackgroundImage) {
            this.createdBackgroundImage()
        } else {
            this.removeBackgroundImage()
            return
        }
        if (this.mindBackgroundImage != null) {
            this.mindBackgroundImage.setAttribute('width', this.data.width);
            this.mindBackgroundImage.setAttribute('height', this.data.height);
        }
        if (this.mindBackgroundImageChild != null) {
            this.mindBackgroundImageChild.style.width = (this.data.type == MindElementType.EXPLAIN ? this.data.width + this.data.lineContent.width : this.data.width) + 'px';
            this.mindBackgroundImageChild.style.height = this.data.height + 'px';
        }
        let srcHttp = httpImageToPrefix(bgUrl)
        new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
            this.mindBackgroundImageChild.style.backgroundImage = 'url(' + imageBase64 + ')';
            this.mindBackgroundImageChild.style.backgroundPosition = 'center center';
            this.mindBackgroundImageChild.style.backgroundRepeat = 'no-repeat';
            this.mindBackgroundImageChild.style.backgroundSize = '100% 100%';
        })
    }

    setCircularRightTop() {
        if (this.data == null) {
            return;
        }
        let r = this.data.width >> 1;
        let path = new LinePath();
        path.moveTo(this.data.width, this.data.height / 2);
        path.circle(r, r, 0, 1, 1, this.data.width / 2, 0);
        path.lineTo(this.data.width, 0);
        path.lineTo(this.data.width, this.data.height / 2);
        this.renderCanvas(path);
    }

    setCircular() {     //isShadow函数是什么？
        if (this.data == null) {
            return;
        }
        let linePath = new LinePath();
        let cornerRadius = this.data.width / 2
        linePath.moveTo(0, this.data.height / 2);
        linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, this.data.height / 2);    //这里都是小圆
        linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, this.data.height / 2);    //这里都是小圆
        this.renderCanvas(linePath)
    }

    setEllipse() {
        if (this.data == null) {
            return;
        }

        let path = new LinePath();
        let radiusX = this.data.width / 2;
        let radiusY = this.data.height / 2;

        path.moveTo(this.data.width, this.data.height / 2);
        if (this.data.type == MindElementType.EXPLAIN && this.data.lineContent != null) {
            if (this.data.lineContent.orientation == LineOrientation.BOTTOM) {  
                let arrowHeight = this.data.lineContent.height;
                let endPoint1 = Util.getEllipsePoint(radiusX,radiusY,new Point(radiusX,radiusY),80);
                // radiusX * Math.cos(Math.PI * 2 * 80 / 360) + radiusX
                // let y = radiusY * Math.sin(Math.PI * 2 * 80 / 360) + radiusY
                // Util.getCircleCenter()
                path.circle(radiusX, radiusY, 0, 0, 1, endPoint1.x,endPoint1.y);  
                path.lineTo(this.data.width / 2, this.data.height + arrowHeight);
                
                // x = radiusX * Math.cos(Math.PI * 2 * 100 / 360) + radiusX
                // y = radiusY * Math.sin(Math.PI * 2 * 100 / 360) + radiusY
                let endPoint2 = Util.getEllipsePoint(radiusX,radiusY,new Point(radiusX,radiusY),100);
                path.lineTo(endPoint2.x, endPoint2.y);
                path.circle(radiusX, radiusY, 0, 1, 1, this.data.width, this.data.height / 2);  
            }
        } else {
            path.circle(radiusX, radiusY, 0, 0, 1, 0, this.data.height / 2);    //这里都是小圆
            path.circle(radiusX, radiusY, 0, 0, 1, this.data.width, this.data.height / 2);
        }

        this.renderCanvas(path);

    }

    setForm() {
        if (this.data == null) {
            return;
        }

        let radius = this.data.width / 4;
        let formHeight = this.data.isEmpty() ? this.UiUtil.dip2px(5) : Config.getFormHeight(this.data, this.data.width, this.data.height);
        let space = this.UiUtil.dip2px(6);
        let leftBottomPoint = new CGPoint(0, this.data.height - formHeight / 2);
        let lefftMiddlePoint = new CGPoint(radius, this.data.height);
        let middlePoint = new CGPoint(radius * 2, this.data.height - formHeight / 2);
        let rightBottomPoint = new CGPoint(radius * 4, this.data.height - formHeight / 2);
        let rightMiddlePoint = new CGPoint(radius * 3, this.data.height - formHeight);

        let leftCircleCenter = this.Util.getCircleCenter(leftBottomPoint, lefftMiddlePoint, middlePoint);
        let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, leftBottomPoint);
        let leftStartAngle = this.Util.getCircleDegreesInPoint(leftCircleCenter, leftBottomPoint);
        let leftEndAngle = this.Util.getCircleDegreesInPoint(leftCircleCenter, middlePoint);


        let rightCircleCenter = this.Util.getCircleCenter(middlePoint, rightMiddlePoint, rightBottomPoint);
        let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, middlePoint);
        let rightStartAngle = this.Util.getCircleDegreesInPoint(rightCircleCenter, rightBottomPoint);
        let rightEndAngle = this.Util.getCircleDegreesInPoint(rightCircleCenter, middlePoint);

        let topPath = new LinePath();
        topPath.moveTo(0, space);
        topPath.lineTo(leftBottomPoint.x, leftBottomPoint.y);
        topPath.circle(leftCircleRadius, leftCircleRadius, 0, 0, 0, middlePoint.x, middlePoint.y);
        topPath.circle(rightCircleRadius, rightCircleRadius, 0, 0, 1, rightBottomPoint.x, rightBottomPoint.y);

        topPath.lineTo(this.data.width, 0);
        topPath.lineTo(0, 0);
        topPath.lineTo(0, space);
        this.renderCanvas(topPath);
    }


    setCube() {
        if (this.data == null) {
            return;
        }
        let topHeight = this.data.isEmpty() ? this.UiUtil.dip2px(4) : Config.getCube3DHeight(this.data, this.data.width, this.data.height);
        let space = this.UiUtil.dip2px(6);
        let topPath = new LinePath();
        topPath.moveTo(space, topHeight);
        topPath.lineTo(0, topHeight);
        topPath.lineTo(topHeight, 0);
        topPath.lineTo(this.data.width, 0);
        topPath.lineTo(this.data.width - topHeight, topHeight);
        topPath.lineTo(space, topHeight);

        let bottomPath = new LinePath();
        bottomPath.moveTo(space, topHeight);
        bottomPath.lineTo(0, topHeight);
        bottomPath.lineTo(0, this.data.height);
        bottomPath.lineTo(this.data.width - topHeight, this.data.height);
        bottomPath.lineTo(this.data.width - topHeight, topHeight);
        bottomPath.lineTo(space, topHeight);

        let rightPath = new LinePath();
        rightPath.moveTo(this.data.width - topHeight, topHeight + space);
        rightPath.lineTo(this.data.width - topHeight, topHeight);
        rightPath.lineTo(this.data.width, 0);
        rightPath.lineTo(this.data.width, this.data.height - topHeight);
        rightPath.lineTo(this.data.width - topHeight, this.data.height);
        rightPath.lineTo(this.data.width - topHeight, topHeight + space);


        let topColor;
        let rightColor;
        let bottomColor;
        if (!Colors.isClear(this.data.backgroundColor)) {
            topColor = Colors.getNumberToRgbStringDark(this.data.backgroundColor, -0.15);

            rightColor = Colors.getNumberToRgbStringDark(this.data.backgroundColor);

            bottomColor = this.getBackgorundColor();
        }
        this.renderCanvasByView(this.backgroundLine, topPath, topColor);
        this.renderCanvasByView(this.backgroundDom, rightPath, rightColor);
        this.renderCanvasByView(this.pathDomList[0], bottomPath, bottomColor);

    }


    setEllipse3D() {
        if (this.data == null) {
            return;
        }

        let ellipseHeight = this.data.isEmpty() ? this.UiUtil.dip2px(8) : Config.getEllipse3DHeight(this.data, this.data.width, this.data.height);
        let radiusX = this.data.width / 2;
        let radiusY = ellipseHeight / 2;

        let topPath = new LinePath();
        topPath.moveTo(0, radiusY);
        topPath.circle(radiusX, radiusY, 0, 0, 1, this.data.width, radiusY)
        topPath.circle(radiusX, radiusY, 0, 0, 1, 0, radiusY)

        let bottomBorderPath = new LinePath();

        bottomBorderPath.moveTo(0, ellipseHeight / 2);
        bottomBorderPath.circle(radiusX, radiusY, 0, 0, 0, this.data.width, radiusY)
        bottomBorderPath.lineTo(this.data.width, this.data.height - radiusY)
        bottomBorderPath.circle(radiusX, radiusY, 0, 0, 1, 0, this.data.height - radiusY);
        bottomBorderPath.lineTo(0, radiusY);

        let backgroundColor;
        let backgroundColorLight;
        if (!Colors.isClear(this.data.backgroundColor)) {
            backgroundColorLight = Colors.getNumberToRgbStringDark(this.data.backgroundColor, -0.15);
            backgroundColor = this.getBackgorundColor();
        }


        this.renderCanvasByView(this.backgroundDom, topPath, backgroundColorLight)

        this.renderCanvasByView(this.backgroundLine, bottomBorderPath, backgroundColor)
    }

    setRing() {
        if (this.data == null) {
            return;
        }

        let r = this.data.width >> 1;
        let backgroundPath = new LinePath();
        backgroundPath.moveTo(0, r);
        backgroundPath.circle(r, r, 0, 0, 1, this.data.width, r);    //这里都是小圆
        backgroundPath.circle(r, r, 0, 0, 1, 0, r);    //这里都是小圆

        this.renderCanvasByView(this.backgroundDom, backgroundPath, null, 0)
        let linePathSmall = new LinePath();
        let ringSpace = (this.data.isEmpty() ? this.UiUtil.dip2px(2) : Config.Node_Ring_Space)
        let smallR = r - ringSpace;
        linePathSmall.moveTo(0 + ringSpace, r);
        linePathSmall.circle(smallR, smallR, 0, 0, 1, this.data.width - ringSpace, r);    //这里都是小圆
        linePathSmall.circle(smallR, smallR, 0, 0, 1, 0 + ringSpace, r);    //这里都是小圆
        let dottString = this.UiUtil.dip2px(this.data.borderWidth) + ',' + (this.UiUtil.dip2px(2) + this.UiUtil.dip2px(this.data.borderWidth))
        this.renderCanvasByView(this.backgroundLine, linePathSmall, 'rgba(255,255,255,0)', null, dottString)
    }

    setRing2() {
        if (this.data == null) {
            return;
        }
        let r = this.data.width >> 1;

        let angle45X = r + (r * this.Util.cosd(45));
        let angle45Y = r + ((r) * this.Util.sind(45));
        let angle135X = r + ((r) * this.Util.cosd(135));
        let angle135Y = r + ((r) * this.Util.sind(135));
        let bottomX = r;
        let bottomY = Math.sqrt(Math.pow(r, 2) + Math.pow(r, 2)) + r;
        let topX = r;
        let topY = 0;


        let leftPath = new LinePath();
        leftPath.moveTo(angle135X, angle135Y);
        leftPath.circle(r, r, 0, 0, 1, topX, topY);    //这里都是小圆
        leftPath.lineTo(bottomX, bottomY);
        leftPath.lineTo(angle135X, angle135Y);
        let leftPathColor;
        if (Colors.isClear(this.data.borderColor)) {
            leftPathColor = Colors.getUiColorByAlpha(Colors.black45, 0.5);
        } else {
            leftPathColor = Colors.getUiColor(this.data.borderColor);
        }
        this.renderCanvasByView(this.backgroundLine, leftPath, leftPathColor, 0);

        let rightPath = new LinePath();
        rightPath.moveTo(topX, topY);
        rightPath.circle(r, r, 0, 0, 1, angle45X, angle45Y);    //这里都是小圆
        rightPath.lineTo(bottomX, bottomY);
        rightPath.lineTo(topX, topY);
        let rightPathColor;
        if (Colors.isClear(this.data.borderColor)) {
            rightPathColor = Colors.getUiColorByAlpha(Colors.black48, 0.7);
        } else {
            rightPathColor = Colors.getUiColor(this.data.borderColor);//Colors.getNumberToRgbStringDark(this.data.borderColor);
        }
        this.renderCanvasByView(this.backgroundDom, rightPath, rightPathColor, 0);


        let fullColor;
        if (Colors.isClear(this.data.backgroundColor)) {
            fullColor = (Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor));
        } else {
            fullColor = (this.getBackgorundColor());
        }
        let space = (this.data.isEmpty() ? this.UiUtil.dip2px(2) : Config.Node_Ring_Space);
        let smallR = r - space;
        let path = new LinePath();
        path.moveTo(r, space);
        path.circle(smallR, smallR, 0, 0, 1, r, space + 2 * smallR);    //这里都是小圆
        path.circle(smallR, smallR, 0, 0, 1, r, space);
        this.renderCanvasByView(this.pathDomList[0], path, fullColor, 0);

    }

    setRing2Up() {
        if (this.data == null) {
            return;
        }

        let width = this.data.width;
        let r = this.data.width >> 1;
        let height = this.data.isEmpty() ? Math.sqrt(Math.pow(r, 2) + Math.pow(r, 2)) + r : this.data.height;

        let centerX = r;
        let centerY = height - r;
        let angle225X = centerX + ((r) * this.Util.cosd(225));
        let angle225Y = centerY + ((r) * this.Util.sind(225));
        let angle315X = centerX + ((r) * this.Util.cosd(315));
        let angle315Y = centerY + ((r) * this.Util.sind(315));

        let bottomX = r;
        let bottomY = height;
        let topX = r;
        let topY = 0;

        let leftPath = new LinePath();
        leftPath.moveTo(angle225X, angle225Y);
        leftPath.circle(r, r, 0, 0, 0, bottomX, bottomY)
        leftPath.lineTo(topX, topY);
        leftPath.lineTo(angle225X, angle225Y);
        let leftPathColor;
        if (Colors.isClear(this.data.borderColor)) {
            leftPathColor = Colors.getUiColorByAlpha(Colors.black45, 0.5);
        } else {
            leftPathColor = Colors.getUiColor(this.data.borderColor);
        }
        this.renderCanvasByView(this.backgroundLine, leftPath, leftPathColor, 0);

        let rightPath = new LinePath();
        rightPath.moveTo(bottomX, bottomY);
        rightPath.circle(r, r, 0, 0, 0, angle315X, angle315Y);
        rightPath.lineTo(topX, topY)
        rightPath.lineTo(bottomX, bottomY);
        let rightPathColor;
        if (Colors.isClear(this.data.borderColor)) {
            rightPathColor = Colors.getUiColorByAlpha(Colors.black45, 0.7);
        } else {
            rightPathColor = Colors.getNumberToRgbStringDark(this.data.borderColor);
        }
        this.renderCanvasByView(this.backgroundDom, rightPath, rightPathColor, 0);
        let fullColor;
        if (Colors.isClear(this.data.backgroundColor)) {
            fullColor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
        } else {
            fullColor = this.getBackgorundColor();
        }


        let space = (this.data.isEmpty() ? this.UiUtil.dip2px(2) : Config.Node_Ring_Space);
        let smallR = r - space;
        let path = new LinePath();
        path.moveTo(r, bottomY - space);
        path.circle(smallR, smallR, 0, 0, 1, r, bottomY - space - 2 * smallR);    //这里都是小圆
        path.circle(smallR, smallR, 0, 0, 1, r, bottomY - space);
        this.renderCanvasByView(this.pathDomList[0], path, fullColor, 0);
    }


    setRing3() {
        if (this.data == null || this.context == null) {
            return;
        }
        if (this.setRing3BackgroundLinearGradient == null) {
            this.setRing3BackgroundLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', "linearGradient");
            this.setRing3BackgroundLinearGradientStartStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");
            this.setRing3BackgroundLinearGradientEndStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");

            this.setRing3SmallCircleGradient = document.createElementNS('http://www.w3.org/2000/svg', "linearGradient");
            this.setRing3SmallCircleGradientStartStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");
            this.setRing3SmallCircleGradientEndStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");
        }

        if (this.defsTag == null && this.context != null) {
            this.defsTag = document.createElementNS('http://www.w3.org/2000/svg', "defs");
            this.context.appendChild(this.defsTag);
        }

        this.defsTag.appendChild(this.setRing3BackgroundLinearGradient);
        this.setRing3BackgroundLinearGradient.appendChild(this.setRing3BackgroundLinearGradientStartStop);
        this.setRing3BackgroundLinearGradient.appendChild(this.setRing3BackgroundLinearGradientEndStop);
        this.setRing3BackgroundLinearGradient.setAttribute("id", "setRing3BackgroundLinearGradient" + this.data.id);
        this.setRing3BackgroundLinearGradient.setAttribute("x1", "0%");
        this.setRing3BackgroundLinearGradient.setAttribute("y1", "0%");
        this.setRing3BackgroundLinearGradient.setAttribute("x2", "100%");
        this.setRing3BackgroundLinearGradient.setAttribute("y2", "100%");
        this.setRing3BackgroundLinearGradientStartStop.setAttribute('offset', '0%');
        this.setRing3BackgroundLinearGradientEndStop.setAttribute('offset', '100%');


        this.defsTag.appendChild(this.setRing3SmallCircleGradient);
        this.setRing3SmallCircleGradient.appendChild(this.setRing3SmallCircleGradientStartStop);
        this.setRing3SmallCircleGradient.appendChild(this.setRing3SmallCircleGradientEndStop);
        this.setRing3SmallCircleGradient.setAttribute("id", "setRing3SmallCircleGradient" + this.data.id);
        this.setRing3SmallCircleGradient.setAttribute("x1", "0%");
        this.setRing3SmallCircleGradient.setAttribute("y1", "0%");
        this.setRing3SmallCircleGradient.setAttribute("x2", "100%");
        this.setRing3SmallCircleGradient.setAttribute("y2", "100%");
        this.setRing3SmallCircleGradientStartStop.setAttribute('offset', '0%');
        this.setRing3SmallCircleGradientEndStop.setAttribute('offset', '100%');


        let r = this.data.width >> 1;
        let ringSpace = this.data.isEmpty() ? this.UiUtil.dip2px(2) : Config.Node_Ring_Space + this.data.borderWidth * 2;
        let startColor;
        let endColor;
        if (Colors.isClear(this.data.borderColor)) {
            startColor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
            endColor = Colors.getNumberToRgbDark(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor, 0.2);
        } else if (this.data.isEmpty()) {
            startColor = Colors.getUiColor(Colors.getUiColorByAlpha(Colors.white, 0.8));
            endColor = Colors.getUiColorByAlpha(Colors.getNumberToRgbNumberDark(Colors.white, 0.2), 0.8);
        } else {
            startColor = Colors.getUiColor(this.data.borderColor);
            endColor = Colors.getNumberToRgbStringDark(this.data.borderColor, 0.2);
        }

        this.setRing3BackgroundLinearGradientStartStop.setAttribute('stop-color', startColor);
        this.setRing3BackgroundLinearGradientEndStop.setAttribute('stop-color', endColor);

        let linePath = new LinePath();
        linePath.moveTo(0, r);
        linePath.circle(r, r, 0, 0, 1, this.data.width, r);    //这里都是小圆
        linePath.circle(r, r, 0, 0, 1, 0, r);    //这里都是小圆
        this.renderCanvas(linePath, "url(#" + "setRing3BackgroundLinearGradient" + this.data.id + ")");

        let smallStartColor;
        let smallEndColor;
        if (!Colors.isClear(this.data.backgroundColor)) {
            smallStartColor = Colors.getNumberToRgbStringDark(this.data.backgroundColor, 0.2);
            smallEndColor = this.getBackgorundColor();
        } else if (this.data.isEmpty()) {
            smallStartColor = Colors.getUiColorByAlpha(Colors.getNumberToRgbNumberDark(Colors.white, 0.2), 0.8);
            smallEndColor = Colors.getUiColorByAlpha(Colors.white, 0.8);
        } else {
            smallStartColor = 'rgba(255,255,255,0)';
            smallEndColor = 'rgba(255,255,255,0)';
        }


        this.setRing3SmallCircleGradientStartStop.setAttribute('stop-color', smallStartColor);
        this.setRing3SmallCircleGradientEndStop.setAttribute('stop-color', smallEndColor);

        let linePathSmall = new LinePath();
        let smallR = r - ringSpace;
        linePathSmall.moveTo(0 + ringSpace, r);
        linePathSmall.circle(smallR, smallR, 0, 0, 1, this.data.width - ringSpace, r);    //这里都是小圆
        linePathSmall.circle(smallR, smallR, 0, 0, 1, 0 + ringSpace, r);    //这里都是小圆
        this.renderCanvasByView(this.backgroundLine, linePathSmall, "url(#" + "setRing3SmallCircleGradient" + this.data.id + ")")

    }

    //自己添加的一个函数
    renderCanvas(path, fill) {
        this.renderCanvasByView(this.backgroundDom, path, fill)
    }

    renderCanvasByView(documentView, path, fill, strokeWidth, strokeDasharray, strokeLinejoin, strokeColor, linecap) {  //dom对象， 绘制的形状， 填充色， 边框宽度， 边框的虚线样式, strokeLinejoin转角样式
        documentView.setAttribute("d", path.getLine())
        //设置线条颜色
        if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            this.data.borderWidth == 0) {
            documentView.setAttribute("stroke", Colors.getUiColor(Colors.clear))
        } else {
            if (strokeColor == null) {
                documentView.setAttribute("stroke", Colors.getUiColor(this.data.borderColor))
            } else {
                documentView.setAttribute("stroke", strokeColor)
            }
        }

        if (strokeWidth != null) {  //自定义线条宽度
            documentView.setAttribute("stroke-width", strokeWidth)
        } else {
            if (this.data.borderWidth != undefined && this.data.borderWidth != undefined != null && this.data.borderWidth != 0) {
                documentView.setAttribute("stroke-width", this.data.borderWidth)
            }
            if (this.data.mindElementShape == MindElementShapeType.Underline || this.data.mindElementShape == MindElementShapeType.Two_Underline || this.data.mindElementShape == MindElementShapeType.Ring3) {
                documentView.setAttribute("stroke-width", 0)
            }
        }
        if (linecap != null) {
            documentView.setAttribute("stroke-linecap", linecap)
        } else if (this.data.mindElementShape == MindElementShapeType.CornerShadow) {
            documentView.setAttribute("stroke-linecap", "square")
        } else {
            documentView.setAttribute("stroke-linecap", "round")
        }
        
        
        if (strokeLinejoin != null) {
            documentView.setAttribute("stroke-linejoin", strokeLinejoin)
        } else {
            if (this.data.mindElementShape == MindElementShapeType.CornerShadow) {
                documentView.setAttribute("stroke-linejoin", "miter-clip")
            } else {
                documentView.setAttribute("stroke-linejoin", "round")
            }

            // documentView.setAttribute("stroke-linejoin", "round")
        }

        if (this.isAnimation) {
            documentView.style.strokeDashoffset = documentView.getTotalLength()
            documentView.style.strokeDasharray = documentView.getTotalLength()
            documentView.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'

        } else {
            if (strokeDasharray != null) {  //自定义边框虚线样式
                documentView.setAttribute("stroke-dasharray", strokeDasharray)
            } else {
                this.setLineDot(documentView, path)
            }
        }
        if (fill != null) {  //自定义背景填充色
            documentView.setAttribute("fill", fill);
        } else {
            documentView.setAttribute("fill", this.getBackgorundColor());
        }

        if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            this.data.borderWidth == 0) {
            documentView.setAttribute("stroke", Colors.getUiColor(Colors.clear))
        } else {
            if (strokeColor == null) {
                documentView.setAttribute("stroke", Colors.getUiColor(this.data.borderColor))
            } else {
                documentView.setAttribute("stroke", strokeColor)
            }
        }

        if (strokeWidth != null) {  //自定义线条宽度
            documentView.setAttribute("stroke-width", strokeWidth)
        } else {
            if (this.data.borderWidth != undefined && this.data.borderWidth != undefined != null && this.data.borderWidth != 0) {
                documentView.setAttribute("stroke-width", this.data.borderWidth)
            }
            if (this.data.mindElementShape == MindElementShapeType.Underline || this.data.mindElementShape == MindElementShapeType.Two_Underline || this.data.mindElementShape == MindElementShapeType.Ring3) {
                documentView.setAttribute("stroke-width", 0)
            }
        }
        this.setBackgorundFullShape();
        //设置隐藏。
        this.renderObj({ d: path, dom: this.clipPathGraph});
        //设置背景图
        this.drawBgImage()
    }

    setBackgorundFullShape() {
        if (this.data.backgroundFullType == MindElementFullType.FULL) {
            this.mindBackgroundImageClipPathGraph.setAttribute("fill", Colors.getUiColor(Colors.clear));
            return
        }
        if (this.data.backgroundFullType == MindElementFullType.Gradient || this.data.backgroundFullType == MindElementFullType.GradientLR) {
            this.mindBackgroundImageClipPathGraph.setAttribute("d", this.getRectPath().getLine())
            
            if (this.setBackgroundFullShapeLinearGradient == null) {
                this.setBackgroundFullShapeLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', "linearGradient");
                this.setBackgroundFullShapeLinearGradientStartStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");
                this.setBackgroundFullShapeLinearGradientEndStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");
            }
            if (this.defsTag == null && this.context != null) {
                this.defsTag = document.createElementNS('http://www.w3.org/2000/svg', "defs");
                this.context.appendChild(this.defsTag);
            }
            this.defsTag.appendChild(this.setBackgroundFullShapeLinearGradient);
            this.setBackgroundFullShapeLinearGradient.appendChild(this.setBackgroundFullShapeLinearGradientStartStop);
            this.setBackgroundFullShapeLinearGradient.appendChild(this.setBackgroundFullShapeLinearGradientEndStop);
            this.setBackgroundFullShapeLinearGradient.setAttribute("id", "setBackgroundFullShapeLinearGradient" + this.data.id);
            if (this.data.backgroundFullType == MindElementFullType.Gradient) {
                this.setBackgroundFullShapeLinearGradient.setAttribute("x1", "0%");
                this.setBackgroundFullShapeLinearGradient.setAttribute("y1", "0%");
                this.setBackgroundFullShapeLinearGradient.setAttribute("x2", "0");
                this.setBackgroundFullShapeLinearGradient.setAttribute("y2", "100%");
                this.setBackgroundFullShapeLinearGradientStartStop.setAttribute('offset', '0%');
                this.setBackgroundFullShapeLinearGradientEndStop.setAttribute('offset', '100%');
                
                this.setBackgroundFullShapeLinearGradientStartStop.setAttribute('stop-color', Colors.getNumberToRgbStringDark(this.data.backgroundColor, -0.1));
                this.setBackgroundFullShapeLinearGradientEndStop.setAttribute('stop-color', Colors.getNumberToRgbStringDark(this.data.backgroundColor, 0.2));
            } else {
                this.setBackgroundFullShapeLinearGradient.setAttribute("x1", "0%");
                this.setBackgroundFullShapeLinearGradient.setAttribute("y1", "0%");
                this.setBackgroundFullShapeLinearGradient.setAttribute("x2", "100%");
                this.setBackgroundFullShapeLinearGradient.setAttribute("y2", "0");
                this.setBackgroundFullShapeLinearGradientStartStop.setAttribute('offset', '0%');
                this.setBackgroundFullShapeLinearGradientEndStop.setAttribute('offset', '100%');
                if (this.data.layout == NodeLayoutType.LAYOUT_LEFT ||this.data.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                    this.setBackgroundFullShapeLinearGradientStartStop.setAttribute('stop-color', Colors.getNextRgbaColor(this.data.backgroundColor, 36, this.data.backgroundColorAlpha));
                    this.setBackgroundFullShapeLinearGradientEndStop.setAttribute('stop-color', Colors.getUiColor(this.data.backgroundColor, this.data.backgroundColorAlpha));
                } else {
                    this.setBackgroundFullShapeLinearGradientStartStop.setAttribute('stop-color', Colors.getUiColor(this.data.backgroundColor, this.data.backgroundColorAlpha));
                    this.setBackgroundFullShapeLinearGradientEndStop.setAttribute('stop-color', Colors.getNextRgbaColor(this.data.backgroundColor, 36, this.data.backgroundColorAlpha));
                }
            }
            this.mindBackgroundImageClipPathGraph.setAttribute("fill", "url(#" + "setBackgroundFullShapeLinearGradient" + this.data.id + ")");
        } else {
            let linePath = new LinePath();
            new UiUtil().handDrawRect(linePath,
                this.data.backgroundFullType,
                this.data.width,
                this.data.height,
                this.data.backgroundFullType == MindElementFullType.DrawGrid ? 4 : 7,
                2)

            this.mindBackgroundImageClipPathGraph.setAttribute("d", linePath.getLine())
            this.mindBackgroundImageClipPathGraph.setAttribute("fill", Colors.getUiColor(this.data.backgroundColor, this.data.backgroundColorAlpha));
        }
    }

    getRectPath() {
        let linePath = new LinePath();
        linePath.moveTo(0, 0);
        linePath.lineTo(this.data.width, 0);
        linePath.lineTo(this.data.width, this.data.height)
        linePath.lineTo(0, this.data.height)
        linePath.lineTo(0, 0)
        return linePath;
    }

    getBackgorundColor() {
        if (this.data.mindElementShape == MindElementShapeType.Ring2 ||
            this.data.mindElementShape == MindElementShapeType.Ring2_UP) {
            return Colors.getUiColor(this.data.backgroundColor) //Colors.getUiColorByAlpha(this.data.backgroundColor, 0.9);//
        }
        if (this.data.backgroundFullType != MindElementFullType.FULL || 
            this.data.backgroundUrl != null && this.data.backgroundUrl.length > 0) {
            return Colors.getUiColor(Colors.clear)
        }
        if (this.isShadow() && (this.data.mindElementShape == MindElementShapeType.Underline ||
            this.data.mindElementShape == MindElementShapeType.Two_Underline) &&
            this.data.backgroundColor == this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor) {
            return Colors.getUiColor(Colors.clear)
        }
        if (this.data.mindElementShape == MindElementShapeType.CornerShadow &&
            Colors.isClear(this.data.backgroundColor)) {
            return Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)
        }
        return Colors.getUiColor(this.data.backgroundColor, this.data.backgroundColorAlpha)
    }

    renderCanvas2(documentView, path, fill, stroke) {
        documentView.setAttribute("d", path.getLine())
        if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            this.data.borderWidth == 0) {
            documentView.setAttribute("stroke", Colors.getUiColor(Colors.clear))
            if (stroke == undefined) {
                documentView.setAttribute("stroke", Colors.getUiColor(Colors.clear))
            } else {
                documentView.setAttribute("stroke", fill)
            }
        } else {
            if (stroke == undefined) {
                documentView.setAttribute("stroke", Colors.getUiColor(this.data.borderColor))
            } else {
                documentView.setAttribute("stroke", stroke)
            }
        }
        if (this.data.mindElementShape == MindElementShapeType.CornerShadow ||
            this.data.mindElementShape == MindElementShapeType.Underline ||
            this.data.mindElementShape == MindElementShapeType.Two_Underline) {
            documentView.setAttribute("stroke-linecap", "square")
            documentView.setAttribute("stroke-linejoin", "miter-clip")
        } else {
            documentView.setAttribute("stroke-linecap", "round")
            documentView.setAttribute("stroke-linejoin", "round")
        }
        if (this.data.borderWidth != undefined && this.data.borderWidth != undefined != null && this.data.borderWidth != 0) {
            documentView.setAttribute("stroke-width", this.data.borderWidth)
        }
        if (fill == undefined) {
            documentView.setAttribute("fill", this.getBackgorundColor());
        } else {
            documentView.setAttribute("fill", fill);
        }
        if (this.isAnimation) {
            documentView.style.strokeDashoffset = documentView.getTotalLength()
            documentView.style.strokeDasharray = documentView.getTotalLength()
            documentView.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
        } else {
            this.setLineDot(documentView, path)
        }
        //设置隐藏。
        let linePath = new LinePath();
        linePath.moveTo(0, 0);
        linePath.lineTo(this.data.width, 0);
        linePath.lineTo(this.data.width, this.data.height);
        linePath.lineTo(0, this.data.height);
        linePath.lineTo(0, 0);

        if (this.data.borderWidth != undefined && this.data.borderWidth != undefined != null && this.data.borderWidth != 0) {
            this.renderObj({ d: linePath, dom: this.clipPathGraph ,  strokeWidth: this.data.borderWidth, stroke: Colors.getUiColor(Colors.clear)});
        } else {
            this.renderObj({ d: linePath, dom: this.clipPathGraph});
        }
        
    }

    setLineDot(lineDocument) {
        if (this.data.borderDot && 
            this.data.borderWidth > 0 && 
            !Colors.isClear(this.data.borderColor)) {
            var dottedLineWidth = 15
            if (this.data.borderWidth >= 5) {
                dottedLineWidth = 20
            } else if (this.data.borderWidth >= 4) {
                dottedLineWidth = 18
            } else if (this.data.borderWidth >= 3) {
                dottedLineWidth = 16
            }
            if (lineDocument.getTotalLength == undefined || lineDocument.getTotalLength == null) {
                lineDocument.style.strokeDashoffset = dottedLineWidth
                lineDocument.style.strokeDasharray = dottedLineWidth
            } else {
                let lineLenght = lineDocument.getTotalLength()                
                let dotCount = Math.floor(lineLenght / dottedLineWidth)
                if (dotCount < 2) {
                    dottedLineWidth = this.data.borderWidth * 2
                } else {
                    //MindElementShapeType.Underline 特殊处理为闭合
                    if (this.data.mindElementShape == MindElementShapeType.Large_braces ||
                        this.data.mindElementShape == MindElementShapeType.Angle_braces||
                        this.data.mindElementShape == MindElementShapeType.Middle_brackets||
                        this.data.mindElementShape == MindElementShapeType.Brackets) {
                        if (dotCount % 2 == 0) { //非闭合形状，虚线段数为奇数
                            dotCount += 1
                        }
                    } else {
                        if (dotCount % 2 == 1) { //闭合形状，虚线段数为偶数
                            dotCount += 1
                        }
                    }
                    dottedLineWidth = lineLenght / dotCount
                }
                let strokeDashScale = dottedLineWidth * 0.25 < (this.data.borderWidth + 1) ? 0.35 : 0.25
                lineDocument.style.strokeDashoffset = dottedLineWidth
                lineDocument.style.strokeDasharray = [dottedLineWidth * (1 - strokeDashScale), dottedLineWidth * strokeDashScale]
            }
        } else {
            lineDocument.style.strokeDashoffset = 0
            lineDocument.style.strokeDasharray = 0
            
        }
        lineDocument.style.animation = ''
    }

    renderObj(obj = { dom, d, stroke, strokeWidth }) {  //设置path
        if (obj.dom == null) {
            return
        }
        if (this.data.backgroundFullType == MindElementFullType.FULL && 
            !this.isAddBackgroundImage() && 
            obj.fill == undefined) {
            if (obj.stroke == undefined ||
                 obj.strokeWidth == undefined || 
                 obj.strokeWidth == 0 || 
                 obj.stroke == Colors.getUiColor(Colors.clear)) {
                return
            }
        }
        if (obj.d != undefined) {
            obj.dom.setAttribute("d", obj.d.getLine())
        }
        if (obj.stroke != undefined) {
            obj.dom.setAttribute("stroke", obj.stroke)
        }
        if (obj.strokeWidth != undefined) {
            obj.dom.setAttribute("stroke-width", obj.strokeWidth)
        }
        if (obj.fill != undefined) {
            obj.dom.setAttribute("fill", obj.fill)
        }
    }

    setCorner() {       //画圆角矩形
        if (this.data == null) {
            return;
        }

        var cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0.0 : new UiUtil().dip2px(6);
        if (cornerRadius > 0) {
            switch (this.data.type) {
                case MindElementType.SUBJECT:
                case MindElementType.CONTENT_GENERALIZATION:
                    cornerRadius = new UiUtil().dip2px(6);
                    break;
                case MindElementType.SON_SUBJECT:
                    cornerRadius = new UiUtil().dip2px(5);
                    break;
            }
        }
        let linePath = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != MindElementType.CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {
            
            linePath.moveTo(cornerRadius, 0);
            linePath.lineTo(this.data.width - cornerRadius, 0);
            if (this.data.type == MindElementType.EXPLAIN && this.data.lineContent != null) {
                if (this.data.lineContent.orientation == LineOrientation.BOTTOM) {
                    let arrowWidth = this.data.lineContent.width;
                    let arrowHeight = this.data.lineContent.height;

                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, cornerRadius);    //这里都是小圆
                    linePath.lineTo(this.data.width, cornerRadius);
                    linePath.lineTo(this.data.width, this.data.height - cornerRadius);
                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);
                    
                    let space = -arrowWidth/2;
                    linePath.lineTo(this.data.width / 2 + arrowWidth + space, this.data.height);
                    linePath.lineTo(this.data.width / 2, this.data.height + arrowHeight);
                    linePath.lineTo(this.data.width / 2 + space , this.data.height);
                    linePath.lineTo(cornerRadius, this.data.height);
                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, this.data.height - cornerRadius);
                    linePath.lineTo(0, cornerRadius);
                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
                }
            } else {
                linePath.moveTo(cornerRadius, 0);
                linePath.lineTo(this.data.width - cornerRadius, 0);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, cornerRadius);    //这里都是小圆
                linePath.lineTo(this.data.width, this.data.height - cornerRadius);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);
                linePath.lineTo(cornerRadius, this.data.height);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, this.data.height - cornerRadius);
                linePath.lineTo(0, cornerRadius);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
            }
        }

        this.renderCanvas(linePath)
    }

    setCard() {       //画圆角矩形卡片
        if (this.data == null) {
            return;
        }
        if (!this.data.isCardContent()) {
            this.setCorner()
            return
        }
        var cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0.0 : new UiUtil().dip2px(6);
        if (cornerRadius > 0) {
            switch (this.data.type) {
                case MindElementType.SUBJECT:
                case MindElementType.CONTENT_GENERALIZATION:
                    cornerRadius = new UiUtil().dip2px(6);
                    break;
                case MindElementType.SON_SUBJECT:
                    cornerRadius = new UiUtil().dip2px(5);
                    break;
            }
        }
        let linePath = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != MindElementType.CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {
            
            linePath.moveTo(cornerRadius, 0);
            linePath.lineTo(this.data.width - cornerRadius, 0);
            if (this.data.type == MindElementType.EXPLAIN && this.data.lineContent != null) {
                if (this.data.lineContent.orientation == LineOrientation.BOTTOM) {
                    let arrowWidth = this.data.lineContent.width;
                    let arrowHeight = this.data.lineContent.height;

                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, cornerRadius);    //这里都是小圆
                    linePath.lineTo(this.data.width, cornerRadius);
                    linePath.lineTo(this.data.width, this.data.height - cornerRadius);
                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);
                    
                    let space = -arrowWidth/2;
                    linePath.lineTo(this.data.width / 2 + arrowWidth + space, this.data.height);
                    linePath.lineTo(this.data.width / 2, this.data.height + arrowHeight);
                    linePath.lineTo(this.data.width / 2 + space , this.data.height);
                    linePath.lineTo(cornerRadius, this.data.height);
                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, this.data.height - cornerRadius);
                    linePath.lineTo(0, cornerRadius);
                    linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
                }
            } else {
                let titleLinePath = new LinePath()
                titleLinePath.moveTo(cornerRadius, 0);
                titleLinePath.lineTo(this.data.width - cornerRadius, 0);
                titleLinePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, cornerRadius);    //这里都是小圆
                titleLinePath.lineTo(this.data.width, this.data.titleContent.height);
                titleLinePath.lineTo(0, this.data.titleContent.height);
                titleLinePath.lineTo(0, cornerRadius);
                titleLinePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
                titleLinePath.lineTo(cornerRadius, 0);

                this.renderCanvasByView(this.backgroundLine2, titleLinePath, Colors.getUiColor(this.data.borderColor), 
                                    1, null, null, Colors.getUiColor(Colors.clear))

                linePath.moveTo(cornerRadius, 0);
                linePath.lineTo(this.data.width - cornerRadius, 0);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width, cornerRadius);    //这里都是小圆
                linePath.lineTo(this.data.width, this.data.height - cornerRadius);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);
                linePath.lineTo(cornerRadius, this.data.height);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, this.data.height - cornerRadius);
                linePath.lineTo(0, cornerRadius);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
            }
        }

        this.renderCanvas(linePath)
    }

    setCornerRight() {
        if (this.data == null) {
            return;
        }
        let rightWidth = this.UiUtil.dip2px(10);
        if (this.data.isEmpty()) {
            rightWidth = this.UiUtil.dip2px(8);
        } else if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != MindElementType.CONTENT_GENERALIZATION)) {
            rightWidth = 0;
        } else {
            switch (this.data.type) {
                case MindElementType.MAIN_SUBJECT:
                    rightWidth = this.UiUtil.dip2px(16);
                    break;
                case MindElementType.SUBJECT:
                case MindElementType.CONTENT_GENERALIZATION:
                    rightWidth = this.UiUtil.dip2px(12);
                    break;
                case MindElementType.SON_SUBJECT:
                    rightWidth = this.UiUtil.dip2px(10);
                    break;
                default:
            }
        }
        let path = new LinePath();
        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != MindElementType.CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {
            path.moveTo(0, 0);
            path.lineTo(this.data.width - rightWidth, 0);
            path.lineTo(this.data.width - rightWidth, rightWidth);
            path.lineTo(this.data.width, rightWidth);
            path.lineTo(this.data.width, this.data.height);
            path.lineTo(0, this.data.height);
            path.lineTo(0, 0);
            this.renderCanvasByView(this.backgroundLine, path)

            let borderPath = new LinePath();
            borderPath.moveTo(0, rightWidth);
            borderPath.lineTo(0, 0);
            borderPath.lineTo(this.data.width - rightWidth, 0);
            borderPath.lineTo(this.data.width, rightWidth);
            borderPath.lineTo(this.data.width, rightWidth);
            borderPath.lineTo(this.data.width, this.data.height);
            borderPath.lineTo(0, this.data.height);
            borderPath.lineTo(0, rightWidth);
            let borderWidth = this.UiUtil.dip2px(this.data.borderWidth);
            borderPath.moveTo(this.data.width - rightWidth, borderWidth / 2);
            borderPath.lineTo(this.data.width - rightWidth, rightWidth);
            borderPath.lineTo(this.data.width - borderWidth / 2, rightWidth);
            this.renderCanvasByView(this.backgroundDom, borderPath, "rgba(255,255,255,0)")
        }
    }

    setTriangleShape() {
        let path = new LinePath();
        let moveX = ((this.data.height) / Math.tan(this.Util.toRadians(60)));
        path.moveTo(0, this.data.height);
        if (Math.abs(moveX - this.data.width / 2) < this.UiUtil.dip2px(2)) {
            path.lineTo(this.data.width >> 1, 0);
        } else {
            path.lineTo(moveX, 0);
            path.lineTo(this.data.width - moveX, 0);
        }
        path.lineTo(this.data.width, this.data.height);
        path.lineTo(0, this.data.height);
        this.renderCanvas(path)
    }

    setCornerShadow() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = new UiUtil().dip2px(5);
        let bottomPath = null;

        if (this.data.layout != NodeLayoutType.LAYOUT_FORM && this.data.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL && 
            (this.data.backgroundFullType == MindElementFullType.FULL || Colors.isClear(this.data.backgroundColor))) {
            let angle = 45.0;
            let angleHeight = new UiUtil().dip2px(3) + this.data.borderWidth / 2;
            if (!Colors.isClear(this.data.borderColor) && this.data.borderWidth > 0) {
                angleHeight += new UiUtil().dip2px(this.data.borderWidth);
            }

            let moveX = Math.sin(Util.toRadians(angle)) * angleHeight;
            let moveY = Math.cos(Util.toRadians(angle)) * angleHeight;

            bottomPath = new LinePath();
            // bottomPath.moveTo(cornerRadius + moveX, 0 + moveY);
            // bottomPath.lineTo(this.data.width - cornerRadius + moveX, 0 + moveY);
            // bottomPath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width + moveX, cornerRadius + moveY);
            // bottomPath.lineTo(this.data.width + moveX, this.data.height - cornerRadius + moveY);
            // bottomPath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius + moveX, this.data.height + moveY);
            // bottomPath.lineTo(cornerRadius + moveX, this.data.height + moveY);
            // bottomPath.circle(cornerRadius, cornerRadius, 0, 0, 1, 0 + moveX, this.data.height - cornerRadius + moveY);
            // bottomPath.lineTo(0 + moveX, cornerRadius + moveY);
            // bottomPath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius + moveX, 0 + moveY);
            
            bottomPath.moveTo(cornerRadius + moveX, moveY);
            bottomPath.lineTo(this.data.width + moveX, moveY);
            bottomPath.lineTo(this.data.width + moveX, this.data.height + moveY);
            bottomPath.lineTo(moveX, this.data.height + moveY);
            bottomPath.lineTo(moveX, moveY);
            bottomPath.lineTo(cornerRadius + moveX, moveY);

            this.renderCanvas2(this.backgroundDom, bottomPath, 
                Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor), 
                Colors.getUiColor(this.data.borderColor));
        }

        let path = new LinePath();

        path.moveTo(cornerRadius, 0);
        path.lineTo(this.data.width, 0);
        path.lineTo(this.data.width, this.data.height);
        path.lineTo(0, this.data.height);
        path.lineTo(0, 0);
        path.lineTo(cornerRadius, 0);

        this.renderCanvasByView(this.backgroundLine, path)
    }

    setSemicircle() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.data.height / 2;
        let linePath = new LinePath();
        linePath.moveTo(cornerRadius, 0);
        linePath.lineTo(this.data.width - cornerRadius, 0);
        if (this.data.type == MindElementType.EXPLAIN && this.data.lineContent != null) {
            if (this.data.lineContent.orientation == LineOrientation.BOTTOM) {
                let arrowWidth = this.data.lineContent.width;
                let arrowHeight = this.data.lineContent.height;
                
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);

                let space = -arrowWidth/2;//UiUtil.dip2px(1);
                linePath.lineTo(this.data.width / 2 + arrowWidth + space, this.data.height);
                linePath.lineTo(this.data.width / 2, this.data.height + arrowHeight);
                linePath.lineTo(this.data.width / 2 + space , this.data.height);
                linePath.lineTo(cornerRadius, this.data.height);
                linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
            }

        } else {
            linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, this.data.width - cornerRadius, this.data.height);
            linePath.lineTo(cornerRadius, this.data.height);
            linePath.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0);
        }
        this.renderCanvas(linePath)
    }

    setDiamond() {
        if (this.data == null) {
            return;
        }
        let borderWidth = 0;

        let centerX = this.data.width / 2 + borderWidth;
        let centerY = this.data.height / 2 + borderWidth;
        let linePath = new LinePath();
        linePath.moveTo(0, centerY);
        linePath.lineTo(centerX, centerY * 2);
        linePath.lineTo(centerX * 2, centerY);
        linePath.lineTo(centerX, 0);
        linePath.lineTo(0, centerY);
        linePath.lineTo(centerX, centerY * 2);
        this.renderCanvas(linePath)
    }

    setCloud() {
        let radiusX = new UiUtil().dip2px(6);
        let countX = (Math.ceil(this.data.width / (radiusX * 2)));
        radiusX = this.data.width / countX / 2;

        let radiusY = new UiUtil().dip2px(6);
        let countY = (Math.ceil(this.data.height / (radiusY * 2)));
        radiusY = this.data.height / countY / 2;
        if (countX == 0 || countY == 0) {
            return;
        }
        let linePath = new LinePath()
        linePath.moveTo(0, radiusX);
        for (let index = 0; index < countX; index++) {      //上
            linePath.circle(radiusX, radiusX, 0, 1, 1, (index + 1) * radiusX * 2, radiusX);
        }

        for (let index = 0; index < countY; index++) {  //右
            if (index == 0) {
                linePath.circle(radiusY, radiusY, 0, 0, 1, this.data.width - radiusX, radiusX + radiusY);
            } else {
                linePath.circle(radiusY, radiusY, 0, 1, 1, this.data.width - radiusX, (radiusX + radiusY) + 2 * index * radiusY);
            }
        }

        for (let index = 0; index < countX; index++) {    //下
            if (index == 0) {
                linePath.circle(radiusY, radiusY, 0, 0, 1, this.data.width - radiusX * 2, this.data.height - radiusX);
            } else {
                linePath.circle(radiusX, radiusX, 0, 0, 1, this.data.width - (index + 1) * radiusX * 2, this.data.height - radiusX);
            }
        }

        for (let index = 0; index < countY; index++) {  //左
            if (index == 0) {
                linePath.circle(radiusY, radiusY, 0, 0, 1, radiusX, this.data.height - radiusY - radiusX);
            } else {
                linePath.circle(radiusY, radiusY, 0, 0, 1, radiusX, this.data.height - radiusY - radiusX - 2 * index * radiusY);
            }
        }

        this.renderCanvas(linePath)

    }

    setBook() {        
        let centerH = this.data.width / 2;
        let topShrink = centerH * 0
        let bottomThickness = 10//this.data.height * 0.05
        let topV = 10
        let pointLeftTop = new CGPoint(topShrink, topV * 0.5)
        let pointCenterTop = new CGPoint(centerH, topV)
        let pointRigthTop = new CGPoint(this.data.width - topShrink, topV * 0.5)
        let pointBottomRigth1 = new CGPoint(this.data.width, this.data.height - bottomThickness)
        let pointBottomRigth2 = new CGPoint(this.data.width, this.data.height)
        let pointCenterBottom1 = new CGPoint(centerH - 6, this.data.height)
        let pointCenterBottom2 = new CGPoint(centerH + 6, this.data.height)
        let pointCenterBottom = new CGPoint(centerH, this.data.height - bottomThickness)
        let pointBottomLeft1 = new CGPoint(0, this.data.height - bottomThickness)
        let pointBottomLeft2 = new CGPoint(0, this.data.height)
        let controlSpaceScale = 0.65
        let controlSpaceOutScale = 0.35
        let linePath = new LinePath()
        linePath.moveTo(pointLeftTop.x, pointLeftTop.y);

        let degrees = Util.getCircleDegreesInPoint(pointCenterTop, pointLeftTop)
        let space = Util.getPointSpacing(pointCenterTop, pointLeftTop)
        let controlPoint = Util.getCirclePoint(pointCenterTop, degrees + 10, space * controlSpaceScale)
        let controlPoint2 = Util.getCirclePoint(pointCenterTop, degrees + 12, space * controlSpaceOutScale)
        linePath.cubicTo(
            controlPoint.x,
            controlPoint.y,
            controlPoint2.x,
            controlPoint2.y,
            pointCenterTop.x,
            pointCenterTop.y);

        degrees = Util.getCircleDegreesInPoint(pointCenterTop, pointRigthTop)
        space = Util.getPointSpacing(pointCenterTop, pointRigthTop)
        controlPoint = Util.getCirclePoint(pointCenterTop, degrees - 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterTop, degrees - 12, space * controlSpaceOutScale)
        linePath.cubicTo(
            controlPoint2.x,
            controlPoint2.y,
            controlPoint.x,
            controlPoint.y,
            pointRigthTop.x,
            pointRigthTop.y);
        linePath.lineTo(pointBottomRigth1.x, pointBottomRigth1.y)

        degrees = Util.getCircleDegreesInPoint(pointCenterBottom, pointBottomRigth1)
        space = Util.getPointSpacing(pointCenterBottom, pointBottomRigth1)
        controlPoint = Util.getCirclePoint(pointCenterBottom, degrees - 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterBottom, degrees - 12, space * controlSpaceOutScale)
        linePath.cubicTo(
            controlPoint.x,
            controlPoint.y,
            controlPoint2.x,
            controlPoint2.y,
            pointCenterBottom.x,
            pointCenterBottom.y);
        
        degrees = Util.getCircleDegreesInPoint(pointCenterBottom, pointBottomLeft1)
        space = Util.getPointSpacing(pointCenterBottom, pointBottomLeft1)
        controlPoint = Util.getCirclePoint(pointCenterBottom, degrees + 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterBottom, degrees + 12, space * controlSpaceOutScale)
        linePath.cubicTo(
            controlPoint2.x,
            controlPoint2.y,
            controlPoint.x,
            controlPoint.y,
            pointBottomLeft1.x,
            pointBottomLeft1.y);
        linePath.lineTo(pointLeftTop.x, pointLeftTop.y)
        this.renderCanvas(linePath)

        let bottomPath = new LinePath()
        bottomPath.moveTo(pointBottomRigth1.x, pointBottomRigth1.y);

        degrees = Util.getCircleDegreesInPoint(pointCenterBottom, pointBottomRigth1)
        space = Util.getPointSpacing(pointCenterBottom, pointBottomRigth1)
        controlPoint = Util.getCirclePoint(pointCenterBottom, degrees - 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterBottom, degrees - 12, space * controlSpaceOutScale)
        bottomPath.cubicTo(
            controlPoint.x,
            controlPoint.y,
            controlPoint2.x,
            controlPoint2.y,
            pointCenterBottom.x,
            pointCenterBottom.y);
        
        degrees = Util.getCircleDegreesInPoint(pointCenterBottom, pointBottomLeft1)
        space = Util.getPointSpacing(pointCenterBottom, pointBottomLeft1)
        controlPoint = Util.getCirclePoint(pointCenterBottom, degrees + 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterBottom, degrees + 12, space * controlSpaceOutScale)
        bottomPath.cubicTo(
            controlPoint2.x,
            controlPoint2.y,
            controlPoint.x,
            controlPoint.y,
            pointBottomLeft1.x,
            pointBottomLeft1.y);

        bottomPath.lineTo(pointBottomLeft2.x, pointBottomLeft2.y);
        degrees = Util.getCircleDegreesInPoint(pointCenterBottom1, pointBottomLeft2)
        space = Util.getPointSpacing(pointCenterBottom1, pointBottomLeft2)
        controlPoint = Util.getCirclePoint(pointCenterBottom1, degrees + 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterBottom1, degrees + 12, space * controlSpaceOutScale)
        bottomPath.cubicTo(
            controlPoint.x,
            controlPoint.y,
            controlPoint2.x,
            controlPoint2.y,
            pointCenterBottom1.x,
            pointCenterBottom1.y);
        bottomPath.cubicTo(
            pointCenterBottom1.x,
            pointCenterBottom1.y - 6,
            pointCenterBottom2.x,
            pointCenterBottom2.y - 6,
            pointCenterBottom2.x,
            pointCenterBottom2.y);

        degrees = Util.getCircleDegreesInPoint(pointCenterBottom2, pointBottomRigth2)
        space = Util.getPointSpacing(pointCenterBottom2, pointBottomRigth2)
        controlPoint = Util.getCirclePoint(pointCenterBottom2, degrees - 10, space * controlSpaceScale)
        controlPoint2 = Util.getCirclePoint(pointCenterBottom2, degrees - 12, space * controlSpaceOutScale)
        bottomPath.cubicTo(
            controlPoint2.x,
            controlPoint2.y,
            controlPoint.x,
            controlPoint.y,
            pointBottomRigth2.x,
            pointBottomRigth2.y);
        bottomPath.lineTo(pointBottomRigth1.x, pointBottomRigth1.y);
        if (Colors.isClear(this.data.backgroundColor)) {
            this.renderCanvasByView(this.backgroundLine, bottomPath, Colors.getUiColor(Colors.clear))
        } else {
            this.renderCanvasByView(this.backgroundLine, bottomPath, Colors.getNumberToRgbDark(this.data.backgroundColor, 0.1))
        }
        let centerLinePath = new LinePath()
        centerLinePath.moveTo(pointCenterTop.x, pointCenterTop.y);
        centerLinePath.lineTo(pointCenterBottom.x, pointCenterBottom.y);
        this.renderCanvasByView(this.backgroundLine2, centerLinePath, Colors.getUiColor(Colors.clear), 
                            1, null, null, Colors.getUiColor(this.data.borderColor, 0.4))
    }

    setUnderline() {
        let cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0.0 : new UiUtil().dip2px(4);
        let width = this.data.width;
        let height = this.data.height;
        let isLeft = this.data.layout == NodeLayoutType.LAYOUT_LEFT ||
                this.data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                this.data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT;
        let lineThicken = this.data.borderThicken;
        let lineWidth = this.data.borderWidth
        let linePath = new LinePath();
        linePath.moveTo(0, 0);
        linePath.lineTo(width, 0);
        linePath.lineTo(width, height);
        linePath.lineTo(0, height);
        linePath.lineTo(0, 0);
        this.renderCanvas(linePath)

        let linePath2 = new LinePath();
        let lineBorderSpaceWidth = lineWidth/2
        if (lineThicken) {
            let extend = this.UiUtil.dip2px(10)
            if (isLeft) {
                linePath2.moveTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(width + lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo( -lineBorderSpaceWidth, height);
                linePath2.lineTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.moveTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(width + lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo(-extend - lineBorderSpaceWidth, height);
                linePath2.lineTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
            } else {
                linePath2.moveTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo(width + lineBorderSpaceWidth, height);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.moveTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo(width + extend + lineBorderSpaceWidth, height);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
            }
            this.renderCanvas2(this.backgroundLine, linePath2, Colors.getUiColor(this.data.borderColor), Colors.getUiColor(Colors.clear))
        } else {
            if (this.data.type == MindElementType.EXPLAIN && this.data.lineContent != null) {
                if (this.data.lineContent.orientation == LineOrientation.BOTTOM) {
                    let arrowWidth = this.data.lineContent.width;
                    let arrowHeight = this.data.lineContent.height;
                    let space = -arrowWidth/2;

                    linePath2.moveToByPoint(new CGPoint(0, height));
                    linePath2.lineTo(width / 2 + space , height);
                    linePath2.lineTo(width / 2, height + arrowHeight);
                    linePath2.lineTo(width / 2 + arrowWidth + space, height);
                    linePath2.lineToByPoint(new CGPoint(width, height));
                    this.renderCanvas2(this.backgroundLine, linePath2, Colors.getUiColor(Colors.clear), Colors.getUiColor(this.data.borderColor))
                    
                    let fullPath = new LinePath();

                    fullPath.moveToByPoint(new CGPoint(0, 0));
                    fullPath.lineToByPoint(new CGPoint(width, 0));
                    fullPath.lineToByPoint(new CGPoint(width, height));

                    fullPath.lineTo(width / 2 + arrowWidth + space, height);
                    fullPath.lineTo(width / 2, height + arrowHeight);
                    fullPath.lineTo(width / 2 + space , height);

                    fullPath.lineToByPoint(new CGPoint(0, height));
                    fullPath.lineToByPoint(new CGPoint(0, 0));
                    this.renderCanvas(fullPath)

                }
            } else {
                linePath2.moveTo(0, height);
                linePath2.lineTo(width, height);
                this.renderCanvas2(this.backgroundLine, linePath2, Colors.getUiColor(Colors.clear), Colors.getUiColor(this.data.borderColor)) 
            }
            
        }
    }

    setTwoUnderline() {
        let cornerRadius = 0.0
        let width = this.data.width;
        let height = this.data.height;
        let isLeft = this.data.layout == NodeLayoutType.LAYOUT_LEFT ||
                this.data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                this.data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT;
        let lineThicken = this.data.borderThicken;
        let lineWidth = this.data.borderWidth
        let linePath = new LinePath();
        linePath.moveTo(0, 0);
        linePath.lineTo(width, 0);
        linePath.lineTo(width, height);
        linePath.lineTo(0, height);
        linePath.lineTo(0, 0);
        this.renderCanvas(linePath)

        let linePath2 = new LinePath();
        let lineBorderSpaceWidth = lineWidth/2
        if (lineThicken) {
            let extend = this.UiUtil.dip2px(10)
            if (isLeft) {
                linePath2.moveTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(width + lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo( -lineBorderSpaceWidth, height);
                linePath2.lineTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.moveTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(width + lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo(-extend - lineBorderSpaceWidth, height);
                linePath2.lineTo(width + lineBorderSpaceWidth, height + lineBorderSpaceWidth);
            } else {
                linePath2.moveTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo(width + lineBorderSpaceWidth, height);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.moveTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height - lineBorderSpaceWidth);
                linePath2.lineTo(width + extend + lineBorderSpaceWidth, height);
                linePath2.lineTo(0 - lineBorderSpaceWidth, height + lineBorderSpaceWidth);
            }
            this.renderCanvas2(this.backgroundLine, linePath2, Colors.getUiColor(this.data.borderColor), Colors.getUiColor(Colors.clear))
        } else {
            if (this.data.type == MindElementType.EXPLAIN && this.data.lineContent != null) {
                if (this.data.lineContent.orientation == LineOrientation.BOTTOM) {
                    let arrowWidth = this.data.lineContent.width;
                    let arrowHeight = this.data.lineContent.height;
                    let space = -arrowWidth/2;

                    linePath2.moveToByPoint(new CGPoint(0, height));
                    linePath2.lineTo(width / 2 + space , height);
                    linePath2.lineTo(width / 2, height + arrowHeight);
                    linePath2.lineTo(width / 2 + arrowWidth + space, height);
                    linePath2.lineToByPoint(new CGPoint(width, height));
                    this.renderCanvas2(this.backgroundLine, linePath2, Colors.getUiColor(Colors.clear), Colors.getUiColor(this.data.borderColor))
                    
                    let fullPath = new LinePath();

                    fullPath.moveToByPoint(new CGPoint(0, 0));
                    fullPath.lineToByPoint(new CGPoint(width, 0));
                    fullPath.lineToByPoint(new CGPoint(width, height));

                    fullPath.lineTo(width / 2 + arrowWidth + space, height);
                    fullPath.lineTo(width / 2, height + arrowHeight);
                    fullPath.lineTo(width / 2 + space , height);

                    fullPath.lineToByPoint(new CGPoint(0, height));
                    fullPath.lineToByPoint(new CGPoint(0, 0));
                    this.renderCanvas(fullPath)

                }
            } else {
                let circleRadius = 10
                let circleSpace = (height / 2) - circleRadius
                linePath2.moveTo(0, height);
                linePath2.lineTo(width, height);
                let space = new UiUtil().dip2px(2);
                linePath2.moveTo(0, height - space - this.data.borderWidth);
                linePath2.lineTo(width, height - space - this.data.borderWidth);

                this.renderCanvas2(this.backgroundLine, linePath2, Colors.getUiColor(Colors.clear), Colors.getUiColor(this.data.borderColor))
            }
            
        }
    }

    setOrderText(text, x, y) {
        let width = 30
        let height = 30
        let childElement;
        if (this.orderElement == null) {
            this.orderElement = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject")
            childElement = document.createElement("div")
            this.orderElement.appendChild(childElement);
            this.backgroundGTag.appendChild(this.orderElement)
        }

        //设置文本区域的宽高位置
        this.orderElement.setAttribute('width', width)
        this.orderElement.setAttribute('height', height)
        this.orderElement.setAttribute('x', x - width/2)
        this.orderElement.setAttribute('y', y - height/2)
        //设置文本区域内容的样式

        childElement.style.lineHeight = height + 'px'   //'20px'
        childElement.style.fontFamily = Config.textFontFamily //"SimSun"
        childElement.style.fontSize = 12 + 'px'
        childElement.style.whiteSpace = "pre-wrap"
        childElement.style.wordBreak = "break-word"
        childElement.style.fontWeight = 600
        childElement.style.textAlign = "center"
        childElement.innerHTML = text;
        childElement.style.color = Colors.getUiColor(this.data.borderColor);
    }

    setHexagon() {
        if (this.data == null) {
            return;
        }
        let linePath = new LinePath();
        let corner = this.data.isEmpty() ? new UiUtil().dip2px(1) : new UiUtil().dip2px(3);
        let borderWidth = this.data.borderWidth;
        let radius = this.getHexagonRadius();
        let centerY = this.data.height / 2;
        let width = this.data.width;
        let height = this.data.height;

        linePath.moveTo(radius + borderWidth + corner * 2, borderWidth);
        linePath.lineTo(width - radius - borderWidth, borderWidth);
        linePath.lineTo(width - borderWidth, centerY);
        linePath.lineTo(width - radius - borderWidth, height - borderWidth);
        linePath.lineTo(radius + borderWidth, height - borderWidth);
        linePath.lineTo(borderWidth, centerY);
        linePath.lineTo(radius + borderWidth, borderWidth);
        linePath.lineTo(radius + borderWidth + corner * 2, borderWidth);
        this.renderCanvas(linePath)
    }

    setLeftAndRightThreeSide() {
        if (this.data == null) {
            return;
        }

        let corner = this.data.isEmpty() ? new UiUtil().dip2px(1) : new UiUtil().dip2px(2);
        let radius = this.getHexagonRadius();

        let leftPoint0 = new CGPoint(radius + corner * 2, 0);
        let leftPoint1 = new CGPoint(radius, 0);
        let leftPoint2 = new CGPoint(0.0, (this.data.height * 0.3));
        let leftPoint3 = new CGPoint(0.0, this.data.height * 0.7);
        let leftPoint4 = new CGPoint(radius, this.data.height);

        let rightPoint1 = new CGPoint(this.data.width - radius, 0);
        let rightPoint2 = new CGPoint(this.data.width, this.data.height * 0.3);
        let rightPoint3 = new CGPoint(this.data.width, this.data.height * 0.7);
        let rightPoint4 = new CGPoint(this.data.width - radius, this.data.height);

        let linePath = new LinePath();
        linePath.moveTo(leftPoint0.x, leftPoint0.y);

        linePath.lineTo(rightPoint1.x, rightPoint1.y);
        linePath.lineTo(rightPoint2.x, rightPoint2.y);
        linePath.lineTo(rightPoint3.x, rightPoint3.y);
        linePath.lineTo(rightPoint4.x, rightPoint4.y);

        linePath.lineTo(leftPoint4.x, leftPoint4.y);
        linePath.lineTo(leftPoint3.x, leftPoint3.y);
        linePath.lineTo(leftPoint2.x, leftPoint2.y);
        linePath.lineTo(leftPoint1.x, leftPoint1.y);
        linePath.lineTo(leftPoint0.x, leftPoint0.y);
        this.renderCanvas(linePath)
    }

    setLeftStraightRightArrow() {
        if (this.data == null) {
            return;
        }
        let corner = new UiUtil().dip2px(1);

        let radius = this.data.height >> 1;
        let linePath = new LinePath()
        linePath.moveTo(corner * 2, 0);
        linePath.lineTo(this.data.width - radius, 0);
        linePath.lineTo(this.data.width, this.data.height / 2);
        linePath.lineTo(this.data.width - radius, this.data.height);
        linePath.lineTo(0, this.data.height);
        linePath.lineTo(0, 0);
        linePath.lineTo(corner * 2, 0);
        this.renderCanvas(linePath)

    }

    setRightStraightLeftArrow() {
        if (this.data == null) {
            return;
        }
        let corner = new UiUtil().dip2px(1);

        let radius = this.data.height >> 1;
        let linePath = new LinePath()
        linePath.moveTo(radius + corner * 2, 0);
        linePath.lineTo(this.data.width, 0);
        linePath.lineTo(this.data.width, this.data.height);
        linePath.lineTo(radius, this.data.height);
        linePath.lineTo(0, this.data.height / 2);
        linePath.lineTo(radius, 0);
        linePath.lineTo(radius + corner * 2, 0);
        this.renderCanvas(linePath)
    }

    setRightArrow() {
        if (this.data == null) {
            return;
        }

        let radius = this.data.height >> 1;

        let linePath = new LinePath();
        linePath.moveTo(0, 0);
        linePath.lineTo(this.data.width - radius, 0);
        linePath.lineTo(this.data.width, this.data.height / 2);
        linePath.lineTo(this.data.width - radius, this.data.height);
        linePath.lineTo(0, this.data.height);
        linePath.lineTo(radius, this.data.height / 2);
        linePath.lineTo(0, 0);
        linePath.lineTo(this.data.width - radius, 0);
        this.renderCanvas(linePath)

    }

    setLeftArrow() {
        if (this.data == null) {
            return;
        }
        let radius = this.data.height >> 1;
        let linePath = new LinePath()
        linePath.moveTo(radius, 0);
        linePath.lineTo(this.data.width, 0);
        linePath.lineTo(this.data.width - radius, this.data.height / 2);
        linePath.lineTo(this.data.width, this.data.height);
        linePath.lineTo(radius, this.data.height);
        linePath.lineTo(0, this.data.height / 2);
        linePath.lineTo(radius, 0);
        linePath.lineTo(this.data.width, 0);
        this.renderCanvas(linePath)

    }

    setLeftArrow2() {
        if (this.data == null) {
            return;
        }
        let arrowAngle = 80;
        let width = this.data.width;
        let height = this.data.height;

        let arrowWidth = ((height) / 2 / this.Util.tan(arrowAngle / 2));
        let arrowCellHeight = (height - 6) / 2.6 * 0.8;

        let bezierPath = new LinePath();
        bezierPath.moveTo(width - this.UiUtil.dip2px(8), arrowCellHeight);
        bezierPath.lineTo(arrowWidth, arrowCellHeight);
        bezierPath.lineTo(arrowWidth, 0);
        bezierPath.lineTo(0, height / 2);
        bezierPath.lineTo(arrowWidth, height);
        bezierPath.lineTo(arrowWidth, height - arrowCellHeight);
        bezierPath.lineTo(width, height - arrowCellHeight);
        bezierPath.lineTo(width, arrowCellHeight);
        bezierPath.lineTo(width - this.UiUtil.dip2px(8), arrowCellHeight);
        this.renderCanvas(bezierPath)
    }

    setRightArrow2() {
        if (this.data == null) {
            return;
        }
        let arrowAngle = 80;
        let width = this.data.width;
        let height = this.data.height;

        let arrowWidth = ((height) / 2 / this.Util.tan(arrowAngle / 2));
        let arrowCellHeight = (height - 6) / 2.6 * 0.8;

        let bezierPath = new LinePath();
        bezierPath.moveTo(this.UiUtil.dip2px(8), arrowCellHeight);
        bezierPath.lineTo(width - arrowWidth, arrowCellHeight);
        bezierPath.lineTo(width - arrowWidth, 0);
        bezierPath.lineTo(width, height / 2);
        bezierPath.lineTo(width - arrowWidth, height);
        bezierPath.lineTo(width - arrowWidth, height - arrowCellHeight);
        bezierPath.lineTo(0, height - arrowCellHeight);
        bezierPath.lineTo(0, arrowCellHeight);
        bezierPath.lineTo(this.UiUtil.dip2px(8), arrowCellHeight);
        this.renderCanvas(bezierPath)

    }


    setThreeCircularCloud() {
        if (this.data == null) {
            return;
        }
        let leftCloudAngle = 16;
        let rightCloudAngle = 8;
        let height = this.data.height;
        let radius = height / 2;

        let leftIntersectionPointX = radius + ((radius) * this.Util.cosd(180 + leftCloudAngle));
        let leftIntersectionPointY = radius + ((radius) * this.Util.sind(180 + leftCloudAngle));
        let rightIntersectionPointX = radius + ((radius) * this.Util.cosd(360 - rightCloudAngle));
        let rightIntersectionPointY = radius + ((radius) * this.Util.sind(360 - rightCloudAngle));
        let leftRadius = (radius * 2 - leftIntersectionPointY) / 2;
        let rightRadius = (radius * 2 - rightIntersectionPointY) / 2;
        let width = leftRadius + rightRadius + radius * 2 - leftIntersectionPointX - (radius * 2 - rightIntersectionPointX);

        let path = new LinePath();
        path.moveTo(width / 2, height);
        path.lineTo(leftRadius, height);

        path.circle(leftRadius, leftRadius, 0, 0, 1, leftRadius, leftIntersectionPointY);
        path.circle(radius, radius, 0, 0, 1, width - rightRadius, rightIntersectionPointY);
        path.circle(rightRadius, rightRadius, 0, 0, 1, width - rightRadius, height);

        path.lineTo(width / 2, height);
        this.renderCanvas(path)

    }




    setLeftRightCornerTopBottomSemicircle() {
        if (this.data == null) {
            return;
        }

        let topAndBottomSize = ((this.data.height) * 0.12);
        if (topAndBottomSize > new UiUtil().dip2px(10)) {
            topAndBottomSize = new UiUtil().dip2px(10);
        }
        let cornerSize = this.data.isEmpty() ? new UiUtil().dip2px(1) : new UiUtil().dip2px(4);

        let leftPoint1 = new CGPoint(0, topAndBottomSize);
        let leftPoint2 = new CGPoint(0, this.data.height - topAndBottomSize);
        let rightPoint1 = new CGPoint(this.data.width, topAndBottomSize);
        let rightPoint2 = new CGPoint(this.data.width, this.data.height - topAndBottomSize);

        let cornercontrolSpace = 0;
        let whscale = this.data.width / this.data.height;

        if (whscale < 0.2) {
            cornerSize = 0.1;
            cornercontrolSpace = cornerSize / 2;
        } else if (whscale < 0.4) {
            cornercontrolSpace = cornerSize / 2 * 0.98;
        } else if (whscale < 0.6) {
            cornercontrolSpace = cornerSize / 2 * 0.94;
        } else if (whscale < 0.8) {
            cornercontrolSpace = cornerSize / 2 * 0.9;
        } else if (whscale < 0.9) {
            cornercontrolSpace = cornerSize / 2 * 0.85;
        } else if (whscale < 1) {
            cornercontrolSpace = cornerSize / 2 * 0.8;
        } else if (whscale < 1.1) {
            cornercontrolSpace = cornerSize / 2 * 0.6;
        } else if (whscale < 1.3) {
            cornercontrolSpace = cornerSize / 2 * 0.4;
        } else if (whscale < 1.4) {
            cornercontrolSpace = cornerSize / 2 * 0.3;
        } else if (whscale < 1.6) {
            cornercontrolSpace = cornerSize / 2 * 0.28;
        } else if (whscale < 1.8) {
            cornercontrolSpace = cornerSize / 2 * 0.24;
        } else if (whscale < 2.0) {
            cornercontrolSpace = cornerSize / 2 * 0.2;
        } else if (whscale < 2.2) {
            cornercontrolSpace = cornerSize / 2 * 0.15;
        } else if (whscale < 2.4) {
            cornercontrolSpace = cornerSize / 2 * 0.1;
        }

        let linePath = new LinePath();

        linePath.moveTo(leftPoint1.x, leftPoint1.y + cornerSize);
        linePath.cubicOneTo(leftPoint1.x + cornercontrolSpace, leftPoint1.y + cornercontrolSpace, leftPoint1.x + cornerSize, leftPoint1.y);
        linePath.cubicOneTo(this.data.width / 2, -topAndBottomSize, rightPoint1.x - cornerSize, rightPoint1.y);
        linePath.cubicOneTo(rightPoint1.x - cornercontrolSpace, rightPoint1.y + cornercontrolSpace, rightPoint1.x, rightPoint1.y + cornerSize);
        linePath.lineTo(rightPoint2.x, rightPoint2.y - cornerSize);
        linePath.moveTo(rightPoint2.x, rightPoint2.y - cornerSize);
        linePath.cubicOneTo(rightPoint2.x - cornercontrolSpace, rightPoint2.y - cornercontrolSpace, rightPoint2.x - cornerSize, rightPoint2.y);

        linePath.cubicOneTo(this.data.width / 2, this.data.height + topAndBottomSize, leftPoint2.x + cornerSize, leftPoint2.y);

        linePath.cubicOneTo(leftPoint2.x + cornercontrolSpace, leftPoint2.y - cornercontrolSpace, leftPoint2.x, leftPoint2.y - cornerSize);
        linePath.lineTo(leftPoint1.x, leftPoint1.y + cornerSize);
        this.renderCanvas(linePath)
    }

    setLeftRightCornerTopBottomCorner() {
        if (this.data == null) {
            return;
        }

        let centerHeight = new UiUtil().dip2px(10);
        if (this.data.isEmpty()) {
            centerHeight = new UiUtil().dip2px(3);
        } else if (this.data.type == MindElementType.MAIN_SUBJECT) {
            centerHeight = new UiUtil().dip2px(12);
        } else if (this.data.type == MindElementType.SON_SUBJECT) {
            centerHeight = new UiUtil().dip2px(6);
        } else {
            centerHeight = new UiUtil().dip2px(10);
        }

        let cornerSize = this.data.isEmpty() ? new UiUtil().dip2px(1) : new UiUtil().dip2px(4);
        let height = this.data.height;
        let width = this.data.width;

        let point1 = new CGPoint(0, centerHeight);
        let point2 = new CGPoint(width / 2, 0);
        let point3 = new CGPoint(width, centerHeight);
        let point4 = new CGPoint( width, height - centerHeight);
        let point5 = new CGPoint(width / 2, height);
        let point6 = new CGPoint(0, height - centerHeight);

        let point1s = new CGPoint(0, centerHeight + cornerSize);
        let point1e = Util.getCirclePoint(point1, Util.getCircleDegreesInPoint(point1, point2), cornerSize);

        let point2s = Util.getCirclePoint(point2, Util.getCircleDegreesInPoint(point2, point1), cornerSize);
        let point2e = Util.getCirclePoint(point2, Util.getCircleDegreesInPoint(point2, point3), cornerSize);

        let point3s = Util.getCirclePoint(point3, Util.getCircleDegreesInPoint(point3, point2), cornerSize);
        let point3e = Util.getCirclePoint(point3, Util.getCircleDegreesInPoint(point3, point4), cornerSize);

        let point4s = Util.getCirclePoint(point4, Util.getCircleDegreesInPoint(point4, point3), cornerSize);
        let point4e = Util.getCirclePoint(point4, Util.getCircleDegreesInPoint(point4, point5), cornerSize);

        let point5s = Util.getCirclePoint(point5, Util.getCircleDegreesInPoint(point5, point4), cornerSize);
        let point5e = Util.getCirclePoint(point5, Util.getCircleDegreesInPoint(point5, point6), cornerSize);

        let point6s = Util.getCirclePoint(point6, Util.getCircleDegreesInPoint(point6, point5), cornerSize);
        let point6e = Util.getCirclePoint(point6, Util.getCircleDegreesInPoint(point6, point1), cornerSize);


        let linePath = new LinePath();

        linePath.move(point1s);
        linePath.addQuadCurve(point1e, point1);
        linePath.lineToByPoint(point2s);
        linePath.addQuadCurve(point2e, point2);

        linePath.lineToByPoint(point3s);
        linePath.addQuadCurve(point3e, point3);

        linePath.lineToByPoint(point4s);
        linePath.addQuadCurve(point4e, point4);

        linePath.lineToByPoint(point5s);
        linePath.addQuadCurve(point5e, point5);

        linePath.lineToByPoint(point6s);
        linePath.addQuadCurve(point6e, point6);

        linePath.lineToByPoint(point1s);

        this.renderCanvas(linePath)
    }

    setTriangle() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : new UiUtil().dip2px(4);
        let linePath = new LinePath();
        linePath.moveTo(cornerRadius * 2, this.data.height);
        linePath.lineTo(0, this.data.height);
        linePath.lineTo(this.data.width / 2, 0);
        linePath.lineTo(this.data.width, this.data.height);
        linePath.lineTo(cornerRadius * 2, this.data.height);
        this.renderCanvas(linePath)
    }

    setInvertedTriangle() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : new UiUtil().dip2px(4);
        let linePath = new LinePath();
        linePath.moveTo(cornerRadius * 2, 0);
        linePath.lineTo(this.data.width, 0);
        linePath.lineTo(this.data.width / 2, this.data.height);
        linePath.lineTo(0, 0);
        linePath.lineTo(cornerRadius * 2, 0);
        this.renderCanvas(linePath)
    }

    setRightTriangle() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : new UiUtil().dip2px(4);
        let linePath = new LinePath();
        linePath.moveTo(0, cornerRadius * 2);
        linePath.lineTo(0, 0);
        linePath.lineTo(this.data.width, this.data.height / 2);
        linePath.lineTo(0, this.data.height);
        linePath.lineTo(0, cornerRadius * 2);
        this.renderCanvas(linePath)
    }

    setLeftTriangle() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : new UiUtil().dip2px(4);
        let linePath = new LinePath();
        linePath.moveTo(this.data.width, cornerRadius * 2);
        linePath.lineTo(this.data.width, this.data.height);
        linePath.lineTo(0, this.data.height / 2);
        linePath.lineTo(this.data.width, 0);
        linePath.lineTo(this.data.width, cornerRadius * 2);
        this.renderCanvas(linePath)
    }

    setCircularAndRight() {
        if (this.data == null) {
            return;
        }
        let cornerRadius = 0;
        let radius = new UiUtil().dip2px(20);
        if (this.data.width > radius * 2 && this.data.height > radius * 2) {
            if (this.data.width > this.data.height) {
                radius = this.data.height * 0.8;
            } else {
                radius = this.data.width * 0.8;
            }

            if (radius > new UiUtil().dip2px(50)) {
                radius = new UiUtil().dip2px(50);
            }
        } else {
            radius = this.data.height >> 1;
        }
        let linePath = new LinePath();
        linePath.moveTo(0, this.data.height);
        linePath.lineTo(0, radius / 2);
        linePath.circle(radius / 2, radius / 2, 0, 0, 1, radius / 2, 0);
        linePath.lineTo(this.data.width, 0)
        linePath.lineTo(this.data.width, this.data.height - radius / 2)
        linePath.circle(radius / 2, radius / 2, 0, 0, 1, this.data.width - radius / 2, this.data.height)
        linePath.lineTo(0, this.data.height)
        linePath.lineTo(0, radius / 2);
        this.renderCanvas(linePath)
    }

    setObliqueCorner() {//斜边60度矩形
        if (this.data == null) {
            return;
        }

        let obliqueAngle = this.data.isEmpty() ? 75 : 60;
        let leftSpace = 0;
        if (this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL || (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION)) {
            leftSpace = 0;
        } else {
            leftSpace = ((this.data.height) / this.Util.tan(obliqueAngle));
        }

        let linePath = new LinePath();
        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            let moveX = ((this.data.height) / Math.tan(Util.toRadians(60)));
            linePath.moveTo(0, this.data.height);
            if (Math.abs(moveX - this.data.width / 2) < this.UiUtil.dip2px(2)) {
                linePath.lineTo(this.data.width>>1, 0);
            } else {
                linePath.lineTo(moveX, 0);
                linePath.lineTo(this.data.width - moveX, 0);
            }
            linePath.lineTo(this.data.width, this.data.height);
            linePath.lineTo(0, this.data.height);
        } else {
            linePath.moveTo(0, this.data.height);
            linePath.lineTo(leftSpace, 0);
            linePath.lineTo(this.data.width, 0);
            linePath.lineTo(this.data.width - leftSpace, this.data.height);
            linePath.lineTo(0, this.data.height);
        }

        this.renderCanvas(linePath)
    }

    setLargeBraces() {//大括号
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.UiUtil.dip2px(3)
        let spcae = this.UiUtil.dip2px(1)
        let margin = this.UiUtil.dip2px(2);

        let path = new LinePath();
        let borderLeftPath = new LinePath();
        let borderRightPath = new LinePath();

        borderLeftPath.setEffectRadius(cornerRadius)
        borderRightPath.setEffectRadius(cornerRadius)
        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            let moveX = ((data.height) / Math.tan(Util.toRadians(60)));
            path.moveTo(0, this.data.height);
            if (Math.abs(moveX - this.data.width / 2) < this.UiUtil.dip2px(2)) {
                path.lineTo(this.data.width>>1, 0);
            } else {
                path.lineTo(moveX, 0);
                path.lineTo(this.data.width - moveX, 0);
            }
            path.lineTo(this.data.width, this.data.height);
            path.lineTo(0, this.data.height);

            borderLeftPath.moveTo(0, this.data.height);
            if (Math.abs(moveX - this.data.width / 2) < this.UiUtil.dip2px(2)) {
                borderLeftPath.lineTo(this.data.width>>1, 0);
            } else {
                borderLeftPath.lineTo(moveX, 0);
                borderLeftPath.lineTo(this.data.width - moveX, 0);
            }
            borderLeftPath.lineTo(this.data.width, this.data.height);
            borderLeftPath.lineTo(0, this.data.height);
        } else {
            path.moveTo(cornerRadius * 2, 0);
            path.lineTo(this.data.width - cornerRadius * 2, 0);
            path.lineTo(this.data.width - cornerRadius * 2, this.data.height);
            path.lineTo(cornerRadius * 2, this.data.height);
            path.lineTo(cornerRadius * 2, 0);

            borderLeftPath.moveTo(margin + cornerRadius * 2 + spcae, 0);
            borderLeftPath.lineTo(margin + cornerRadius, 0);
            borderLeftPath.lineTo(margin + cornerRadius, this.data.height/2);
            borderLeftPath.lineTo(margin, this.data.height/2);
            borderLeftPath.lineTo(margin + cornerRadius, this.data.height/2);
            borderLeftPath.lineTo(margin + cornerRadius, this.data.height);
            borderLeftPath.lineTo(margin + cornerRadius * 2 + spcae, this.data.height);

            borderRightPath.moveTo(this.data.width - cornerRadius * 2 - margin - spcae, 0);
            borderRightPath.lineTo(this.data.width - cornerRadius - margin, 0);
            borderRightPath.lineTo(this.data.width - cornerRadius - margin, this.data.height/2);
            borderRightPath.lineTo(this.data.width - margin, this.data.height/2);
            borderRightPath.lineTo(this.data.width - cornerRadius - margin, this.data.height/2);
            borderRightPath.lineTo(this.data.width - cornerRadius - margin, this.data.height);
            borderRightPath.lineTo(this.data.width - cornerRadius * 2 - margin - spcae, this.data.height);
        }


        this.renderCanvasByView(this.backgroundDom, path, this.fullColor, 0);
        this.renderCanvasByView(this.backgroundLine, borderLeftPath, Colors.getUiColor(Colors.clear))
        this.renderCanvasByView(this.backgroundLine2, borderRightPath, Colors.getUiColor(Colors.clear))
        this.renderObj({ d: path, dom: this.clipPathGraph});
    }

    setMiddleBraces() {//中括号
        if (this.data == null) {
            return;
        }

        let bracesWidth = this.UiUtil.dip2px(6);


        let path = new LinePath();
        let borderLeftPath = new LinePath();
        let borderRightPath = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            let moveX = ((this.data.height) / Math.tan(Util.toRadians(60)));
            path.moveTo(0, this.data.height);
            if (Math.abs(moveX - this.data.width / 2) < this.UiUtil.dip2px(2)) {
                path.lineTo(this.data.width>>1, 0);
            } else {
                path.lineTo(moveX, 0);
                path.lineTo(this.data.width - moveX, 0);
            }
            path.lineTo(this.data.width, this.data.height);
            path.lineTo(0, this.data.height);

        } else {
            path.moveTo(0, 0);
            path.lineTo(this.data.width, 0);
            path.lineTo(this.data.width, this.data.height);
            path.lineTo(0, this.data.height);
            path.lineTo(0, 0);

            borderLeftPath.moveTo(bracesWidth, 0);
            borderLeftPath.lineTo(0, 0);
            borderLeftPath.lineTo(0, this.data.height);
            borderLeftPath.lineTo(bracesWidth, this.data.height);

            borderRightPath.moveTo(this.data.width - bracesWidth, 0);
            borderRightPath.lineTo(this.data.width, 0);
            borderRightPath.lineTo(this.data.width, this.data.height);
            borderRightPath.lineTo(this.data.width - bracesWidth, this.data.height);
        }


        this.renderCanvasByView(this.backgroundDom, path, this.fullColor, 0);
        this.renderCanvasByView(this.backgroundLine, borderLeftPath, Colors.getUiColor(Colors.clear))
        this.renderCanvasByView(this.backgroundLine2, borderRightPath, Colors.getUiColor(Colors.clear))
        this.renderObj({ d: path, dom: this.clipPathGraph});
    }

    flowerBrackets() {
        if (this.data == null) {
            return;
        }
        let bracesWidth = this.UiUtil.dip2px(6)
        let bracesHeight = Math.min(this.UiUtil.dip2px(200), this.data.height);
        let leafBorderWidth = 2
        let borderWidth = 1

        let path = new LinePath();
        let borderLeftPath = new LinePath();
        let borderRightPath = new LinePath();

        let borderLeafPath = new LinePath();
        
        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {
            
            let leftTopPoint = new CGPoint(bracesWidth, (this.data.height - bracesHeight)/2);
            let leftMiddlePoint = new CGPoint(0, this.data.height/2);
            let leftBottomPoint = new CGPoint(bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);

            let testCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftMiddlePoint, leftBottomPoint);
            let testStartAngle = Math.floor(Util.getDegreesByTwoPoints(testCircleCenter, leftBottomPoint))
            if (testStartAngle > 160) {
                for (let index = 1; index < 20; index++) {
                    bracesWidth = bracesWidth + index * 0.5
                    leftTopPoint = new CGPoint(bracesWidth, (this.data.height - bracesHeight)/2);
                    leftMiddlePoint = new CGPoint(0, this.data.height/2);
                    leftBottomPoint = new CGPoint(bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);

                    testCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftMiddlePoint, leftBottomPoint);
                    testStartAngle = Math.floor(Util.getDegreesByTwoPoints(testCircleCenter, leftBottomPoint))
                    if (testStartAngle <= 160) {
                        break
                    }
                }
            } else if (testStartAngle < 150) {
                for (let index = 1; index < 6; index++) {
                    bracesWidth = bracesWidth - index * 0.5
                    leftTopPoint = new CGPoint(bracesWidth, (this.data.height - bracesHeight)/2);
                    leftMiddlePoint = new CGPoint(0, this.data.height/2);
                    leftBottomPoint = new CGPoint(bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);

                    testCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftMiddlePoint, leftBottomPoint);
                    testStartAngle = Math.floor(Util.getDegreesByTwoPoints(testCircleCenter, leftBottomPoint))
                    if (testStartAngle >= 150) {
                        break
                    }
                }
            }            

            let rightTopPoint = new CGPoint(this.data.width - bracesWidth, (this.data.height - bracesHeight)/2);
            let rightmiddlePoint = new CGPoint(this.data.width,  this.data.height/2);
            let rightBottomPoint = new CGPoint(this.data.width - bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);


            let leftCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftMiddlePoint, leftBottomPoint);
            let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, leftBottomPoint);

            let rightCircleCenter = this.Util.getCircleCenter(rightTopPoint, rightmiddlePoint, rightBottomPoint);
            let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, rightmiddlePoint);

            path.moveToByPoint(leftBottomPoint);
            path.circle(leftCircleRadius, leftCircleRadius, 0,0,1,leftTopPoint.x,leftTopPoint.y);
            path.lineToByPoint(leftTopPoint);
            path.lineToByPoint(rightTopPoint);
            path.circle(rightCircleRadius, rightCircleRadius, 0,0,1,rightBottomPoint.x,rightBottomPoint.y);
            path.lineToByPoint(rightBottomPoint);
            path.lineToByPoint(leftBottomPoint);

            let leftStartAngle = Math.floor(Util.getDegreesByTwoPoints(leftCircleCenter, leftBottomPoint))
            let letfAngle = Math.floor(Util.getDegreesByTwoPoints(leftCircleCenter, leftTopPoint) - Util.getDegreesByTwoPoints(leftCircleCenter, leftBottomPoint))
            let arcLength = 2 * Math.PI * leftCircleRadius * (letfAngle / 360)          
            let leafThickness = Math.max(Math.min(0.0385 * bracesHeight, 3.0), 1.0)
            // leafThickness = Math.max(Math.min(0.0465 * bracesHeight, 4.0), 1.5)
            let count = parseInt(Math.floor(arcLength / (Math.max(leafThickness, 2.5) * 3)))
            if (count < 5) {
                leafThickness = 0.8
                count = parseInt(Math.floor(arcLength / (Math.max(leafThickness, 2.0) * 2)))
                leafBorderWidth = 1.0
                borderWidth = 0.5
            }    
            let leafLength = leafThickness * 4
            let offsetAngle = 0            
            let letfAverageAngle = letfAngle / (count + 1)

            for (let index = 0; index < count; index++) {
                let angle = leftStartAngle + letfAverageAngle * (index + 1) - letfAverageAngle / 2              
                let point = Util.getCirclePoint(leftCircleCenter, angle, leftCircleRadius)
                let tangentAngle = 0
                if (angle >= 179 && angle <= 181) {
                    tangentAngle = 270
                } else if (angle < 180) {
                    let x = leftCircleCenter.x - leftCircleRadius / Util.cosd(180 - angle)
                    tangentAngle = Util.getCircleDegreesInPoint(point, new Point(x, leftCircleCenter.y))                    
                } else {
                    let x = leftCircleCenter.x - leftCircleRadius / Util.cosd(angle - 180)
                    tangentAngle = Util.getCircleDegreesInPoint(point, new Point(x, leftCircleCenter.y)) + 180
                }
                if (offsetAngle == 0) {
                    let angle = leftStartAngle + letfAverageAngle * (index + 2)                
                    let nextPoint = Util.getCirclePoint(leftCircleCenter, angle, leftCircleRadius)
                    let pointAndNextPointAngle = Util.getCircleDegreesInPoint(point, nextPoint)
                    offsetAngle = Math.min(Math.abs(pointAndNextPointAngle - tangentAngle) * 3, 20)
                }
                let outsidePoint = Util.getCirclePoint(point, tangentAngle - 45, leafLength)
                let insidePoint = Util.getCirclePoint(point, tangentAngle + 45 + offsetAngle, leafLength * 0.90)
                
                this.setLeafPath(borderLeafPath, point, outsidePoint)
                this.setLeafPath(borderLeafPath, point, insidePoint)
                
                if (index == count - 1) {       
                    angle = angle + letfAverageAngle / 2
                    point = Util.getCirclePoint(leftCircleCenter, angle, leftCircleRadius)  
                    // let x = leftCircleCenter.x - leftCircleRadius / Util.cosd(angle - 180)
                    tangentAngle =  Util.getCircleDegreesInPoint(point, leftTopPoint) //Util.getCircleDegreesInPoint(point, new Point(x, leftCircleCenter.y)) + 180     


                    let endPoint = Util.getCirclePoint(point, tangentAngle, leafLength)
                    this.setLeafPath(borderLeafPath, point, endPoint)

                    leftTopPoint = Util.getCirclePoint(leftCircleCenter, angle, leftCircleRadius)
                }
            }            

            let rightStartAngle = Math.floor(Util.getDegreesByTwoPoints(rightCircleCenter, rightBottomPoint))
            let rightAngle = Math.floor((360 - Util.getDegreesByTwoPoints(rightCircleCenter, rightTopPoint)) + Util.getDegreesByTwoPoints(rightCircleCenter, rightBottomPoint))
            let rightAverageAngle = Math.floor((rightAngle / (count + 1)) * 100) / 100
            
            for (let index = 0; index < count; index++) {
                let angle = Math.floor((rightStartAngle - rightAverageAngle * (index + 1) + rightAverageAngle / 2) * 100) / 100
                angle = angle < 0 ? 360 + angle : angle
                let point = Util.getCirclePoint(rightCircleCenter, angle, rightCircleRadius)
                
                let tangentAngle = 0
                if (angle >= 359 || angle <= 1) {
                    tangentAngle = 270
                } else if (0 < angle && 90 > angle) {
                    let x = rightCircleCenter.x + rightCircleRadius / Util.cosd(angle)
                    tangentAngle = Math.floor(Util.getCircleDegreesInPoint(point, new Point(x, rightCircleCenter.y)) * 100) / 100
                } else {
                    let x = rightCircleCenter.x + rightCircleRadius / Util.cosd(360 - angle)
                    tangentAngle = Math.floor(Util.getCircleDegreesInPoint(point, new Point(x, rightCircleCenter.y)) * 100) / 100 - 180
                    tangentAngle = tangentAngle < 0 ? 360 + tangentAngle : tangentAngle
                }

                let outsidePoint = Util.getCirclePoint(point, tangentAngle + 45, leafLength)
                let insidePoint = Util.getCirclePoint(point, tangentAngle - 45 - offsetAngle, leafLength * 0.90)
                
                this.setLeafPath(borderLeafPath, point, outsidePoint)
                this.setLeafPath(borderLeafPath, point, insidePoint)

                if (index == count - 1) {
                    angle = angle - letfAverageAngle / 2
                    point = Util.getCirclePoint(rightCircleCenter, angle, rightCircleRadius)

                    let x = rightCircleCenter.x + rightCircleRadius / Util.cosd(360 - angle)
                    tangentAngle = Util.getCircleDegreesInPoint(point, rightTopPoint)//Util.getCircleDegreesInPoint(point, new Point(x, rightCircleCenter.y)) - 180
                    tangentAngle = tangentAngle < 0 ? 360 + tangentAngle : tangentAngle 

                    let endPoint = Util.getCirclePoint(point, tangentAngle, leafLength)
                    this.setLeafPath(borderLeafPath, point, endPoint)

                    rightTopPoint = Util.getCirclePoint(rightCircleCenter, angle, rightCircleRadius)
                }
            }

            borderLeftPath.moveToByPoint(leftBottomPoint);
            borderLeftPath.circle(leftCircleRadius, leftCircleRadius, 0,0,1,leftTopPoint.x,leftTopPoint.y);

            borderLeftPath.lineToByPoint(leftTopPoint);
            borderRightPath.moveToByPoint(rightTopPoint);
            borderRightPath.circle(rightCircleRadius, rightCircleRadius, 0,0,1,rightBottomPoint.x,rightBottomPoint.y);

            borderRightPath.lineToByPoint(rightBottomPoint);            
        }


        this.renderCanvasByView(this.backgroundDom, path, this.fullColor, 0);
        this.renderCanvasByView(this.backgroundLine, borderLeftPath, Colors.getUiColor(Colors.clear), borderWidth)
        this.renderCanvasByView(this.backgroundLine2, borderRightPath, Colors.getUiColor(Colors.clear), borderWidth)
        if (this.data.borderWidth > 0) {
            this.renderCanvasByView(this.pathDomList[0], borderLeafPath, Colors.getUiColor(this.data.borderColor), leafBorderWidth, null, "miter-clip", Colors.getUiColor(this.data.borderColor))
        }
        this.renderObj({ d: path, dom: this.clipPathGraph});
    }

    setLeafPath(path, point1, point2) {
        let bottomPoint = point1//point1.y > point2.y ? point1 : point2
        let topPoint = point2//point1.y > point2.y ? point2 : point1
        // if (point1.y == point2.y) {
        //     bottomPoint = point1
        //     topPoint = point2
        // }        
        
        let leafLength = Util.getPointSpacing(point1, point2)
        let leafThickness = leafLength / 4
        let angle = Util.getCircleDegreesInPoint(bottomPoint, topPoint)

        let middlePointAngle = Util.getTanDegrees((leafThickness / 2) / (leafLength / 2))
        let middleLength = (leafThickness / 2) / Util.sind(middlePointAngle)

        let leftMiddlePoint = Util.getCirclePoint(bottomPoint, angle - middlePointAngle, middleLength)
        let rightMiddlePoint = Util.getCirclePoint(bottomPoint, angle + middlePointAngle, middleLength)
        
        path.moveToByPoint(bottomPoint);
        let outCirclePoint = this.Util.getCircleCenter(bottomPoint, leftMiddlePoint, topPoint)
        let outCircleRadius = this.Util.getPointSpacingByCGPoint(outCirclePoint, bottomPoint);
        path.circle(outCircleRadius, outCircleRadius, 0,0,1, topPoint.x, topPoint.y);
        path.lineToByPoint(topPoint);

        outCirclePoint = this.Util.getCircleCenter(topPoint, rightMiddlePoint, bottomPoint)
        outCircleRadius = this.Util.getPointSpacingByCGPoint(outCirclePoint, topPoint);
        path.circle(outCircleRadius, outCircleRadius, 0,0,1, bottomPoint.x, bottomPoint.y);
        path.lineToByPoint(bottomPoint);
    }

    setBraces() {//小括号
        if (this.data == null) {
            return;
        }
        let bracesWidth = this.UiUtil.dip2px(6)
        let bracesHeight = Math.min(this.UiUtil.dip2px(200), this.data.height);

        let path = new LinePath();
        let borderLeftPath = new LinePath();
        let borderRightPath = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {
            let leftTopPoint = new CGPoint(bracesWidth, (this.data.height - bracesHeight)/2);
            let leftMiddlePoint = new CGPoint(0, this.data.height/2);
            let leftBottomPoint = new CGPoint(bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);

            let rightTopPoint = new CGPoint(this.data.width - bracesWidth, (this.data.height - bracesHeight)/2);
            let rightmiddlePoint = new CGPoint(this.data.width,  this.data.height/2);
            let rightBottomPoint = new CGPoint(this.data.width - bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);


            let leftCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftMiddlePoint, leftBottomPoint);
            let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, leftBottomPoint);

            let rightCircleCenter = this.Util.getCircleCenter(rightTopPoint, rightmiddlePoint, rightBottomPoint);
            let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, rightmiddlePoint);

            path.moveToByPoint(leftBottomPoint);
            path.circle(leftCircleRadius, leftCircleRadius, 0,0,1,leftTopPoint.x,leftTopPoint.y);
            path.lineToByPoint(leftTopPoint);
            path.lineToByPoint(rightTopPoint);
            path.circle(rightCircleRadius, rightCircleRadius, 0,0,1,rightBottomPoint.x,rightBottomPoint.y);
            path.lineToByPoint(rightBottomPoint);
            path.lineToByPoint(leftBottomPoint);

            borderLeftPath.moveToByPoint(leftBottomPoint);
            borderLeftPath.circle(leftCircleRadius, leftCircleRadius, 0,0,1,leftTopPoint.x,leftTopPoint.y);

            borderLeftPath.lineToByPoint(leftTopPoint);

            borderRightPath.moveToByPoint(rightTopPoint);
            borderRightPath.circle(rightCircleRadius, rightCircleRadius, 0,0,1,rightBottomPoint.x,rightBottomPoint.y);

            borderRightPath.lineToByPoint(rightBottomPoint);
        }


        this.renderCanvasByView(this.backgroundDom, path, this.fullColor, 0);
        this.renderCanvasByView(this.backgroundLine, borderLeftPath, Colors.getUiColor(Colors.clear))
        this.renderCanvasByView(this.backgroundLine2, borderRightPath, Colors.getUiColor(Colors.clear))
        this.renderObj({ d: path, dom: this.clipPathGraph});
    }

    setAngleBraces() {//尖括号
        if (this.data == null) {
            return;
        }

        let path = new LinePath();
        let borderLeftPath = new LinePath();
        let borderRightPath = new LinePath();

        let bracesWidth =  this.UiUtil.dip2px(10);
        let bracesHeight = Math.min(this.UiUtil.dip2px(100), this.data.height);

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {
            path.moveTo(bracesWidth, this.data.height);
            path.lineTo(0, this.data.height / 2);
            path.lineTo(bracesWidth, 0);
            path.lineTo(this.data.width - bracesWidth, 0);
            path.lineTo(this.data.width, this.data.height/2);
            path.lineTo(this.data.width - bracesWidth, this.data.height);
            path.lineTo(bracesWidth, this.data.height);

            borderLeftPath.moveTo(bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);
            borderLeftPath.lineTo(0, this.data.height / 2);
            borderLeftPath.lineTo(bracesWidth, (this.data.height - bracesHeight)/2);

            borderRightPath.moveTo(this.data.width - bracesWidth, this.data.height - (this.data.height - bracesHeight)/2);
            borderRightPath.lineTo(this.data.width, this.data.height / 2);
            borderRightPath.lineTo(this.data.width - bracesWidth, (this.data.height - bracesHeight)/2);
        }


        this.renderCanvasByView(this.backgroundDom, path, this.fullColor, 0);
        this.renderCanvasByView(this.backgroundLine, borderLeftPath, Colors.getUiColor(Colors.clear))
        this.renderCanvasByView(this.backgroundLine2, borderRightPath, Colors.getUiColor(Colors.clear))
        this.renderObj({ d: path, dom: this.clipPathGraph});
    }

    setHeartShape() {//心
        if (this.data == null) {
            return;
        }

        let path = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {

            let middleTopPoint = new CGPoint(this.data.width/2, this.data.height * 0.13893);
            let middleBottomPoint = new CGPoint(this.data.width/2, this.data.height);

            let leftTopPoint = new CGPoint(this.data.width * 0.2712, 0);
            let leftMiddle1Point = new CGPoint(this.data.width * 0.018574, this.data.height * 0.19516);
            let leftMiddle2Point = new CGPoint(0, this.data.height * 0.316129);
            let leftBottomPoint = new CGPoint(this.data.width * 0.0423477, this.data.height * 0.500807);
            let leftBottom1Point = new CGPoint(this.data.width * 0.2726597, this.data.height * 0.809677);

            let rightTopPoint = new CGPoint(this.data.width - leftTopPoint.x, 0);
            let rightmiddle1Point = new CGPoint(this.data.width - leftMiddle1Point.x,  leftMiddle1Point.y);
            let rightmiddle2Point = new CGPoint(this.data.width - leftMiddle2Point.x,  leftMiddle2Point.y);
            let rightBottomPoint = new CGPoint(this.data.width - leftBottomPoint.x,  leftBottomPoint.y);
            let rightBottom1Point = new CGPoint(this.data.width - leftBottom1Point.x,  leftBottom1Point.y);

            let leftTopCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftMiddle1Point, middleTopPoint);
            let leftTopCircleRadius = this.Util.getPointSpacingByCGPoint(leftTopCircleCenter, middleTopPoint);

            let leftCircleCenter = this.Util.getCircleCenter(leftMiddle1Point, leftMiddle2Point, leftBottomPoint);
            let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, leftBottomPoint);

            let rightTopCircleCenter = this.Util.getCircleCenter(rightTopPoint, rightmiddle1Point, middleTopPoint);
            let rightTopCircleRadius = this.Util.getPointSpacingByCGPoint(rightTopCircleCenter, rightmiddle1Point);

            let rightCircleCenter = this.Util.getCircleCenter(rightmiddle2Point, rightmiddle1Point, rightBottomPoint);
            let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, rightBottomPoint);

            let leftBottomCircleCenter = this.Util.getCircleCenter(leftBottomPoint, leftBottom1Point, middleBottomPoint);
            let leftBottomCircleRadius = this.Util.getPointSpacingByCGPoint(leftBottomCircleCenter, leftBottomPoint);

            let rightBottomCircleCenter = this.Util.getCircleCenter(rightBottomPoint, rightBottom1Point, middleBottomPoint);
            let rightBottomCircleRadius = this.Util.getPointSpacingByCGPoint(rightBottomCircleCenter, rightBottomPoint);

            path.moveToByPoint(rightBottomPoint);
            path.circle(rightBottomCircleRadius, rightBottomCircleRadius, 0, 0, 1, middleBottomPoint.x, middleBottomPoint.y);
            path.circle(leftBottomCircleRadius, leftBottomCircleRadius, 0, 0, 1, leftBottomPoint.x, leftBottomPoint.y);
            path.circle(leftCircleRadius, leftCircleRadius, 0, 0, 1, leftMiddle1Point.x, leftMiddle1Point.y);
            path.circle(leftTopCircleRadius, leftTopCircleRadius, 0, 0, 1, middleTopPoint.x, middleTopPoint.y);
            path.circle(rightTopCircleRadius, rightTopCircleRadius, 0, 0, 1, rightmiddle1Point.x, rightmiddle1Point.y);
            path.circle(rightCircleRadius, rightCircleRadius, 0, 0, 1, rightBottomPoint.x, rightBottomPoint.y);
            path.lineToByPoint(rightBottomPoint);
        }
        this.renderCanvas(path, this.getBackgorundColor())
        this.backgroundDom.setAttribute("stroke-linejoin", "arcs")
    }

    setGemstoneShape() {//宝石
        if (this.data == null) {
            return;
        }
        let cornerRadius = this.UiUtil.dip2px(4);

        let path = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape()
            return
        } else {

            let leftTopPoint = new CGPoint(this.data.width * 0.13735, 0);
            let leftMiddlePoint = new CGPoint(0, this.data.height * 0.250);

            let rightTopPoint = new CGPoint(this.data.width - leftTopPoint.x, 0);
            let rightMiddlePoint = new CGPoint(this.data.width - leftMiddlePoint.x,  leftMiddlePoint.y);

            let bottomMiddlePoint = new CGPoint(this.data.width/2,  this.data.height);

            path.moveTo(leftTopPoint.x + this.UiUtil.dip2px(10), leftTopPoint.y);
            path.lineToByPoint(leftTopPoint);
            path.lineToByPoint(leftMiddlePoint);
            path.lineToByPoint(bottomMiddlePoint);
            path.lineToByPoint(rightMiddlePoint);
            path.lineToByPoint(rightTopPoint);
            path.lineTo(leftTopPoint.x + this.UiUtil.dip2px(10), leftTopPoint.y);
        }

        this.renderCanvas(path, this.getBackgorundColor())
    }

    setStarShape() {//五角星
        if (this.data == null) {
            return;
        }

        let path = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape();
            return
        } else {

            let circleCenter = new CGPoint(this.data.width/2, this.data.height/2);
            let radius = this.data.width/2;
            let innerRadius = radius * 0.55;
            let angle = 72;

            let startPoint = new Point();
            for (var index = 0; index < 5; index++) {
                let point = this.Util.getCirclePoint(circleCenter, (270 + index * angle), radius);
                let innerPoint = this.Util.getCirclePoint(circleCenter, (270 + angle/2 + index * angle), innerRadius);
                if (index == 0) {
                    path.moveToByPoint(point);
                    startPoint = point;
                } else {
                    path.lineToByPoint(point);
                }
                path.lineToByPoint(innerPoint);
            }
            path.lineToByPoint(startPoint);
        }

        this.renderCanvas(path, this.getBackgorundColor())
    }

    setOctagonalShape() {//八边形
        if (this.data == null) {
            return;
        }

        let path = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape();
            return
        } else {

            let circleCenter = new CGPoint(this.data.width/2, this.data.height/2);
            let radius = this.data.width/2;
            let angle = 360.0/8.0;

            let startPoint = new Point();
            for (var index = 0; index < 8; index++) {
                let point = this.Util.getCirclePoint(circleCenter, (270 - angle/2 + index * angle), radius);
                if (index == 0) {
                    path.moveToByPoint(point);
                    startPoint = point;
                } else {
                    path.lineToByPoint(point);
                }
            }
            path.lineToByPoint(startPoint);
        }

        this.renderCanvas(path, this.getBackgorundColor())
    }

    setRegularHexagonShape() {//正六边形
        if (this.data == null) {
            return;
        }

        let path = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape();
            return
        } else {

            let circleCenter = new CGPoint(this.data.width/2, this.data.height/2);
            let radius = this.data.width/2;
            let angle = 360.0/6.0;

            let startPoint = new Point();
            for (var index = 0; index < 6; index++) {
                let point = this.Util.getCirclePoint(circleCenter, (270 - angle/2 + index * angle), radius);
                if (index == 0) {
                    path.moveToByPoint(point);
                    startPoint = point;
                } else {
                    path.lineToByPoint(point);
                }
            }
            path.lineToByPoint(startPoint);
        }
        this.renderCanvas(path, this.getBackgorundColor())
    }

    setShieldShape() {//盾牌
        if (this.data == null) {
            return;
        }

        let path = new LinePath();

        if (this.data.layout == NodeLayoutType.LAYOUT_TRIANGLE && this.data.type != CONTENT_GENERALIZATION) {
            this.setTriangleShape();
            return
        } else {

            let middleTopPoint = new CGPoint(this.data.width/2, 0);
            let middleBottomPoint = new CGPoint(this.data.width/2, this.data.height);

            let leftTopPoint = new CGPoint(0, this.data.height * 0.0768);
            let leftTopMiddlePoint = new CGPoint(this.data.width * 0.235, this.data.height * 0.089);
            let leftMiddle1Point = new CGPoint(0, this.data.height * 0.6334);
            let leftMiddle2Point = new CGPoint(this.data.width * 0.0514, this.data.height * 0.71094);
            let leftMiddle3Point = new CGPoint(this.data.width * 0.1634, this.data.height * 0.81594);
            let leftMiddle4Point = new CGPoint(this.data.width * 0.3534, this.data.height * 0.93594);

            let rightTopPoint = new CGPoint(this.data.width - leftTopPoint.x, leftTopPoint.y);
            let rightTopMiddlePoint = new CGPoint(this.data.width - leftTopMiddlePoint.x, leftTopMiddlePoint.y);
            let rightmiddle1Point = new CGPoint(this.data.width - leftMiddle1Point.x,  leftMiddle1Point.y);
            let rightmiddle2Point = new CGPoint(this.data.width - leftMiddle2Point.x,  leftMiddle2Point.y);
            let rightmiddle3Point = new CGPoint(this.data.width - leftMiddle3Point.x,  leftMiddle3Point.y);
            let rightmiddle4Point = new CGPoint(this.data.width - leftMiddle4Point.x,  leftMiddle4Point.y);

            let leftTopCircleCenter = this.Util.getCircleCenter(leftTopPoint, leftTopMiddlePoint, middleTopPoint);
            let leftTopCircleRadius = this.Util.getPointSpacingByCGPoint(leftTopCircleCenter, leftTopPoint);

            let leftCircleCenter = this.Util.getCircleCenter(leftMiddle1Point, leftMiddle2Point, leftMiddle3Point);
            let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, leftMiddle1Point);

            let leftBottomCircleCenter = this.Util.getCircleCenter(leftMiddle3Point, leftMiddle4Point, middleBottomPoint);
            let leftBottomCircleRadius = this.Util.getPointSpacingByCGPoint(leftBottomCircleCenter, middleBottomPoint);

            let rightTopCircleCenter = this.Util.getCircleCenter(rightTopPoint, rightTopMiddlePoint, middleTopPoint);
            let rightTopCircleRadius = this.Util.getPointSpacingByCGPoint(rightTopCircleCenter, rightTopPoint);

            let rightCircleCenter = this.Util.getCircleCenter(rightmiddle1Point, rightmiddle2Point, rightmiddle3Point);
            let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, rightmiddle2Point);

            let rightBottomCircleCenter = this.Util.getCircleCenter(rightmiddle3Point, rightmiddle4Point, middleBottomPoint);
            let rightBottomCircleRadius = this.Util.getPointSpacingByCGPoint(rightBottomCircleCenter, middleBottomPoint);

            path.moveToByPoint(leftTopPoint);

            path.circle(leftTopCircleRadius, leftTopCircleRadius, 0, 0, 0, middleTopPoint.x, middleTopPoint.y);
            path.circle(rightTopCircleRadius, rightTopCircleRadius, 0, 0, 0, rightTopPoint.x, rightTopPoint.y);
            path.lineToByPoint(rightmiddle1Point);
            path.circle(rightCircleRadius, rightCircleRadius, 0, 0, 1, rightmiddle3Point.x, rightmiddle3Point.y);
            path.circle(rightBottomCircleRadius, rightBottomCircleRadius, 0, 0, 1, middleBottomPoint.x, middleBottomPoint.y);
            path.lineToByPoint(middleBottomPoint);
            path.circle(leftBottomCircleRadius, leftBottomCircleRadius, 0, 0, 1, leftMiddle3Point.x, leftMiddle3Point.y);
            path.lineToByPoint(leftMiddle3Point);
            path.circle(leftCircleRadius, leftCircleRadius, 0, 0, 1, leftMiddle1Point.x, leftMiddle1Point.y);
            path.lineToByPoint(leftTopPoint);
        }
        this.renderCanvas(path, this.getBackgorundColor())
    }


    getHexagonRadius() {
        return this.data.isEmpty() ? new UiUtil().dip2px(5) : new UiUtil().dip2px(8);
    }

    isShadow() {    //判断哪些类型的可以有阴影
        if (this.data == null || this.data.isEmpty() || this.data.layout == NodeLayoutType.LAYOUT_FORM || this.data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return false;
        }
        return this.data.isShadow && !Colors.isClear(this.data.backgroundColor);
    }
}

export default MindNodeBackgroundView
