import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import MindType from "../../../viewmodel/datatype/MindType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import HashMap from "../../../viewmodel/core/base/HashMap"
import Util from "../../../utils/Util"
import CGRect from "../../../viewmodel/core/base/basedata/Rect"
import FreeNodeCheckPointDirection from "../../../viewmodel/datatype/FreeNodeCheckPointDirection"
import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/10
 * Copyright(c) 2020 mindyushu.com
 */

class MindElementFreeNodeCheckPoint {
    constructor() {
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.allRootNodeTreeRectMaps = new HashMap();
        this.allRootNodeTreeRectShapeTypeMaps = new HashMap();
        this.originalCGRect = new CGRect(0, 0, 0, 0);
        this.rootRect = new CGRect(0, 0, 0, 0);
        this.rectShapeType = MindElementShapeType.Unknown;
        this.rootRectShapeType = MindElementShapeType.Unknown;
    }

    setData(mainMindNodeUnit, 
            timeMindTypeNodeUnit, 
            doubleBubbleMindNodeUnit, 
            allRootNodeTreeRectMaps, 
            allRootNodeTreeRectShapeTypeMaps = new HashMap()) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
        this.allRootNodeTreeRectMaps = allRootNodeTreeRectMaps;
        this.allRootNodeTreeRectShapeTypeMaps = allRootNodeTreeRectShapeTypeMaps;
        if (this.mainMindNodeUnit.rootTreeNode != null && !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            this.rootRect = this.mainMindNodeUnit.getRectByMargin(0, 0, 0, 0);
            if (this.mainMindNodeUnit.rootTreeNode.children.length == 0) {
                this.rootRectShapeType = this.mainMindNodeUnit.rootTreeNode.value.mindElementShape;
            } else {
                this.rootRectShapeType = MindElementShapeType.Unknown;
            }
        }
        if (this.doubleBubbleMindNodeUnit.rootTreeNode != null && !this.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty()) {
            this.rootRect = this.doubleBubbleMindNodeUnit.getRect(0);
            if (this.doubleBubbleMindNodeUnit.rootTreeNode.children.length == 0) {
                this.rootRectShapeType = this.doubleBubbleMindNodeUnit.rootTreeNode.value.mindElementShape;
            } else {
                this.rootRectShapeType = MindElementShapeType.Unknown;
            }
        }
        if (this.timeMindTypeNodeUnit.title != null && !this.timeMindTypeNodeUnit.title.isEmpty()) {
            this.rootRect = this.timeMindTypeNodeUnit.getRect(0, 0);
        }
    }

    setRect(rect, shapeType = MindElementShapeType.Unknown) {
        this.originalCGRect = new CGRect(rect.x, rect.y, rect.width(), rect.height());
        this.rectShapeType = shapeType;
    }

    getPoint(rect) {
        let left = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Left)
        if (left.x == -1 && left.y == -1) {
            return left
        }
        let top = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Top)
        if (top.x == -1 && top.y == -1) {
            return top
        }
        let right = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Rigth)
        if (right.x == -1 && right.y == -1) {
            return right
        }
        let bottom = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Bottom)
        if (bottom.x == -1 && bottom.y == -1) {
            return bottom
        }
        let target = left
        let targetHorizontal = true
        if (Math.abs(left.x) > Math.abs(top.y)) {
            target = top
            targetHorizontal = false
        }
        if (targetHorizontal) {
            if (Math.abs(target.x) > Math.abs(right.x)) {
                target = right
                targetHorizontal = true
            }
        } else {
            if (Math.abs(target.y) > Math.abs(right.x)) {
                target = right
                targetHorizontal = true
            }
        }
        if (targetHorizontal) {
            if (Math.abs(target.x) > Math.abs(bottom.y)) {
                target = bottom
                targetHorizontal = false
            }
        } else {
            if (Math.abs(target.y) > Math.abs(bottom.y)) {
                target = bottom
                targetHorizontal = false
            }
        }
        return target
    }

    getHorizontalPoint(rect) {       
        let left = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Left)
        if (left.x == -1 && left.y == -1) {
            return left
        }
        let right = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Rigth)
        if (right.x == -1 && right.y == -1) {
            return right
        }
        let target = left
        if (Math.abs(left.x) > Math.abs(right.x)) {
            target = right
        }
        return target
    }

    getVerticalPoint(rect) {       
        let top = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Top)
        if (top.x == -1 && top.y == -1) {
            return top
        }
        let bottom = this.getPointByDirection(new CGRect(this.originalCGRect.x, this.originalCGRect.y, rect.width(), rect.height()), 0, FreeNodeCheckPointDirection.Bottom)
        if (bottom.x == -1 && bottom.y == -1) {
            return bottom
        }
        let target = top
        if (Math.abs(top.y) > Math.abs(bottom.y)) {
            target = bottom
        }
        return target
    }

    getPointByDirection(rect, count, direction) {
        if (arguments.length == 1) {
            count = 0;
            direction = FreeNodeCheckPointDirection.Unknown;
        }
        if (count > 500) {
            return new CGPoint(-1, -1);
        }
        let nodeList = []
        let mainMindElementDataDictKeys = this.mainMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < mainMindElementDataDictKeys.length; index++) {
            let key = mainMindElementDataDictKeys[index]
            var node = this.mainMindNodeUnit.mainMindElementDataDict.get(key);
            if (node != null && !node.isEmpty() && !node.value.isHidden) {
                nodeList.push(node.value)
            }
        }
        let mainMindElementDataDict =  this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < mainMindElementDataDict.length; index++) {
            let key = mainMindElementDataDict[index]
            let  node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
            if (node != null && !node.isEmpty() && !node.value.isHidden) {
                nodeList.push(node.value)
            }
        }
        let commonGroundMindElementDatasLenght = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length
        for (let index = 0; index < commonGroundMindElementDatasLenght; index++) {
            let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
            if (data != null && !data.isEmpty() && !data.isHidden) {
                nodeList.push(data)
            }
        }
        let generalizationMindElementDataDict = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()
        for (let index = 0; index < generalizationMindElementDataDict.length; index++ ) {
            let key = generalizationMindElementDataDict[index]
            let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
            if (node != null && !node.isEmpty() && !node.value.isHidden) {
                nodeList.push(node.value)
            }
        }
        let explainMindElementDataDict = this.mainMindNodeUnit.explainMindElementDataDict.keys()
        let explainMindElementDataDictCount = explainMindElementDataDict.length;
        for (let index = 0; index < explainMindElementDataDictCount; index++ ) {
            let key = explainMindElementDataDict[index]
            let data = this.mainMindNodeUnit.explainMindElementDataDict.get(key);
            if (data != null && !data.isEmpty() && !data.isHidden) {
                nodeList.push(data)
            }
        }
        let timeMindTypeNodeUnitMainMindElementDataDictArr = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys();
        for (let index = 0; index < timeMindTypeNodeUnitMainMindElementDataDictArr.length; index++) {
            let key = timeMindTypeNodeUnitMainMindElementDataDictArr[index]
            let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
            if (node != null && !node.head.isEmpty()) {
                nodeList.push(node.head)
            }
            if (node != null && !node.title.isEmpty()) {
                nodeList.push(node.title)
            }
            if (node != null && !node.desc.isEmpty()) {
                nodeList.push(node.desc)
            }
        }
        for (let index = 0; index < nodeList.length; index++) {
            var data = nodeList[index];
            if (data != null && this.matrixHitByRect(rect, data)) {
                switch (direction) {
                    case FreeNodeCheckPointDirection.Unknown:                            
                        let left = Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width / 2, data.height));
                        let top = Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width, data.height / 2));
                        let right = Util.getCrossArea(rect, new CGRect(data.x + data.width / 2, data.y, data.width / 2, data.height));
                        let bottom = Util.getCrossArea(rect, new CGRect(data.x, data.y + data.height / 2, data.width, data.height / 2));
                        
                        if (left > top && left > right && left > bottom) {
                            rect.x = data.x - rect.width() - 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Left);
                        } else if (top > left && top > right && top > bottom) {
                            rect.y = data.y - rect.height() - 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Top);
                        } else if (right > left && right > top && right > bottom) {
                            rect.x = data.x + data.width + 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Rigth);
                        } else {
                            rect.y = data.y + data.height + 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Bottom);
                        }
                    case FreeNodeCheckPointDirection.Left:
                        rect.x = data.x - rect.width() - 1;
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Left);
                    case FreeNodeCheckPointDirection.Top:
                        rect.y = data.y - rect.height() - 1;
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Top);
                    case FreeNodeCheckPointDirection.Rigth:
                        rect.x = data.x + data.width + 1;
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Rigth);
                    case FreeNodeCheckPointDirection.Bottom:
                        rect.y = data.y + data.height + 1;
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Bottom);
                    default:
                }
            }
        }
        
        let allRootNodeTreeRectMapsKeys = this.allRootNodeTreeRectMaps.keys()
        for (let index = 0; index < allRootNodeTreeRectMapsKeys.length; index++) {
            let key = allRootNodeTreeRectMapsKeys[index]
            var cell = this.allRootNodeTreeRectMaps.get(key);
            var cellShapeType = this.allRootNodeTreeRectShapeTypeMaps.get(key);
            if (this.matrixRectHitByRect(rect, cell, cellShapeType, this.rectShapeType)) {
                switch (direction) {
                    case FreeNodeCheckPointDirection.Unknown:
                        let x = rect.x + rect.width() / 2;
                        let y = rect.y + rect.height() / 2;
                        let left = x - cell.x;
                        let top = y - cell.y;
                        let right = cell.x + cell.width() - x;
                        let bottom = cell.y + cell.height() - y;
                        if (left < top && left < right && left < bottom) {
                            rect.x = cell.x - rect.width() - 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Left);
                        } else if (top < left && top < right && top < bottom) {
                            rect.y = cell.y - rect.height() - 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Top);
                        } else if (right < left && right < top && right < bottom) {
                            rect.x = cell.x + cell.width() + 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Rigth);
                        } else {
                            rect.y = cell.y + cell.height() + 1;
                            return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Bottom);
                        }
                    case FreeNodeCheckPointDirection.Left:
                        if (cellShapeType == MindElementShapeType.Regular_hexagon ||
                            this.rectShapeType == MindElementShapeType.Regular_hexagon) {                                
                            rect.x = rect.x - 4 - 1;
                        } else {
                            rect.x = cell.x - rect.width() - 1;
                        }
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Left);
                    case FreeNodeCheckPointDirection.Top:
                        if (cellShapeType == MindElementShapeType.Regular_hexagon ||
                            this.rectShapeType == MindElementShapeType.Regular_hexagon) {
                            rect.y = rect.y - 4 - 1;
                        } else {
                            rect.y = cell.y - rect.height() - 1;
                        }
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Top);
                    case FreeNodeCheckPointDirection.Rigth:
                        if (cellShapeType == MindElementShapeType.Regular_hexagon ||
                            this.rectShapeType == MindElementShapeType.Regular_hexagon) {
                            rect.x = rect.x + 4 + 1;
                        } else {
                            rect.x = cell.x + cell.width() + 1;
                        }
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Rigth);
                    case FreeNodeCheckPointDirection.Bottom:
                        if (cellShapeType == MindElementShapeType.Regular_hexagon ||
                            this.rectShapeType == MindElementShapeType.Regular_hexagon) {
                            rect.y = rect.y + 4 + 1;
                        } else {
                            rect.y = cell.y + cell.height() + 1;
                        }                        
                        return this.getPointByDirection(rect, count + 1, FreeNodeCheckPointDirection.Bottom);
                    default:
                }
            }
        }

        if (rect.x == this.originalCGRect.x && rect.y == this.originalCGRect.y) {
            return new CGPoint(-1, -1);
        } else {
            return new CGPoint(rect.x - this.originalCGRect.x, rect.y - this.originalCGRect.y);
        }
    }

    matrixHitByRect(rect, data) {  
        if (this.rectShapeType == MindElementShapeType.Circular ||
            this.rectShapeType == MindElementShapeType.Ring3 ||
            this.rectShapeType == MindElementShapeType.Ring) {
                let rectCenterX = rect.x + rect.width() / 2
                let rectCenterY = rect.y + rect.height() / 2
                if (data.mindElementShape == MindElementShapeType.Circular ||
                    data.mindElementShape == MindElementShapeType.Ring3 ||
                    data.mindElementShape == MindElementShapeType.Ring) {
                        let centerDistance = Util.getPointSpacing(new CGPoint(rectCenterX, rectCenterY), new CGPoint(data.x + data.width / 2, data.y + data.height / 2));
                        if (centerDistance < (rect.width() / 2 + data.width / 2)) {
                            return true;
                        } else {
                            return false;
                        }
                } else if (!data.isHidden && Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width, data.height)) > 2) {
                    return true;
                } else {
                    return false;
                }
        } else if (this.rectShapeType == MindElementShapeType.Regular_hexagon) {
                if (data.mindElementShape == MindElementShapeType.Regular_hexagon) {
                    let fullCrossArea = Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width, data.height))
                    if (fullCrossArea <= 2) {
                        return false;
                    }
                    let rectCenter = new CGPoint(rect.x + rect.width()/2, rect.y + rect.height()/2);
                    let rectRadius = rect.width()/2;

                    let dataCenter = new CGPoint(data.x + data.width/2, data.y + data.height/2);
                    let dataRadius = data.width/2;
                    let angle = 360.0/6.0;
                    let rectPoints = []
                    let dataPoints = []                    
                    for (var index = 0; index < 6; index++) {
                        let point1 = Util.getCirclePoint(rectCenter, (270 - angle/2 + index * angle), rectRadius);
                        rectPoints.push(point1)
                        let point2 = Util.getCirclePoint(dataCenter, (270 - angle/2 + index * angle), dataRadius);
                        dataPoints.push(point2)
                    }
                    if (fullCrossArea == rect.width() * rect.height() || fullCrossArea == data.width * data.height) {
                        return true;
                    }
                    let rectPointsLength = rectPoints.length
                    let dataPointsLength = dataPoints.length
                    for (var index = 0; index < rectPointsLength; index++) {
                        let linePoint1 = rectPoints[index]
                        let linePoint2 = null
                        if (index == rectPointsLength - 1) {
                            linePoint2 = rectPoints[0]
                        } else {
                            linePoint2 = rectPoints[index + 1]
                        }
                        for (var j = 0; j < dataPointsLength; j++) {
                            let linePoint3 = dataPoints[j]
                            let linePoint4 = null
                            if (j == dataPointsLength - 1) {
                                linePoint4 = dataPoints[0]
                            } else {
                                linePoint4 = dataPoints[j + 1]
                            }
                            
                            let intersect = Util.intersection(linePoint1.x, linePoint1.y, linePoint2.x, linePoint2.y,
                                                                linePoint3.x, linePoint3.y, linePoint4.x, linePoint4.y);
                            if (intersect) {
                                return true
                            }
                        }
                    }
                    return false;
                } else if (!data.isHidden && Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width, data.height)) > 2) {
                    return true;
                } else {
                    return false;
                }
        } else if (!data.isHidden && Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width, data.height)) > 2) {
            return true;
        } else {
            return false;
        }
    }

    matrixRectHitByRect(rect1, rect2, rect1ShapeType = MindElementShapeType.Corner, rect2ShapeType = MindElementShapeType.Corner) {
        if (rect1ShapeType == MindElementShapeType.Circular ||
            rect1ShapeType == MindElementShapeType.Ring3 ||
            rect1ShapeType == MindElementShapeType.Ring) {
                let rectCenterX = rect1.x + rect1.width() / 2
                let rectCenterY = rect1.y + rect1.height() / 2
                if (rect2ShapeType == MindElementShapeType.Circular ||
                    rect2ShapeType == MindElementShapeType.Ring3 ||
                    rect2ShapeType == MindElementShapeType.Ring) {
                        let centerDistance = Util.getPointSpacing(new CGPoint(rectCenterX, rectCenterY), 
                                                                new CGPoint(rect2.x + rect2.width() / 2, rect2.y + rect2.height() / 2));
                        if (centerDistance < (rect1.width() / 2 + rect2.width() / 2)) {
                            return true;
                        } else {
                            return false;
                        }
                } else {
                    return Util.getCrossArea(rect1, rect2) > 2;
                }
        } else if (rect1ShapeType == MindElementShapeType.Regular_hexagon) {
            if (rect2ShapeType == MindElementShapeType.Regular_hexagon) {
                let fullCrossArea = Util.getCrossArea(rect1, rect2)
                if (fullCrossArea <= 2) {
                    return false;
                }
                
                let rect1Center = new CGPoint(rect1.x + rect1.width()/2, rect1.y + rect1.height()/2);
                let rect1Radius = rect1.width()/2;

                let rect2Center = new CGPoint(rect2.x + rect2.width()/2, rect2.y + rect2.height()/2);
                let rect2Radius = rect2.width()/2;
                let angle = 360.0/6.0;
                let rect1Points = []
                let rect2Points = []                    
                for (var index = 0; index < 6; index++) {
                    let point1 = Util.getCirclePoint(rect1Center, (270 - angle/2 + index * angle), rect1Radius);
                    rect1Points.push(point1)
                    let point2 = Util.getCirclePoint(rect2Center, (270 - angle/2 + index * angle), rect2Radius);
                    rect2Points.push(point2)
                }
                if (fullCrossArea == rect1.width() * rect1.height() || fullCrossArea == rect2.width() * rect2.height()) {
                    return true;
                }
                let rect1PointsLength = rect1Points.length
                let rect2PointsLength = rect2Points.length
                for (var index = 0; index < rect1PointsLength; index++) {
                    let linePoint1 = rect1Points[index]
                    let linePoint2 = null
                    if (index == rect1PointsLength - 1) {
                        linePoint2 = rect1Points[0]
                    } else {
                        linePoint2 = rect1Points[index + 1]
                    }
                    for (var j = 0; j < rect2PointsLength; j++) {
                        let linePoint3 = rect2Points[j]
                        let linePoint4 = null
                        if (j == rect2PointsLength - 1) {
                            linePoint4 = rect2Points[0]
                        } else {
                            linePoint4 = rect2Points[j + 1]
                        }
                        
                        let intersect = Util.intersection(linePoint1.x, linePoint1.y, linePoint2.x, linePoint2.y,
                                                            linePoint3.x, linePoint3.y, linePoint4.x, linePoint4.y);
                        if (intersect) {
                            return true
                        }
                    }
                }
                return false;
            } else if (Util.getCrossArea(rect1, rect2) > 2) {
                return true;
            } else {
                return false;
            }
        }
        return Util.getCrossArea(rect1, rect2) > 2;
    }

    getRegularHexagonIntersectSpace(rect1, rect2, direction = FreeNodeCheckPointDirection.Bottom) {
        let fullCrossArea = Util.getCrossArea(rect1, rect2)
        if (fullCrossArea <= 2) {
            return new CGPoint(0, 0);
        }
        let rect1Center = new CGPoint(rect1.x + rect1.width()/2, rect1.y + rect1.height()/2);
        let rect1Radius = rect1.width()/2;

        let rect2Center = new CGPoint(rect2.x + rect2.width()/2, rect2.y + rect2.height()/2);
        let rect2Radius = rect2.width()/2;
        let angle = 360.0/6.0;
        let rect1Points = []
        let rect2Points = []                    
        for (var index = 0; index < 6; index++) {
            let point1 = Util.getCirclePoint(rect1Center, (270 - angle/2 + index * angle), rect1Radius);
            rect1Points.push(point1)
            let point2 = Util.getCirclePoint(rect2Center, (270 - angle/2 + index * angle), rect2Radius);
            rect2Points.push(point2)
        }
        if (fullCrossArea == rect1.width() * rect1.height() || fullCrossArea == rect2.width() * rect2.height()) {
            switch (direction) {
                case FreeNodeCheckPointDirection.Left:
                    if (rect1.width() > rect2.width()) {
                        return new CGPoint(rect2.x + rect2.width() - rect1.x, 0);
                    } else {
                        return new CGPoint(rect1.x + rect1.width() - rect2.x, 0);
                    }
                case FreeNodeCheckPointDirection.Top:
                    if (rect1.height() > rect2.height()) {
                        return new CGPoint(0, rect2.y + rect2.height() - rect1.y);
                    } else {
                        return new CGPoint(0, rect1.y + rect1.height() - rect2.y);
                    }
                case FreeNodeCheckPointDirection.Rigth:
                    if (rect1.width() > rect2.width()) {
                        return new CGPoint(rect1.x + rect1.width() - rect2.x, 0);
                    } else {
                        return new CGPoint(rect2.x + rect2.width() - rect1.x, 0);
                    }
                case FreeNodeCheckPointDirection.Bottom:
                    if (rect1.height() > rect2.height()) {
                        return new CGPoint(0, rect1.y + rect1.height() - rect2.y);
                    } else {
                        return new CGPoint(0, rect2.y + rect2.height() - rect1.y);
                    }
                default:
                    if (rect1.height() > rect2.height()) {
                        return new CGPoint(0, rect1.y + rect1.height() - rect2.y);
                    } else {
                        return new CGPoint(0, rect2.y + rect2.height() - rect1.y);
                    } 
            }
        }
        let rect1PointsLength = rect1Points.length
        let rect2PointsLength = rect2Points.length
        let points = []
        for (var index = 0; index < rect1PointsLength; index++) {
            let linePoint1 = rect1Points[index]
            let linePoint2 = null
            if (index == rect1PointsLength - 1) {
                linePoint2 = rect1Points[0]
            } else {
                linePoint2 = rect1Points[index + 1]
            }
            for (var j = 0; j < rect2PointsLength; j++) {
                let linePoint3 = rect2Points[j]
                let intersect = Util.intersection(linePoint1.x, linePoint1.y, linePoint2.x, linePoint2.y,
                                                    linePoint3.x, linePoint3.y, rect1Center.x, rect1Center.y);
                if (intersect) {
                    continue
                } else {
                    
                    switch (direction) {
                        case FreeNodeCheckPointDirection.Left:
                            if (rect1.width() > rect2.width()) {
                                return new CGPoint(rect2.x + rect2.width() - rect1.x, 0);
                            } else {
                                return new CGPoint(rect1.x + rect1.width() - rect2.x, 0);
                            }
                        case FreeNodeCheckPointDirection.Top:
                            if (rect1.height() > rect2.height()) {
                                return new CGPoint(0, rect2.y + rect2.height() - rect1.y);
                            } else {
                                return new CGPoint(0, rect1.y + rect1.height() - rect2.y);
                            }
                        case FreeNodeCheckPointDirection.Rigth:
                            if (rect1.width() > rect2.width()) {
                                return new CGPoint(rect1.x + rect1.width() - rect2.x, 0);
                            } else {
                                return new CGPoint(rect2.x + rect2.width() - rect1.x, 0);
                            }
                        case FreeNodeCheckPointDirection.Bottom:
                            if (rect1.height() > rect2.height()) {
                                return new CGPoint(0, rect1.y + rect1.height() - rect2.y);
                            } else {
                                return new CGPoint(0, rect2.y + rect2.height() - rect1.y);
                            }
                        default:
                            if (rect1.height() > rect2.height()) {
                                return new CGPoint(0, rect1.y + rect1.height() - rect2.y);
                            } else {
                                return new CGPoint(0, rect2.y + rect2.height() - rect1.y);
                            } 
                    }
                }
            }
        }
        return new CGPoint(0, 0);
    }
}

export default MindElementFreeNodeCheckPoint