<template>
  <div class="menu-canvas">

    <!-- 布局 -->
    <div class="menu-bgcolor menu-layout" 
      style="width: 255px; height:80px; border-top: 0px;"
      v-if="showMindLayoutButton"
     @click.stop="setLayoutAccout" >
      <div class="menu-left-accout" ref="titlebox2">
        {{ getString(strings.Mind_Skeleton)
        }}<span @click.stop="detailQuestion(2)" style="margin-left: 10px"
          ><a-icon type="question-circle"
        /></span>
      </div>
      <div
        class="menu-right-accout cursor-pointer"
        style="
          border: 1px solid var(--exportMenuDashedColor);
          padding: 0 5px;
          width: 76px;
          height: 32px;
          line-height: 28px;
        ">
        <img
          class="menu-right-accout-ly"
          :src="nodeStyleLayout"
          style="width: 28px; margin-left: 6px"
          alt=""
        />
        <a-icon
          class="imgthree"
          :class="layoutAccout ? 'imgthree-sel' : 'imgthree-no'"
          type="caret-up"
        />
      </div>
      <div class="menu-right-bg background-transparent">
        <transition name="slide-fade">
          <ComplieChildMenuLayout
            :selectedLayoutType="selectedLayoutType"
            v-if="layoutAccout"
          />
        </transition>
      </div>
    </div>

    <!-- 快速样式 border-top-->
    <div class="menu-bgcolor" style="border-top: 0; height:80px; margin-top: 10px">

      <div class="menu-right-fast-cell" 
        @click.stop="setFastStyleRainbow" >
        <div class="menu-left-accout menu-right-fast-title" 
          style="left: 10px">
          {{ getString(strings.Mind_Fast_Style_Rainbow_Label)
          }}
        </div>
        <img
          class="menu-right-fast-swap-icon"
          :src="fastSwapIcon"
          style="width: 18px; margin-left: 6px"
          alt=""
        />
        <div
          class="menu-right-fast-cell-color"
          style=" ">
          <span
                  class="menu-right-fast-cell-color-rect"
                  v-for="(item, index) in fastRainbowTtems"
                  :key="index"
                  :style="{ background: getRgb(item)}"
                ></span>
        </div>
      </div>

      <div class="menu-right-fast-cell"
          style="margin-left: 10px"
        @click.stop="setFastStyleSolid" >
        <div class="menu-left-accout menu-right-fast-title"
          style="left: 10px">
          {{ getString(strings.Mind_Fast_Style_Solid_Label)
          }}
        </div>
        <img
          class="menu-right-fast-swap-icon"
          :src="fastSwapIcon"
          style="width: 18px; margin-left: 6px"
          alt=""
        />
        <div
          class="menu-right-fast-cell-color"
          style=" ">
          <span
              class="menu-right-fast-cell-color-rect"
              v-for="(item, index) in fastSolidTtems"
              :key="index"
              :style="{ background: getRgb(item) }"
            ></span>
        </div>
      </div>
    </div>

    <!-- 背景色 border-top-->
    <div class="menu-bgcolor" style="border-top: 0; margin-top: -5px"
     @click="setBgcolorAccout">
      <div class="menu-left-accout">
        {{ getString(strings.Mind_Edit_Right_Menu_background_Color) }}
      </div>
      <div class="menu-right-accout cursor-pointer">
        <div
          id="menubgcolor"
          class="menu-right-bgcolor"
          :style="'background:' + bgMindColor + ';'"
        ></div>
      </div>
    </div>
    <div class="menu-right-bg">
      <transition name="slide-fade">
        <CommonBgColor v-if="bgColorAccout" :top="palletTop" />
      </transition>
    </div>
    <div
      v-if="showMindElementButton && showMindLayoutButton"
      class="menu-bgcolor menu-bgcolor-clear-border-top"
      @click="setMindElementTypeShow"
    >
      <div class="menu-left-accout">
        节点类型
        <!-- {{ getString(strings.Mind_Edit_Right_Menu_layout)
        }} -->
        <!-- <span @click.stop="detailQuestion(2)" style="margin-left: 10px"
          ><a-icon type="question-circle"
        /></span> -->
      </div>
      <div
        class="menu-right-accout cursor-pointer"
        style="border: dashed 1px var(--exportMenuDashedColor); padding: 5px"
      >
        <img class="menu-right-accout-ly" :src="mindElementTypeLayout" alt="" />
      </div>
      <div class="menu-right-bg background-transparent">
        <transition name="slide-fade">
          <ComplieChildMenuMIndElementType v-if="mindElementTypeShow" />
        </transition>
      </div>
    </div>
    <div class="menu-bgcolor" style="height: 60px; margin-top: 5px; padding-top: 20px">
      <div class="menu-left-accout">
        <a-checkbox
          :checked="isBranchFreeLayout"
          :disabled="!isShowBranchFreeLayout"
          @change="onChangeBranchFreeLayout"
        >
          <span
            style="margin-left: 0px"
            :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >{{ getString(strings.Mind_Branch_Free_Layout) }}</span
          >
        </a-checkbox>

        <div
          style="
            font-size: 12px;
            transform: scale(0.85);
            position: relative;
            margin-left: 9px;
          "
          :class="
            mapEditDarkMode
              ? 'branch-free-layout-explain-deep'
              : 'branch-free-layout-explain-shallow'
          "
        >
          {{ getString(strings.Mind_Branch_Free_Layout_explain) }}
        </div>
      </div>
    </div>
    <div class="menu-linecolor-box">
      <div class="menu-left-accout">
        <a-checkbox
          :checked="isFreeNodeMoveAdsorbent"
          @change="onChangeFreeNodeMoveAdsorbent"
        >
          <span
            style="margin-left: 0px"
            :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >{{ getString(strings.Mind_Free_Theme_Drag_Dnd_Snap) }}</span
          >
        </a-checkbox>
      </div>
    </div>

    <div class="menu-linecolor-box">
      <div class="menu-left-accout">
        <a-checkbox
          :checked="isTightMode"
          @change="onChangeTightMode"
        >
          <span
            style="margin-left: 0px"
            :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >{{ getString(strings.Mind_Style_Tight_Mode_Label) }}</span
          >
        </a-checkbox>
      </div>
    </div>
    <div class="menu-linecolor-box">
      <div class="menu-left-accout">
        <a-checkbox
          :checked="isSameLevelAlignment"
          @change="onChangeSameLevelAlignment"
        >
          <span
            style="margin-left: 0px"
            :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >{{ getString(strings.Mind_Style_Same_Level_Alignment_Label) }}</span
          >
        </a-checkbox>
      </div>
    </div>
    <div class="menu-linecolor-box" style="margin-bottom: 10px">
      <div class="menu-left-accout">
        <a-checkbox
          :checked="isOverlap"
          @change="onChangeNodeOverlap"
        >
          <span
            style="margin-left: 0px"
            :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >{{ getString(strings.Mind_Style_Node_Overlap) }}</span
          >
        </a-checkbox>
      </div>
    </div>

    <!-- 线条样式 -->
    <div class="menu-bgcolor" v-if="isShowLineStyle">
      <div class="menu-left-accout">
        {{ getString(strings.Mind_Edit_Right_Menu_Line_Style) }}
      </div>
      <div
        class="menu-right-accout cursor-pointer"
        style="
          border: 1px solid var(--exportMenuDashedColor);
          padding: 0 5px;
          width: 76px;
        "
        @click.stop="setLineStlyeAccout"
      >
        <img class="menu-right-straight" style="height:24px" :src="lineStyleLayout" alt="" />
        <a-icon
          class="imgthree"
          :class="lineStlyeAccout ? 'imgthree-sel' : 'imgthree-no'"
          type="caret-up"
        />
      </div>
      <div class="menu-right-bg background-transparent">
        <transition name="slide-fade">
          <ComplieChildMenuBorder v-if="lineStlyeAccout" />
        </transition>
      </div>
    </div>
    <!-- <div class="line-box" style="width: 253px; margin-left: 16px"></div> -->
    <!-- 线条颜色  -->
    <div class="menu-linecolor menu-linecolor-clear-border-top"
      style="padding-top: 0px">
      <div class="menu-linecolor-box" @click="setLineColorAccout">
        <div class="menu-left-accout" v-show="isTimeLineMode">
          <a-checkbox 
            :checked="isUseTimeLineColor"
            @change="onChangeUseTimeLineColor"
          >
            <span
              style="margin-left: 0px"
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
              >{{ getString(strings.Mind_Edit_Right_Menu_line_Color) }}</span
            >
          </a-checkbox>
        </div>
        <div class="menu-left-accout" v-show="!isTimeLineMode">
          {{ getString(strings.Mind_Edit_Right_Menu_line_Color) }}
        </div>
        <div class="menu-right-accout cursor-pointer">
          <div
            class="menu-right-linecolor"
            :style="'background:' + LineColor + ';'"
          ></div>
        </div>
      </div>
      <div class="menu-right-bg" style="top: -300px;">
        <transition name="slide-fade">
          <CommonBgColor v-if="lineColorAccout" />
        </transition>
      </div>
      <!-- 彩虹色 -->
      <section class="menu-linecolor-box">
        <div class="menu-left-accout">
          {{ getString(strings.Rainbow_Color) }}
        </div>
        <div
          class="menu-right-accout"
          @click.stop="setRainbowColor"
          style="width: 76px; height: 24px"
        >
          <div
            class="menu-right-rainbow-color"
            style="position: absolute; width: 100%"
          >
            <div class="rainbow-color-box">
              <span class="rainbow-color-item rainbow-color-item1"></span>
              <span class="rainbow-color-item rainbow-color-item2"></span>
              <span class="rainbow-color-item rainbow-color-item3"></span>
            </div>
          </div>
        </div>
      </section>
      <div class="menu-right-bg" style="top: 80px">
        <transition name="slide-fade">
          <section class="rainbow-color-container" v-if="rainbowColorShow">
            <div
              class="rainbow-main-item"
              v-for="(mitem, mindex) in rainbowColors"
              :key="mindex"
              @click.stop="clickRainbowColor(mindex)"
            >
              <div
                class="rainbow-mitem-box"
                :class="{ 'rainbow-active': rainbowActive == mindex }"
              >
                <span
                  class="rainbow-colors-rect"
                  v-for="(item, index) in mitem"
                  :key="index"
                  :style="{ background: getRgb(item) }"
                ></span>
              </div>
            </div>
          </section>
        </transition>
      </div>
      <!-- 线条宽度 -->
      <div class="menu-change-number-box">
        <div>
          <div class="menu-left-title">
            {{ getString(strings.Mind_Edit_Right_Menu_Line_Width) }}
          </div>
        </div>
        <div>
          <!-- <a-slider
            v-model="slider"
            :tooltip-visible="false"
            :min="sliderExtremum.min"
            :max="sliderExtremum.max"
            :step="zeroPointFive"
            @change="mainLineWitdhs"
          /> -->
        </div>
        <div class="selected-box">
          <WzcSelect
            :width="76"
            :height="24"
            :maxHeight="200"
            :placeholder="slider"
            :inputIdName="'slider'"
            :sliderMum="sliderExtremum"
            @changeInputVal="mainLineWitdhs"
          >
            <template v-slot:wzc_option>
              <WzcOption
                v-for="item in sliderItem"
                :key="item.id"
                :precision="true"
                :label="item.name"
                :optionid="item.id"
                @changeVal="mainLineWitdhs"
              ></WzcOption>
            </template>
          </WzcSelect>
        </div>
      </div>
      <!-- 线条渐细 -->
      <div v-if="!showMindElementButton && isShowLineTapering" class="menu-linecolor-box">
        <div class="menu-left-accout">
          <a-checkbox :checked="lineThinningStart" @change="onChangeStartLine">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >
              {{ getString(strings.Mind_Edit_Right_Menu_Line_Tapering) }}({{
                getString(strings.Mind_Beginning)
              }})
            </span>
          </a-checkbox>
        </div>
        <div class="menu-middle-accout">
          <a-checkbox :checked="lineThinningEnd" @change="onChangeEndLine">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >
              {{ getString(strings.Mind_Edit_Right_Menu_Line_Tapering) }}({{
                getString(strings.Mind_End)
              }})
            </span>
          </a-checkbox>
        </div>
      </div>

      <!-- 线条虚线 -->
      <div v-if="!showMindElementButton" class="menu-linecolor-box" style="margin-bottom: 10px">
        <div class="menu-left-accout">
          <a-checkbox :checked="lineIsDottedLine" @change="lineChangeDottedLine">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'">
                          {{ getString(strings.Line_Dashed_Line) }}
            </span>
          </a-checkbox>
        </div>
        <div class="menu-middle-accout">
          <a-checkbox :checked="nodeIsShadow" @change="lineChangeShadow">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >
              {{ getString(strings.Node_Shadow_Label) }}
            </span>
          </a-checkbox>
        </div>
      </div>

      <!-- 线条虚线 -->
      <div v-if="showMindElementButton" class="menu-linecolor-box" style="margin-bottom: 10px">
        <div class="menu-left-accout">
          <a-checkbox :checked="tiemLinsDotted" @change="onChangeTimeLineDotted">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'">
                          {{ getString(strings.Line_Dashed_Line) }}
            </span>
          </a-checkbox>
        </div>
        <div class="menu-middle-accout">
          <a-checkbox :checked="nodeIsShadow" @change="lineChangeShadow">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'"
            >
              {{ getString(strings.Node_Shadow_Label) }}
            </span>
          </a-checkbox>
        </div>
      </div>

      <!-- 箭头 -->
      <div v-if="showMindElementButton" class="menu-linecolor-box">
        <div class="menu-left-accout">
          <a-checkbox :checked="tiemLineArrow" @change="onChangeTimeLineArrow">
            <span
              :class="mapEditDarkMode ? 'checkbox-deep' : 'checkbox-shallow'">
                          {{ getString(strings.Mind_Arrow) }}
            </span>
          </a-checkbox>
        </div>
      </div>

      <div class="line-box"></div>

      <div class="menu-change-number-box" style="padding-top: 10px">
        <div>
          <div class="menu-left-title">
            {{ getString(strings.Mind_Edit_Right_Menu_Theme_Font) }}
          </div>
        </div>
        <div>
        </div>
        <div class="selected-box">
          <WzcSelect
            :width="76"
            :height="24"
            :maxHeight="200"
            :placeholder="firstFontSize"
            :inputIdName="'firstFontSize'"
            :sliderMum="firstFontSizeExtremum"
            @changeInputVal="setFirstFontSizes"
          >
            <template v-slot:wzc_option>
              <WzcOption
                v-for="item in firstFontSizeItem"
                :key="item.id"
                :label="item.name"
                :optionid="item.id"
                @changeVal="setFirstFontSize"
              ></WzcOption>
            </template>
          </WzcSelect>
        </div>
      </div>
      <div class="menu-change-number-box"  style="margin-bottom: 5px">
        <div>
          <div class="menu-left-title">
            {{ getString(strings.Mind_Edit_Right_Menu_Child_Font) }}
          </div>
        </div>
        <div>
        </div>
        <div class="selected-box">
          <WzcSelect
            :width="76"
            :height="24"
            :maxHeight="200"
            :placeholder="childrenNodeFontSize"
            :inputIdName="'childrenNodeFontSize'"
            :sliderMum="childrenNodeFontSizeExtremum"
            @changeInputVal="setChildrenNodeFontSizes"
          >
            <template v-slot:wzc_option>
              <WzcOption
                v-for="item in childrenNodeFontSizeItem"
                :key="item.id"
                :label="item.name"
                :optionid="item.id"
                @changeVal="setChildrenNodeFontSize"
              ></WzcOption>
            </template>
          </WzcSelect>
        </div>
      </div>
      <div class="line-box"></div>
      <!-- 分支间距 -->
      <div class="menu-change-number-box" style="height: 48px">
        <div>
          <div class="menu-left-title">
            {{
              getString(strings.Mind_SubjectLine_Space)
            }}&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <!-- {{ getString(strings.Mind_SubjectLine_Space) }} -->
        </div>
        <div>
          <span :title="getString(strings.Mind_SubjectLine_Vertical_Space)">
            <img
              v-if="mapEditDarkMode"
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/darkModeIcon/verticalSpacingWhite.svg"
            />
            <img
              v-else
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/vertical_spacing.svg"
            />
          </span>
          <a-input-number
            id="inputNumber"
            style="width: 52px"
            v-model="verticalValue"
            :step="10"
            :min="-200"
            :max="300"
            @change="onChangeVertical"
          />
        </div>
        <div>
          <span :title="getString(strings.Mind_SubjectLine_H)">
            <img
              v-if="mapEditDarkMode"
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/darkModeIcon/levelSpacingWhite.svg"
            />
            <img
              v-else
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/level_spacing.svg"
            />
          </span>
          <a-input-number
            id="inputNumber"
            style="width: 52px"
            v-model="leftRightValue"
            :step="10"
            :min="-200"
            :max="300"
            @change="onChangeLevel"
          />
        </div>
        <!-- <div class="selected-box">
        </div> -->
      </div>
      <!-- 子分支间距 -->
      <div class="menu-change-number-box" style="height: 48px">
        <div>
          <div class="menu-left-title">
            {{ getString(strings.Mind_SonSubjectLine_Space) }}
          </div>
        </div>
        <div>
          <span :title="getString(strings.Mind_SonSubjectLine_Vertical_Space)">
            <img
              v-if="mapEditDarkMode"
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/darkModeIcon/verticalSpacingWhite.svg"
            />
            <img
              v-else
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/vertical_spacing.svg"
            />
          </span>
          <a-input-number
            id="inputNumber"
            style="width: 52px"
            v-model="verticalSonValue"
            :step="10"
            :min="-200"
            :max="300"
            @change="onChangeVerticalSon"
          />
        </div>
        <div>
          <span :title="getString(strings.Mind_SonSubjectLine_H)">
            <img
              v-if="mapEditDarkMode"
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/darkModeIcon/levelSpacingWhite.svg"
            />
            <img
              v-else
              style="width: 24px"
              src="./../../../assets/img/mapIcon/res/mipmap-hdpi/level_spacing.svg"
            />
          </span>
          <a-input-number
            id="inputNumber"
            style="width: 52px"
            v-model="leftRightSonValue"
            :step="10"
            :min="-200"
            :max="300"
            @change="onChangeLevelSon"
          />
        </div>
        <!-- <div class="selected-box">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import CommonBgColor from "../../common/CommonBgColor/CommonBgColor";
import ComplieChildMenuLayout from "../ComplieRightMenuChild/ComplieChildMenuLayout";
import ComplieChildMenuMIndElementType from "../ComplieRightMenuChild/ComplieChildMenuMIndElementType";
import ComplieChildMenuBorder from "../ComplieRightMenuChild/ComplieChildMenuBorder";
import Colors from "../../../utils/Colors";
import complieRightMenu from "@/assets/css/complieRightMenu/complieRightMenu.less";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import MindType from "../../../viewmodel/datatype/MindType";

import WzcSelect from "../../common/Select/WzcSelect";
import WzcOption from "../../common/Select/WzcOption";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import IdGenerator from "../../../viewmodel/core/base/IdGenerator";
import LayoutImg from "../tools/LayoutImg";
import NodeLayoutType from '../../../viewmodel/datatype/NodeLayoutType';
import CreateMapDatas from '../../../common/CreateMapDatas';
export default {
  data() {
    return {
      maxSlider: 6, //线条最大宽度
      slider: 1,
      firstFontSize: 12, //主题节点字体大小
      childrenNodeFontSize: 12, //子节点字体大小
      firstNodePadding:0, // 主题内边距
      childrenNodePadding:0, // 子主题内边距
      bgMindColor: Colors.getUiColor(this.$store.state.busColor) ,
      LineColor: this.$store.state.lineColor,
      bgColorAccout: false,
      bgColorNumber: null,
      layoutAccout: false,
      lineColorAccout: false,
      lineStlyeAccout: false,
      showMindElementButton: false, //是否展示节点类型的选择按钮。
      isShowLineTapering: true, //线条渐细。
      mindElementTypeShow: false, //是否展示节点类型选择菜单。
      tiemLinsDotted: false, //是否是时间线虚线
      tiemLineArrow: false, //是否展示时间线箭头
      isFreeNodeMoveAdsorbent: true, //自由主题拖动吸附
      isTightMode: false, //紧促型布局
      isUseTimeLineColor: false, //是否使用线条颜色
      isTimeLineMode: false, //时间线模式this.$store.state.editMindmap.mindType == MindType.TIME_MAP
      isSameLevelAlignment: false, //同级主题对齐
      isOverlap: false, //是否允许重叠
      isBranchFreeLayout: false, //分支自由拖拽布局
      isShowBranchFreeLayout: false, //分支自由拖拽布局能否使用
      showMindLayoutButton:true,
      isShowLineStyle:true,
      palletTop: 0,
      strings: strings,
      selectedLayoutType: {},
      nodeStyleLayout: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"), //节点布局
      lineStyleLayout: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"), //线条布局D:\web\static\img\mapIcon\res\mipmap-hdpi\new_content.svg
      mindElementTypeLayout: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType1.svg"), //时间线节点类型
      fastSwapIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/swap_icon.svg"),
      sliderExtremum: {
        //线条粗细极值
        min: 1,
        max: 6,
      },
      sliderItem: [], //线条粗细数组

      firstFontSizeExtremum: {
        //主题字体极值
        min: 12,
        max: 100,
      },
      firstFontSizeItem: [], //主题字体数组

      childrenNodeFontSizeExtremum: {
        //子节点大小
        min: 12,
        max: 100,
      },
      childrenNodePaddingSizeExtremum: {
        //子节点大小
        min: -20,
        max: 20,
      },
      
      childrenNodeFontSizeItem: [], //子节点大小数组
      childrenNodePaddingSizeItem: [], 
      lineLength: 0,
      verticalValue: 10,
      leftRightValue: 10,
      verticalSonValue: 10,
      leftRightSonValue: 10,
      lineIsDottedLine: false, // 当前分支线条是否是虚线
      lineThinningStart: false, // 线条渐细始
      lineThinningEnd: false, // 线条渐细末
      rainbowColorShow: false, // 显示彩虹色
      nodeIsShadow: false, 
      rainbowColors: Colors.RainbowColores, //彩虹色列表
      rainbowActive: null, //选中彩虹色
      zeroPointFive: 0.5,
      mousedownViewClassName: "",
      mousedownViewTagName: "",
      isClickCommonMoreBgColor: false,
      fastRainbowTtems: Colors.FistTabRainbowColores[0],
      fastSolidTtems: Colors.FistTabRainbowColores[1],
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode, //深色模式
    }),
  },
  components: {
    CommonBgColor,
    ComplieChildMenuLayout,
    ComplieChildMenuBorder,
    ComplieChildMenuMIndElementType,
    WzcSelect,
    WzcOption,
  },
  mounted() {

    document.addEventListener("mousedown", (e) => {
      this.mousedownViewClassName = e.target.className
      this.mousedownViewTagName = e.target.tagName.toUpperCase();
      let commonMoreBgColor = document.getElementsByClassName("CommonMoreBgColor")
      if (commonMoreBgColor != null && commonMoreBgColor.length > 0) {
        this.isClickCommonMoreBgColor = commonMoreBgColor[0].contains(e.target);
      } else {
        this.isClickCommonMoreBgColor = false
      }
    });
    
    document.addEventListener("click", (e) => {
      if (this.mousedownViewClassName == null || this.mousedownViewClassName.length == 0 ||
        this.mousedownViewTagName == null || this.mousedownViewTagName.length == 0) {
        this.mousedownViewClassName = e.target.className
        this.mousedownViewTagName = e.target.tagName.toUpperCase();
      }
      var hideTag = this.mousedownViewTagName;
      this.verticalValue = EditMindmapVM.getSubjectSpcaeVertical();
      this.leftRightValue = EditMindmapVM.getSubjectSpcaeHorizontal();
      this.verticalSonValue = EditMindmapVM.getSonSubjectSpcaeVertical();
      this.leftRightSonValue = EditMindmapVM.getSonSubjectSpcaeHorizontal();
      // 线条虚线
      this.lineIsDottedLine = EditMindmapVM.isLineDotted();
      //线条渐细
      this.lineThinningStart = EditMindmapVM.isLineThicken();
      this.lineThinningEnd = EditMindmapVM.isLineEndThicken();
      //重置选中彩虹色
      // this.rainbowActive = null;

      if (
        (this.mousedownViewClassName != "menu-right-straight" &&
          this.mousedownViewClassName != "menu-right-linecolor" &&
          this.mousedownViewClassName != "menu-right-accout" &&
          this.mousedownViewClassName != "menu-use-box" &&
          this.mousedownViewClassName != "menu-right-accout-ly" &&
          this.mousedownViewClassName != "menu-bgcolor" &&
          this.mousedownViewClassName != "menu-right-bgcolor" &&
          this.mousedownViewClassName != "menu-linecolor-box" &&
          this.mousedownViewClassName != "menu-left-accout" &&
          this.mousedownViewClassName != "color-content-max-titel" &&
          this.mousedownViewClassName != "vc-sketch-presets" &&
          this.mousedownViewClassName != "vc-saturation" &&
          this.mousedownViewClassName != "vc-hue-container" &&
          this.mousedownViewClassName != "vc-alpha-picker" &&
          this.mousedownViewClassName != "vc-input__input" &&
          this.mousedownViewClassName != "vc-input__label" &&
          this.mousedownViewClassName != "vc-alpha-container" &&
          this.mousedownViewClassName != "vc-saturation--black" &&
          this.mousedownViewClassName != "vc-sketch" &&
          this.mousedownViewClassName != "vc-sketch-sliders" &&
          this.mousedownViewClassName != "vc-hue-picker" && 
          this.mousedownViewClassName != "color-mode-title" &&
          this.mousedownViewClassName != "vc-chrome-body" && 
          this.mousedownViewClassName != "color-setting ant-input" &&
          this.mousedownViewClassName != "color-alpha-setting ant-input" &&
          this.mousedownViewClassName != "color-setting-content" &&
          this.mousedownViewClassName != "color-setting-tag" &&
          this.mousedownViewClassName != "color-alpha-tag" &&
          !this.isClickCommonMoreBgColor) ||
        (this.mousedownViewClassName == "menu-content" &&
          hideTag == "SVG" &&
          hideTag != "PATH" &&
          hideTag != "IMG")
      ) {
        this.closeRightModel();
      }
    });
    setTimeout(() => {
      this.delayGetLayout();
    }, 5*1000);
    this.isTimeLineMode = this.$store.state.editMindmap.mindType == MindType.TIME_MAP;
    this.isUseTimeLineColor = EditMindmapVM.getUseTimeLineColor()
  },
  methods: {
    ...mapMutations([
      "showChunkColor",
      "markColorAccout",
      "getFontTextStyle",
      "setDomImg",
      "ImgSiteStyle",
      "ImgShapeStyle",
      "mainLineWitdh",
      "onChangeLineAccout",
      "setFirstFontSize",
      "setChildrenNodeFontSize",
      "setFunctionDetail",
      "changeTimeLineArrowVuex",
      "changeTimeLineDottedVuex",
      "setFreeNodeMoveAdsorbent",
      "setBranchFreeLayout",
      "setShowBranchFreeLayout",
      "setGlobalLineWidth",
      "setShowSkeleton",
    ]),
    getString(i) {
      return getString(i);
    },
    getRgb(item) {
      return Colors.getUiColor(item);
    },
    detailQuestion(index) {
      let detail = {};

      let obj = {
        1: "背景颜色",
        2: "布局",
      };
      let rect = this.$refs["titlebox" + index];
      detail.clientRect = rect.getBoundingClientRect();
      detail.type = obj[index];
      detail.index = index;
      detail.menuboxType = "rightCanvas";
      this.setFunctionDetail(detail);
      // console.log(e);
    },
    closeRightModel() {
      this.bgColorAccout = false;
      this.layoutAccout = false;
      this.lineColorAccout = false;
      this.lineStlyeAccout = false;
      this.mindElementTypeShow = false;
      this.rainbowColorShow = false;
    },
    //背景颜色
    setBgcolorAccout(e) {
      this.bgColorAccout = !this.bgColorAccout;
      this.lineColorAccout = false;
      this.layoutAccout = false;
      this.lineStlyeAccout = false;
      this.mindElementTypeShow = false;
      this.rainbowColorShow = false;
      this.showChunkColor(true);
      this.markColorAccout(0);
    },
    //布局
    setLayoutAccout() {
      this.layoutAccout = false;//!this.layoutAccout;
      this.lineStlyeAccout = false;
      this.bgColorAccout = false;
      this.mindElementTypeShow = false;
      this.rainbowColorShow = false;

      this.setShowSkeleton(true);
    },
    //快速样式活力
    setFastStyleRainbow() {
      EditMindmapVM.onChangeFastStyleColorsByRainbow()
    },
    //快速样式纯真
    setFastStyleSolid() {
      EditMindmapVM.onChangeFastStyleColorsBySolid()
    },
    //节点类型
    setMindElementTypeShow() {
      this.mindElementTypeShow = !this.mindElementTypeShow;
      this.bgColorAccout = false;
      this.lineColorAccout = false;
      this.layoutAccout = false;
      this.lineStlyeAccout = false;
      this.rainbowColorShow = false;
    },
    //线条样式
    setLineStlyeAccout() {
      this.lineStlyeAccout = !this.lineStlyeAccout;
      this.rainbowColorShow = false;
    },
    //线条颜色
    setLineColorAccout() {
      this.lineColorAccout = !this.lineColorAccout;
      this.bgColorAccout = false;
      this.rainbowColorShow = false;
      this.showChunkColor(true);
      this.markColorAccout(1);
    },
    //显示彩虹色
    setRainbowColor() {
      this.rainbowColorShow = !this.rainbowColorShow;
      this.bgColorAccout = false;
    },
    //点击彩虹色
    clickRainbowColor(index) {
      this.rainbowActive = index;
      EditMindmapVM.onChangeRainbowColor(index);
    },
    //线条渐细
    onChangeLine(checked) {
      this.onChangeLineAccout(checked);
      console.log(checked);
    },
    onChangeStartLine() {
      this.lineThinningStart = !this.lineThinningStart;
      EditMindmapVM.setlineThicken(
        this.lineThinningStart,
        this.lineThinningEnd
      );
    },
    onChangeEndLine() {
      this.lineThinningEnd = !this.lineThinningEnd;
      EditMindmapVM.setlineThicken(
        this.lineThinningStart,
        this.lineThinningEnd
      );
    },
    lineChangeShadow() {
      // 修改节点阴影
      this.nodeIsShadow = !this.nodeIsShadow;
      EditMindmapVM.setlineShadow(this.nodeIsShadow);
    },
    lineChangeDottedLine() {
      // 修改线条虚线
      this.lineIsDottedLine = !this.lineIsDottedLine;
      EditMindmapVM.setlineDot(this.lineIsDottedLine);
    },
    //时间线虚实
    onChangeTimeLineDotted(checked) {
      this.tiemLinsDotted = !this.tiemLinsDotted;
      this.changeTimeLineDottedVuex(this.tiemLinsDotted);
    },
    //时间线箭头
    onChangeTimeLineArrow() {
      this.tiemLineArrow = !this.tiemLineArrow;
      this.changeTimeLineArrowVuex(this.tiemLineArrow);
    },

    onChangeFreeNodeMoveAdsorbent() {
      this.isFreeNodeMoveAdsorbent = !this.isFreeNodeMoveAdsorbent;
      this.setFreeNodeMoveAdsorbent(this.isFreeNodeMoveAdsorbent);
    },
    onChangeTightMode() {
      this.isTightMode = !this.isTightMode;
      EditMindmapVM.setTightMode(this.isTightMode)
    },
    onChangeUseTimeLineColor() {
      this.isUseTimeLineColor = !this.isUseTimeLineColor;
      EditMindmapVM.setUseTimeLineColor(this.isUseTimeLineColor)
    },
    onChangeSameLevelAlignment() {
      this.isSameLevelAlignment = !this.isSameLevelAlignment;
      EditMindmapVM.setSameLevelAlignment(this.isSameLevelAlignment)
    },
    onChangeNodeOverlap() {
      this.isOverlap = !this.isOverlap;
      EditMindmapVM.setNodeOverlap(this.isOverlap)
    },
    onChangeBranchFreeLayout() {
      this.isBranchFreeLayout = !this.isBranchFreeLayout;
      this.setBranchFreeLayout(this.isBranchFreeLayout);
    },

    //线条粗细
    mainLineWitdhs(lineWitdh) {
      this.slider = parseFloat(lineWitdh);
      this.mainLineWitdh(this.slider);
    },
    //主题字体大小
    setFirstFontSizes(val) {
      this.firstFontSize = val;
      this.setFirstFontSize(val);
    },
    //子主题字体大小
    setChildrenNodeFontSizes(val) {
      this.childrenNodeFontSize = val;
      this.setChildrenNodeFontSize(val);
    },
    setChildrenNodePaddingSizes(val) {
      // this.childrenNodeFontSize = val;
      // this.setChildrenNodeFontSize(val);
    },
    syncFirstFontSize(val) {
      this.firstFontSize = val;
    },
    onChangeLevel(value) {
      EditMindmapVM.setSubjectSpcaeHorizontal(value);
    },
    onChangeVertical(value) {
      EditMindmapVM.setSubjectSpcaeVertical(value);
    },
    onChangeVerticalSon(value) {
      EditMindmapVM.setSonSubjectSpcaeVertical(value);
    },
    onChangeLevelSon(value) {
      EditMindmapVM.setSonSubjectSpcaeHorizontal(value);
    },
    setSliderItem() {
      let extremum = this.sliderExtremum;
      this.sliderItem = [];
      for (
        let index = extremum.min;
        index <= extremum.max;
        index += this.zeroPointFive
      ) {
        let obj = {};
        obj.name = index;
        obj.id = index;
        this.sliderItem.push(obj);
      }
    },
    setLineWidth(newval) {
      //设置线条宽度，时间线导图最小为0.5最大为40，其它最小为0最大为6
      if (newval == MindType.TIME_MAP) {
        this.showMindElementButton = true;
        this.maxSlider = 40;
        this.sliderExtremum.max = 40;
        this.sliderExtremum.min = 0.5;
      } else {
        this.showMindElementButton = false;
        this.maxSlider = 6;
        this.sliderExtremum.max = 6;
        this.sliderExtremum.min = 0;
      }
      this.setSliderItem();
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (dardarkMode) {
          this.setDarkModeIcon();
        }
      });
    },

    setDarkModeIcon() {
      // 设置深色模式图标
      let mindMapType = this.$store.state.editMindmap.mindType;
      
      if (this.mapEditDarkMode) {
        this.fastSwapIcon = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/swap_dark_icon.svg")
      } else {
        this.fastSwapIcon = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/swap_icon.svg")
      }
      if (mindMapType == MindType.TIME_MAP) {
        //时间线结构
        if (this.mapEditDarkMode) {
          this.nodeStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_vertical_icon.svg"); //节点布局
          this.lineStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular.svg"); //线条布局
          this.mindElementTypeLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/darkModeIcon/nodeType1.svg"); //时间线节点类型
        } else {
          this.nodeStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_vertical_icon.svg"); //节点布局
          this.lineStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular.svg"); //线条布局
          this.mindElementTypeLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_element_layout/lightModeIcon/nodeType1.svg"); //时间线节点类型
        }
      } else if (
        mindMapType == MindType.BUBBLE_MAP ||
        mindMapType == MindType.DOUBLE_BUBBLE_MAP
      ) {
        //气泡结构和双气泡结构
        if (this.mapEditDarkMode) {
          this.nodeStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_boubel.svg"); //节点布局
          this.lineStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"); //线条布局
        } else {
          this.nodeStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"); //节点布局
          this.lineStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"); //线条布局
        }
      } else {
        if (this.mapEditDarkMode) {
          this.nodeStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/leftArrowWhite.svg"); //节点布局
          this.lineStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"); //线条布局
        } else {
          this.nodeStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"); //节点布局
          this.lineStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"); //线条布局
        }
      }
    },
    setLayoutStyle(newNodeMessage) {
      //获取当前布局
      let mindMapType = this.$store.state.editMindmap.mindType;
      let layoutList = [];
      if (mindMapType == MindType.TIME_MAP) {//时间线结构
        layoutList = new LayoutImg().layoutStyleLine;
      }else{
        layoutList = new LayoutImg().layoutStyleList;
      }
      let nodeContentLayoutType = null;
      if (
        newNodeMessage == null ||
        newNodeMessage == "" ||
        newNodeMessage == undefined
      ) {
        let rootNode = EditMindmapVM.getNodeById(IdGenerator.getRootId());
        nodeContentLayoutType = rootNode.value.layout;
      } else {
        nodeContentLayoutType = newNodeMessage.layout;
      }
      for (let i = 0, layoutListCount = layoutList.length;i < layoutListCount; i++) {
        let type = layoutList[i];
        if (nodeContentLayoutType == type.layoutType) {
          this.nodeStyleLayout = this.mapEditDarkMode
            ? type.imgWhite
            : type.layoutIcon;
          this.selectedLayoutType = type;//当前选中布局
        }
      }
      if ((mindMapType == MindType.TIME_MAP && EditMindmapVM.getGlobalLayout() == NodeLayoutType.LAYOUT_BRIDGE) ||
          mindMapType == MindType.FLOW_CHART) {
        this.showMindLayoutButton = false
      } else {
        this.showMindLayoutButton = true
      }
      if (mindMapType == MindType.FLOW_CHART) {
        this.isShowLineStyle = false
        this.isShowLineTapering = false
      } else {
        this.isShowLineStyle = true
        this.isShowLineTapering = true
      }
      
    },
    delayGetLayout() {
      //延时获取当前布局
      setTimeout(() => {
        this.setLayoutStyle(null);
      }, 1000);
    },
  },
  watch: {
    $route: {
      handler() {
        this.closeRightModel();
        this.rainbowActive = null;
        this.setLineWidth(this.$store.state.editMindmap.mindType);
        this.delayGetLayout();
      },
      deep: true,
    },
    sliderExtremum: {
      handler(newval, olval) {
        // this.sliderItem = [];
        // for (let index = newval.min; index <= newval.max; index++) {
        //   let obj = {};
        //   obj.name = index;
        //   obj.id = index;
        //   this.sliderItem.push(obj);
        // }
        // newval.min = 0.5;
        // this.sliderItem.unshift(newval.min)
      },
      immediate: true,
      deep: true,
    },

    firstFontSizeExtremum: {
      handler(newval, olval) {
        this.firstFontSizeItem = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.firstFontSizeItem.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },

    childrenNodeFontSizeExtremum: {
      handler(newval, olval) {
        this.childrenNodeFontSizeItem = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.childrenNodeFontSizeItem.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },
    childrenNodePaddingSizeExtremum: {
      handler(newval, olval) {
        this.childrenNodePaddingSizeItem = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.childrenNodePaddingSizeItem.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },
    // childrenNodeFontSizeExtremum: { //子节点大小
    //   min: 12,
    //   max: 100
    // },
    // childrenNodeFontSizeItem: [],  //子节点大小数组

    slider: {
      handler(newLineWitdh, olLineWidth) {
        // this.mainLineWitdhs(newLineWitdh);
      },
    },
    firstFontSize: {
      handler(newLineWitdh, olLineWidth) {
        // this.setFirstFontSize(newLineWitdh);
      },
    },
    childrenNodeFontSize: {
      handler(newLineWitdh, olLineWidth) {
        // this.setChildrenNodeFontSize(newLineWitdh);
      },
    },
    "$store.state.nodeContentMessage": {
      handler(newNodeMessage, olval) {
        this.setLayoutStyle(newNodeMessage);
      },
      deep: true,
    },
    "$store.state.showBranchFreeLayout": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.isShowBranchFreeLayout = newval.type;
        }
      },
      deep: true,
    },
    "$store.state.branchFreeLayout": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.isBranchFreeLayout = newval.type;
        }
      },
      deep: true,
    },
    "$store.state.freeNodeMoveAdsorbent": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.isFreeNodeMoveAdsorbent = newval.type;
        }
      },
      deep: true,
    },
    "$store.state.globalLineWidth": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.slider = newval;
        }
      },
      deep: true,
    },
    "$store.state.busColor"(newBusColor, olBusColor) {
      this.bgMindColor =  Colors.getUiColor(newBusColor);
    },
    "$store.state.lineColor"(newLineColor, olLineColor) {
      if (newLineColor == null) {
        return;
      }
      let LineColor = newLineColor.substring(newLineColor.indexOf("0x") + 2);
      this.LineColor = "#" + LineColor;
    },
    "$store.state.createdEditMindmapVMNumber": {
      //当重新init创建导图的时候
      handler(newVal, oldVal) {
        //箭头
        if (
          this.$store.state.editMindmap.mindType == MindType.TIME_MAP &&
          this.$store.state.editMindmap.timeMindTypeNodeUnit.baseLine != null &&
          !this.$store.state.editMindmap.timeMindTypeNodeUnit.baseLine.isEmpty()
        ) {
          this.tiemLineArrow =
            this.$store.state.editMindmap.timeMindTypeNodeUnit.baseLine.timeLineContent.isShowArrow;
        }
        //设置虚线
        if (
          this.$store.state.editMindmap.mindType == MindType.TIME_MAP &&
          this.$store.state.editMindmap.timeMindTypeNodeUnit.baseLine != null &&
          !this.$store.state.editMindmap.timeMindTypeNodeUnit.baseLine.isEmpty()
        ) {
          this.tiemLinsDotted =
            this.$store.state.editMindmap.timeMindTypeNodeUnit.baseLine.timeLineContent.dottedLine;
        }
      },
    },
    "$store.state.editMindmap.mindType"(newval, olval) {
      //判断当前导图的类型
      this.setLineWidth(newval);
      this.setDarkModeIcon();
      this.isTimeLineMode = newval == MindType.TIME_MAP;
      this.isUseTimeLineColor = EditMindmapVM.getUseTimeLineColor()
    },
    "$store.state.initMindData"(newInitMindData, olInitMindData) {
      this.bgMindColor = Colors.getUiColor(EditMindmapVM.mindBGColor);
      this.LineColor = Colors.getUiColor(EditMindmapVM.getGolbalLineColor());
      this.isUseTimeLineColor = EditMindmapVM.getUseTimeLineColor();
      this.isTimeLineMode = EditMindmapVM.mindType == MindType.TIME_MAP;
      this.isTightMode = EditMindmapVM.isTightMode()
      this.isSameLevelAlignment = EditMindmapVM.isSameLevelAlignment()
      this.isOverlap = EditMindmapVM.isNodeOverlap()
    },
    "$store.state.showShopping"(newval, olval) {
      this.lineColorAccout = false;
      this.bgColorAccout = false;
    },
    //节点布局图标
    "$store.state.nodeStyleLayout"(icon) {
      this.nodeStyleLayout = icon;
    },
    //线条布局图标
    "$store.state.lineStyleLayout"(icon) {
      this.lineStyleLayout = icon;
    },
    "$store.state.mindElementTypeLayout"(icon) {
      this.mindElementTypeLayout = icon;
    },
    // 导图右侧菜单布局弹框是否显示
    "$store.state.isShowMindmapRightMenuLayout"(newVal) {
      this.setLayoutAccout();
    },
    // 导图右侧菜单线条样式弹框是否显示
    "$store.state.isShowBorderlLineStyle"(newVal) {
      this.setLineStlyeAccout();
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.menu-right-linestyle-show {
  display: block;
}
.menu-right-linestyle-hide {
  display: none;
}
.menu-right-line-show {
  display: block;
}
.menu-right-line-hide {
  display: none;
}
.menu-right-ly-show {
  display: block;
}
.menu-right-ly-hide {
  display: none;
}
.menu-right-bg-hide {
  display: none;
}
.menu-right-bg-show {
  display: block;
}
.menu-right-bg {
  position: absolute;
  background-color: #ffffff;
  z-index: 9999;
  top: 60px;
}

.right-menu-content-box .menu-content .menu-canvas .menu-bgcolor {
  width: 285px;
  height: 54px;
  border-top: 1px solid var(--rightMenuBorderColor);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.menu-bgcolor-clear-border-top {
  border-top: transparent !important;
}

.right-menu-content-box .menu-content .menu-canvas .menu-linecolor {
  width: 285px;
  height: 100%;
  border-top: 1px solid var(--rightMenuBorderColor);
  position: relative;
}

.menu-linecolor-clear-border-top {
  border-top: transparent !important;
}

.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout {
  position: absolute;
  right: 16px;
}

.menu-middle-accout {
  position: absolute;
  left: 155px;
}

.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-left-accout {
  position: absolute;
  left: 16px;
  font-weight: 600;
}
.right-menu-content-box .menu-content .menu-canvas .menu-linecolor-box {
  width: 285px;
  height: 34px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
.menu-change-number-box {
  width: 285px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: 0 16px;
  .ant-slider {
    //为了兼容之前潘潘的slider代码
    margin: 0 !important;
    position: relative !important;
    right: 0 !important;
  }
}

.line-box {
  border-top: 1px solid var(--rightMenuBorderColor);
}
.right-menu-content-box .menu-content .menu-canvas .menu-right-linecolor {
  width: 76px;
  height: 22px;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 2px;
}

.right-menu-content-box .menu-content .menu-canvas .menu-right-rainbow-color {
  // background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  .rainbow-color-box {
    padding: 6px 10px;
    display: flex;
    .rainbow-color-item {
      width: 20px;
      height: 10px;
    }
    .rainbow-color-item1 {
      background: #ee7158;
    }
    .rainbow-color-item2 {
      background: #99e67e;
    }
    .rainbow-color-item3 {
      background: #6095ff;
    }
  }
}
.rainbow-color-container {
  width: 268px;
  height: 272px;
  overflow: auto;
  padding: 5px 0;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  .rainbow-main-item {
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    cursor: pointer;
    &:hover {
      background: #e0e0e0;
    }
    .rainbow-mitem-box {
      padding: 2px;
      border-radius: 2px;
      display: flex;
      .rainbow-colors-rect {
        width: 40px;
        height: 15px;
      }
      .rainbow-colors-rect:first-child {
        border-radius: 2px 0px 0px 2px;
      }
      .rainbow-colors-rect:last-child {
        border-radius: 0px 2px 2px 0px;
      }
    }
    .rainbow-active {
      border: 1px solid #fd492b;
    }
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout
  .menu-middle-accout
  .menu-right-accout-slider {
  height: 6px;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout
  .menu-middle-accout
  .menu-right-accout-text {
  position: absolute;
  right: 20px;
  top: -4px;
}
.ant-slider {
  width: 100px;
  text-align: center;
  margin: 0;
  position: absolute;
  right: 60px;
}

.menu-bgcolor .menu-left-accout {
  position: absolute;
  left: 16px;
  font-weight: 600;
}
.menu-bgcolor .menu-right-accout {
  position: absolute;
  right: 16px;
}

.menu-bgcolor .menu-middle-accout {
  position: absolute;
  left: 160px;
}

.menu-bgcolor .menu-right-accout .menu-middle-accout .menu-right-bgcolor {
  width: 76px;
  height: 22px;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 2px;
}

.menu-right-bgcolor {
  width: 76px;
  height: 22px;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 2px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

/* 用于设置拖动条和文字位置，兼容英语 开始 */
.position-static {
  position: static !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .line-slider {
  width: 100px;
} */

.line-text {
  position: static !important;
  margin-left: 192px;
  /* top: 11px !important ;
  right: 39px  !important; */
}
/* 用于设置拖动条和文字位置，兼容英语 结束 */
.menu-left-title {
  font-weight: 600;
}

.branch-free-layout-explain-deep {
  color: #e0e0e0;
}
.branch-free-layout-explain-shallow {
  color: #a4a4a4;
}

.checkbox-deep {
  color: #ffffff;
  margin-left: -4px;
  font-weight: 600;
}
.checkbox-shallow {
  color: #333333;
  margin-left: -4px;
  font-weight: 600;
}

.imgthree {
  line-height: var(--select-height);
  transform: rotate(180deg);
  transition: all 0.3s;
  margin-left: 15px;
}
.imgthree:before {
  cursor: pointer;
  color: #d3dce6;
}
.imgthree-sel {
  transform: rotate(0deg);
}
.menu-layout {
  width: 255px;
  height: 80px;
  margin: 15px;
  background: rgba(211, 211, 211, 0.4);
  border-radius: 8px;
  border-top: 0px;
}
.menu-right-fast-swap-icon {
  position: absolute;
  width: 18px;
  top: 6px;
  right: 10px;
}
.menu-right-fast-title {
  position: absolute;
  top: 4px;
  left: 10px;
}
.menu-right-fast-cell-color {
  position: absolute;  
  width: 108px;
  height: 30px;
  bottom: 10px;
  left: 10px;
  border-radius: 6px;

  .menu-right-fast-cell-color-rect:first-child {
  border-radius: 6px 0px 0px 6px;
  }
  .menu-right-fast-cell-color-rect:last-child {
    border-radius: 0px 6px 6px 0px;
  }
}

.menu-right-fast-cell {
  position: relative;
  width: 123px;
  height: 70px;
  background: rgba(211, 211, 211, 0.4);
  border-radius: 8px;
  border-top: 0px;
  cursor: pointer;
}
.menu-right-fast-cell-color-rect {
  width: 13px;
  height: 30px;
  display: inline-block;
}
</style>