
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

import UiUtil from "../../../utils/UiUtil";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import OrientationType from "../../../viewmodel/datatype/OrientationType";

class CalculMindElementsRect {
    constructor(mindElements) {
        this.mindElements = mindElements;
    }

    getIncludeMargin() {
        let rect = this.getAbsolute();
        rect.x = rect.x - new UiUtil().dip2px(20);
        rect.y = rect.y - new UiUtil().dip2px(20);
        rect.width(rect.width() + new UiUtil().dip2px(40));
        rect.height(rect.height() + new UiUtil().dip2px(40));
        return rect;
    }

    getAbsolute() {
        let rect = new CGRect();
        let left = 500000;
        let top = 500000;
        let right = 0;
        let bottom = 0;
        let length = this.mindElements.length
        for (let index = 0; index < length; index++) {
            let data = this.mindElements[index];
            if (data.isHidden) {
                continue;
            }
            if (left > data.x) {
                left = data.x;
            }
            if (top > data.y) {
                top = data.y;
            }
            if (right < data.x + data.width) {
                right = data.x + data.width;
            }
            if (bottom < data.y + data.height) {
                bottom = data.y + data.height;
            }
            if (data.timeLineContent != null) {
                if (data.timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                    var dotTop = 99999
                    var dotBottom = -99999
                    var dotLeft = 99999
                    var dotRight = -99999
                    var radius = 0
                    for (let i = 0; i < data.timeLineContent.dots.length; i++) {
                        const dot = data.timeLineContent.dots[i];
                        dotLeft = Math.min(dotLeft, (dot.x - dot.left))
                        dotRight = Math.max(dotRight, (dot.x + dot.right))
                        dotTop = Math.min(dotTop, (dot.y))
                        dotBottom = Math.max(dotBottom, (dot.y))
                        radius = dot.radius
                    }

                    if (Math.abs(dotBottom - dotTop) > 10) {
                        dotLeft = dotLeft - (Math.abs(dotBottom - dotTop)/2 + data.timeLineContent.lineWidth)
                        dotRight = dotRight + (Math.abs(dotBottom - dotTop)/2 + data.timeLineContent.lineWidth) 
                    }
                    
                    if (dotLeft != 99999) {
                        left = Math.min(left, data.x + dotLeft)
                        right = Math.max(right, data.x + dotRight)
                    }
                } else if (data.timeLineContent.orientationType == OrientationType.HORIZONTAL_S) {
                    var dotTop = 99999
                    var dotBottom = -99999
                    var dotLeft = 99999
                    var dotRight = -99999
                    var radius = 0
                    for (let i = 0; i < data.timeLineContent.dots.length; i++) {
                        const dot = data.timeLineContent.dots[i];
                        dotLeft = Math.min(dotLeft, (dot.x - dot.left))
                        dotRight = Math.max(dotRight, (dot.x + dot.right))
                        dotTop = Math.min(dotTop, (dot.y))
                        dotBottom = Math.max(dotBottom, (dot.y))
                        radius = dot.radius
                        if (i > 0 && dot.y != data.timeLineContent.dots[i - 1].y) {
                            let preDot = data.timeLineContent.dots[i - 1]
                            if (preDot.lineClockwise) { 
                                dotRight = Math.max(dotRight, (dot.x + radius + dot.right + Math.abs(dot.y - preDot.y)/2 + data.timeLineContent.lineWidth)) 
                                dotRight = Math.max(dotRight, (preDot.x + radius + preDot.right + Math.abs(dot.y - preDot.y)/2 + data.timeLineContent.lineWidth))                              
                            } else {
                                dotLeft = Math.min(dotLeft, (dot.x - dot.left - Math.abs(dot.y - preDot.y)/2 - data.timeLineContent.lineWidth)) 
                                dotLeft = Math.min(dotLeft, (preDot.x - preDot.left - Math.abs(dot.y - preDot.y)/2 - data.timeLineContent.lineWidth)) 
                            }
                        }
                        
                    }
                    left = Math.min(left, data.x + dotLeft)
                    right = Math.max(right, data.x + dotRight)
                }
            }
        }

        rect.x = left;
        rect.y = top;
        rect.width(right - left);
        rect.height(bottom - top);
        return rect;
    }
}
export default CalculMindElementsRect
