// package mind.yushu.com.mindmap.utils;

// import android.annotation.SuppressLint;
// import android.app.Activity;
// import android.content.ClipData;
// import android.content.ClipboardManager;
// import android.content.Context;
// import android.content.Intent;
// import android.content.pm.PackageManager;
// import android.content.pm.ResolveInfo;
// import android.content.res.Configuration;
// import android.graphics.Bitmap;
// import android.graphics.Canvas;
// import android.graphics.Point;
import Point from '../viewmodel/core/base/Point'
// import android.graphics.Rect;
// import android.graphics.drawable.BitmapDrawable;
// import android.graphics.drawable.Drawable;
// import android.graphics.drawable.GradientDrawable;
// import android.graphics.drawable.StateListDrawable;
// import android.net.Uri;
// import android.os.Build;
// import android.provider.MediaStore;
// //import android.support.v4.content.ContextCompat;
// //import android.support.v4.view.ViewCompat;
// import android.text.TextUtils;
// import android.util.TypedValue;
// import android.view.Gravity;
// import android.view.View;
// import android.view.ViewGroup;
// import android.view.Window;
// import android.view.WindowManager;
// import android.widget.TextView;
// import android.widget.Toast;

// import java.lang.reflect.Field;
// import java.lang.reflect.Method;
// import java.util.List;

// import androidx.core.content.ContextCompat;
// import androidx.core.view.ViewCompat;
// import mind.yushu.com.R;
// import mind.yushu.com.mindmap.core.common.Definition;
// import mind.yushu.com.mindmap.core.common.IOController;
// import mind.yushu.com.mindmap.core.storage.MemCacheManager;
// import mind.yushu.com.mindmap.core.storage.StorageManager;
// import mind.yushu.com.mindmap.main.MindMapApplication;
// import mind.yushu.com.mindmap.main.settings.AboutActivity;
// import mind.yushu.com.mindmap.main.settings.upgrade.UpgradeActivity;
// import mind.yushu.com.mindmap.main.settings.login.LoginActivity;
// import mind.yushu.com.mindmap.utils.dialog.AlertDialogOKCancelWithTitle;
// import mind.yushu.com.mindmap.utils.dialog.SpotsDialog;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.facade.HttpCallback;
// import mind.yushu.com.mindmap.viewmodel.facade.MindFacade;
// import mind.yushu.com.mindmap.viewmodel.facade.MindMe;
// import mind.yushu.com.mindmap.viewmodel.facade.inbound.HttpCommonResultCode;
// import mind.yushu.com.mindmap.viewmodel.facade.inbound.data.CheckVersionResponseData;
// import mind.yushu.com.mindmap.viewmodel.facade.inbound.data.HttpInboundPacketData;
// import mind.yushu.com.mindmap.viewmodel.facade.outbound.HttpOutboundCheckVersionPacketData;
import Util from './Util'
import en from '../common/lang/en';
import zhCN from '../common/lang/zh-cn';
import ja from '../common/lang/ja';
import Colors from './Colors';
import Size from '../viewmodel/core/base/Size';
import MindElementFullType from '../viewmodel/datatype/MindElementFullType';
import strings from '../common/lang/strings';
import tools from '../common/commonFuntion';
import download from 'downloadjs';
/**
 * Created by tony on 2019/12/10.
 */

class UiUtil {
    // this.density = MindMapApplication.getSharedContext().getResources().getDisplayMetrics().density;
    constructor() {
        this.screen_height = 0;
        this.screen_width = 0;
        this.appToast;
    }



    // public static void setDensity(float value) {
    //     density = value;
    // }

    // dip2px(dpValue) {
    //     if (dpValue == 0) {
    //         return 0;
    //     }
    //     return dpValue * 2;
    // }

    px2dp(pxValue) {
        if (pxValue == 0) {
            return 0;
        }
        return pxValue;
    }

    //     public static float px2dp(int pxValue) {
    //         if (pxValue == 0) {
    //             return 0;
    //         }
    //         return (float)pxValue / density + 0.5f;
    //     }

    //     public static int dp(float value) {
    //         if (value == 0) {
    //             return 0;
    //         }
    //         return (int) Math.ceil(density * value);
    //     }

    dip2px(dpValue) {
        try {
            if ((typeof dpValue) == "number") {
                try {
                    if (dpValue == 0) {
                        return 0;
                    } else {
                        return dpValue * 1;
                    }
                } catch (e) {
                    //            Logger.log("dip to px error!");
                }
            } else if ((typeof dpValue) == "string") {
                if (dpValue.endsWith("dp")) {
                    let dp = parseInt(dpValue.substring(0, dpValue.indexOf("dp")));
                    return this.dip2px(dp);
                } else if (dpValue.endsWith("px")) {
                    return parseInt(dpValue.substring(0, dpValue.indexOf("px")));
                } else if (dpValue.endsWith("pt")) {
                    return parseInt(dpValue.substring(0, dpValue.indexOf("pt")));
                } else {
                    return parseInt(dpValue);
                }
            } 
        } catch (error) {

        }
        return 0;
    }

    
    //     public static void showText(String info) {
    //         if (appToast == null) {
    //             appToast = Toast.makeText(MindMapApplication.getSharedContext(), info, Toast.LENGTH_SHORT);
    //         } else {
    //             appToast.setText(info);
    //             appToast.setDuration(Toast.LENGTH_SHORT);
    //         }
    //         appToast.show();
    //     }

    //     public static void showText(int info) {
    //         if (appToast == null) {
    //             appToast = Toast.makeText(MindMapApplication.getSharedContext(), info, Toast.LENGTH_SHORT);
    //         } else {
    //             appToast.setText(info);
    //             appToast.setDuration(Toast.LENGTH_SHORT);
    //         }
    //         appToast.show();
    //     }

    getString(resId) {
        let stringsMap = {}
        var language = (navigator.browserLanguage || navigator.language).toLowerCase();
        if (language.indexOf("zh") > -1) {
            stringsMap = zhCN;
        } else if (language.indexOf("ja") > -1) {
            stringsMap = ja;
        } else {
            stringsMap = en;
        }

        return stringsMap[resId]//MindMapApplication.getSharedContext().getString(resId);
    }

    //     public static TextView createText(Context context, int fontSize) {
    //         TextView label = new TextView(context);
    //         label.setTextColor(colors(R.color.black80));
    //         label.setTextSize(TypedValue.COMPLEX_UNIT_DIP, fontSize);
    //         label.setLines(1);
    //         label.setMaxLines(1);
    //         label.setSingleLine(true);
    //         label.setEllipsize(TextUtils.TruncateAt.MIDDLE);
    //         label.setGravity(Gravity.LEFT | Gravity.CENTER_VERTICAL);
    //         return label;
    //     }

    //     public static TextView createText(Context context) {
    //         return createText(context, 14);
    //     }

    colors(res) {
        return this.MindMapApplication.getSharedContext().getResources().getColor(res);
    }

    // public static Drawable getDrawableResource(int id) {
    //     BitmapDrawable bitmapDrawable = MemCacheManager.get().getDrawableFromMemCache(id);
    //     if (bitmapDrawable != null) {
    //         return bitmapDrawable;
    //     } else {
    //         Drawable drawable = ContextCompat.getDrawable(MindMapApplication.getSharedContext(), id);
    //         if (drawable instanceof BitmapDrawable) {
    //             MemCacheManager.get().addDrawableToMemCache(id, (BitmapDrawable) drawable);
    //         }
    //         return drawable;
    //     }
    // }

    // public static Bitmap getBitmapResource(int id) {
    //     BitmapDrawable bitmapDrawable = MemCacheManager.get().getDrawableFromMemCache(id);
    //     if (bitmapDrawable != null) {
    //         return bitmapDrawable.getBitmap();
    //     } else {
    //         Drawable drawable = ContextCompat.getDrawable(MindMapApplication.getSharedContext(), id);
    //         if (drawable instanceof BitmapDrawable) {
    //             MemCacheManager.get().addDrawableToMemCache(id, (BitmapDrawable) drawable);
    //             return ((BitmapDrawable)drawable).getBitmap();
    //         }
    //         return null;
    //     }

    // }

    //     public static boolean isActivityFinished(Activity activity) {
    //         if (activity == null) {
    //             return true;
    //         }
    //         if (Build.VERSION.SDK_INT >= Build.VERSION_CODES.JELLY_BEAN_MR1) {
    //             return activity.isFinishing() || activity.isDestroyed();
    //         } else {
    //             return activity.isFinishing();
    //         }
    //     }

    getScreenWidth() {
        if (this.isPhoneAndPad()) {
            return document.documentElement.clientWidth
        }
        let viewPort = document.getElementById('canvas-father');
        if (viewPort == null || viewPort == undefined) {
            return document.documentElement.clientWidth
        }
        return viewPort.clientWidth;

        // return 900;
    }

    //     public static int getScreenHeight(boolean includeStatusBar) {
    //         if (screen_height == 0 || true) {
    //             WindowManager wm = (WindowManager) MindMapApplication.getSharedContext().getSystemService(
    //                     Context.WINDOW_SERVICE);
    //             screen_height = wm.getDefaultDisplay().getHeight();
    //         }
    //         if (!includeStatusBar) {
    //             int statusBarHeight = getStatusBarHeight();
    //             screen_height -= statusBarHeight;
    //         }
    //         return screen_height;
    //     }

    getScreenHeight() {
        let viewPort = document.getElementById('canvas-father');
        if (viewPort == null || viewPort == undefined) {
            return document.documentElement.clientHeight
        }
        return viewPort.clientHeight;

        // return 400;
    }

    //     public static int getStatusBarHeight() {
    //         int statusBarHeight = -1;
    //         //获取status_bar_height资源的ID
    //         int resourceId = MindMapApplication.getSharedContext().getResources().getIdentifier("status_bar_height", "dimen", "android");
    //         if (resourceId > 0) {
    //             //根据资源ID获取响应的尺寸值
    //             statusBarHeight = MindMapApplication.getSharedContext().getResources().getDimensionPixelSize(resourceId);
    //         }
    //         return statusBarHeight;
    //     }

    //     public static Bitmap viewConversionBitmap(View v, boolean isHD) {
    //         int w = v.getWidth();
    //         int h = v.getHeight();
    //         try {
    //             Bitmap bmp = Bitmap.createBitmap(w, h, isHD ? Bitmap.Config.ARGB_8888 : Bitmap.Config.ARGB_4444);
    //             Canvas c = new Canvas(bmp);
    //             v.draw(c);
    //             return bmp;
    //         } catch (Exception e) {
    //             return Bitmap.createBitmap(50, 50, isHD ? Bitmap.Config.ARGB_8888 : Bitmap.Config.ARGB_4444);
    //         }
    //     }

    //     public static Bitmap viewConversionBitmap(View v, CGRect rect, boolean isHD) {
    //         try {
    //             int w = rect.width();
    //             int h = rect.height();

    //             Bitmap bmp = Bitmap.createBitmap(w, h, isHD ? Bitmap.Config.ARGB_8888 : Bitmap.Config.ARGB_4444);
    //             Canvas c = new Canvas(bmp);
    //             c.drawColor(Colors.clear);
    //             c.translate(-rect.x, -rect.y);
    //             v.draw(c);
    //             c.translate(0, 0);
    //             //Bitmap bitmap = bmp;//viewConversionBitmap(v, isHD);
    //             //if (bitmap != null) {
    //                 //c.drawBitmap(bitmap, rect.getRect(), new Rect(0, 0, rect.width(), rect.height()), null);
    // //            }
    //             return bmp;
    //         } catch (Exception e) {
    //             return Bitmap.createBitmap(50, 50, isHD ? Bitmap.Config.ARGB_8888 : Bitmap.Config.ARGB_4444);
    //         }
    //     }

    //     public static Bitmap viewConversionBitmap(View v, CGRect rect) {
    //         return viewConversionBitmap(v, rect, true);
    //     }

    //     public static void setStatusBarColor(Activity activity, int statusColor) {
    //         Window window = activity.getWindow();
    //         //取消状态栏透明
    //         window.clearFlags(WindowManager.LayoutParams.FLAG_TRANSLUCENT_STATUS);
    //         //添加Flag把状态栏设为可绘制模式
    //         window.addFlags(WindowManager.LayoutParams.FLAG_DRAWS_SYSTEM_BAR_BACKGROUNDS);
    //         //设置状态栏颜色
    //         window.setStatusBarColor(statusColor);
    //         //设置系统状态栏处于可见状态
    //         window.getDecorView().setSystemUiVisibility(View.SYSTEM_UI_FLAG_VISIBLE);
    //         //让view不根据系统窗口来调整自己的布局
    //         ViewGroup mContentView = (ViewGroup) window.findViewById(Window.ID_ANDROID_CONTENT);
    //         View mChildView = mContentView.getChildAt(0);
    //         if (mChildView != null) {
    //             ViewCompat.setFitsSystemWindows(mChildView, false);
    //             ViewCompat.requestApplyInsets(mChildView);
    //         }
    //     }

    // setStatusBarLightMode(activity, color) {
    //     if (Build.VERSION.SDK_INT >= Build.VERSION_CODES.KITKAT) {
    //         //判断是否为小米或魅族手机，如果是则将状态栏文字改为黑色
    //         if (MIUISetStatusBarLightMode(activity, true)) {
    //             setMiuiUI(activity, Colors.isDarkColor(Colors.getUiColor(color)));
    //         } else if (FlymeSetStatusBarLightMode(activity, true)) {
    //             setFlymeUI(activity, Colors.isDarkColor(Colors.getUiColor(color)));
    //         } if (Build.VERSION.SDK_INT >= Build.VERSION_CODES.M) {
    //             //如果是6.0以上将状态栏文字改为黑色，并设置状态栏颜色
    //             if (Colors.isLightColor(Colors.getUiColor(color))) {
    //                 activity.getWindow().setBackgroundDrawableResource(android.R.color.transparent);
    //                 activity.getWindow().getDecorView().setSystemUiVisibility(View.SYSTEM_UI_FLAG_LAYOUT_FULLSCREEN | View.SYSTEM_UI_FLAG_LIGHT_STATUS_BAR);
    //             } else {
    //                 activity.getWindow().getDecorView().setSystemUiVisibility(View.SYSTEM_UI_FLAG_LAYOUT_FULLSCREEN);
    //             }
    //             activity.getWindow().setStatusBarColor(color);
    //             ViewGroup mContentView = (ViewGroup) activity.getWindow().findViewById(Window.ID_ANDROID_CONTENT);
    //             View mChildView = mContentView.getChildAt(0);
    //             if (mChildView != null) {
    //                 ViewCompat.setFitsSystemWindows(mChildView, true);
    //                 ViewCompat.requestApplyInsets(mChildView);
    //             }
    //         }
    //     }
    // }

    //     public static boolean setFlymeUI(Activity activity, boolean dark) {
    //         try {
    //             Window window = activity.getWindow();
    //             WindowManager.LayoutParams lp = window.getAttributes();
    //             Field darkFlag = WindowManager.LayoutParams.class.getDeclaredField("MEIZU_FLAG_DARK_STATUS_BAR_ICON");
    //             Field meizuFlags = WindowManager.LayoutParams.class.getDeclaredField("meizuFlags");
    //             darkFlag.setAccessible(true);
    //             meizuFlags.setAccessible(true);
    //             int bit = darkFlag.getInt(null);
    //             int value = meizuFlags.getInt(lp);
    //             if (dark) {
    //                 value |= bit;
    //             } else {
    //                 value &= ~bit;
    //             }
    //             meizuFlags.setInt(lp, value);
    //             window.setAttributes(lp);
    //             return true;
    //         } catch (Exception e) {
    //             e.printStackTrace();
    //             return false;
    //         }
    //     }

    //     public static boolean setMiuiUI(Activity activity, boolean dark) {
    //         try {
    //             Window window = activity.getWindow();
    //             Class<?> clazz = activity.getWindow().getClass();
    //             @SuppressLint("PrivateApi") Class<?> layoutParams = Class.forName("android.view.MiuiWindowManager$LayoutParams");
    //             Field field = layoutParams.getField("EXTRA_FLAG_STATUS_BAR_DARK_MODE");
    //             int darkModeFlag = field.getInt(layoutParams);
    //             Method extraFlagField = clazz.getDeclaredMethod("setExtraFlags", int.class, int.class);
    //             extraFlagField.setAccessible(true);

    //             if (dark) { //状态栏亮色且黑色字体
    //                 extraFlagField.invoke(window, darkModeFlag, darkModeFlag);
    //             } else {
    //                 extraFlagField.invoke(window, 0, darkModeFlag);
    //             }
    //             return true;
    //         } catch (Exception e) {
    //             e.printStackTrace();
    //             return false;
    //         }
    //     }

    //     static boolean MIUISetStatusBarLightMode(Activity activity, boolean darkmode) {
    //         boolean result = false;
    //         Class<? extends Window> clazz = activity.getWindow().getClass();
    //         try {
    //             int darkModeFlag = 0;
    //             Class<?> layoutParams = Class.forName("android.view.MiuiWindowManager$LayoutParams");
    //             Field field = layoutParams.getField("EXTRA_FLAG_STATUS_BAR_DARK_MODE");
    //             darkModeFlag = field.getInt(layoutParams);
    //             Method extraFlagField = clazz.getMethod("setExtraFlags", int.class, int.class);
    //             extraFlagField.invoke(activity.getWindow(), darkmode ? darkModeFlag : 0, darkModeFlag);
    //             result = true;
    //         } catch (Exception e) {
    // //            e.printStackTrace();
    //         }
    //         return result;
    //     }

    //     static boolean FlymeSetStatusBarLightMode(Activity activity, boolean darkmode) {
    //         boolean result = false;
    //         try {
    //             WindowManager.LayoutParams lp = activity.getWindow().getAttributes();
    //             Field darkFlag = WindowManager.LayoutParams.class
    //                     .getDeclaredField("MEIZU_FLAG_DARK_STATUS_BAR_ICON");
    //             Field meizuFlags = WindowManager.LayoutParams.class
    //                     .getDeclaredField("meizuFlags");
    //             darkFlag.setAccessible(true);
    //             meizuFlags.setAccessible(true);
    //             int bit = darkFlag.getInt(null);
    //             int value = meizuFlags.getInt(lp);
    //             if (darkmode) {
    //                 value |= bit;
    //             } else {
    //                 value &= ~bit;
    //             }
    //             meizuFlags.setInt(lp, value);
    //             activity.getWindow().setAttributes(lp);
    //             result = true;
    //         } catch (Exception e) {
    // //            e.printStackTrace();
    //         }
    //         return result;
    //     }

    getDrawable(cornerValue, strokeWidth, strokeColor, fullColor) {
        //未翻译
        let drawable = new GradientDrawable();
        drawable.setCornerRadius(cornerValue);
        drawable.setStroke(strokeWidth, Colors.getUiColor(strokeColor));
        drawable.setColor(Colors.getUiColor(fullColor));
        return drawable;
    }

    getDrawable(color) {
        //未翻译
        let drawable = new GradientDrawable();
        drawable.setCornerRadius(0);
        drawable.setStroke(0, 0);
        drawable.setColor(Colors.getUiColor(color));
        return drawable;
    }

    // //    float[] radii = new float[]{
    // //            mLeftTopCorner, mLeftTopCorner,
    // //            mRightTopCorner, mRightTopCorner,
    // //            mRightBottomCorner, mRightBottomCorner,
    // //            mLeftBottomCorner, mLeftBottomCorner
    // //    }
    //     public static Drawable getDrawable(float[] radii, int strokeWidth, int strokeColor, int fullColor) {
    //         GradientDrawable drawable = new GradientDrawable();
    //         drawable.setCornerRadii(radii);
    //         drawable.setStroke(strokeWidth, Colors.getUiColor(strokeColor));
    //         drawable.setColor(Colors.getUiColor(fullColor));
    //         return drawable;
    //     }

    //     public static StateListDrawable getStateListDrawable(int resId, int selectedResId) {
    //         StateListDrawable drawable = new StateListDrawable();
    //         drawable.addState(new int[]{android.R.attr.state_selected}, getDrawableResource(selectedResId));
    //         drawable.addState(new int[]{}, getDrawableResource(resId));

    //         return drawable;
    //     }

    getArrowWidth(fPoint, tPoint, lineWidth) {
        let line = Math.sqrt(Math.pow(Math.abs(tPoint.x - fPoint.x), 2) + Math.pow(Math.abs(tPoint.y - fPoint.y), 2));
        let arrowH = line > 10 ? lineWidth * 3 : line / 3;
        if (arrowH < 5) {
            arrowH = 5;
        } else if (arrowH > 12) {
            arrowH = 12;
        }
        return arrowH;
    }

    getArrowPoint(fPoint, tPoint, lineWidth, arrowHeight = 0) {
        let p1 = new Point();          //箭头点1
        let p2 = new Point();        //箭头点2
        let p3 = new Point();           //箭头最前面点
        let arrowH = arrowHeight <= 0 ? this.getArrowWidth(fPoint, tPoint, lineWidth) : arrowHeight;
        
        //线与水平方向的夹角
        let angle = Util.getAnglesWithThreePoints(fPoint, tPoint, new Point(fPoint.x, tPoint.y));
        let _x = (Math.abs(Math.sin(angle))) * arrowH / 2;
        let _y = (Math.abs(Math.cos(angle))) * arrowH / 2;
        //向右上角、水平向右
        if (tPoint.x >= fPoint.x && Math.abs(tPoint.x - fPoint.x) > 2 && tPoint.y <= fPoint.y) {
            p1.x = tPoint.x - _x;
            p1.y = tPoint.y - _y;

            p2.x = tPoint.x + _x;
            p2.y = tPoint.y + _y;

            p3.x = tPoint.x + _y * 2;
            p3.y = tPoint.y - _x * 2;
        } else if (tPoint.x > fPoint.x && Math.abs(tPoint.x - fPoint.x) > 2 && tPoint.y > fPoint.y) {
            //向右下角
            p1.x = tPoint.x + _x;
            p1.y = tPoint.y - _y;

            p2.x = tPoint.x - _x;
            p2.y = tPoint.y + _y;

            p3.x = tPoint.x + _y * 2;
            p3.y = tPoint.y + _x * 2;
        } else if (tPoint.x < fPoint.x && Math.abs(tPoint.x - fPoint.x) > 2 && tPoint.y < fPoint.y) {
            //向左上角
            p1.x = tPoint.x - _x;
            p1.y = tPoint.y + _y;

            p2.x = tPoint.x + _x;
            p2.y = tPoint.y - _y;

            p3.x = tPoint.x - _y * 2;
            p3.y = tPoint.y - _x * 2;

        } else if (tPoint.x < fPoint.x && Math.abs(tPoint.x - fPoint.x) > 2 && tPoint.y >= fPoint.y) {
            //向左下角,水平向左
            p1.x = tPoint.x - _x;
            p1.y = tPoint.y - _y;

            p2.x = tPoint.x + _x;
            p2.y = tPoint.y + _y;

            p3.x = tPoint.x - _y * 2;
            p3.y = tPoint.y + _x * 2;
        } else if (Math.abs(tPoint.x - fPoint.x) <= 2) {
            //竖直方向
            p1.x = tPoint.x - arrowH / 2;
            p1.y = tPoint.y;
            p2.x = tPoint.x + arrowH / 2;
            p2.y = tPoint.y;
            p3.x = tPoint.x;
            p3.y = tPoint.y > fPoint.y ? tPoint.y + arrowH : tPoint.y - arrowH;
        }

        return [p1, p2, p3];
    }

    isPhoneAndPad() {
        return window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|micromessenger|weibo|qq)/i // midp|iphone os|ipad|ucweb|windows mobile|
        )
    }

    getViewPoint(view) {
        var point = new Point(-1, -1);
        if (view == null) {
            return point;
        }
        // transform: translate(7659.97px, 7352.8px);
        if (view.style != null && view.style.transform != null && view.style.transform != "" && view.style.transform.indexOf("translate") > -1) {
            let transforms = view.style.transform.split("(");
            if (transforms.length > 1) {
                let transformsValues = transforms[1].split(",");
                if (transformsValues.length > 0) {
                    point.x = this.getViewPxValue(transformsValues[0])
                }
                if (transformsValues.length > 1) {
                    let valueY = transformsValues[1].split(")");
                    if (valueY.length > 0) {
                        point.y = this.getViewPxValue(valueY[0])
                    }
                }
            }
        }
        if (view.style != null && view.style.left != null && view.style.left != "") {
            point.x = this.getViewPxValue(view.style.left)
        } else if (view.getAttribute != null && view.getAttribute("x") != null && view.getAttribute("x") != "") {
            let ax = view.getAttribute("x")
            point.x = this.getViewPxValue(ax)
        } else if (view.x != null && view.x != "") {
            point.x = this.getViewPxValue(view.x)
        }
        if (view.style != null && view.style.top != null && view.style.top != "") {
            point.y = this.getViewPxValue(view.style.top)
        } else if (view.getAttribute != null && view.getAttribute("y") != null && view.getAttribute("y") != "") {
            let ay = view.getAttribute("y")
            point.y = this.getViewPxValue(ay)
        } else if (view.y != null && view.y != "") {
            point.y = this.getViewPxValue(view.y)
        }
        return point;
    }

    getViewSize(view) {
        var size = new Size(-1, -1);
        if (view == null) {
            return size;
        }
        if (view.style != null && view.style.width != null && view.style.width != "") {
            size.width = this.getViewPxValue(view.style.width)
        } else if (view.getAttribute != null) {
            let awidth = view.getAttribute("width")
            if (awidth != null && awidth != "") {
                size.width = this.getViewPxValue(awidth)
            }
        } else if (view.width != null && view.width != "") {
            size.width = this.getViewPxValue(view.width)
        }
        if (view.style != null && view.style.height != null && view.style.height != "") {
            size.height = this.getViewPxValue(view.style.height)
        } else if (view.getAttribute != null) {
            let aheight = view.getAttribute("height")
            if (aheight != null && aheight != "") {
                size.height = this.getViewPxValue(aheight)
            }
        } else if (view.y != null && view.height != "") {
            size.height = this.getViewPxValue(view.height)
        } 
        return size;
    }

    getViewPxValue(value) {
        if (value == null) {
            return -1;
        }
        try {
            if (typeof value == "string") {
                if (value.indexOf("px") > -1) {
                    value = value.replace("px", "");
                } else if (value.trim() == "") {
                    return -1;
                }
                return parseInt(value);
            } else if (typeof value == "number") {
                return value;
            }
        } catch (error) {
            console.log(error);
        }
        
        return -1;
    }
    getTopByWindow(element){
        let offset = element.offsetTop;
        if(element.offsetParent!=null) {
             offset += this.getTopByWindow(element.offsetParent);
         }
       return offset;
    }

    isElementChild(target, element){
        if (target == null || element == null) {
            return false
        }
        if(target.parentNode == element) {
             return true
        } else if (target.parentNode != null) {
            return this.isElementChild(target.parentNode, element)
        }
       return false;
    }

    isShowColorPanel() {
        //CommonBgColor-box
        let colorPanel = document.getElementsByClassName("CommonBgColor-box")
        if (colorPanel == null || colorPanel.length == 0) {
            return false;
        }
        for (let index = 0; index < colorPanel.length; index++) {
            if (colorPanel[index].style != null && colorPanel[index].style.display != 'none') {
                return true;
            }
        }
        return false;
    }

    handDrawRect(bezierPath, type,
                width, height,
                strokeWidth, random) {
        if (width * height <= 0) {
            return
        }
        if (type == MindElementFullType.Grid) {
            var space = 1.0
            var lineFullWidth = 5

            var fullLenght = width
            var cellCount = (Math.ceil(fullLenght/lineFullWidth + space)) + 1
            var lastStart = 0

            for (let i = 0; i < cellCount; i++) {
                let startX = lastStart
                let startY = 0
                let endX = lastStart
                let endY = height

                bezierPath.moveTo(startX, startY)
                bezierPath.lineTo(startX + lineFullWidth, startY)
                bezierPath.lineTo(endX + lineFullWidth, endY)
                bezierPath.lineTo(endX, endY)
                bezierPath.lineTo(startX, startY)
                lastStart += lineFullWidth + space
            }
            fullLenght = height
            cellCount = Math.ceil(fullLenght/lineFullWidth + space) + 1
            lastStart = 0
            for (let i = 0; i < cellCount; i++) {
                let startX = 0
                let startY = lastStart
                let endX = width
                let endY =  lastStart

                bezierPath.moveTo(startX, startY)
                bezierPath.lineTo(endX, startY)
                bezierPath.lineTo(endX, endY + lineFullWidth)
                bezierPath.lineTo(startX, startY + lineFullWidth)
                bezierPath.lineTo(startX, startY)
                lastStart += lineFullWidth + space
            }
        } else if (type == MindElementFullType.DrawGrid) {
            var degrees = 45
            let lineWidth = strokeWidth
            var space = 1.5
            var minLineWidth = lineWidth/6

            degrees = 0
            space = 2
            minLineWidth = lineWidth/2

            var fullLenght = width + Math.abs((Util.tan(degrees)) * height)
            var cellCount = Math.ceil(fullLenght/minLineWidth)
            var lastStart = 0

            for (let index = 0; index < cellCount; index++) {
                let randomNum = Math.ceil(Math.random()*10) + 1; //1~11 随机数

                let startDirection = (Math.random()*10 > 4 ? 1 : -1)
                let endDirection = (Math.random()*10 > 4 ? 1 : -1)

                let cellSpace = space / (randomNum)
                let cellLineWidth = lineWidth * (Math.max(minLineWidth, randomNum)/10)

                let startOffset = index > 0 ? cellSpace * 2 * startDirection : 0
                let endOffset = index > 0 ? cellSpace * 2 * endDirection : 0

                let startX = lastStart + startOffset
                let startY = 0
                let endX = lastStart - (Util.tan(degrees)) * height + endOffset
                let endY = height

                bezierPath.moveTo(startX, startY)
                bezierPath.lineTo(startX + cellLineWidth + cellSpace * 2, startY)
                bezierPath.lineTo(endX + cellLineWidth + cellSpace * 2, endY)
                bezierPath.lineTo(endX, endY)
                bezierPath.lineTo(startX, startY)
                lastStart += cellLineWidth + cellSpace * 2
                if (lastStart > fullLenght) {
                    break
                }
            }
            degrees = 90
            fullLenght = height
            cellCount = (Math.ceil(fullLenght/minLineWidth))
            lastStart = 0
            for (let index = 0; index < cellCount; index++) {
                let randomNum = Math.ceil(Math.random()*10) + 1 //1~10 随机数

                let startDirection = (Math.random()*10 > 4 ? 1 : -1)
                let endDirection = (Math.random()*10 > 4 ? 1 : -1)

                let cellSpace = space / (randomNum)
                let cellLineWidth = lineWidth * (Math.max(minLineWidth, randomNum)/10)

                let startOffset = index > 0 ? cellSpace * 2 * startDirection : 0
                let endOffset = index > 0 ? cellSpace * 2 * endDirection : 0

                let startX = 0
                let startY = lastStart + startOffset
                let endX = width
                let endY =  lastStart + endOffset

                bezierPath.moveTo(startX, startY)
                bezierPath.lineTo(endX, startY)
                bezierPath.lineTo(endX, endY + cellLineWidth + cellSpace * 2)
                bezierPath.lineTo(startX, startY + cellLineWidth + cellSpace * 2)
                bezierPath.lineTo(startX, startY)
                lastStart += cellLineWidth + cellSpace * 2
                if (lastStart > fullLenght) {
                    break
                }
            }
        } else if (type == MindElementFullType.DrawHorizontal) {
            let lineWidth = strokeWidth
            let space = 1.0
            var minLineWidth = lineWidth/6

            minLineWidth = lineWidth/6

            let fullLenght = height
            let cellCount = (Math.ceil(fullLenght/minLineWidth))
            var lastStart = 0

            for (let index = 0; index < cellCount; index++) {
                let randomNum = Math.ceil(Math.random()*10) + 1 //1~10 随机数

                let startDirection = (Math.random()*10 > 4 ? 1 : -1)
                let endDirection = (Math.random()*10 > 4 ? 1 : -1)

                let cellSpace = space / (randomNum)
                let cellLineWidth = lineWidth * (Math.max(minLineWidth, randomNum)/10)

                let startOffset = index > 0 ? cellSpace * 2 * startDirection : 0
                let endOffset = index > 0 ? cellSpace * 2 * endDirection : 0

                let startX = 0
                let startY = lastStart + startOffset
                let endX = width
                let endY = lastStart + endOffset

                bezierPath.moveTo(startX, startY)
                bezierPath.lineTo(endX, startY)
                bezierPath.lineTo(endX, endY + cellLineWidth + cellSpace * 2)
                bezierPath.lineTo(startX, startY + cellLineWidth + cellSpace * 2)
                bezierPath.lineTo(startX, startY)
                lastStart += cellLineWidth + cellSpace * 2
                if (lastStart > fullLenght) {
                    break
                }
            }
        } else {
            var degrees = 45
            let lineWidth = strokeWidth
            var space = 1.5
            var minLineWidth = lineWidth/6

            degrees = 45
            space = 1.5
            minLineWidth = lineWidth/6

            let fullLenght = width + Math.abs((Util.tan(degrees)) * height)
            let cellCount = (Math.ceil(fullLenght/minLineWidth))
            var lastStart = 0

            for (let index = 0; index < cellCount; index++) {
                let randomNum = Math.ceil(Math.random()*10) + 1 //1~10 随机数

                let startDirection = (Math.random()*10 > 4 ? 1 : -1)
                let endDirection = (Math.random()*10 > 4 ? 1 : -1)

                let cellSpace = space / (randomNum)
                let cellLineWidth = lineWidth * (Math.max(minLineWidth, randomNum)/10)

                let startOffset = index > 0 ? cellSpace * 2 * startDirection : 0
                let endOffset = index > 0 ? cellSpace * 2 * endDirection : 0

                let startX = lastStart + startOffset
                let startY = 0
                let endX = lastStart - (Util.tan(degrees)) * height + endOffset
                let endY = height

                bezierPath.moveTo(startX, startY)
                bezierPath.lineTo(startX + cellLineWidth + cellSpace * 2, startY)
                bezierPath.lineTo(endX + cellLineWidth + cellSpace * 2, endY)
                bezierPath.lineTo(endX, endY)
                bezierPath.lineTo(startX, startY)
                lastStart += cellLineWidth + cellSpace * 2
                if (lastStart > fullLenght) {
                    break
                }
            }
        }
    }

    showNeedToUpload(view, editMindmapVM) {
        if (view == null || editMindmapVM == null) {
            return;
        }
        let that = this;
        view.$confirm({
          title: that.getString(strings.Mind_Tips),
          content: "请先同步文件，再使用该功能，确认同步吗？",
          cancelText: that.getString(strings.Global_Cancel),
          okText: that.getString(strings.Global_Ok),
          centered: true,
          style: {
            borderRadius: "20px",
          },
          onOk() {
            editMindmapVM.cloudStorage()
          },
          onCancel() {},
        });
    }

    createATagOpenUrl(url) {
        const a = document.createElement('a');//创建a标签浏览器不会阻止弹出窗口
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
        }, 2000);
    }

    //下载图片
    downLoadImg(imgSrc, imgName, outType) {
        let defaultImgType = 'png';
        if (typeof outType === 'object') {
            if (!outType.imgType) {
                outType.imgType = defaultImgType;//如果保存类型为空，默认为png
            }
        }
        imgName = (imgName || "photo") + '.' + (outType.imgType || defaultImgType);
        if (tools.isElectron()) {
            download(imgSrc, imgName, "image/" + outType.imgType);
        } else {
            let a = document.createElement("a");
            let event = new MouseEvent("click");
            a.download = imgName;
            a.href = imgSrc;
            a.dispatchEvent(event);
            setTimeout(() => a = null, 1000);
        }
    }

    getElementViewPosition(element){
        //计算x坐标
        var actualLeft = element.offsetLeft;
        var current = element.offsetParent;
        while (current !== null){
          actualLeft +=  (current.offsetLeft+current.clientLeft);
          current = current.offsetParent;
        }
        if (document.compatMode == "BackCompat"){
          var elementScrollLeft=document.body.scrollLeft;
        } else {
          var elementScrollLeft=document.documentElement.scrollLeft;
        }
        var left = actualLeft - elementScrollLeft;
        //计算y坐标
        var actualTop = element.offsetTop;
        var current = element.offsetParent;
        while (current !== null){
          actualTop += (current.offsetTop+current.clientTop);
          current = current.offsetParent;
        }
        if (document.compatMode == "BackCompat"){
          var elementScrollTop=document.body.scrollTop;
        } else {
          var elementScrollTop=document.documentElement.scrollTop;
        }
        var right = actualTop-elementScrollTop;
        //返回结果
        return {x: left, y: right}
    }

    scaleDownAnimation(elem, complete) {
        if (elem == null || elem.style == null) {
            return
        }
        let point = this.getViewPoint(elem)
        let size = this.getViewSize(elem)
        let transformOrigin = (point.x + size.getWidth() / 2) + 'px ' + (point.y + size.getHeight() / 2) + 'px'
        elem.style.transformOrigin = transformOrigin
        elem.style.transform = 'scale(1.0 )';
        var pos = 0;
        var max = 20;
        var id = setInterval(frame, 5);
        function frame() {
            if (pos == max) {
                clearInterval(id);
                elem.style.transform = "scale(1.0)"
                if (complete != null) {
                    complete()
                }
            } else {
                pos++; 
                let transform = 'scale(' + (1.0 - pos/max) + ')'
                elem.style.transform = transform;
            }
        }
    }

    scaleUpAnimation(elem, complete) {
        if (elem == null || elem.style == null) {
            return
        }
        let point = this.getViewPoint(elem)
        let size = this.getViewSize(elem)

        let transformOrigin = (point.x + size.getWidth() / 2) + 'px ' + (point.y + size.getHeight() / 2) + 'px'
        elem.style.transformOrigin = transformOrigin
        elem.style.transform = 'scale(0.0 )';
        var pos = 0;
        var max = 20;
        var id = setInterval(frame, 5);
        function frame() {
            if (pos == max) {
                clearInterval(id);
                elem.style.transform = "scale(1.0)"
                if (complete != null) {
                    complete()
                }
            } else {
                pos++; 
                let transform = 'scale(' + (pos/max) + ')'
                elem.style.transform = transform;
            }
        }
    }
}
export default UiUtil
