<template>
  <!-- 导出图片面板 -->
  <div id="getimg-type">
    <div class="img-type-box">
      <div class="img-title-box">
        <div class="img-title-text">
          <span v-if="imgSaveMode == saveImgModes.PrintPictures">
            {{ getString(strings.Mind_Edit_Left_Menu_Printing) }}
          </span>
          <span v-else>
            {{ getString(strings.Mind_Edit_Left_Menu_Save_Picture) }}
          </span>
        </div>
        <section class="img-close-box">
          <div class="img-close-model" @click="colseImgTypeModel">
            <img
              class="close-button-img"
              src="../../../assets/img/popupsImg/colse_new.svg"
              alt=""
            />
          </div>
        </section>
      </div>
      <section class="export-picture-box">
        <article class="setting-picture-left">
          <!-- 保存格式  -->
          <section class="setting-picture-item" v-if="showSaveFormat">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Edit_Left_Menu_Save_Picture_Format) }}
            </label>
            <div class="img-type-obj">
              <div
                class="img-obj-content"
                v-for="(item, index) in radioStyle"
                :key="index"
                @click="onChange(index, item.type)"
              >
                <div
                  class="img-obj-text"
                  :class="
                    downloadImageData.imgTypeIndex == index ? 'active' : ''
                  "
                >
                  <div
                    v-if="downloadImageData.imgTypeIndex == index"
                    class="img-active"
                  >
                    <img
                      src="../../../../static/img/saveImg/selectedBg.svg"
                      alt=""
                    />
                  </div>
                  <p>{{ item.title }}</p>
                </div>
              </div>
            </div>
          </section>
          <!-- 水印设置  -->
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Watermark_Settings) }}
            </label>
            <article class="setting-item-content">
              <div class="watermark-settings-box">
                <div class="watermark-settings-item">
                  <a-checkbox
                    :checked="downloadImageData.chopSwitch"
                    @change="onChangeChopSwitch"
                  >
                    <span
                      class="font-style text-overflow"
                      :title="getString(strings.Mind_Simplification_Watermark)"
                    >
                      {{ getString(strings.Mind_Simplification_Watermark) }}
                      <!-- 寻简水印 -->
                    </span>
                  </a-checkbox>
                </div>
                <div class="watermark-settings-item">
                  <a-checkbox
                    :checked="customWatermarkShow"
                    @change="onChangeWatermarkSwitch"
                  >
                    <span
                      class="font-style text-overflow"
                      :title="getString(strings.Mind_Custom_Watermark)"
                    >
                      {{ getString(strings.Mind_Custom_Watermark) }}
                      <!-- 自定义水印 -->
                    </span>
                  </a-checkbox>
                </div>
              </div>
            </article>
          </section>
          <template v-if="customWatermarkShow">
            <!-- 水印文字 -->
            <section class="setting-picture-item">
              <label class="setting-item-label">
                {{ getString(strings.Save_Img_Watermark_Text) }}
              </label>
              <article class="setting-item-content">
                <a-input
                  class="setting-width"
                  :placeholder="getString(strings.Save_Img_Please_Input)"
                  :value="downloadImageData.chopText"
                  @blur="onChnageChopText"
                />
              </article>
            </section>
            <!-- 水印Logo -->
            <section class="setting-picture-item">
              <label class="setting-item-label">
                <!-- {{getString(strings.Save_Img_Watermark_Logo)}} -->
                Logo
              </label>
              <article class="setting-item-content chop-logo">
                <img
                  v-if="downloadImageData.chopLogo == ''"
                  src="../../../../static/img/saveImg/add_logo.svg"
                  @click="setLogo"
                  alt=""
                />
                <img
                  v-else
                  :src="downloadImageData.chopLogo"
                  @click="setLogo"
                  alt=""
                />
                <input
                  type="file"
                  accept="image/*"
                  @change="handleFile"
                  class="hiddenInput"
                />
                <img
                  v-if="downloadImageData.chopLogo"
                  src="../../../../static/img/saveImg/closeBtn.svg"
                  @click="deletedChopLogo"
                  class="deleted-button"
                  alt=""
                />
                <!-- <span
                  v-if="downloadImageData.chopLogo"
                  class="font-style deleted-button"
                  @click="deletedChopLogo"
                >
                  {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                </span> -->
              </article>
            </section>
            <!-- 水印样式 -->
            <section
              class="setting-picture-item"
              style="align-items: flex-start" >

              <label class="setting-item-label">
                {{ getString(strings.Save_Img_Watermark_Style) }}
              </label>
              <article class="setting-item-content">
                <div
                  class="chop-type-content"
                  v-for="(item, index) in chopTypes"
                  :key="index"
                  @click="getChopType(index, item.type)"
                >
                  <img
                    class="clickType"
                    v-if="index == clickChopTypeIndex"
                    src="../../../../static/img/saveImg/selectedBg.svg"
                    alt=""
                  />
                  <img
                    class="clickImg"
                    :class="
                      clickChopTypeIndex == index ? 'chop-type-active' : ''
                    "
                    :src="item.img"
                    alt=""
                  />
                  <span class="font-style">{{ getString(item.text) }}</span>
                </div>
              </article>
            </section>
          </template>
          <!-- 插入背景 -->
          <section class="setting-picture-item" v-if="showBgImg">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Insert_Background) }}
            </label>
            <article class="setting-item-content chop-logo">
              <img
                v-if="downloadImageData.insertBgImg == ''"
                src="../../../../static/img/saveImg/add_logo.svg"
                @click="setBgImg"
                alt=""
              />
              <template v-else>
                <img
                  :src="downloadImageData.insertBgImg"
                  @click="setBgImg"
                  alt=""
                />
                <input
                  type="file"
                  accept="image/*"
                  @change="handleFile"
                  class="hiddenInput"
                />
                <img
                  v-if="downloadImageData.insertBgImg"
                  src="../../../../static/img/saveImg/closeBtn.svg"
                  @click="deletedBgImg"
                  class="deleted-button"
                  alt=""
                />
                <div class="img-type-obj" style="margin-left: 28px">
                  <div
                    class="img-obj-content"
                    v-for="(item, index) in insertBgStyle"
                    :key="index"
                    @click="onChangeInsertBgType(index, item.type)"
                    style="min-width: 90px"
                  >
                    <div
                      class="img-obj-text"
                      :class="
                        downloadImageData.insertBgType == item.type
                          ? 'active'
                          : ''
                      "
                    >
                      <div
                        v-if="downloadImageData.insertBgType == item.type"
                        class="img-active"
                      >
                        <img
                          src="../../../../static/img/saveImg/selectedBg.svg"
                          alt=""
                        />
                      </div>
                      <p>{{ getString(item.title) }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </article>
          </section>
          <!-- 尺寸适配 -->
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Size_Fit) }}
            </label>
            <article class="setting-item-content">
              <a-select
                v-model="sizeFit"
                defaultValue="sizeFit"
                style="width: 342px"
                @change="sizeFitChange"
              >
                <a-icon
                  slot="suffixIcon"
                  style="color: #333"
                  type="caret-down"
                />
                <a-select-option
                  v-for="item of sizeFitList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </article>
          </section>
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Map_Picture_Paddinng_H_Text) }}
            </label>            
            <div class="selected-box">
              <WzcSelect
                :width="80"
                :height="24"
                :maxHeight="200"
                :placeholder="mapPadingHSpace"
                :itemList="mapPadingItems"
                :inputIdName="'mapPadingHSpace'"
                :sliderMum ="mapPadingExtremum"
                @changeInputVal="changeMapPadingHVal"
              >
                <template v-slot:wzc_option>
                  <WzcOption
                    v-for="item in mapPadingItems"
                    :key="item.id"
                    :label="item.name"
                    :optionid="item.id"
                    :sizeSliderCurrent="mapPadingHSpace"
                    @changeVal="changeMapPadingHVal"
                  ></WzcOption>
                </template>
              </WzcSelect>
            </div>

            <label class="setting-item-label" style="margin-left: 20px" >
              {{ getString(strings.Mind_Map_Picture_Paddinng_V_Text) }}
            </label>            
            <div class="selected-box">
              <WzcSelect
                :width="80"
                :height="24"
                :maxHeight="200"
                :placeholder="mapPadingVSpace"                
                :itemList="mapPadingItems"
                :inputIdName="'mapPadingVSpace'"
                :sliderMum ="mapPadingExtremum"
                @changeInputVal="changeMapPadingVVal"
              >
                <template v-slot:wzc_option>
                  <WzcOption
                    v-for="item in mapPadingItems"
                    :key="item.id"
                    :label="item.name"
                    :optionid="item.id"
                    :sizeSliderCurrent="mapPadingVSpace"
                    @changeVal="changeMapPadingVVal"
                  ></WzcOption>
                </template>
              </WzcSelect>
            </div>

          </section>
          <!-- 布局 -->
          <section class="setting-picture-item">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Edit_Right_Menu_layout) }}
            </label>
            <article class="setting-item-content">
              <div
                @click="setImgLayout"
                class="layout-picture-item"
                :class="{
                  'layout-picture-active': !downloadImageData.pictureLayout,
                }"
              >
                <img
                  v-if="!downloadImageData.pictureLayout"
                  src="../../../../static/img/saveImg/imgLayout/transverse_active.svg"
                  alt=""
                />
                <img
                  v-else
                  src="../../../../static/img/saveImg/imgLayout/transverse.svg"
                  alt=""
                />
              </div>
              <div
                @click="setImgLayout"
                style="margin-left: 10px"
                class="layout-picture-item"
                :class="{
                  'layout-picture-active': downloadImageData.pictureLayout,
                }"
              >
                <img
                  v-if="downloadImageData.pictureLayout"
                  src="../../../../static/img/saveImg/imgLayout/vertical_active.svg"
                  alt=""
                  height="24"
                />
                <img
                  v-else
                  src="../../../../static/img/saveImg/imgLayout/vertical.svg"
                  alt=""
                  height="24"
                />
              </div>
            </article>
          </section>

          <!-- 背景是否透明  -->
          <section class="setting-picture-item" v-if="downloadImageData.imgTypeIndex == 0">
            <label class="setting-item-label">
              {{ getString(strings.Mind_Edit_OutImage_Background_Transparent) }}
            </label>
            <a-checkbox
                    :checked="isBackgroundTransparent"
                    @change="onChangeBackgroundTransparent"
                  >
            </a-checkbox>
          </section>
          <!-- 背景边框 -->
          <section
              class="setting-picture-item"
              style="align-items: flex-start" >
              
              <label class="setting-item-label">
                {{ getString(strings.Save_Img_Border_Style) }}
              </label>
              <article class="setting-item-content">
                <div
                  class="chop-type-content"
                  v-for="(item, index) in borderTypes"
                  :key="index"
                  @click="setBorderType(index, item.type)"
                >
                  <img
                    class="borderClickType"
                    v-if="index == clickBorderTypeIndex"
                    src="../../../../static/img/saveImg/selectedBg.svg"
                    alt=""
                  />
                  <div class="clickImg" 
                  :class="
                      clickChopTypeIndex == index ? 'chop-type-active' : ''
                    "
                  :style="{
                          'background-color': item.borderColor,
                          'padding': '8px',
                          'border-radius' : '6px',
                          'padding-top': item.top + 'px',
                          'padding-bottom': item.bottom + 'px',
                        }" >

                        <div class="border-content" 
                          :style="{
                                  'background-color': item.color,
                                  'border-radius' : item.topRadius + 'px ' + item.topRadius + 'px ' + item.bottomRadius + 'px ' + item.bottomRadius + 'px',
                                }" ></div>
                  </div>
                </div>
              </article>
            </section> 
          <!-- 边框颜色 -->
          <section class="setting-picture-item" v-if="clickBorderTypeIndex != 0">
            <label class="setting-item-label">
              {{ getString(strings.Save_Img_Border_Color) }}
            </label>
            <div class="setting-border-color" 
                @click="setBorderColor()"></div>
            <div class="setting-border-color2" 
                @click="setBorderColor2()"></div>
            <div class="menu-right-bg" style="margin-top: -360px; right:246px">
                <transition name="slide-fade">
                  <CommonBgColor v-if="borderColorAccout" style="position: absolute;"/>
                </transition>
            </div>
            
          </section>   
          <!-- 边框大小 -->
          <section class="setting-picture-item"  v-if="clickBorderTypeIndex != 0">
            <label class="setting-item-label">
              {{ getString(strings.Save_Img_Border_Width) }}
            </label>
            <article class="setting-border-select-content">
              <a-select
                v-model="borderWidthFit"
                defaultValue="大"
                style="width: 100px"
                @change="changeBorderWidthFitChange"
              >
                <a-icon
                  slot="suffixIcon"
                  style="color: #333"
                  type="caret-down"
                />
                <a-select-option
                  v-for="item of borderWidthFitList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </article>
          </section>   
          <!-- 边框阴影 -->
          <section class="setting-picture-item"  v-if="clickBorderTypeIndex != 0">
            <label class="setting-item-label">
              {{ getString(strings.Save_Img_Border_Shadow) }}
            </label>
            <article class="setting-border-select-content">
              <a-select
                v-model="borderShadowFit"
                defaultValue="2"
                style="width: 100px"
                @change="changeBorderShadowVuleFitChange"
              >
                <a-icon
                  slot="suffixIcon"
                  style="color: #333"
                  type="caret-down"
                />
                <a-select-option
                  v-for="item of borderShadowFitList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </article>
          </section>   
          <!-- 边框圆角大小 -->
          <section class="setting-picture-item"  v-if="clickBorderTypeIndex != 0">
            <label class="setting-item-label">
              {{ getString(strings.Save_Img_Border_Radius) }}
            </label>
            <article class="setting-border-select-content">
              <a-select
                v-model="borderRadiusFit"
                defaultValue="3"
                style="width: 100px"
                @change="changeBorderRadiusVuleFitChange"
              >
                <a-icon
                  slot="suffixIcon"
                  style="color: #333"
                  type="caret-down"
                />
                <a-select-option
                  v-for="item of borderRadiusFitList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </article>
          </section>  
        </article>
        <aside class="preview-right">
          <section
            class="preview-content"
            :style="{ height: `${customWatermarkShow ? 450 : 382}px` }"
          >
            <img
              v-if="previewPictures"
              :src="previewPictures"
              class="preview-img"
              alt="加载失败"
            />
            <span v-else class="font-style">{{
              getString(strings.Loading)
            }}</span>
          </section>
        </aside>
      </section>
      <div class="img-to-dowlod">
        <a-button @click="colseImgTypeModel" class="cancel">{{
          getString(strings.Global_Cancel)
        }}</a-button>
        <a-button
          v-if="imgSaveMode == saveImgModes.PrintPictures"
          type="primary"
          html-type="submit"
          @click="printImgFun"
        >
          {{ getString(strings.Mind_Edit_Left_Menu_Printing) }}
        </a-button>
        <a-button
          v-else
          type="primary"
          html-type="submit"
          @click="imgTypeToDowload"
        >
          {{ getString(strings.Global_Ok) }}
        </a-button>
      </div>
    </div>

    <!-- 会员权益modal -->
    <a-modal
      class="report-member-model"
      :centered="true"
      v-model="chopIsMeberModelAccout"
      :title="getString(strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="chopIsMeberCancelHandleOk"
      @ok="chopIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Save_Img_Chop_Tips) }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import publicModalStyle from "../../../assets/css/publicModalStyle/publicModalStyle.less";
import ToImage from "../../../utils/ToImage";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import Config from "../../../core/core/calcule/Config";
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType";
import WzcSelect from "../../common/Select/WzcSelect";
import WzcOption from "../../common/Select/WzcOption";
import CommonBgColor from "../../common/CommonBgColor/CommonBgColor";

import {
  materialLibraryType,
  saveImgInsertBgType,
  eventBusMethod,
  sessionStorageKey,
  saveImgModes,
} from "../../../assets/js/GlobalVariable.js";
import Template from "../../../views/template/Template.vue";
import MindMe from '../../../viewmodel/facade/MindMe';
import colorChangeClass from "../../../utils/ColorToSystem";
import Colors from "../../../utils/Colors.js";
import Util from "../../../utils/Util.js";

export default {
  components: { 
    Template,
    WzcSelect,
    WzcOption,
    CommonBgColor,
    },
  data() {
    return {
      radioStyle: [
        {
          url: require("../../../../static/img/saveImg/PNG.png"),
          name: strings.Mind_Edit_Left_Menu_Save_Picture_Png,
          type: "png",
          title: "png",
        },
        {
          url: require("../../../../static/img/saveImg/JPG.png"),
          name: strings.Mind_Edit_Left_Menu_Save_Picture_Jpg,
          type: "jpg",
          title: "jpg",
        },
        {
          url: require("../../../../static/img/saveImg/WEBP.png"),
          name: strings.Mind_Edit_Left_Menu_Save_Picture_Webp,
          type: "webp",
          title: "webp",
        },
        //  {
        //   type: "svg",
        //   title: "svg",
        // },
      ], //保存格式
      insertBgStyle: [
        {
          type: saveImgInsertBgType.Stretching,
          title: strings.Mind_Stretching, //"拉伸"
        },
        {
          type: saveImgInsertBgType.EqualRatio,
          title: strings.Mind_Equal_Ratio, //等比
        },
      ], //插入背景类型
      chopTypes: [
        {
          img: require("../../../../static/img/saveImg/add_logo_full.png"),
          type: "File_Full",
          text: strings.Save_Img_Fill_Full,
        },
        {
          img: require("../../../../static/img/saveImg/add_logo_center.png"),
          type: "Center_Tilt",
          text: strings.Save_Img_Center_Tilt,
        },
        {
          img: require("../../../../static/img/saveImg/add_logo_drack_full.png"),
          type: "File_Full_Dark_Color",
          text: strings.Save_Img_File_Full_Dark_Color,
        },
      ],
      borderTypes: [
        {
          img: require("../../../../static/img/saveImg/add_logo_full.png"),
          type: "File_Full",
          text: strings.Save_Img_Fill_Full,
          borderColor: "#ffffff",
          color: "#ffffff",
          top: 8,
          bottom: 8,
          topRadius: 6,
          bottomRadius: 6,
        },
        {
          img: require("../../../../static/img/saveImg/add_logo_center.png"),
          type: "Center_Tilt",
          text: strings.Save_Img_Center_Tilt,
          borderColor: "#000000",
          color: "#ffffff",
          top: 8,
          bottom: 8,
          topRadius: 6,
          bottomRadius: 6,
        },
        {
          img: require("../../../../static/img/saveImg/add_logo_drack_full.png"),
          type: "File_Full_Dark_Color",
          text: strings.Save_Img_File_Full_Dark_Color,
          borderColor: "#000000",
          color: "#ffffff",
          top: 8,
          bottom: 25,
          topRadius: 6,
          bottomRadius: 0,
        },
      ],
      mapPadingExtremum: {
        //文字大小
        max: 400, //最大
        min: -20, //最小。
      },
      strings, //语言包
      clickChopTypeIndex: 0, // 当前选中的水印类型
      clickBorderTypeIndex: 0, // 当前选中的水印类型
      chopIsMeberModelAccout: false, //会员权益模态框
      toImage: null,
      disabled: true, //会员switch
      customWatermarkShow: false, //自定义水印
      borderColorAccout: false,
      isBackgroundTransparent: false,
      showColorPanelTime: -1,
      mapPadingHSpace:40,
      mapPadingVSpace:40,
      mapPadingItems: [], 
      downloadImageData: {
        imgTypeIndex: 0, //导出格式索引
        imgType: "png", //导出格式
        chopSwitch: true, //寻简水印是否添加
        chopText: "", //水印文字
        chopLogo: "", // 水印图片
        chopType: "File_Full", //水印类型
        pictureLayout: false, //布局
        isBackgroundTransparent: false,
        imgSize: null, //图片尺寸
        insertBgImg: "", //插入背景图片
        insertBgType: saveImgInsertBgType.Stretching, //插入背景类型
        paddingH: 40,
        paddingV: 40,
        borderType: 0,
        borderWidth: 3,
        borderColor: 0xffffff,
        borderColor2: 0xffffff,
        shadowVule: 2,
        borderRadius: 3,
      },
      showBgImg: true, //是否显示插入背景
      showSaveFormat: true, //是否显示保存格式
      previewPictures: "", // 预览图片
      previewPicturesTimer: null,
      sizeFit: 0, //尺寸适配
      borderWidthFit: 3, //尺寸适配
      borderShadowFit: 2, //尺寸适配
      borderRadiusFit: 3, //尺寸适配
      sizeFitList: [
        {
          label: "auto",
          value: 0,
        },
      ], //尺寸适配下拉列表
      borderWidthFitList: [
        {
          label: "auto",
          value: 0,
        },
      ], //尺寸适配下拉列表
      borderShadowFitList: [
        {
          label: "auto",
          value: 0,
        },
      ], //尺寸适配下拉列表
      borderRadiusFitList: [
        {
          label: "auto",
          value: 0,
        },
      ], //尺寸适配下拉列表
      imgSaveMode:
        sessionStorage.getItem(sessionStorageKey.ImgSaveMode) ||
        saveImgModes.AllNodeSaveImg,
      saveImgModes,
    };
  },

  created() {
    this.toImage = new ToImage()
    this.downloadImageData.chopSwitch = !MindMe.isUpgrade();
    this.borderWidthFitList = []
    this.borderWidthFitList.push({
              label: getString(strings.Save_Img_Border_Small),
              value: 1,
            });
    this.borderWidthFitList.push({
      label: getString(strings.Save_Img_Border_Middle),
      value: 2,
    });
    this.borderWidthFitList.push({
      label: getString(strings.Save_Img_Border_Big),
      value: 3,
    });
    this.borderShadowFitList = []
    this.borderShadowFitList.push({
              label: getString(strings.Save_Img_Border_None),
              value: 0,
            });
    this.borderShadowFitList.push({
      label: getString(strings.Save_Img_Border_Small),
      value: 1,
    });
    this.borderShadowFitList.push({
      label: getString(strings.Save_Img_Border_Middle),
      value: 2,
    });
    this.borderShadowFitList.push({
      label: getString(strings.Save_Img_Border_Big),
      value: 3,
    });
    let bgColor = EditMindmapVM.mindBGColor
    let mainData = EditMindmapVM.getRootSelectedNode()
    let mainColor = mainData.backgroundColor
    if (Colors.isClear(bgColor)) {
      bgColor = 0xffffff
      this.isBackgroundTransparent = true
    } else {
      this.isBackgroundTransparent = false
    }
    this.downloadImageData.isBackgroundTransparent = this.isBackgroundTransparent; 
    if (Colors.isClear(mainColor)) {
      bgColor = bgColor
    }
    if (mainColor == bgColor) {
      if (mainColor == 0xffffff) {
        mainColor = 0xEEEEEE;
      } else if (Colors.isDarkColor(bgColor)) {
        mainColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(bgColor, -0.5));
      } else {
        mainColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(bgColor, 0.5));
      }
    }
    this.borderTypes[0].borderColor = Colors.getUiColor(bgColor)
    this.borderTypes[0].color =  Colors.getUiColor(bgColor)

    this.borderTypes[1].borderColor =  Colors.getUiColor(mainColor)
    this.borderTypes[1].color =  Colors.getUiColor(bgColor)

    this.borderTypes[2].borderColor = Colors.getUiColor(mainColor)
    this.borderTypes[2].color =  Colors.getUiColor(bgColor)
    this.downloadImageData.borderColor = mainColor;     
    this.downloadImageData.borderColor2 = mainColor;     

    this.borderRadiusFitList = []
    this.borderRadiusFitList.push({
              label: getString(strings.Save_Img_Border_None),
              value: 0,
            });
    this.borderRadiusFitList.push({
      label: getString(strings.Save_Img_Border_Small),
      value: 1,
    });
    this.borderRadiusFitList.push({
      label: getString(strings.Save_Img_Border_Middle),
      value: 2,
    });
    this.borderRadiusFitList.push({
      label: getString(strings.Save_Img_Border_Big),
      value: 3,
    });
    this.sizeFitList = [];
    if (this.imgSaveMode) {
      switch (this.imgSaveMode) {
        case saveImgModes.AllNodeSaveImg:
          Config.PrintSizes.forEach((item, index) => {
            this.sizeFitList.push({
              label: item.name,
              value: index,
            });
          });
          if (EditMindmapVM.delegate.mindMapingType && EditMindmapVM.delegate.mindMapingType.value) {
            this.showBgImg = true;
          }else{
            this.showBgImg = false;
          }
          break;
        case saveImgModes.SelectNodeSaveImg:
        case saveImgModes.MultiSelectNodeSaveImg:
          this.sizeFitList = [
            {
              label: "auto",
              value: 0,
            },
          ];
          break;
        case saveImgModes.PrintPictures:
          Config.PrintSizes.forEach((item, index) => {
            this.sizeFitList.push({
              label: item.name,
              value: index,
            });
          });
          this.showSaveFormat = false;
          this.downloadImageData.pictureLayout = true;
          this.sizeFit = 1;
          break;
      }
    }
    this.downloadImageData.imgSize = {
      width: Config.PrintSizes[this.sizeFit].width,
      height: Config.PrintSizes[this.sizeFit].height,
    };
    let daGangModels = this.$store.state.showDaGangModels; //大纲
    if (daGangModels) {
      this.showBgImg = !daGangModels;
      this.sizeFitList = [
        {
          label: "auto",
          value: 0,
        },
      ];
      this.sizeFit = 0;
    }

    document.addEventListener("click", (e) => {
      if (!this.borderColorAccout || 
        this.showColorPanelTime <= 0 || 
        Util.getCurrentTime() - this.showColorPanelTime < 500) {
        return
      }
      let mousedownViewClassName = e.target.className
      var hideTag = e.target.tagName.toUpperCase();
      if (
        (mousedownViewClassName != "menu-right-straight" &&
          mousedownViewClassName != "menu-right-linecolor" &&
          mousedownViewClassName != "menu-right-accout" &&
          mousedownViewClassName != "menu-use-box" &&
          mousedownViewClassName != "menu-right-accout-ly" &&
          mousedownViewClassName != "menu-bgcolor" &&
          mousedownViewClassName != "menu-right-bgcolor" &&
          mousedownViewClassName != "menu-linecolor-box" &&
          mousedownViewClassName != "menu-left-accout" &&
          mousedownViewClassName != "color-content-max-titel" &&
          mousedownViewClassName != "vc-sketch-presets" &&
          mousedownViewClassName != "vc-saturation" &&
          mousedownViewClassName != "vc-hue-container" &&
          mousedownViewClassName != "vc-alpha-picker" &&
          mousedownViewClassName != "vc-input__input" &&
          mousedownViewClassName != "vc-input__label" &&
          mousedownViewClassName != "vc-alpha-container" &&
          mousedownViewClassName != "vc-saturation--black" &&
          mousedownViewClassName != "vc-sketch" &&
          mousedownViewClassName != "vc-sketch-sliders" &&
          mousedownViewClassName != "vc-hue-picker" && 
          mousedownViewClassName != "color-mode-title" &&
          mousedownViewClassName != "vc-chrome-body" && 
          mousedownViewClassName != "color-setting ant-input" &&
          mousedownViewClassName != "color-alpha-setting ant-input" &&
          mousedownViewClassName != "color-setting-content" &&
          mousedownViewClassName != "color-setting-tag" &&
          mousedownViewClassName != "color-alpha-tag" ) ||
        (mousedownViewClassName == "menu-content" &&
          hideTag == "SVG" &&
          hideTag != "PATH" &&
          hideTag != "IMG")) {
        this.borderColorAccout = false;
      }
    });
  },
  mounted() {
    if (!MindMe.isUpgrade()) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.bus.$on(eventBusMethod.InsertBgImgEvent, (val) => {
      //保存图片插入背景图
      if (val) {
        this.downloadImageData.insertBgImg = val.imageBase64;
      }
    });
  },
  methods: {
    ...mapMutations([
      "imgTypeModelAccout",
      "isShowShoppingModel",
      "setmaterialLibraryModulesModel",
      "showChunkColor",
      "markColorAccout",
    ]),
    getString(i) {
      return getString(i);
    },
    setBorderColor() {
      if (this.borderColorAccout) {
        this.borderColorAccout = false
        setTimeout(() => {
          this.showColorPanelTime = Util.getCurrentTime()
          this.borderColorAccout = true
          this.showChunkColor(true);
          this.markColorAccout('IMAGE_BORDER_COLOR_STYLE');
        }, 100);
      } else {
        this.showColorPanelTime = Util.getCurrentTime()
        this.borderColorAccout = true
        this.showChunkColor(true);
        this.markColorAccout('IMAGE_BORDER_COLOR_STYLE');      
      }      
    },
    setBorderColor2() {
      if (this.borderColorAccout) {
        this.borderColorAccout = false
        setTimeout(() => {
          this.showColorPanelTime = Util.getCurrentTime()
          this.borderColorAccout = true
          this.showChunkColor(true);
          this.markColorAccout('IMAGE_BORDER_COLOR2_STYLE');
        }, 100);
      } else {
        this.showColorPanelTime = Util.getCurrentTime()
        this.borderColorAccout = true
        this.showChunkColor(true);
        this.markColorAccout('IMAGE_BORDER_COLOR2_STYLE');     
      }
    },
    //付费模态框
    chopIsMeberCancelHandleOk() {
      this.borderColorAccout = false
      this.chopIsMeberModelAccout = false;
    },
    chopIsMeberDeleteHandleOk() {
      this.borderColorAccout = false
      this.chopIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    changeMapPadingHVal(newVal) {
      this.borderColorAccout = false
      this.mapPadingHSpace = newVal;
      this.downloadImageData.paddingH = newVal;
    },
    changeMapPadingVVal(newVal) {
      this.borderColorAccout = false
      this.mapPadingVSpace = newVal;
      this.downloadImageData.paddingV = newVal;
    },
    //关闭保存图片面板
    colseImgTypeModel() {
      this.borderColorAccout = false
      this.toImage = null
      this.imgTypeModelAccout({ accout: false, type: "" });
    },
    // 打印
    printImgFun() {
      this.borderColorAccout = false
      if (this.imgSaveMode == saveImgModes.PrintPictures) {
        this.bus.$emit(eventBusMethod.PrintImg, this.previewPictures);
        this.colseImgTypeModel();
      } else {
        this.imgTypeModelAccout({
          accout: false,
          type: this.downloadImageData,
        });
        this.toImage = null
      }
    },
    //确定保存
    imgTypeToDowload() {
      this.borderColorAccout = false
      var meMessage = JSON.parse(localStorage.getItem("meMessage"));
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        if (!meMessage.isMember) {
          //是否为会员
          if (
            !this.downloadImageData.chopSwitch ||
            this.downloadImageData.chopText != "" ||
            this.downloadImageData.chopLogo != ""
          ) {
            this.chopIsMeberModelAccout = true;
          } else {
            this.imgTypeModelAccout({
              accout: false,
              type: this.downloadImageData,
            });
          }
        } else {
          this.imgTypeModelAccout({
            accout: false,
            type: this.downloadImageData,
          });
        }
      } else {
        //未登录
        if (
          !this.downloadImageData.chopSwitch ||
          this.downloadImageData.chopText != "" ||
          this.downloadImageData.chopLogo != ""
        ) {
          this.chopIsMeberModelAccout = true;
        } else {
          this.imgTypeModelAccout({
            accout: false,
            type: this.downloadImageData,
          });
        }
      }
      this.toImage = null
    },
    //获取水印类型
    getChopType(index, type) {
      var meMessage = localStorage.getItem("meMessage");
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        meMessage = JSON.parse(meMessage);
        if (!meMessage.isMember) {
          this.chopIsMeberModelAccout = true;
          return;
        }
      } else {
        this.chopIsMeberModelAccout = true;
        return;
      }
      this.clickChopTypeIndex = index;
      this.downloadImageData.chopType = type;
    },
    //获取水印类型
    setBorderType(index, type) {
      this.downloadImageData.borderType = index;
      this.clickBorderTypeIndex = index;
      this.borderColorAccout = false
      if (index > 0) {
        setTimeout(() => {
          let borderBGColorView = document.getElementsByClassName("setting-border-color");
          if (borderBGColorView != null && borderBGColorView[0] != null) {
            borderBGColorView[0].style.backgroundColor = Colors.getUiColor(this.downloadImageData.borderColor);
          }
          let borderBGColorView2 = document.getElementsByClassName("setting-border-color2");
          if (borderBGColorView2 != null && borderBGColorView2[0] != null) {
            borderBGColorView2[0].style.backgroundColor = Colors.getUiColor(this.downloadImageData.borderColor);
          }
        }, 100);
      }
      
    },
    //获取保存图片的type
    onChange(index, type) {
      this.borderColorAccout = false
      this.downloadImageData.imgTypeIndex = index;
      this.downloadImageData.imgType = type;
    },
    //获取水印文字
    onChnageChopText(e) {
      this.borderColorAccout = false
      var meMessage = localStorage.getItem("meMessage");
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        meMessage = JSON.parse(meMessage);
        if (!meMessage.isMember) {
          this.chopIsMeberModelAccout = true;
          this.downloadImageData.chopText = "";
        } else {
          this.downloadImageData.chopText = e.target.value;
        }
      } else {
        this.chopIsMeberModelAccout = true;
        this.downloadImageData.chopText = "";
      }
    },
    //获取switch状态
    onChangeChopSwitch() {
      this.borderColorAccout = false
      this.downloadImageData.chopSwitch = !this.downloadImageData.chopSwitch;
      // this.customWatermarkShow = false;
      if (!this.downloadImageData.chopSwitch) {
        let meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (meMessage != null && meMessage != undefined && meMessage != "") {
          if (!meMessage.isMember) {
            this.chopIsMeberModelAccout = true;
            this.downloadImageData.chopSwitch = true;
          }
        }
      }
    },
    //自定义水印状态
    onChangeWatermarkSwitch() {
      this.borderColorAccout = false
      this.customWatermarkShow = !this.customWatermarkShow;
      // this.downloadImageData.chopSwitch = false;
      if (this.customWatermarkShow) {
        let meMessage = JSON.parse(localStorage.getItem("meMessage"));
        if (meMessage != null && meMessage != undefined && meMessage != "") {
          if (!meMessage.isMember) {
            this.chopIsMeberModelAccout = true;
            this.customWatermarkShow = false;
            this.downloadImageData.chopSwitch = true;
          }
        }
      }
    },
    //自定义水印状态
    onChangeBackgroundTransparent() {
      this.isBackgroundTransparent = !this.isBackgroundTransparent;
      this.downloadImageData.isBackgroundTransparent = this.isBackgroundTransparent
    },
    //获取本地图片
    handleFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        let logoImage = res.result;
        this.downloadImageData.chopLogo = logoImage;
        // this.showCropperImgUrl({ img: userImage, type: "uploadImg" });
      };
      reader.readAsDataURL(file);
      if (file < 1024 * 1024 * 3) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
        );
        return false;
      } else {
        // this.showCropperImg(true);
        this.$el.querySelector(".hiddenInput").value = null;
      }
    },
    //本地添加Logo
    setLogo() {
      this.borderColorAccout = false
      var meMessage = localStorage.getItem("meMessage");
      if (meMessage != null && meMessage != undefined && meMessage != "") {
        meMessage = JSON.parse(meMessage);
        if (!meMessage.isMember) {
          this.chopIsMeberModelAccout = true;
          return;
        }
      } else {
        this.chopIsMeberModelAccout = true;
        return;
      }
      this.$el.querySelector(".hiddenInput").click();
    },
    // 删除自定义水印Logo
    deletedChopLogo() {
      this.downloadImageData.chopLogo = "";
    },
    // 尺寸适配
    sizeFitChange(value) {
      this.borderColorAccout = false
      this.sizeFit = value;
      this.downloadImageData.imgSize = {
        width: Config.PrintSizes[value].width,
        height: Config.PrintSizes[value].height,
      };
    },
    // 边框大小适配
    changeBorderWidthFitChange(value) {
      this.borderColorAccout = false
      this.downloadImageData.borderWidth = value;
      this.borderWidthFit = value
    },
    // 边框阴影大小适配
    changeBorderShadowVuleFitChange(value) {
      this.borderColorAccout = false
      this.downloadImageData.shadowVule = value;
      this.borderShadowFit = value
    },
    changeBorderRadiusVuleFitChange(value) {
      this.borderColorAccout = false
      this.downloadImageData.borderRadius = value;
      this.borderRadiusFit = value
    },
    //设置布局
    setImgLayout() {
      this.borderColorAccout = false
      this.downloadImageData.pictureLayout =
        !this.downloadImageData.pictureLayout;
    },
    //插入背景图弹窗
    setBgImg() {
      /*if (this.isMember()) {
        this.setmaterialLibraryModulesModel({
          accout: true,
          type: materialLibraryType.InsertBgImage,
        });
      } else {
        this.chopIsMeberModelAccout = true;
      }*/
      this.borderColorAccout = false
      this.setmaterialLibraryModulesModel({
        accout: true,
        type: materialLibraryType.InsertBgImage,
      });
    },
    // 删除插入背景图
    deletedBgImg() {
      this.downloadImageData.insertBgImg = "";
    },
    //设置插入背景图的type
    onChangeInsertBgType(index, type) {
      this.borderColorAccout = false
      // this.downloadImageData.imgTypeIndex = index;
      this.downloadImageData.insertBgType = type;
    },
  },
  watch: {
    mapPadingExtremum: {
      handler(newval, olval) {
        this.mapPadingItems = [];          
        for (let index = -2; index <= 40; index++) {
          let obj = {};
          obj.name = index * 10;
          obj.id = index * 10;
          this.mapPadingItems.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },
    downloadImageData: {
      //预览
      handler(newVal) {
        if (this.previewPicturesTimer) {
          clearTimeout(this.previewPicturesTimer);
        }
        this.previewPicturesTimer = setTimeout(() => {
          if (newVal && newVal.imgSize) {
            let imgWidth = newVal.imgSize.width;
            let imgHeight = newVal.imgSize.height;
            if (imgWidth && imgHeight) {
              let rect = null;
              let spaceH = newVal.paddingH != null ? newVal.paddingH : 40
              let spaceV = newVal.paddingV != null ? newVal.paddingV : 40
              if (this.imgSaveMode) {
                switch (this.imgSaveMode) {
                  case saveImgModes.AllNodeSaveImg:
                  case saveImgModes.PrintPictures:
                    rect = EditMindmapVM.getMindContentRectForSaveImage(
                      newVal.pictureLayout,
                      imgWidth,
                      imgHeight,
                      spaceH,
                      spaceV
                    );
                    break;
                  case saveImgModes.SelectNodeSaveImg:
                  case saveImgModes.MultiSelectNodeSaveImg:
                    rect = EditMindmapVM.getNodeRectForSaveImage(spaceH, spaceV); //获取节点信息
                    EditMindmapVM.removeNodeLineWithOut(); //移除线条
                    // EditMindmapVM.uncheckNode(); //取消选中节点
                    break;
                }
              }
              let name = EditMindmapVM.getTitle();
              let bgColor = this.$store.state.saveImageBgcolor;
              // let toImage = new ToImage();
              let type = "previewPicture";
              let outType = newVal;
              this.previewPictures = "";
              let image = null;
              if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
                image = this.toImage.toImage(rect, bgColor, type, outType, name, false);
                EditMindmapVM.refresh(true);
              } else {
                image = this.toImage.outlineToImage(16777215, type, outType, name);
              }
              image.then((res) => {
                this.previewPictures = res;
                // console.log(newVal, res);
              });
            }
          }
        }, 1000);
      },
      deep: true,
    },
    "$store.state.showDaGangModels"(newVal) {
      //大纲
      this.showBgImg = !newVal;
    },
    "$store.state.outImageBorderColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.downloadImageData.borderColor = newval; 
      let borderBGColorView = document.getElementsByClassName("setting-border-color");
      borderBGColorView[0].style.backgroundColor = Colors.getUiColor(newval);
      this.borderColorAccout = false
    },
    "$store.state.outImageBorderColor2"(newval, olval) {
      if (newval == null) {        
        return;
      }
      this.downloadImageData.borderColor2 = newval; 
      let borderBGColorView2 = document.getElementsByClassName("setting-border-color2");
      borderBGColorView2[0].style.backgroundColor = Colors.getUiColor(newval);  
      this.borderColorAccout = false    
    },
    "$store.state.outImageBorderColorFromMoreColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.downloadImageData.borderColor = newval; 
      let borderBGColorView = document.getElementsByClassName("setting-border-color");
      borderBGColorView[0].style.backgroundColor = Colors.getUiColor(newval);
    },
    "$store.state.outImageBorderColor2FromMoreColor"(newval, olval) {
      if (newval == null) {        
        return;
      }
      this.downloadImageData.borderColor2 = newval; 
      let borderBGColorView2 = document.getElementsByClassName("setting-border-color2");
      borderBGColorView2[0].style.backgroundColor = Colors.getUiColor(newval);
    },
  },
};
</script>

<style lang="less" scoped>
#getimg-type {
  max-width: 820px;
  min-height: 380px;
  // max-height: 100vh;
  // overflow-y: auto;
  width: 100%;
  border-radius: 9px;
  background: #ffffff;
  position: absolute;
  z-index: 1000;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .img-type-box {
    // margin: 20px 30px 20px 30px;
    position: relative;
    .img-title-box {
      height: 40px;
      padding: 0 22px;
      background: #ffffff;      
      border-radius: 9px 9px 0 0;
      .img-title-text {
        // margin: 29px 0 40px 0;
        span {
          display: flex;
          // justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 40px;
          color: #0e0e0e;
          opacity: 1;
        }
      }
      .img-close-box {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-close-model {
          position: relative;
          top: -2px;
          .close-button-img {
            width: 16px;
            cursor: pointer;
            opacity: 0.8;
          }
          .close-button-img:hover {
            opacity: 1;
          }
        }
      }
    }
    .export-picture-box {
      width: 100%;
      padding: 30px 0;
      display: flex;
      .setting-picture-left {
        padding: 0 20px 0 22px;
        max-height: 550px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 58%;
        .setting-picture-item {
          width: 100%;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          align-items: center;
          .font-style {
            color: #333333;
            font-size: 13px;
          }
          .text-overflow {
            max-width: 118px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: absolute;
          }
          .setting-width {
            width: 342px;
          }
          .hiddenInput {
            display: none;
          }
          .setting-item-label {
            width: 60px;
            font-size: 12px;
            color: #444444;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
          }

          .setting-item-content {
            display: flex;
            position: relative;
            .watermark-settings-box {
              display: flex;
              .watermark-settings-item {
                width: 150px;
              }
            }
            .chop-type-content {
              text-align: center;
              cursor: pointer;
              position: relative;
              padding-right: 10px;
              width: 118px;
              span {
                margin-top: 8px;
              }
              .clickImg {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                width: 108px;
                height: 74px;

                .border-content {
                  width: 100%;
                  height: 100%;
                }
              }
              .clickType {
                position: absolute;
                bottom: 28px;
                right: 10px;
              }
              .borderClickType {
                position: absolute;
                bottom: 0px;
                right: 10px;                
              }
            }
            .chop-type-active {
              border: 1px solid #fd492b;
            }
            .layout-picture-item {
              width: 65px;
              height: 30px;
              background: #ffffff;
              border-radius: 6px;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .layout-picture-active {
              background: #fd492b;
              border: 1px solid #eeeeee;
              box-shadow: none;
            }
          }
          .setting-border-select-content {
            display: flex;
            position: absolute;
            right: 20px;
            .watermark-settings-box {
              display: flex;
              .watermark-settings-item {
                width: 150px;
              }
            }
            .chop-type-content {
              text-align: center;
              cursor: pointer;
              position: relative;
              padding-right: 10px;
              width: 118px;
              span {
                margin-top: 8px;
              }
              .clickImg {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                width: 108px;
                height: 74px;
              }
              .clickType {
                position: absolute;
                bottom: 28px;
                right: 10px;
              }
            }
            .chop-type-active {
              border: 1px solid #fd492b;
            }
            .layout-picture-item {
              width: 65px;
              height: 30px;
              background: #ffffff;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .layout-picture-active {
              background: #fd492b;
              border: 1px solid #eeeeee;
              box-shadow: none;
            }
          }
          .chop-logo {
            img {
              width: 30px;
              height: 30px;
              cursor: pointer;
            }
            .deleted-button {
              width: 14px;
              height: 14px;
              margin-left: 6px;
              align-self: center;
              cursor: pointer;
              position: absolute;
              top: -10px;
              left: 30px;
            }
          }
        }
      }
      .preview-right {
        width: 42%;
        .preview-content {
          width: 312px;
          padding: 30px;
          background: #ffffff;
          box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.16);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0;
          }
          .preview-img {
            width: auto;
            max-width: 100%;
            border: 1px dashed #6c6c6c66;
          }
        }
      }
    }
    .img-type-obj {
      max-width: 378px;
      display: flex;
      justify-content: space-between;
      // header
      .img-obj-content {
        min-width: 88px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img-obj-text {
          background: #ffffff;
          // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          cursor: pointer;
          position: relative;
          border: 1px solid #cccccc;
          border-radius: 6px;
          img {
            width: 110px;
            height: 110px;
          }
          .img-active {
            position: absolute;
            right: -1px;
            bottom: -1px;
            img {
              width: auto;
              height: auto;
            }
          }
          p {
            width: 74px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            margin: 0;
          }
        }
        .active {
          border: 1px solid #fd492b;
          position: absolute;
        }
      }
    }
    .img-to-dowlod {
      display: flex;
      justify-content: center;
      height: 34px;
      flex-wrap: nowrap;
      margin: 0 0 20px 0;
      button {
        width: 55px;
        opacity: 1;
        margin: 0 30px;
        padding: 0 12px;
        width: 68px;
        height: 32px;
        border-radius: 6px;
        span {
          text-align: center;
        }
      }
      .cancel {
        background-color: #eeeeee;
      }
    }

    .setting-border-color {
      width: 100px;
      height: 30px;
      position: absolute;
      border-radius: 4px;
      border: solid 1px #33333339;
      right: 20px;
    }
    .setting-border-color2 {
      width: 100px;
      height: 30px;
      position: absolute;
      border-radius: 4px;
      border: solid 1px #33333339;
      right: 140px;
    }
  }

  .selected-box {
    .ant-select-selection {
      height: 20px !important;
    }
    .ant-select-selection--single {
      height: 20px !important;
    }
  }
}
</style>