import Config from "../core/core/calcule/Config"
import Colors from "./Colors";
import url from "postcss-url";
// import { jsPDF } from "jspdf";
import imageToStyle from "./ImageToolkit";
import Size from "../viewmodel/core/base/Size";
import MindElementCalculation from "../core/core/calcule/elementCalculation/MindElementCalculation";
import Util from "./Util";
import pdfMake from 'pdfmake'
import UiUtil from "../utils/UiUtil"
import StringsLanguage from '../common/lang/strings';
import {
    saveImgInsertBgType,
} from "../assets/js/GlobalVariable.js";
import download from 'downloadjs'
import tools from "../common/commonFuntion.js";
import html2canvas from "html2canvas";
import HashMap from "../viewmodel/core/base/HashMap.js";
import CGRect from "../viewmodel/core/base/basedata/Rect.js";

//截图类文件
class ToImage {
    constructor() {
        this.imgUrl //用于存储图片地址
        this.rect //导图xy轴
        this.outlineSize //大纲宽高
        this.darkMode //深色模式
        this.bgColor //背景颜色
        this.imgTopMargin = 0;
        this.imgLeftMargin = 0;

        this.svgElemStr = null;
        this.svgLineStr = null;
        this.svgBaseMapStr = null;
        
    }

    createDarwMindmapElement(svgElem, rect, ratio) {
        if (svgElem == null) {
            return;
        }
        let id = "darw_mindmap_scrollview";
        let contentView = document.getElementById(id)
        if (contentView != null && contentView.parentNode != null) {
            contentView.parentNode.removeChild(contentView)
        }
        let width = rect.width() * ratio
        let height = rect.height() * ratio
        
        contentView = document.createElement("div")
        contentView.id = id
        contentView.style.position = "absolute"
        contentView.style.zIndex = -9999;
        contentView.style.width = (width) + "px";
        contentView.style.height = (height) + "px";
        contentView.style.top = "0px";
        contentView.style.left = "0px";
        
        contentView.setAttribute("width", "" + width);
        contentView.setAttribute("height", "" + height);
        
        let svgDiv = document.createElement("div")
        svgDiv.style.position = "absolute"
        svgDiv.style.width = width + "px";
        svgDiv.style.height = height + "px";
        svgDiv.style.top = "0px";
        svgDiv.style.left = "0px";
        
        svgDiv.setAttribute("width", "" + width);
        svgDiv.setAttribute("height", "" + height);

        let gTags = svgElem.getElementsByClassName("mindElementView")
        let uiUtil = new UiUtil()
        for (let index = 0; index < gTags.length; index++) {
            const element = gTags[index];
            let size = uiUtil.getViewSize(element)
            let point = uiUtil.getViewPoint(element)

            if (point.x - rect.x > 0 && point.x - rect.x + size.width < rect.width() &&
                point.y - rect.y > 0 && point.y - rect.y + size.height < rect.height()) {
                var textMindElementViewContent = element.getElementsByClassName("textMindElementViewContent")
                var textMindElementView = element.getElementsByClassName("textMindElementView")
    
                var textMindElementViewContentSize = uiUtil.getViewSize(textMindElementViewContent[0])
                var textMindElementViewContentPoint = uiUtil.getViewPoint(textMindElementViewContent[0])

                let mindElementViewDiv = document.createElement("div")
                mindElementViewDiv.style.position = "absolute"
                mindElementViewDiv.style.width = size.width + "px";
                mindElementViewDiv.style.height = size.height + "px";
                mindElementViewDiv.style.left = (point.x - rect.x) + "px";
                mindElementViewDiv.style.top = (point.y - rect.y) + "px";
                
                svgDiv.appendChild(mindElementViewDiv)
                    
                let textMindElementViewContentDiv = document.createElement("div")
                textMindElementViewContentDiv.style.position = "absolute"
                textMindElementViewContentDiv.style.width = textMindElementViewContentSize.width + "px";
                textMindElementViewContentDiv.style.height = textMindElementViewContentSize.height + "px";
                textMindElementViewContentDiv.style.left = textMindElementViewContentPoint.x + "px";
                textMindElementViewContentDiv.style.top = textMindElementViewContentPoint.y + "px";
                
                mindElementViewDiv.appendChild(textMindElementViewContentDiv)
                if (textMindElementView.length > 0) {
                    textMindElementViewContentDiv.appendChild(textMindElementView[0].cloneNode(!0))
                    textMindElementView[0].style.visibility = "hidden"
                }
            }
        }

        document.body.appendChild(contentView)
        contentView.appendChild(svgDiv)
    }

    parseCode(str) { 
        let out_val = str;
        let chartsMap = new HashMap()
        chartsMap.put("-", "‐")
        chartsMap.put("：", ": ")
        chartsMap.put(" ", " ")
        let length = chartsMap.keys().length;
        for (let index = 0; index < length; index++) {
            let key = chartsMap.keys()[index];
            let value = chartsMap.get(key);
            out_val = this.replaceStrByRegExp(out_val, key, value)
        }
        return out_val
    }

    replaceStrByRegExp(text, target, replace) {        
        if (text == null || text.length == 0 || target == null || target.length == 0) {
            return ""
        }
        var specialCharacter = "^$.*+?|()[]{}=!:-,";
        var newTarget = ""
        for (let index = 0; index < target.length; index++) {
            const element = target[index];
            if (specialCharacter.indexOf(element) > -1) {
                newTarget = newTarget + "\\" + element
            } else {
                newTarget = newTarget + element
            }
        }
        return text.replace(new RegExp(newTarget, "gi"), replace);
    }

    chromeBrowserSpecialCharactersText(svgElem) {
        if (svgElem == null || 1 == 1) {
            return svgElem;
        }
        if (window.navigator.userAgent.toUpperCase().indexOf("CHROME") == -1) {
            return svgElem
        }       

        let gTags = svgElem.getElementsByClassName("mindElementView")
        let uiUtil = new UiUtil()
        for (let index = 0; index < gTags.length; index++) {
            const element = gTags[index];
            
            var textMindElementView = element.getElementsByClassName("textMindElementView")
            if (textMindElementView.length > 0) {
                for (let index = 0; index < textMindElementView.length; index++) {
                    const element = textMindElementView[index];
                    const innerHTML = element.innerHTML
                    const innerHTMLTrim = innerHTML.trim()
                    if (innerHTMLTrim.toUpperCase().indexOf("<DIV") == 0) {
                        for (let j = 0; j < element.childNodes.length; j++) {
                            const childNode = element.childNodes[j];

                            const childNodeInnerHTML = childNode.innerHTML
                            const childNodeInnerHTMLTrim = childNodeInnerHTML.trim()
                            if (childNodeInnerHTMLTrim.toUpperCase().indexOf("<SPAN") == 0 ) {  
                                for (let n = 0; n < childNode.childNodes.length; n++) {
                                    const spanNode = childNode.childNodes[n];
                                    var text = spanNode.innerText
                                    spanNode.innerText = this.parseCode(text)
                                }
                            }
                        }
                    } else if (innerHTMLTrim.toUpperCase().indexOf("<SPAN") == 0 && element.childNodes.length == 1) {
                        for (let n = 0; n < element.childNodes.length; n++) {
                            const spanNode = element.childNodes[n];
                            var text = spanNode.innerText
                            spanNode.innerText = this.parseCode(text)
                        }
                    }
                }
            }
        }
        return svgElem
    }

    //绘制入口
    toImage(rect, bgColor, type, outType, name = "", isSettingFontFamily = true) {
        let userData = JSON.parse(localStorage.getItem('meMessage'))
        if ((outType != null && outType.chopSwitch) || (userData == null || userData == "" || !userData.isMember)) {
            rect.y = rect.y - 30
            rect.height(rect.height() + 30)
        }
        this.rect = rect;
        let editmapDoc = document.getElementById("editmap")
        if (editmapDoc == null || editmapDoc.cloneNode == null) {
            return
        }
        
        var canvas2 = document.createElement("canvas");
        var context = canvas2.getContext("2d");
        let ratio = Util.getPixelRatio(context);
        if (ratio < 2) {
            ratio = 2;
        }
        
        if (this.svgElemStr == null) {
            let svgElem = editmapDoc.cloneNode(!0);
            this.chromeBrowserSpecialCharactersText(svgElem)
            if ((type == undefined || type == null || (type != "cover" && type != "previewPicture")) && isSettingFontFamily) {
                this.createDarwMindmapElement(svgElem, rect, ratio)
            }            
            let svgLine = document.getElementById("linemap").cloneNode(!0);
            let svgBaseMap = document.getElementById("basemap").cloneNode(!0);
    
            editmapDoc.setAttribute("width", "" + Config.Mind_Width);
            editmapDoc.setAttribute("height", "" + Config.Mind_Height);
            
            svgElem.setAttribute("width", "" + Config.Mind_Width);
            svgElem.setAttribute("height", "" + Config.Mind_Height);
            svgLine.setAttribute("width", "" + Config.Mind_Width);
            svgLine.setAttribute("height", "" + Config.Mind_Height);
            svgBaseMap.setAttribute("width", "" + Config.Mind_Width);
            svgBaseMap.setAttribute("height", "" + Config.Mind_Height);
    
            editmapDoc.setAttribute("version", "1.1")
    
            svgElem.setAttribute("version", "1.1")
            svgLine.setAttribute("version", "1.1")
            svgBaseMap.setAttribute("version", "1.1")        
    
            let svgElemStr = new XMLSerializer().serializeToString(svgElem);
            let svgLineStr = new XMLSerializer().serializeToString(svgLine);
            let svgBaseMapStr = new XMLSerializer().serializeToString(svgBaseMap);
            //创建base64 编码 》 解码 》 编码        
            this.svgElemStr = window.btoa(unescape(encodeURIComponent(svgElemStr)));
            this.svgLineStr = window.btoa(unescape(encodeURIComponent(svgLineStr)));
            this.svgBaseMapStr = window.btoa(unescape(encodeURIComponent(svgBaseMapStr)));
        }
        let darwMindmapScrollview = document.getElementById("darw_mindmap_scrollview")

        let src = "data:image/svg+xml;base64," + this.svgElemStr;
        // img.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(new XMLSerializer().serializeToString(svg));
        
        let srcLine = "data:image/svg+xml;base64," + this.svgLineStr;
        let srcBaseMapStr = "data:image/svg+xml;base64," + this.svgBaseMapStr;
        this.bgColor = bgColor;
        return this.drawMindMapBg(outType).then(url => {
            this.darkMode = Colors.isDarkColor(bgColor);//判断深色模式
            let mindLogo = require("../../static/img/canvasLogo/canvasLogo.png")
            let bgLogo = require("../../static/img/canvasLogo/watermark.png");
            
            let that = this;
            var img = document.createElement("img");
            var imgtext = document.createElement("img");
            var imgLine = document.createElement("img");
            var imgLogo = document.createElement("img");
            var imgBgLogo = document.createElement("img");
            var baseMap = document.createElement("img");
            var mindmapChop = document.createElement("img");
            let insertImgBg = document.createElement("img");//插入背景图片

            let totalLoad = 7;//总共要onload的img数量
            let loadNumber = 0;
            if (url == null || url == 'jpg' || url == 'png') {
                totalLoad -= 1
            }
            
            if (darwMindmapScrollview != null && darwMindmapScrollview.parentNode != null && 
                (type == undefined || type == null || (type != "cover" && type != "previewPicture") && isSettingFontFamily)) {
                canvas2.width = rect.width() * ratio;
                canvas2.height = rect.height() * ratio;
                canvas2.style.width = rect.width() + "px";
                canvas2.style.height = rect.height() + "px";
                html2canvas(darwMindmapScrollview,  
                {
                    canvas: canvas2,
                    backgroundColor: Colors.getUiColor(Colors.clear),
                    imageTimeout: 35000,
                    logging: false,
                    scale: ratio, 
                    dpi: 300, //提高清晰度
                    allowTaint: true,
                    width: rect.width(),
                    height: rect.height(),
    
                } ).then(function (canvas) {
                    let imageData = canvas.toDataURL('image/png')
                    imgtext.src = imageData
                    if (darwMindmapScrollview != null && darwMindmapScrollview.parentNode != null) {
                        darwMindmapScrollview.parentNode.removeChild(darwMindmapScrollview)
                    }
                });
            } else {
                totalLoad = totalLoad - 1;
            }

            img.src = src
            imgLine.src = srcLine
            baseMap.src = srcBaseMapStr
            mindmapChop.src = url
            if (outType && outType.insertBgImg) {
                insertImgBg.src = outType.insertBgImg
            }
            new imageToStyle().localhostToBase64(bgLogo).then(base64MindLogo => {
                imgBgLogo.src = base64MindLogo
            });
            function loadComplete(resolve, reject) {
                loadNumber++;
                if (totalLoad == loadNumber) {
                    resolve(that.drawMindMap(imgLine, img, imgtext, imgLogo, imgBgLogo, baseMap, mindmapChop, insertImgBg, rect, bgColor, type, outType, name, 200))
                }
            };
            return new Promise(function (resolve, reject) {
                new imageToStyle().localhostToBase64(mindLogo).then(base64MindLogo => {
                    imgLogo.src = base64MindLogo
                }).catch(() => {
                    loadComplete(resolve, reject)
                });
                imgLine.onload = () => {
                    loadComplete(resolve, reject)
                }
                imgLogo.onload = () => {
                    loadComplete(resolve, reject)
                }
                imgBgLogo.onload = () => {
                    loadComplete(resolve, reject)
                }
                img.onload = () => {
                     loadComplete(resolve, reject)
                }
                imgtext.onload = () => {
                    loadComplete(resolve, reject)
                }
                baseMap.onload = () => {
                    loadComplete(resolve, reject)
                }
                insertImgBg.onload = () => {
                    loadComplete(resolve, reject)
                }
                if (type == "download" || type == "copyPicture" || type == "previewPicture") {
                    mindmapChop.onload = () => {
                        loadComplete(resolve, reject)
                    }
                }
            })
        })
    }

    //开始绘制
    drawMindMap(lineImg, nodeImg, nodeImgtext, imgLogo, imgBgLogo, baseMap, 
        mindmapChop, insertImgBg, rect, bgColor, type, outType = { imgType: "png" }, name, maxSize) {
        var canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let ratio = Util.getPixelRatio(ctx)
        if (ratio < 2) {
            ratio = 2
        }
        this.pixelRatio = ratio;
        var width = rect.width()
        var height = rect.height()
        var canvasWidth = width
        var canvasHeight = height
        let contentStartX = 0
        let contentStartY = 0
        let borderWidth = 0
        if (outType != null && outType.borderType != null && outType.borderType > 0) {            
            if (outType.borderWidth == 3) {
                borderWidth = Math.min(Math.max(width * ratio * 0.052, 100), 500)
            } else if (outType.borderWidth == 2) {
                borderWidth = Math.min(Math.max(width * ratio * 0.0334, 40), 500)
            } else {
                borderWidth = Math.min(Math.max(width * ratio * 0.0165, 20), 500)
            }
            
            if (outType.borderType == 1) {
                canvasWidth = width + borderWidth
                canvasHeight = height + borderWidth
                contentStartX = borderWidth
                contentStartY = borderWidth
            } else if (outType.borderType == 2) {
                canvasWidth = width + borderWidth
                canvasHeight = height + borderWidth + Math.min(Math.max(width * ratio * 0.08, 120 * ratio), 400)
                contentStartX = borderWidth
                contentStartY = borderWidth
            }
            
        } 
        canvas.width = canvasWidth * ratio
        canvas.height = canvasHeight * ratio
        canvas.style.width = canvasWidth + "px"
        canvas.style.height = canvasHeight + "px"

        width = width * ratio
        height = height * ratio
        var cornerRadius = 2;
        if (outType != null && outType.borderType != null && outType.borderType > 0) {            
            // ctx.fillStyle = Colors.getUiColor(outType.borderColor)
            var jb = ctx.createLinearGradient(0, 0, canvasWidth * ratio, canvasHeight * ratio);
            jb.addColorStop(0, Colors.getUiColor(outType.borderColor2));
            jb.addColorStop(1, Colors.getUiColor(outType.borderColor));
            //填充渐变
            ctx.fillStyle = jb;
            ctx.fillRect(0, 0, canvasWidth * ratio, canvasHeight * ratio);
            ctx.rect(0, 0, canvasWidth * ratio, canvasHeight * ratio)

            if (outType.borderRadius == 1) {
                cornerRadius = 20
            } else if (outType.borderRadius == 2) {
                cornerRadius = 40
            } else if (outType.borderRadius == 3) {
                cornerRadius = 60
            }
            const x = contentStartX;
            const y = contentStartX;            
            // 开始绘制路径
            ctx.beginPath();            
            // 移动到左上角
            ctx.moveTo(x + cornerRadius, y);
            // 绘制上边线
            ctx.lineTo(x + width - cornerRadius, y);
            // 绘制右上角圆角
            ctx.arc(x + width - cornerRadius, y + cornerRadius, cornerRadius, Math.PI * 1.5, Math.PI * 2);
            // 绘制右边线
            ctx.lineTo(x + width, y + height - cornerRadius);
            // 绘制右下角圆角
            if (outType.borderType == 2) {
                ctx.lineTo(x + width, y + height);
            } else {
                ctx.arc(x + width - cornerRadius, y + height - cornerRadius, cornerRadius, 0, Math.PI * 0.5);
            }            
            // 绘制下边线
            ctx.lineTo(x + cornerRadius, y + height);
            // 绘制左下角圆角     
            if (outType.borderType == 2) {
                ctx.lineTo(x, y + height);
            } else {
                ctx.arc(x + cornerRadius, y + height - cornerRadius, cornerRadius, Math.PI * 0.5, Math.PI);
            }
            // 绘制左边线
            ctx.lineTo(x, y + cornerRadius);
            // 绘制左上角圆角
            ctx.arc(x + cornerRadius, y + cornerRadius, cornerRadius, Math.PI, Math.PI * 1.5);
            // 关闭路径
            ctx.closePath();
            if (outType != null && outType.isBackgroundTransparent) {
                ctx.fillStyle = Colors.getUiColor(Colors.clear)
            } else {
                ctx.fillStyle = Colors.getUiColor(bgColor)
            }
            // ctx.fillStyle = Colors.getUiColor(bgColor)
            if (outType.shadowVule > 0) {
                
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                if (outType.shadowVule == 3) {
                    ctx.shadowColor = "rgba(0, 0, 0, 0.75)";
                    ctx.shadowBlur = Math.min(borderWidth, 25);
                } else if (outType.shadowVule == 2) {
                    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
                    ctx.shadowBlur = Math.min(borderWidth, 20);
                } else {
                    ctx.shadowColor = "rgba(0, 0, 0, 0.25)";
                    ctx.shadowBlur = Math.min(borderWidth, 15);
                }
            }
            
            // 填充矩形
            ctx.fill();
            ctx.shadowColor = "rgba(0, 0, 0, 0)";
        } else {
            cornerRadius = 0
            if (outType != null && outType.isBackgroundTransparent) {
                ctx.fillStyle = Colors.getUiColor(Colors.clear)
            } else {
                ctx.fillStyle = Colors.getUiColor(bgColor)
            }
            
            ctx.fillRect(0, 0, width, height);
            ctx.rect(0, 0, width, height)
        }       

        let drawX = rect.x;
        let drawY = rect.y;

        //会员水印logo
        let userData = JSON.parse(localStorage.getItem('meMessage'))
        if (typeof outType === 'object') {
            if (!outType.imgType) {
                outType.imgType = 'png';
            }
        }
        //未登录
        if (userData == null || userData == "" || !userData.isMember) { //游客、非会员

            if (type == "download" || type == "savePdf" || type == "printf" || type == "copyPicture" || type == "previewPicture") {
                let logWidth = imgLogo.width;
                let logHeight = imgLogo.height;
                if (logWidth > rect.width() * 0.3) {
                    logWidth = rect.width() * 0.3
                    logHeight = logWidth * imgLogo.height / imgLogo.width;
                }
                if (outType.insertBgImg) {//插入背景图片
                    if (type == "download" || type == "copyPicture" || type == "previewPicture") {
                        this.setInsertImgBg(ctx, width, height, contentStartX, contentStartY, insertImgBg, outType);
                    }
                }
                this.drawWatermark(ctx, width, height, contentStartX, contentStartY, imgBgLogo, imgBgLogo.width, imgBgLogo.height);
                ctx.drawImage(imgLogo, 0, 0, logWidth, logHeight);
                ctx.drawImage(baseMap, drawX, drawY, baseMap.width, baseMap.height, contentStartX, contentStartY, baseMap.width * ratio, baseMap.height * ratio);
                ctx.drawImage(lineImg, drawX, drawY, lineImg.width, lineImg.height, contentStartX, contentStartY, lineImg.width * ratio, lineImg.height * ratio)
                ctx.drawImage(nodeImg, drawX, drawY, nodeImg.width, nodeImg.height, contentStartX, contentStartY, nodeImg.width * ratio, nodeImg.height * ratio);
                ctx.drawImage(nodeImgtext, 0, 0, nodeImgtext.width, nodeImgtext.height, contentStartX, contentStartY, nodeImgtext.width, nodeImgtext.height);
            } else {
                ctx.drawImage(baseMap, drawX, drawY, baseMap.width, baseMap.height, contentStartX, contentStartY, baseMap.width * ratio, baseMap.height * ratio);
                ctx.drawImage(lineImg, drawX, drawY, lineImg.width, lineImg.height, contentStartX, contentStartY, lineImg.width * ratio, lineImg.height * ratio)
                ctx.drawImage(nodeImg, drawX, drawY, nodeImg.width, nodeImg.height, contentStartX, contentStartY, nodeImg.width * ratio, nodeImg.height * ratio);
                ctx.drawImage(nodeImgtext, 0, 0, nodeImgtext.width, nodeImgtext.height, contentStartX, contentStartY, nodeImgtext.width, nodeImgtext.height);
            }
            //截取封面
            var canvasData = canvas.toDataURL("image/" + outType.imgType)// + outType);
            if (type == "download") {
                this.downLoadImg(canvasData, name, outType)
            } else if (type == "printf") {
                this.imgUrl = canvasData;
            } else if (type == "savePdf") {
                let contentWidth = rect.width()
                let contentHeight = rect.height()
                this.downLoadPdf(canvasData, name, contentWidth, contentHeight)
            } else if (type == "storm") {
                return canvasData
            } else if (type == "copyPicture" || type == "previewPicture") {
                return canvasData
            } else {
                let targetWidth = rect.width()
                let targetHeight = rect.height()
                if (targetWidth >= targetHeight && targetWidth > maxSize) {
                    targetHeight = targetHeight / targetWidth * maxSize;
                    targetWidth = maxSize;
                } else if (targetWidth < targetHeight && targetHeight > maxSize) {
                    targetWidth = targetWidth / targetHeight * maxSize;
                    targetHeight = maxSize;
                }
                let that = this
                return new Promise(function (resolve, reject) {
                    that.resizeImage(canvasData, targetWidth, targetHeight).then(canvasData => {
                        resolve(canvasData)
                    });
                })
            }
            return
        } else {
            if (outType && typeof outType === 'object') {
                if (outType.insertBgImg) {//插入背景图片
                    if (type == "download" || type == "copyPicture" || type == "previewPicture") {
                        this.setInsertImgBg(ctx, width, height, contentStartX, contentStartY, insertImgBg, outType);
                    }
                }
                if (outType.chopSwitch) {//水印
                    if (type == "download" || type == "printf" || type == "copyPicture" || type == "previewPicture") {
                        let logWidth = imgLogo.width;
                        let logHeight = imgLogo.height;
                        if (logWidth > rect.width() * 0.3) {
                            logWidth = rect.width() * 0.3
                            logHeight = logWidth * imgLogo.height / imgLogo.width;
                        }
                        this.drawWatermark(ctx, width, height, contentStartX, contentStartY, imgBgLogo, imgBgLogo.width, imgBgLogo.height);
                        // ctx.drawImage(imgLogo, 0, 0, logWidth, logHeight);
                        ctx.drawImage(imgLogo, 0, 0, logWidth, logHeight);
                    }
                }
                if (outType.chopLogo != "" || outType.chopText != "") {//自定义水印
                    if (type == "download" || type == "copyPicture" || type == "previewPicture") { //|| type == "savePdf" || type == "printf"
                        ctx.drawImage(mindmapChop, 0, 0, mindmapChop.width, mindmapChop.height, 0, 0, mindmapChop.width * ratio, mindmapChop.height * ratio)
                    }
                }
            }

            ctx.drawImage(baseMap, drawX, drawY, baseMap.width, baseMap.height, contentStartX, contentStartY, baseMap.width * ratio, baseMap.height * ratio);
            ctx.drawImage(lineImg, drawX, drawY, lineImg.width, lineImg.height, contentStartX, contentStartY, lineImg.width * ratio, lineImg.height * ratio)
            ctx.drawImage(nodeImg, drawX, drawY, nodeImg.width, nodeImg.height, contentStartX, contentStartY, nodeImg.width * ratio, nodeImg.height * ratio);
            ctx.drawImage(nodeImgtext, 0, 0, nodeImgtext.width, nodeImgtext.height, contentStartX, contentStartY, nodeImgtext.width, nodeImgtext.height);
            var canvasData = canvas.toDataURL("image/" + outType.imgType)// + outType);
            if (type == "download") {
                this.downLoadImg(canvasData, name, outType)
            } else if (type == "printf") {
                this.imgUrl = canvasData;
            } else if (type == "savePdf") {
                let contentWidth = rect.width()
                let contentHeight = rect.height()
                this.downLoadPdf(canvasData, name, contentWidth, contentHeight)
            } else if (type == "storm") {
                return canvasData
            } else if (type == "copyPicture" || type == "previewPicture") {
                return canvasData
            } else {
                let targetWidth = rect.width()
                let targetHeight = rect.height()
                if (targetWidth >= targetHeight && targetWidth > maxSize) {
                    targetHeight = targetHeight / targetWidth * maxSize;
                    targetWidth = maxSize;
                } else if (targetWidth < targetHeight && targetHeight > maxSize) {
                    targetWidth = targetWidth / targetHeight * maxSize;
                    targetHeight = maxSize;
                }
                let that = this
                return new Promise(function (resolve, reject) {
                    that.resizeImage(canvasData, targetWidth, targetHeight).then(canvasData => {
                        resolve(canvasData)
                    });
                })
            }
        }

    }

    // 绘制插入背景图
    setInsertImgBg(ctx, canvasWidth, canvasHeight, x, y, insertImgBg, outType) {
        
        if (outType != null && outType.borderType != null && outType.borderType > 0) { 
            ctx.save();    
            let width = canvasWidth
            let height = canvasHeight
            var cornerRadius = 2;
            if (outType.borderRadius == 1) {
                cornerRadius = 20
            } else if (outType.borderRadius == 2) {
                cornerRadius = 40
            } else if (outType.borderRadius == 3) {
                cornerRadius = 60
            }         
            // 开始绘制路径
            ctx.beginPath();            
            // 移动到左上角
            ctx.moveTo(x + cornerRadius, y);
            // 绘制上边线
            ctx.lineTo(x + width - cornerRadius, y);
            // 绘制右上角圆角
            ctx.arc(x + width - cornerRadius, y + cornerRadius, cornerRadius, Math.PI * 1.5, Math.PI * 2);
            // 绘制右边线
            ctx.lineTo(x + width, y + height - cornerRadius);
            // 绘制右下角圆角
            if (outType.borderType == 2) {
                ctx.lineTo(x + width, y + height);
            } else {
                ctx.arc(x + width - cornerRadius, y + height - cornerRadius, cornerRadius, 0, Math.PI * 0.5);
            }            
            // 绘制下边线
            ctx.lineTo(x + cornerRadius, y + height);
            // 绘制左下角圆角     
            if (outType.borderType == 2) {
                ctx.lineTo(x, y + height);
            } else {
                ctx.arc(x + cornerRadius, y + height - cornerRadius, cornerRadius, Math.PI * 0.5, Math.PI);
            }
            // 绘制左边线
            ctx.lineTo(x, y + cornerRadius);
            // 绘制左上角圆角
            ctx.arc(x + cornerRadius, y + cornerRadius, cornerRadius, Math.PI, Math.PI * 1.5);
            ctx.clip();
        }
        
        
        if (outType.insertBgType == saveImgInsertBgType.Stretching) {//拉伸
            ctx.drawImage(insertImgBg, 0, 0, insertImgBg.width, insertImgBg.height, x, y, canvasWidth, canvasHeight)
        } else if (outType.insertBgType == saveImgInsertBgType.EqualRatio) {//等比
            // let widthRatio = canvasWidth / insertImgBg.width;
            // let heightRatio = canvasHeight / insertImgBg.height;
            // let widthRatioLarge = widthRatio > heightRatio;
            // let equalRatio = widthRatioLarge ? widthRatio : heightRatio;
            // let insertImgBgWidth = equalRatio * insertImgBg.width;
            // let insertImgBgHeight = equalRatio * insertImgBg.height;

            // if (widthRatioLarge) {
            //     this.imgTopMargin = (canvasHeight - insertImgBgHeight) / 2;
            // } else {
            //     this.imgLeftMargin = (canvasWidth - insertImgBgWidth) / 2;
            // }
            // console.log(
            //     widthRatio,heightRatio,insertImgBgWidth,insertImgBgHeight
            // );
            let imageRatio = insertImgBg.width /insertImgBg.height;
            let canvasRatio = canvasWidth / canvasHeight;
            var imgTargetWidth = insertImgBg.width
            var imgTargetHeight = insertImgBg.height
            if (imageRatio > canvasRatio) {
                imgTargetHeight = insertImgBg.height
                imgTargetWidth = insertImgBg.height * canvasRatio
            } else if (imageRatio < canvasRatio) {
                imgTargetWidth = insertImgBg.width
                imgTargetHeight = insertImgBg.width / canvasRatio
            }
            
            ctx.drawImage(insertImgBg, 0, 0, imgTargetWidth, imgTargetHeight, x, y, canvasWidth, canvasHeight)
        }
        if (outType != null && outType.borderType != null && outType.borderType > 0) { 
            ctx.restore();
        }
    }
    // 绘制水印
    drawWatermark(context, boxWidth, boxHeight, x, y, imgLogo, logWidth, logHeight) {
        let lightModeColor = this.darkMode ? '#ffffff4d' : '#33333333';
        let rectSize = 240;//外边矩形大小
        let maxNum = 8;//外边矩形数量，最大为8，最小为1
        let rectXNum = Math.floor(boxWidth / rectSize); //X轴外边矩形数量
        rectXNum = rectXNum > maxNum ? maxNum : rectXNum;
        rectXNum = rectXNum == 0 ? 1 : rectXNum;
        let rectXBorderOffset = ((boxWidth - (rectXNum * rectSize)) / 2);
        let rectYNum = Math.floor(boxHeight / rectSize); //Y轴外边矩形数量
        rectYNum = rectYNum > maxNum ? maxNum : rectYNum;
        rectYNum = rectYNum == 0 ? 1 : rectYNum;
        let rectYBorderOffset = ((boxHeight - (rectYNum * rectSize)) / 2);

        context.lineWidth = 1;
        context.strokeStyle = lightModeColor;//深浅模式
        context.setLineDash([5, 5]);
        let innerRectSize = 94;//logo矩形大小
        let ratio = Util.getPixelRatio(context)
        if (ratio < 2) {
            ratio = 2
        }
        context.font = parseInt(Math.floor(14 * ratio)) + 'px PingFang SC';
        // for (let i = 0; i < rectXNum; i++) {
        for (let i = 0; i < 0; i++) {
            for (let j = 0; j < rectYNum; j++) {
                let outRectPageX = i * rectSize + rectXBorderOffset;//外边矩形x轴起点
                let outRectPageY = j * rectSize + rectYBorderOffset;//外边矩形y轴起点

                // context.fillStyle = ('#'+this.bgColor.toString(16));
                // let innerRectPageX = outRectPageX + ((rectSize - innerRectSize) / 2);//logo矩形x轴起点
                // let innerRectPageY = outRectPageY + ((rectSize - innerRectSize) / 2);//logo矩形y轴起点
                // context.fillRect(innerRectPageX, innerRectPageY, innerRectSize, innerRectSize); 
                let outRectPageXCenter = outRectPageX + (rectSize / 2);//外边矩形x轴中心点
                let outRectPageYCenter = outRectPageY + (rectSize / 2);//外边矩形y轴中心点

                // 绘制图像
                let imgPageX = outRectPageXCenter - (logWidth / 2);
                let imgPageY = outRectPageYCenter - (logHeight / 2);
                // context.translate(0,20);
                context.drawImage(imgLogo, imgPageX, imgPageY, logWidth, logHeight);
                context.save();

                //画虚线
                context.beginPath();
                context.moveTo(outRectPageX + rectSize, outRectPageY);
                context.lineTo(imgPageX + logHeight, imgPageY);
                context.stroke();

                context.beginPath();
                context.moveTo(outRectPageX + rectSize, outRectPageY + rectSize);
                context.lineTo(imgPageX + logHeight, imgPageY + logHeight);
                context.stroke();

                context.beginPath();
                context.moveTo(outRectPageX, outRectPageY);
                context.lineTo(imgPageX, imgPageY);
                context.stroke();

                context.beginPath();
                context.moveTo(outRectPageX, outRectPageY + rectSize);
                //  context.lineTo(imgPageX,imgPageY+logHeight);
                context.lineTo(imgPageX - 20, imgPageY + logHeight + 20);
                context.stroke();

                //  绘制文字
                let txt = "寻简思维导图";
                let txtWidth = context.measureText(txt).width;
                let txtPageX = outRectPageXCenter - (txtWidth / 2);
                context.save();
                context.translate(txtPageX - 12, outRectPageYCenter - 12);
                context.rotate(45 * Math.PI / 180);
                context.fillStyle = lightModeColor;
                context.fillText(txt, 0, 0);
                context.restore();

                let text = "试用版";
                let textWidth = context.measureText(text).width;
                let textPageX = outRectPageXCenter - (textWidth / 2);
                context.save();
                context.translate((textPageX - (textWidth - 5)), (outRectPageYCenter + 20 + 5));
                context.rotate(45 * Math.PI / 180);
                context.fillStyle = lightModeColor;
                context.fillText(text, 0, 0);
                context.restore();
            }
        }

        //  绘制文字
        let txt = "寻简思维导图 试用版";
        let txtWidth = context.measureText(txt).width;
        let txtPageX = x + boxWidth / 2 - (txtWidth / 2);
        context.save();
        context.translate(txtPageX - 12, y + boxHeight - 32);
        // context.rotate(45 * Math.PI / 180);
        context.fillStyle = lightModeColor;
        context.fillText(txt, 0, 0);
        context.restore();
    }

    //下载图片
    downLoadImg(imgSrc, imgName, outType) {
        let defaultImgType = 'png';
        if (typeof outType === 'object') {
            if (!outType.imgType) {
                outType.imgType = defaultImgType;//如果保存类型为空，默认为png
            }
        }
        imgName = (imgName || "photo") + '.' + (outType.imgType || defaultImgType);
        if (tools.isElectron()) {
            download(imgSrc, imgName, "image/" + outType.imgType);
        } else {
            let a = document.createElement("a");
            let event = new MouseEvent("click");
            a.download = imgName;
            a.href = imgSrc;
            a.dispatchEvent(event);
            setTimeout(() => a = null, 1000);
        }
    }
    /*async downLoadImg(imgSrc, imgName, outType) {
        let defaultImgType = 'png';
        if (typeof outType === 'object') {
            if (!outType.imgType) {
                outType.imgType = defaultImgType;//如果保存类型为空，默认为png
            }
        }
        imgName = (imgName || "photo") + '.' + (outType.imgType || defaultImgType);
        let response = await fetch(imgSrc)
        // console.log(imgSrc);

        // 内容转变成blob地址
        let blob = await response.blob()
        // 创建隐藏的可下载链接
        let objectUrl = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        //地址
        a.href = objectUrl
        //修改文件名
        a.download = imgName
        // 触发点击
        document.body.appendChild(a)
        a.click()
        //移除
        setTimeout(() => document.body.removeChild(a), 1000)
    }*/

    //打印
    async printFun(rect, imgName, contentWidth, contentHeight) {
        this.rect = rect
        var width = Math.round(this.rect.width())
        var height = Math.round(this.rect.height())
        var x = Math.round(this.rect.x)
        var y = Math.round(this.rect.y)

        // const editmap = document.getElementById("editmap");
        // const linemap = document.getElementById('linemap');
        // const basemap = document.getElementById('basemap');

        // const s1 = '<svg viewBox="' + x.toString() + ' ' + y.toString() + ' ' + width.toString() + ' ' + height.toString() + '" ' + editmap.outerHTML.substring(4);
        // const s2 = '<svg viewBox="' + x.toString() + ' ' + y.toString() + ' ' + width.toString() + ' ' + height.toString() + '" ' + linemap.outerHTML.substring(4);
        // const s3 = '<svg viewBox="' + x.toString() + ' ' + y.toString() + ' ' + width.toString() + ' ' + height.toString() + '" ' + basemap.outerHTML.substring(4);

        // let div = document.createElement('div');
        // div.innerHTML = s1 + s2 + s3
        // for (let i = 0; i < div.children.length; i++) {
        //     const element = div.children[i];
        //     element.style.width = width
        //     element.style.height = height
        // }
        // // let w = window.open()
        // const app = document.getElementById("app");
        // if (app) {
        //     app.style.display = 'none'
        // }
        // let divbox = document.createElement('div');
        // divbox.className = 'divbox'
        // divbox.innerHTML = (`<div style="-webkit-print-color-adjust: exact;width:`+width.toString()+`px;height:`+height.toString()+`px;background:`+editmap.parentElement.style.background+`;">`+div.innerHTML+`</div>`)
        // document.body.appendChild(divbox)
        // window.print()
        // if (app) {
        //     app.style.display = 'block'
        // }
        // document.body.removeChild(divbox)


        function doPrint() {
            let scrollviewBox = document.getElementById("mindmap_scrollview").cloneNode(!0);
            scrollviewBox.id = "mindmap_scrollview_box"
            scrollviewBox.style.width = (x + width) + 'px'
            scrollviewBox.style.height = (y + height) + 'px'
            scrollviewBox.style.left = (-x) + 'px'
            scrollviewBox.style.top = (-y) + 'px'
            scrollviewBox.style.overflow = "hidden"
            scrollviewBox.style.webkitPrintColorAdjust = 'exact'
            let screenWidth = document.body.clientWidth;
            let screenHeight = document.body.clientHeight;
            let offsetX = (screenWidth - width) / 4;
            let offsetY = (screenHeight - height) / 4;
            scrollviewBox.style.transform = `scale(1.0) translate(${offsetX < 0 ? 0 : offsetX}px, ${offsetY < 0 ? 0 : offsetY}px)`;//居中
            // document.body.appendChild(scrollviewBox)
            const app = document.getElementById("app");
            if (app) {
                app.style.display = 'none'
            }
            document.body.appendChild(scrollviewBox)
            window.print()
            if (app) {
                app.style.display = 'block'
            }
            document.body.removeChild(scrollviewBox)

            // window.location.reload()
            // if (printScrollviewBox) {
            //     setTimeout(() => {
            //         const style =
            //         "@page {size:" + 'landscape' + ";margin:10mm 10mm };";
            //         printJS({
            //             printable: "mindmap_scrollview_box",
            //             type: "html",
            //             scanStyles: false,
            //              style,

            //         });
            //           console.log(printScrollviewBox);

            //     }, 100);
            // }

        }
        doPrint()


    }


    //下载PDF
    /*downLoadPdf(imgSrc, imgName, contentWidth, contentHeight) {
        let pdfX = (contentWidth + 10) / 2 * 0.75
        let pdfY = (contentHeight + 500) / 2 * 0.75

        let imgX = pdfX
        let imgY = (contentHeight / 2 * 0.75)
        let compress = false;//压缩

    if (pdfX > pdfY) {
        var pdf = new jsPDF('l', 'pt', [pdfX, pdfY], compress)//第四个参数，true压缩，false不压缩,不要压缩，否则pdf会失真
    } else {
        var pdf = new jsPDF('p', 'pt', [pdfX, pdfY], compress)
    }
    pdf.addImage(imgSrc, 'PNG', 0, 0, imgX, imgY)
    pdf.save(imgName + '.pdf')
    // console.log(imgName,imgSrc);
    }*/
    downLoadPdf(imgSrc, imgName, contentWidth, contentHeight) {
        if (imgSrc.length < 10) {//
            let messageDialog = window['messageDialog']
            if (messageDialog) {
                messageDialog.error(new UiUtil().getString(StringsLanguage.Export_Failed));
            }
            return
        }
        let pdfX = (contentWidth + 10) / 2 * 0.75
        let pdfY = (contentHeight + 500) / 2 * 0.75

        let imgX = pdfX
        let imgY = (contentHeight / 2 * 0.75)

        // let pdfWidth = pdfX<794?794:pdfX
        // let pdfHeight = pdfY<1123?1123:pdfY
        // let pdfTopMargin = (pdfWidth-imgX)/2
        // let pdfLeftMargin = (pdfHeight-imgY)/2
        let pdfWidth = pdfX
        let pdfHeight = pdfY
        let pdfTopMargin = 0
        let pdfLeftMargin = 0

        const dd = {
            content: [
                {
                    image: imgSrc,
                    width: imgX,
                    height: imgY
                }
            ],//pdf中显示的内容
            pageSize: {
                width: pdfWidth,
                height: pdfHeight
            },//纸张大小
            pageMargins: [pdfTopMargin, pdfLeftMargin],//页边距：[水平、垂直]
            pageOrientation: pdfWidth > pdfHeight ? 'landscape' : 'portrait',//排版,默认:'portrait'(竖版),'landscape'(横板)
        };
        // console.log(pdfWidth,pdfHeight,pdfTopMargin,pdfLeftMargin,imgX,imgY);
        pdfMake.createPdf(dd).download(imgName + '.pdf');//导出PDF
    }

    // 计算base64图片大小
    imageSize(base64Str) {
        let indexBase64 = base64Str.indexOf('base64,');
        if (indexBase64 < 0) {
            return -1
        }
        let str = base64Str.substr(indexBase64 + 6)
        return (str.length * 0.75).toFixed(2)
    }

    //截取封面
    resizeImage(src, w, h, outType = { imgType: "png" }) {
        var img = document.createElement("img");
        img.src = src
        let targetWidth = w
        let targetHeight = h
        return new Promise(function (resolve, reject) {
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = targetWidth
                canvas.height = targetHeight
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                resolve(canvas.toDataURL("image/" + outType.imgType))
            };
        })
    }

    //背景绘制
    drawMindMapBg(outType) {
        var that = this
        return new Promise(resolve => {
            if (outType == undefined) {
                resolve(null)
            }
            if (outType == 'jpg') {
                resolve('jpg')
            }
            if (outType == 'png') {
                resolve('png')
            }
            let text = outType.chopText
            let imageBg = new Image();
            imageBg.setAttribute('crossOrigin', 'Anonymouse');
            imageBg.src = outType.chopLogo
            if (outType.chopType == "File_Full") {
                FileFull(18, 0.3, false)
            } else if (outType.chopType == "Center_Tilt") {
                FileFull(28, 0.3, true)
            } else if (outType.chopType == "File_Full_Dark_Color") {
                FileFull(18, 0.6, false)
            }

            function FileFull(textSize, opacity, centerTilt) {
                var canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                let ratio = Util.getPixelRatio(context)
                if (ratio < 2) {
                    ratio = 2
                }
                var width = that.rect.width()
                var height = that.rect.height()
                canvas.width = width * ratio
                canvas.height = height * ratio
                canvas.style.width = width + "px"
                canvas.style.height = height + "px"
                context.globalAlpha = opacity;

                that.darkMode = Colors.isDarkColor(that.bgColor);//判断深色模式
                let lightModeColor = that.darkMode ? '#ffffff' : '#333333';
                let fillColor = Colors.getNumberToRgbDark((Util).parseIntForString(lightModeColor), opacity);

                if (outType.chopLogo == "" && text != "") {
                    let bgWidth = imageBg.width;
                    let bgHeight = imageBg.height;
                    if (bgWidth > that.rect.width() * 0.3) {
                        bgWidth = that.rect.width() * 0.3
                        bgHeight = bgWidth * imageBg.height / imageBg.width;
                    }

                    let widthText = 0
                    let heightText = 0
                    if (text != null && text.length > 0) {
                        let sizeObj = MindElementCalculation.caluleText(text, textSize, false);
                        widthText = sizeObj.width
                        heightText = sizeObj.height
                    }

                    let countWidth = Math.floor(that.rect.width() / (Math.max(bgWidth, widthText) + 40));
                    let countHeight = Math.floor(that.rect.height() / (bgHeight + 20 + heightText))

                    if (countWidth > 4) {
                        countWidth = 4
                    }
                    if (countHeight > 4) {
                        countHeight = 4
                    }
                    let sc = bgWidth / bgHeight
                    if (countWidth < 1) {
                        bgWidth = that.rect.width() / 2
                        bgHeight = bgWidth / sc
                        countWidth = 1
                    }
                    if (countHeight < 1) {
                        bgHeight = that.rect.height() / 2
                        bgWidth = bgHeight * sc
                        countHeight = 1
                    }
                    if (centerTilt) {
                        countWidth = 1
                        countHeight = 1
                    }
                    let cellImageWidth = that.rect.width() / countWidth
                    let cellImageHeight = that.rect.height() / countHeight
                    let imageAndTextSpace = 8
                    for (let i = 0; i < countWidth; i++) {
                        for (let j = 0; j < countHeight; j++) {
                            let imageX = cellImageWidth * i + (cellImageWidth - bgWidth) / 2
                            let imageY = cellImageHeight * j + (cellImageHeight - bgHeight - heightText - imageAndTextSpace) / 2
                            let textX = cellImageWidth * i + (cellImageWidth - widthText) / 2
                            let textY = imageY + bgHeight + imageAndTextSpace
                            //文字
                            if (widthText > 0) {
                                let textMoveX = (Util.sind(45)) * bgHeight
                                let imageAndTextCD = ((bgWidth - widthText) / 2 + imageAndTextSpace) * Util.sind(45);
                                let imageSF = Math.sqrt(bgHeight * bgHeight + bgWidth * bgWidth) / 2
                                let imageYMove = imageSF + (Util.sind(45));
                                let imageXMove = imageSF - imageYMove

                                textX = imageX + textMoveX + imageAndTextCD - imageXMove
                                textY = imageY + textMoveX - (imageAndTextCD - (imageAndTextSpace / (Util.sind(45)))) + imageYMove

                                context.save();
                                context.translate(textX, textY);
                                context.rotate(-45 * Math.PI / 180);
                                context.font = textSize + 'px PingFang SC';
                                context.fillStyle = fillColor;
                                context.fillText(text, 0, 0);
                                context.restore();
                            }
                        }
                    }
                    let domBgMindmap = canvas.toDataURL('image/png', 1)
                    resolve(domBgMindmap)
                } else {
                    if (outType.chopLogo == "") {
                        resolve(null)
                    }
                    imageBg.setAttribute("crossOrigin", "Anonymous");
                    imageBg.onload = () => {
                        let bgWidth = imageBg.width;
                        let bgHeight = imageBg.height;
                        if (bgWidth > that.rect.width() * 0.3) {
                            bgWidth = that.rect.width() * 0.3
                            bgHeight = bgWidth * imageBg.height / imageBg.width;
                        }

                        let widthText = 0
                        let heightText = 0
                        if (text != null && text.length > 0) {
                            let sizeObj = MindElementCalculation.caluleText(text, textSize, false);
                            widthText = sizeObj.width
                            heightText = sizeObj.height
                        }

                        let countWidth = Math.floor(that.rect.width() / (Math.max(bgWidth, widthText) + 40));
                        let countHeight = Math.floor(that.rect.height() / (bgHeight + 20 + heightText))
                        if (countWidth > 4) {
                            countWidth = 4
                        }
                        if (countHeight > 4) {
                            countHeight = 4
                        }
                        let sc = bgWidth / bgHeight
                        if (countWidth < 1) {
                            bgWidth = that.rect.width() / 2
                            bgHeight = bgWidth / sc
                            countWidth = 1
                        }
                        if (countHeight < 1) {
                            bgHeight = that.rect.height() / 2
                            bgWidth = bgHeight * sc
                            countHeight = 1
                        }
                        let cellImageWidth = that.rect.width() / countWidth
                        let cellImageHeight = that.rect.height() / countHeight
                        let imageAndTextSpace = 8
                        if (centerTilt) {
                            countWidth = 1
                            countHeight = 1
                        }
                        for (let i = 0; i < countWidth; i++) {
                            for (let j = 0; j < countHeight; j++) {
                                //图片
                                let imageX = cellImageWidth * i + (cellImageWidth - bgWidth) / 2
                                let imageY = cellImageHeight * j + (cellImageHeight - bgHeight - heightText - imageAndTextSpace) / 2
                                let textX = cellImageWidth * i + (cellImageWidth - widthText) / 2
                                let textY = imageY + bgHeight + imageAndTextSpace
                                context.save();
                                context.translate(imageX, imageY + (bgHeight / 2));
                                context.rotate(-45 * Math.PI / 180);
                                if (centerTilt) {
                                    let canvas = document.createElement("canvas");
                                    let ctx = canvas.getContext("2d");
                                    let ratio = Util.getPixelRatio(ctx)
                                    if (ratio < 2) {
                                        ratio = 2
                                    }
                                    let imgTop = ((that.rect.height() * ratio) - (bgHeight * ratio)) / 2 - (bgHeight / 2)
                                    if (outType.pictureLayout) {//竖版
                                        context.drawImage(imageBg, 0, 0, bgWidth, bgHeight);
                                    } else {//横版
                                        context.drawImage(imageBg, imageX, imgTop, bgWidth, bgHeight);
                                    }
                                } else {
                                    context.drawImage(imageBg, 0, 0, bgWidth, bgHeight);
                                }
                                context.restore();

                                //文字
                                if (widthText > 0) {
                                    let textMoveX = (Util.sind(45)) * bgHeight
                                    let imageAndTextCD = ((bgWidth - widthText) / 2 + imageAndTextSpace) * Util.sind(45);
                                    let imageSF = Math.sqrt(bgHeight * bgHeight + bgWidth * bgWidth) / 2
                                    let imageYMove = imageSF + (Util.sind(45));
                                    let imageXMove = imageSF - imageYMove

                                    textX = imageX + textMoveX + imageAndTextCD - imageXMove
                                    textY = imageY + textMoveX - (imageAndTextCD - (imageAndTextSpace / (Util.sind(45)))) + imageYMove

                                    context.save();
                                    context.translate(textX, textY);
                                    context.rotate(-45 * Math.PI / 180);
                                    context.font = textSize + 'px PingFang SC';
                                    context.fillStyle = fillColor;
                                    context.fillText(text, 0, 0);
                                    context.restore();
                                }
                            }
                        }
                        let domBgMindmap = canvas.toDataURL('image/png', 1)
                        resolve(domBgMindmap)
                    };
                }
            }
        })
    }

    //大纲绘制入口
    outlineToImage(bgColor, type, outType, name) {

        //获取绘制节点
        let realOutlineDom = document.getElementById("outline-content")
        let outlineSvg = document.getElementById("outlineBox").cloneNode(true);
        this.outlineSize = { width: "", height: "" }
        this.outlineSize.width = realOutlineDom.getBoundingClientRect().width
        this.outlineSize.height = realOutlineDom.scrollHeight

        //设置节点属性
        outlineSvg.setAttribute("version", "1.1")
        outlineSvg.getElementsByTagName('foreignObject')[0].setAttribute("width", this.outlineSize.width);
        outlineSvg.getElementsByTagName('foreignObject')[0].setAttribute("height", '100%');
        outlineSvg.getElementsByTagName('foreignObject')[0].style.padding = '60px'

        //DOM转化为字符串 》 转化为Base64编码
        let outlineContentStr = new XMLSerializer().serializeToString(outlineSvg);
        outlineContentStr = window.btoa(unescape(encodeURIComponent(outlineContentStr)));

        //拼接Img可识别Base64编码
        let mindLogo = require("../../static/img/canvasLogo/canvasLogo.png")
        let outlineSrc = "data:image/svg+xml;base64," + outlineContentStr;
        let outlineImg = document.createElement("img");
        let imgLogo = document.createElement("img");
        let outlineChop = document.createElement("img");

        //等待水印编码完成
        return this.drawOutlineMindMapBg(outType).then(chopBase64Image => {
            outlineChop.src = chopBase64Image
            outlineImg.src = outlineSrc

            new imageToStyle().localhostToBase64(mindLogo).then(base64MindLogo => {
                imgLogo.src = base64MindLogo
            })

            let that = this
            let loadNumber = 0
            let totalLoad = 3//loadOutlineComplete()执行的次数

            //如果没有设置个性化水印或打印、截图功能
            if (chopBase64Image == null || chopBase64Image == 'jpg' || chopBase64Image == 'png') {
                totalLoad -= 1
            }

            function loadOutlineComplete(resolve) {
                loadNumber++;
                if (totalLoad == loadNumber) {
                    resolve(that.drawOutlineMap(outlineChop, outlineImg, imgLogo, bgColor, type, outType, name))
                }
            };

            return new Promise((resolve) => {
                outlineImg.onload = () => {
                    loadOutlineComplete(resolve)
                }
                imgLogo.onload = () => {
                    loadOutlineComplete(resolve)
                }
                if (type == "download" || type == "copyPicture" || type == "previewPicture") {
                    outlineChop.onload = () => {
                        loadOutlineComplete(resolve)
                    }
                }
            })
        })
    }

    //大纲开始绘制
    drawOutlineMap(outlineChop, outlineImg, imgLogo, bgColor, type, outType, name) {
        //创建Canvas画布
        let canvas = document.createElement('canvas')
        canvas.width = this.outlineSize.width
        canvas.height = this.outlineSize.height
        canvas.style.width = this.outlineSize.width + "px"
        canvas.style.height = this.outlineSize.height + "px"

        let ctx = canvas.getContext("2d");
        ctx.fillStyle = Colors.getUiColor(bgColor)
        ctx.fillRect(0, 0, this.outlineSize.width, this.outlineSize.height);
        ctx.rect(0, 0, this.outlineSize.width, this.outlineSize.height)
        //会员水印logo
        let userData = JSON.parse(localStorage.getItem('meMessage'))
        //未登录
        if (userData == null || userData.isMember == false) {
            //添加mindMap水印
            if (type == "download" || type == "savePdf" || type == "printf" || type == "copyPicture" || type == "previewPicture") {
                let logWidth = imgLogo.width;
                let logHeight = imgLogo.height;
                if (logWidth > this.outlineSize.width * 0.3) {
                    logWidth = this.outlineSize.width * 0.3
                    logHeight = logWidth * imgLogo.height / imgLogo.width;
                }
                ctx.drawImage(imgLogo, 0, 0, logWidth, logHeight);
            }
            ctx.drawImage(outlineImg, 0, 0)
            //导出文件
            var canvasData = canvas.toDataURL("image/" + outType.imgType)// + outType);
            if (type == "download") {
                this.downLoadImg(canvasData, name, outType)
            } else if (type == "printf") {
                this.imgUrl = canvasData;
            } else if (type == "savePdf") {
                let contentWidth = this.outlineSize.width
                let contentHeight = this.outlineSize.height
                this.downLoadPdf(canvasData, name, contentWidth, contentHeight)
            } else if (type == "storm") {
                return canvasData
            } else if (type == "copyPicture" || type == "previewPicture") {
                return canvasData
            }
        } else {
            //登录后判断是否会员
            if (userData.isMember) {
                //绘制自定义水印
                if (outType != null) {
                    if (outType.chopLogo != "" || outType.chopText != "") {
                        if (type == "download" || type == "copyPicture" || type == "previewPicture") {
                            ctx.drawImage(outlineChop, 0, 0)
                        }
                    }
                }
                //绘制大纲
                ctx.drawImage(outlineImg, 0, 0);
                //绘制mindMap Logo
                if (outType != null) {
                    if (outType.chopSwitch) {
                        if (type == "download" || type == "printf" || type == "copyPicture" || type == "previewPicture") {
                            let logWidth = imgLogo.width;
                            let logHeight = imgLogo.height;
                            if (logWidth > this.outlineSize.width * 0.3) {
                                logWidth = this.outlineSize.width * 0.3
                                logHeight = logWidth * imgLogo.height / imgLogo.width;
                            }
                            ctx.drawImage(imgLogo, 0, 0, logWidth, logHeight);
                        }
                    }
                }
                //转化类型导出文件
                var canvasData = canvas.toDataURL("image/" + outType.imgType)
                if (type == "download") {
                    this.downLoadImg(canvasData, name, outType)
                } else if (type == "printf") {
                    this.imgUrl = canvasData;
                } else if (type == "savePdf") {
                    let contentWidth = this.outlineSize.width
                    let contentHeight = this.outlineSize.height
                    this.downLoadPdf(canvasData, name, contentWidth, contentHeight)
                } else if (type == "storm") {
                    return canvasData
                } else if (type == "copyPicture" || type == "previewPicture") {
                    return canvasData
                }
            }
        }
    }

    //大纲水印绘制
    drawOutlineMindMapBg(outType) {
        var that = this
        return new Promise(resolve => {
            //前三项条件满足不绘制水印
            if (outType == undefined) {
                resolve(null)
            }
            if (outType == 'jpg') {
                resolve('jpg')
            }
            if (outType == 'png') {
                resolve('png')
            }
            //水印文本、logo
            let text = outType.chopText
            let chopLogo = new Image(); chopLogo.setAttribute('crossOrigin', 'Anonymouse');
            chopLogo.src = outType.chopLogo

            //三种水印导出样式(字体大小、透明度、中心倾斜)
            if (outType.chopType == "File_Full") {
                FileFull(18, 0.3, false)
            } else if (outType.chopType == "Center_Tilt") {
                FileFull(28, 0.3, true)
            } else if (outType.chopType == "File_Full_Dark_Color") {
                FileFull(18, 0.6, false)
            }

            function FileFull(textSize, opacity, centerTilt) {
                if (outType.chopLogo == "" && text != "") {
                    //根据导图大小缩小logo
                    let bgWidth = chopLogo.width;
                    let bgHeight = chopLogo.height;
                    if (bgWidth > that.outlineSize.width * 0.3) {
                        bgWidth = that.outlineSize.width * 0.3
                        bgHeight = bgWidth * chopLogo.height / chopLogo.width;
                    }
                    let cloneChop = document.getElementById("outlineChop").cloneNode(!0)
                    var gImg = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    var gchild = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                    cloneChop.appendChild(gImg)
                    gImg.appendChild(gchild);

                    //获取文字宽高
                    let widthText = 0
                    let heightText = 0
                    if (text != null && text.length > 0) {
                        let sizeObj = MindElementCalculation.caluleText(text, textSize, false);
                        widthText = sizeObj.width
                        heightText = sizeObj.height
                    }
                    //取文字与图片其中宽高最大值
                    let countWidth = Math.floor(that.outlineSize.width / (Math.max(bgWidth, widthText) + 40));
                    let countHeight = Math.floor(that.outlineSize.height / (bgHeight + 20 + heightText))

                    //限制最大图片文字数量为4
                    if (countWidth > 4) {
                        countWidth = 4
                    }
                    if (countHeight > 4) {
                        countHeight = 4
                    }
                    let sc = bgWidth / bgHeight
                    if (countWidth < 1) {
                        bgWidth = that.outlineSize.width / 2
                        bgHeight = bgWidth / sc
                        countWidth = 1
                    }
                    if (countHeight < 1) {
                        bgHeight = that.outlineSize.height / 2
                        bgWidth = bgHeight * sc
                        countHeight = 1
                    }
                    if (centerTilt) {
                        countWidth = 1
                        countHeight = 1
                    }
                    let cellImageWidth = that.outlineSize.width / countWidth
                    let cellImageHeight = that.outlineSize.height / countHeight
                    let imageAndTextSpace = 8 // 文字距离
                    //循环渲染 x y 轴水印
                    for (let i = 0; i < countWidth; i++) {
                        for (let j = 0; j < countHeight; j++) {
                            //图片等分居中的边距
                            let imageX = cellImageWidth * i + (cellImageWidth - bgWidth) / 2
                            let imageY = cellImageHeight * j + (cellImageHeight - bgHeight - heightText - imageAndTextSpace) / 2
                            let textX = cellImageWidth * i + (cellImageWidth - widthText) / 2
                            let textY = imageY + bgHeight + imageAndTextSpace
                            //文字
                            if (widthText > 0) {
                                let textMoveX = (Util.sind(45)) * bgHeight
                                let imageAndTextCD = ((bgWidth - widthText) / 2 + imageAndTextSpace) * Util.sind(45);
                                let imageSF = Math.sqrt(bgHeight * bgHeight + bgWidth * bgWidth) / 2
                                let imageYMove = imageSF + (Util.sind(45));
                                let imageXMove = imageSF - imageYMove

                                textX = imageX + textMoveX + imageAndTextCD - imageXMove
                                textY = imageY + textMoveX - (imageAndTextCD - (imageAndTextSpace / (Util.sind(45)))) + imageYMove

                                var contentBox = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                                var chopBox = document.createElement('div');
                                chopBox.style.marginTop = "0px"
                                chopBox.style.textAlign = 'center';
                                chopBox.style.fontSize = textSize + "px"
                                chopBox.style.opacity = opacity
                                chopBox.innerText = text
                                contentBox.style.transformOrigin = (textX + widthText / 2) + 'px ' + (textY + heightText) + 'px'
                                contentBox.style.transform = 'rotate(-' + 45 + 'deg' + ') '
                                contentBox.setAttribute("x", textX)
                                contentBox.setAttribute("y", textY)
                                contentBox.setAttribute("width", widthText)
                                contentBox.setAttribute("height", heightText)
                                gchild.appendChild(contentBox)
                                contentBox.appendChild(chopBox)
                            }
                        }
                    }
                    cloneChop.setAttribute("width", "" + Config.Mind_Width);
                    cloneChop.setAttribute("height", "" + Config.Mind_Height);
                    cloneChop.setAttribute("version", "1.1")
                    let domBgMindmap = new XMLSerializer().serializeToString(cloneChop)
                    domBgMindmap = window.btoa(unescape(encodeURIComponent(domBgMindmap)));
                    resolve("data:image/svg+xml;base64," + domBgMindmap)
                } else {
                    //图片文字都存在的情况下
                    if (outType.chopLogo == "") {
                        resolve(null)
                    }
                    chopLogo.setAttribute("crossOrigin", "Anonymous");
                    chopLogo.onload = () => {
                        let bgWidth = chopLogo.width;
                        let bgHeight = chopLogo.height;
                        if (bgWidth > that.outlineSize.width * 0.3) {
                            bgWidth = that.outlineSize.width * 0.3
                            bgHeight = bgWidth * chopLogo.height / chopLogo.width;
                        }

                        let cloneChop = document.getElementById("outlineChop").cloneNode(!0)
                        var gImg = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                        var gchild = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                        cloneChop.appendChild(gImg)
                        gImg.appendChild(gchild);

                        let widthText = 0
                        let heightText = 0
                        if (text != null && text.length > 0) {
                            let sizeObj = MindElementCalculation.caluleText(text, textSize, false);
                            widthText = sizeObj.width
                            heightText = sizeObj.height
                        }

                        let countWidth = Math.floor(that.outlineSize.width / (Math.max(bgWidth, widthText) + 40));
                        let countHeight = Math.floor(that.outlineSize.height / (bgHeight + 20 + heightText))
                        if (countWidth > 4) {
                            countWidth = 4
                        }
                        if (countHeight > 4) {
                            countHeight = 4
                        }
                        let sc = bgWidth / bgHeight
                        if (countWidth < 1) {
                            bgWidth = that.outlineSize.width / 2
                            bgHeight = bgWidth / sc
                            countWidth = 1
                        }
                        if (countHeight < 1) {
                            bgHeight = that.outlineSize.height / 2
                            bgWidth = bgHeight * sc
                            countHeight = 1
                        }
                        let cellImageWidth = that.outlineSize.width / countWidth
                        let cellImageHeight = that.outlineSize.height / countHeight
                        let imageAndTextSpace = 8
                        if (centerTilt) {
                            countWidth = 1
                            countHeight = 1
                        }
                        for (let i = 0; i < countWidth; i++) {
                            for (let j = 0; j < countHeight; j++) {
                                //图片
                                var bgImg = document.createElementNS('http://www.w3.org/2000/svg', "image");
                                let imageX = cellImageWidth * i + (cellImageWidth - bgWidth) / 2
                                let imageY = cellImageHeight * j + (cellImageHeight - bgHeight - heightText - imageAndTextSpace) / 2
                                let textX = cellImageWidth * i + (cellImageWidth - widthText) / 2
                                let textY = imageY + bgHeight + imageAndTextSpace
                                bgImg.setAttribute("href", chopLogo.src)
                                bgImg.setAttribute("width", bgWidth)
                                bgImg.setAttribute("height", bgHeight)
                                bgImg.setAttribute("opacity", opacity)
                                bgImg.setAttribute("x", imageX)
                                bgImg.setAttribute("y", imageY)
                                bgImg.style.transformOrigin = (imageX + bgWidth / 2) + 'px ' + (imageY + bgHeight / 2) + 'px'
                                bgImg.style.transform = 'rotate(-' + 45 + 'deg' + ')'

                                gchild.appendChild(bgImg);
                                //文字
                                if (widthText > 0) {
                                    let textMoveX = (Util.sind(45)) * bgHeight
                                    let imageAndTextCD = ((bgWidth - widthText) / 2 + imageAndTextSpace) * Util.sind(45);
                                    let imageSF = Math.sqrt(bgHeight * bgHeight + bgWidth * bgWidth) / 2
                                    let imageYMove = imageSF + (Util.sind(45));
                                    let imageXMove = imageSF - imageYMove

                                    textX = imageX + textMoveX + imageAndTextCD - imageXMove
                                    textY = imageY + textMoveX - (imageAndTextCD - (imageAndTextSpace / (Util.sind(45)))) + imageYMove
                                    var contentBox = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
                                    var chopBox = document.createElement('div');
                                    chopBox.style.marginTop = "0px"
                                    chopBox.style.fontSize = textSize + "px"
                                    chopBox.style.opacity = '0.5'
                                    chopBox.style.textAlign = 'center';
                                    chopBox.innerText = text
                                    contentBox.style.transformOrigin = (textX) + 'px ' + (textY) + 'px'
                                    contentBox.style.transform = 'rotate(-' + 45 + 'deg' + ') '
                                    contentBox.setAttribute("x", textX)
                                    contentBox.setAttribute("y", textY)
                                    contentBox.setAttribute("width", widthText)
                                    contentBox.setAttribute("height", heightText)
                                    gchild.appendChild(contentBox)
                                    contentBox.appendChild(chopBox)
                                }
                            }
                        }
                        cloneChop.setAttribute("width", "" + Config.Mind_Width);
                        cloneChop.setAttribute("height", "" + Config.Mind_Height);
                        cloneChop.setAttribute("version", "1.1")
                        let domBgMindmap = new XMLSerializer().serializeToString(cloneChop)
                        domBgMindmap = window.btoa(unescape(encodeURIComponent(domBgMindmap)));
                        resolve("data:image/svg+xml;base64," + domBgMindmap)
                    };
                }
            }
        })
    }
}

export default ToImage