// package mind.yushu.com.mindmap.utils;

import Point from "../viewmodel/core/base/Point";
import CGPoint from "../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../viewmodel/core/base/basedata/Rect";
import UiUtil from "./UiUtil";
import Colors from "./Colors";
import Config from "../core/core/calcule/Config";
import strings from "../common/lang/strings";
import getString from "../common/lang/language";
import Strings from "../utils/Strings"
import MindMapZipConstant from "../viewmodel/core/tools/mapfile/MindMapZipConstant";
import MindMe from "../viewmodel/facade/MindMe";
import { md5 } from "../common/netWork/base";
import HashMap from "../viewmodel/core/base/HashMap";
import MindElementShapeType from "../viewmodel/datatype/MindElementShapeType";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class Util {
    constructor() {
        this.nodeTextStrs = [
            getString(strings.Mind_Edit_Main_Idea),
            getString(strings.Mind_Edit_Main_Topic),
            getString(strings.Mind_Edit_Sub_Topic),
            getString(strings.Mind_Edit_Free_Topic),
            getString(strings.Mind_Edit_Summary),
            getString(strings.Mind_Edit_Title),
            getString(strings.Mind_Edit_Details),
            getString(strings.Mind_Organization_Edit_Main_Idea),
            getString(strings.Mind_Organization_Main_Topic),
            getString(strings.Mind_Time_Edit_Main_Idea),
            getString(strings.Mind_Time_Edit_Head),
            getString(strings.Mind_Time_Edit_Title),
            getString(strings.Mind_Double_Bubble_Edit_Main_Characteristic),
            getString(strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic),

            getString(strings.Mind_Shape_Corner_Text),
            getString(strings.Mind_Shape_Start_Text),
            getString(strings.Mind_Shape_End_Text),
            getString(strings.Mind_Shape_Determine_Text),
            getString(strings.Mind_Shape_Ellipse_3D_Text),
            getString(strings.Mind_Shape_Cube_Text),
            getString(strings.Mind_Shape_Form_Text),
            getString(strings.Mind_Shape_Corner_Right_Text),
            getString(strings.Mind_Shape_Book_Text),
            getString(strings.Mind_Shape_Text),
        ]

        this.nodeShapeText = new HashMap()
        this.nodeShapeText.put(MindElementShapeType.Corner, getString(strings.Mind_Shape_Corner_Text))
        this.nodeShapeText.put(MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner, getString(strings.Mind_Shape_Start_Text))
        this.nodeShapeText.put(MindElementShapeType.Ellipse, getString(strings.Mind_Shape_Start_Text))

        this.nodeShapeText.put(MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, getString(strings.Mind_Shape_End_Text))
        this.nodeShapeText.put(MindElementShapeType.Semicircle, getString(strings.Mind_Shape_End_Text))
        this.nodeShapeText.put(MindElementShapeType.Circular, getString(strings.Mind_Shape_End_Text))

        this.nodeShapeText.put(MindElementShapeType.Diamond, getString(strings.Mind_Shape_Determine_Text))
        this.nodeShapeText.put(MindElementShapeType.Ellipse_3D, getString(strings.Mind_Shape_Ellipse_3D_Text))
        this.nodeShapeText.put(MindElementShapeType.Cube, getString(strings.Mind_Shape_Cube_Text))
        this.nodeShapeText.put(MindElementShapeType.Form, getString(strings.Mind_Shape_Form_Text))
        this.nodeShapeText.put(MindElementShapeType.Corner_Right, getString(strings.Mind_Shape_Corner_Right_Text))
        this.nodeShapeText.put(MindElementShapeType.Book, getString(strings.Mind_Shape_Book_Text))
    }

    timeForma(second) {
        if (second < 1) {
            return "00:01";
        }
        var day = Math.floor(second / (3600 * 24));
        var hours = Math.floor((second % (3600 * 24)) / 3600);
        if (day <= 0) {
            var minutes = Math.floor((second - (3600 * hours)) / 60);
            if (hours == 0) {
                return minutes + getString(strings.Mind_Minutes);
            } else {
                return hours + getString(strings.Mind_Hours) + minutes + getString(strings.Mind_Minutes);
            }

        } else {
            if (hours == 0) {
                return day + getString(strings.Mind_Day_Day);
            } else {
                return day + getString(strings.Mind_Day_Day) + hours + getString(strings.Mind_Hours);
            }
        }
    }

    // public static String getTodayYMD() {
    //     long currentTime = System.currentTimeMillis();
    //     SimpleDateFormat sf = new SimpleDateFormat("yyyy-MM-dd");
    //     String sp_time = sf.format(currentTime);
    //     return sp_time;
    // }

    formatTimeValueForTask(time) {
        var date = new Date(time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear();
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()) : date.getDate());

        var currentDate = new Date(this.getCurrentTime());
        var currentYear = currentDate.getFullYear();
        var currentMonth = (currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : date.getMonth() + 1);
        var currentDay = (currentDate.getDate() + 1 < 10 ? '0' + (currentDate.getDate()) : currentDate.getDate());
        let value = "";
        if (Y == currentYear) {
            value = M + getString(strings.Mind_Month) + D + getString(strings.Mind_Day);
        } else {
            value = Y + getString(strings.Mind_Year) + M + getString(strings.Mind_Month) + D + getString(strings.Mind_Day);
        }
        return value;
    }

    getCurrentTime() {
        return Date.parse(new Date());
    }

    getScreenWidth() {
        return (new UiUtil()).getScreenWidth()
    }

    getScreenHeight() {
        return (new UiUtil()).getScreenHeight()
    }

    getLocalStorageUseSize() {
        let cache = 0
        for (let key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {
                cache += localStorage.getItem(key).length
            }
        }
        return (cache / 1024).toFixed(2)
    }

    getCurrentTimeInsecond() {
        let time = Date.parse(new Date());
        return time / 1000;
    }

    parseIntForString(str) {
        if (str == null || str == undefined) {
            return 0
        }
        var value = 0
        if (str.indexOf("0x") > -1) {
            value = parseInt(str, 16)
        } else if (str.indexOf("#") > -1) {
            value = parseInt(str.replace("#", ""), 16)
        } else {
            value = Number(str)
        }

        return value == NaN || value == null || value == "NaN" ? 0 : value
    }
    // public static String getTimeFormat(long timeStamp) {
    //     SimpleDateFormat sf = new SimpleDateFormat(MindMapApplication.context.getString(R.string.Global_Time_MonthDayHour_Format));
    //     String sp_time = sf.format(timeStamp);
    //     return sp_time;
    // }

    // public static String getTimeFormatYearMonthDay(long timeStamp) {
    //     SimpleDateFormat sf = new SimpleDateFormat(MindMapApplication.context.getString(R.string.Global_Time_YearMonthDay_Format));
    //     String sp_time = sf.format(timeStamp);
    //     return sp_time;
    // }

    // public static String getTimeMonthDayHourFormat(long timeStamp) {
    //     SimpleDateFormat sf = new SimpleDateFormat(MindMapApplication.context.getString(R.string.Global_Time_MonthDayHour_Format));
    //     String sp_time = sf.format(timeStamp);
    //     return sp_time;
    // }

    // public static String getHomeTopTimeFormat() {
    //     SimpleDateFormat sf = new SimpleDateFormat(MindMapApplication.context.getString(R.string.MainTab_Home_Title_Date));
    //     String sp_time = sf.format(getCurrentTime());
    //     return sp_time + "   " + weekDay();
    // }

    // public static String weekDay() {
    //     Calendar c = Calendar.getInstance();
    //     String way = String.valueOf(c.get(Calendar.DAY_OF_WEEK));
    //     if (way.length() == 0) {
    //         return "";
    //     }
    //     String[] weekDays = {
    //             UiUtil.getString(R.string.Global_Sunday),
    //             UiUtil.getString(R.string.Global_Monday),
    //             UiUtil.getString(R.string.Global_Tuesday),
    //             UiUtil.getString(R.string.Global_Wednesday),
    //             UiUtil.getString(R.string.Global_Thursday),
    //             UiUtil.getString(R.string.Global_Friday),
    //             UiUtil.getString(R.string.Global_Saturday)};
    //     try {
    //         int wayIndex = Integer.valueOf(way);
    //         return weekDays[wayIndex - 1];
    //     } catch (Exception e) {
    //         return "";
    //     }
    // }

    //线与水平方向的夹角
    getAnglesWithThreePoints(p1, p2, p3) {
        //排除特殊情况，三个点一条线
        if ((p1.x == p2.x && p2.x == p3.x) || (p1.y == p2.x && p2.x == p3.x)) {
            return 0;
        }
        let a = Math.abs(p1.x - p2.x);
        let b = Math.abs(p1.y - p2.y);
        let c = Math.abs(p3.x - p2.x);
        let d = Math.abs(p3.y - p2.y);

        if ((a < 1.0 && b < 1.0) || (c < 1.0 && d < 1.0)) {
            return 0;
        }
        let e = a * c + b * d;
        let f = Math.sqrt(a * a + b * b);
        let g = Math.sqrt(c * c + d * d);
        let r = Math.acos(e / (f * g));
        return r;
    }

    getDaysOfMonth(year, monthIndex) {
        var date = null
        if (monthIndex == 11) {
            date = new Date(year + 1, 1, 0);
        } else {
            date = new Date(year, monthIndex + 1, 0);
        }
        var days = date.getDate();
        return days;
    }

    //根据两个点p1、p2获取p1为起点的角度
    getDegreesByTwoPoints(p1, p2) {
        let degrees = this.getCircleDegreesInPoint(p1, p2)
        return degrees;
    }

    // public static String getVersion() {
    //     try {
    //         PackageManager manager = MindMapApplication.getSharedContext().getPackageManager();
    //         PackageInfo info = manager.getPackageInfo(MindMapApplication.getSharedContext().getPackageName(), 0);
    //         return info.versionName;
    //     } catch (android.content.pm.PackageManager.NameNotFoundException e) {
    //         e.printStackTrace();
    //     }

    //     return "";
    // }

    // public static String getCurrentLanguage() {
    //     return "cn"; //中文
    // }

    // public static boolean isChineseLanguage() {
    //     return getCurrentLanguage().equals("cn");
    // }
    tan(degrees) {
        return Math.tan(degrees * Math.PI / 180.0);
    }

    getTanDegrees(value) {
        return Math.atan(value) * 180.0 / Math.PI;
    }


    sind(degrees) {
        return Math.sin(degrees * Math.PI / 180.0);
    }

    asind(value) {
        return (Math.asin(value) * 180.0 / Math.PI) % 360.0;
    }

    cosd(degrees) {
        return Math.cos(degrees * Math.PI / 180.0);
    }

    toRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    getCircleDegreesInPoint(centerPoint, point) {
        let centerDistance = this.getPointSpacingByCGPoint(centerPoint, point);
        let angleValue = (point.x - centerPoint.x) / centerDistance;
        let angle = Math.acos(angleValue) * 180 / Math.PI;
        let pointY = centerPoint.y + ((centerDistance) * this.sind(angle));
        if (Math.abs(pointY - point.y) > new UiUtil().dip2px(0.5)) {
            angle = 360 - angle;
        }
        return angle;
    }

    getCirclePoint(centerPoint, degrees, radius) {
        let pointX = centerPoint.x + radius * this.cosd(degrees);
        let pointY = centerPoint.y + radius * this.sind(degrees);
        return new CGPoint(pointX, pointY);
    }

    // public static float getStatusBarHeight()  {
    //     return 40;
    // }

    // public static float getTabBarHeight() {
    //     return 40;
    // }

    getCircleCenter(point1, point2, point3) {
        let yDelta_a = point2.y - point1.y;
        let xDelta_a = point2.x - point1.x;
        let yDelta_b = point3.y - point2.y;
        let xDelta_b = point3.x - point2.x;
        let center = new CGPoint(0, 0);

        let aSlope = yDelta_a / xDelta_a;
        let bSlope = yDelta_b / xDelta_b;
        center.x = (aSlope * bSlope * (point1.y - point3.y) + bSlope * (point1.x + point2.x)
            - aSlope * (point2.x + point3.x)) / (2 * (bSlope - aSlope));
        center.y = -1 * (center.x - (point1.x + point2.x) / 2) / aSlope + (point1.y + point2.y) / 2;
        return center;
    }

    isConcentricCircles(point1, point2, point3, point4) {
        if (point1.x == point2.x || point1.x == point3.x || point2.x == point3.x || point4.x == point1.x || 
            point1.y == point2.y || point1.y == point3.y || point2.y == point3.y || point4.y == point1.y) {
            return false
        }
        let circleCenter1 = this.getCircleCenter(point1, point2, point3)
        let circleCenter2 = this.getCircleCenter(point1, point4, point3)

        let circleCenter3 = this.getCircleCenter(point4, point2, point3)
        let circleCenter4 = this.getCircleCenter(point1, point2, point4)

        let spcae1 = this.getPointSpacing(circleCenter1, circleCenter2)
        let spcae2 = this.getPointSpacing(circleCenter1, circleCenter3)
        let spcae3 = this.getPointSpacing(circleCenter1, circleCenter4)
        let spcae4 = this.getPointSpacing(circleCenter2, circleCenter3)
        let spcae5 = this.getPointSpacing(circleCenter2, circleCenter4)
        let spcae6 = this.getPointSpacing(circleCenter3, circleCenter4)
        let ignore = 1.0
        return Math.abs(spcae1 - spcae2) < ignore && Math.abs(spcae1 - spcae3) < ignore && Math.abs(spcae1 - spcae4) < ignore && Math.abs(spcae1 - spcae5) < ignore && Math.abs(spcae1 - spcae6) < ignore &&
                Math.abs(spcae2 - spcae3) < ignore && Math.abs(spcae2 - spcae4) < ignore && Math.abs(spcae2 - spcae5) < ignore && Math.abs(spcae2 - spcae6) < ignore && 
                Math.abs(spcae3 - spcae4) < ignore && Math.abs(spcae3 - spcae5) < ignore && Math.abs(spcae3 - spcae6) < ignore &&
                Math.abs(spcae4 - spcae5) < ignore && Math.abs(spcae4 - spcae6) < ignore &&
                Math.abs(spcae5 - spcae6) < ignore;
    }

    isEditInputSelected(text) {
        if (new Strings().isNullOrEmpty(text)) {
            return false;
        }
        for (let index = 0, nodeTextStrsLength = this.nodeTextStrs.length;
            index < nodeTextStrsLength; index++) {
            let cell = this.nodeTextStrs[index];
            if (text.length <= (cell.length + 3) &&
                (cell == text || text.startsWith(cell + " "))) {
                return true;
            }
        }
        return false;
    }

    //     public static String getAppBaseInif() {
    //         String logMessage = "";
    //         try {
    //             PackageManager manager = MindMapApplication.getSharedContext().getPackageManager();
    //             PackageInfo info = manager.getPackageInfo(MindMapApplication.getSharedContext().getPackageName(), 0);
    //             logMessage = String
    //                     .format("%s\r\n\r\nThread: %d\r\n\r\nManufacturer: %s\r\nModel: %s\r\nProduct: %s\r\n\r\nAndroid Version: %s\r\nAPI Level: %d\r\nHeap Size: %sMB\r\n\r\nVersion Code: %s\r\nVersion Name: %s",
    //                             new Date(), Thread.currentThread().getId(),
    //                             Build.MANUFACTURER, Build.MODEL, Build.PRODUCT,
    //                             Build.VERSION.RELEASE, Build.VERSION.SDK_INT,
    //                             Runtime.getRuntime().maxMemory() / 1024 / 1024,
    //                             info.versionCode, info.versionName);
    //         } catch (android.content.pm.PackageManager.NameNotFoundException e) {
    //             e.printStackTrace();
    //         }
    //         return logMessage;
    //     }

    getStaticgetLineIntersectionPoint(line10, line11, line20, line21) {
        //        System.out.println("--Point- " + line10 + " -- " + line11 + " -- " + line20 + " -- " + line21);
        if (line10.x == line11.x && line20.x == line21.x ||
            line10.y == line11.y && line20.y == line21.y) {
            return new CGPoint(-1, -1);
        }
        if (line10.y == line11.y) { // line1水平线
            if (line20.x == line21.x) { // 垂直相交
                return new Point(line20.x, line10.y);
            } else {
                return new Point(((line21.x - line20.x) * ((line10.y - line20.y) / (line21.y - line20.y))) + line20.x, line10.y);
            }
        } else if (line10.x == line11.x) { // line1垂直线
            if (line20.y == line21.y) { // 垂直相交
                return new Point(line10.x, line20.y);
            } else {
                return new Point(line10.x, ((line11.x - line20.x) * ((line21.y - line20.y) / (line21.x - line20.x))) + line20.y);
            }
        } else if (line20.y == line21.y) { // line2水平线
            if (line10.x == line11.x) { // 垂直相交
                return new Point(line10.x, line20.y);
            } else {
                return new Point(((line11.x - line10.x) * ((line20.y - line10.y) / (line11.y - line10.y))) + line10.x, line20.y);
            }
        } else if (line20.x == line21.x) { // line2垂直线
            if (line10.y == line11.y) { // 垂直相交
                return new Point(line20.x, line10.y);
            } else {
                return new Point(line20.x, ((line21.x - line10.x) * ((line11.y - line10.y) / (line11.x - line10.x))) + line10.y);
            }
        }

        let x0 = line10.x;
        let y0 = line10.y;

        let x1 = line11.x;
        let y1 = line11.y;

        let x2 = line20.x;
        let y2 = line20.y;

        let x3 = line21.x;
        let y3 = line21.y;

        let a = y1 - y0;
        let b = x1 * y0 - x0 * y1;
        let c = x1 - x0;
        let d = y3 - y2;
        let e = x3 * y2 - x2 * y3;
        let f = x3 - x2;
        let y = (a * e - b * d) / (a * f - c * d);
        let x = (y * c - b) / a;

        return new CGPoint(x, y);
    }

    getPointSpacingByXY(x1, y1, x2, y2) {
        let h = x1 > x2 ? (x1 - x2) : (x2 - x1);
        let v = y1 > y2 ? (y1 - y2) : (y2 - y1);
        let spacing = Math.sqrt(h * h + v * v);
        return spacing;
    }

    getPointSpacing(point1, point2) {
        let h = point1.x > point2.x ? (point1.x - point2.x) : (point2.x - point1.x);
        let v = point1.y > point2.y ? (point1.y - point2.y) : (point2.y - point1.y);
        let spacing = Math.sqrt(h * h + v * v);
        return spacing;
    }

    getPointSpacingByCGPoint(point1, point2) {
        let h = point1.x > point2.x ? (point1.x - point2.x) : (point2.x - point1.x);
        let v = point1.y > point2.y ? (point1.y - point2.y) : (point2.y - point1.y);
        let spacing = Math.sqrt(h * h + v * v);
        return spacing;
    }

    getPointAndLineHorizontalSpaceing(point, line0, line1) {
        if (Math.abs(line0.y - line1.y) < 1.0) {
            return -1;
        }

        let intersectionPoint = this.getStaticgetLineIntersectionPoint(new Point(point.x + 100, point.y), point, line0, line1);
        let spaceing = this.getPointSpacing(intersectionPoint, point);

        return spaceing;
    }

    getPointToLineSpaceing(point, line0, line1) {
        // if (line0.x == line1.x && line0.y == line1.y) {
        //     return this.getPointSpacingByCGPoint(point, line0);
        // }
        // let length = 0;
        // let b = this.getPointSpacingByXY(line0.x, line0.y, point.x, point.y);
        // let c = this.getPointSpacingByXY(line1.x, line1.y, point.x, point.y);
        // let a = this.getPointSpacingByXY(line0.x, line0.y, line1.x, line1.y);

        // if (c + b == a) {// 点在线段上
        // 	length = 0;
        // } else if (c * c >= a * a + b * b) { // 组成直角三角形或钝角三角形，投影在point1延长线上，
        // 	length = b;
        // } else if (b * b >= a * a + c * c) {// 组成直角三角形或钝角三角形，投影在point2延长线上，
        // 	length = c;
        // } else {
        // 	// 组成锐角三角形，则求三角形的高
        // 	let p = (a + b + c) / 2;// 半周长
        // 	let s = Math.sqrt(p * (p - a) * (p - b) * (p - c));// 海伦公式求面积
        // 	length = 2 * s / c;// 返回点到线的距离（利用三角形面积公式求高）
        // }
        // return length
        return this.getPointAndLineSpaceing(point, line0, line1)
    }

    getPointAndLineSpaceing(point, line0, line1) {
        if (line0.x == line1.x && line0.y == line1.y) {
            return this.getPointSpacingByCGPoint(point, line0);
        }

        let a = point.x - line0.x;
        let b = point.y - line0.y;
        let c = line1.x - line0.x;
        let d = line1.y - line0.y;

        let dot = a * c + b * d;
        let lenSq = c * c + d * d;
        let param = dot / lenSq;

        var xx = -1
        var yy = -1

        if (param < 0 || (line0.x == line1.x && line0.y == line1.y)) {
            xx = line0.x;
            yy = line0.y;
        } else if (param > 1) {
            xx = line1.x;
            yy = line1.y;
        } else {
            xx = line0.x + param * c;
            yy = line0.y + param * d;
        }

        return Math.sqrt((point.x - xx) * (point.x - xx) + (point.y - yy) * (point.y - yy))
    }

    getPointAndLineVerticalSpaceing(point, line0, line1) {
        if (Math.abs(line0.x - line1.x) < 1.0) {
            return -1;
        }

        let intersectionPoint = this.getStaticgetLineIntersectionPoint(new Point(point.x, point.y + 100), point, line0, line1);
        let spaceing = this.getPointSpacing(intersectionPoint, point);
        return spaceing;
    }

    getEllipsePoint(radiusX, radiusY, centerPoint, degrees) {
        let x = radiusX * Math.cos(Math.PI * 2 * degrees / 360) + centerPoint.x
        let y = radiusY * Math.sin(Math.PI * 2 * degrees / 360) + centerPoint.y
        return new Point(x, y)
    }

    getEllipsePoints(radiusX, radiusY, cx, cy) {
        let data = new Array();
        for (let index = 0; index < 360; index++) {
            let x = (radiusX * Math.cos(Math.PI * 2 * index / 360));
            let y = (radiusY * Math.sin(Math.PI * 2 * index / 360));
            data.push(new CGPoint(x + cx, y + cy));
        }
        return data;
    }

    getInsertPointCircleAndPoint(point, m, n, r) {
        let list = this.getInsertPointBetweenCircleAndLine(point.x, point.y, m, n, m, n, r);
        if (list.length == 0) {
            return new Point(-1, -1);
        }
        let minSpace = 1000000
        let minPoint = new Point(-1, -1);
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            const space = this.getPointSpacing(element, point)
            if (space < minSpace) {
                minSpace = space;
                minPoint = element;
            }
        }
        return minPoint
    }
    //     /**
    //      * 求圆和直线之间的交点
    //      * 直线方程：y = kx + b
    //      * 圆的方程：(x - m)² + (x - n)² = r²
    //      * x1, y1 = 线坐标1, x2, y2 = 线坐标2, m, n = 圆坐标, r = 半径
    //      */
    getInsertPointBetweenCircleAndLine(x1, y1, x2, y2, m, n, r) {

        let insertPoints = new Array();

        if (Math.abs(x1 - x2) < new UiUtil().dip2px(4) &&
            Math.abs(m - x1) < new UiUtil().dip2px(4) &&
            ((x1 == m && y1 == n) || (x2 == m && y2 == n))) {
            insertPoints.push(new Point(x1, n - r));
            insertPoints.push(new Point(x1, n + r));
            return insertPoints;
        }
        // console.log(x1, y1, x2, y2, m, n, r)
        let kbArr = this.binaryEquationGetKB(x1, y1, x2, y2);
        let k = kbArr[0];
        let b = kbArr[1];

        let aX = 1 + k * k;
        let bX = 2 * k * (b - n) - 2 * m;
        let cX = m * m + (b - n) * (b - n) - r * r;

        // let insertPoints = new Array();
        let xArr = this.quadEquationGetX(aX, bX, cX);
        for (let index = 0; index < xArr.length; index++) {
            let y = k * xArr[index] + b;
            insertPoints.push(new Point(xArr[index], y));
        }
        if (insertPoints.length == 2 && ((x1 == m && y1 == n) || (x2 == m && y2 == n))) {
            let spcae = this.getPointSpacing(insertPoints[0], insertPoints[1]);
            if (Math.abs(spcae - r * 2) > new UiUtil().dip2px(2)) {
                if (Math.abs(x1 - x2) < r * 0.2 && Math.abs(m - x1) < r * 0.2) {
                    insertPoints = [];
                    insertPoints.push(new Point(x1, n - r));
                    insertPoints.push(new Point(x1, n + r));
                    return insertPoints;
                }
            }
        }

        return insertPoints;
    }

    intersection(l1x1, l1y1, l1x2, l1y2,
        l2x1, l2y1, l2x2, l2y2) {
        // 快速排斥实验 首先判断两条线段在 x 以及 y 坐标的投影是否有重合。 有一个为真，则代表两线段必不可交。
        if (Math.max(l1x1, l1x2) < Math.min(l2x1, l2x2)
            || Math.max(l1y1, l1y2) < Math.min(l2y1, l2y2)
            || Math.max(l2x1, l2x2) < Math.min(l1x1, l1x2)
            || Math.max(l2y1, l2y2) < Math.min(l1y1, l1y2)) {
            return false;
        }
        // 跨立实验  如果相交则矢量叉积异号或为零，大于零则不相交
        if ((((l1x1 - l2x1) * (l2y2 - l2y1) - (l1y1 - l2y1) * (l2x2 - l2x1))
            * ((l1x2 - l2x1) * (l2y2 - l2y1) - (l1y2 - l2y1) * (l2x2 - l2x1))) > 0
            || (((l2x1 - l1x1) * (l1y2 - l1y1) - (l2y1 - l1y1) * (l1x2 - l1x1))
                * ((l2x2 - l1x1) * (l1y2 - l1y1) - (l2y2 - l1y1) * (l1x2 - l1x1))) > 0) {
            return false;
        }
        return true;
    }

    //     /**
    //      * 求二元一次方程的系数
    //      * y1 = k * x1 + b => k = (y1 - b) / x1
    //      * y2 = k * x2 + b => y2 = ((y1 - b) / x1) * x2 + b
    //      */
    binaryEquationGetKB(x1, y1, x2, y2) {
        let k = (y1 - y2) / (x1 - x2);
        let b = (x1 * y2 - x2 * y1) / (x1 - x2);
        let value = [k, b];
        return value;
    }

    //     /**
    //      * 一元二次方程求根
    //      * ax² + bx + c = 0
    //      */
    quadEquationGetX(a, b, c) {
        let xArr = new Array();
        let result = Math.pow(b, 2) - 4 * a * c;
        if (result > 0) {
            xArr.push((-b + Math.sqrt(result)) / (2 * a));
            xArr.push((-b - Math.sqrt(result)) / (2 * a));
        } else if (result == 0) {
            xArr.push(-b / (2 * a));
        }
        return xArr;
    }

    containsInRectForPoint(rect, point) {
        return point.x >= rect.x && point.x <= rect.x + rect.width() &&
            point.y >= rect.y && point.y <= rect.y + rect.height();
    }

    containsInRectForXY(rect, x, y) {
        return x >= rect.x && x <= rect.x + rect.width() &&
            y >= rect.y && y <= rect.y + rect.height();
    }

    getAreaByRect(rect) {
        return rect.width() * rect.height();
    }

    isLineIntersectRect(rect, point1, point2) {
        let linePointX1 = point1.x;
        let linePointY1 = point1.y;
        let linePointX2 = point2.x;
        let linePointY2 = point2.y;
        let rectangleLeftTopX = rect.x;
        let rectangleLeftTopY = rect.y;
        let rectangleRightBottomX = rect.x + rect.width();
        let rectangleRightBottomY = rect.y + rect.height();

        let lineHeight = linePointY1 - linePointY2;
        let lineWidth = linePointX2 - linePointX1;  // 计算叉乘
        let c = linePointX1 * linePointY2 - linePointX2 * linePointY1;
        if ((lineHeight * rectangleLeftTopX + lineWidth * rectangleLeftTopY + c >= 0 && lineHeight * rectangleRightBottomX + lineWidth * rectangleRightBottomY + c <= 0)
                || (lineHeight * rectangleLeftTopX + lineWidth * rectangleLeftTopY + c <= 0 && lineHeight * rectangleRightBottomX + lineWidth * rectangleRightBottomY + c >= 0)
                || (lineHeight * rectangleLeftTopX + lineWidth * rectangleRightBottomY + c >= 0 && lineHeight * rectangleRightBottomX + lineWidth * rectangleLeftTopY + c <= 0)
                || (lineHeight * rectangleLeftTopX + lineWidth * rectangleRightBottomY + c <= 0 && lineHeight * rectangleRightBottomX + lineWidth * rectangleLeftTopY + c >= 0))
        {

            if (rectangleLeftTopX > rectangleRightBottomX) {
                let temp = rectangleLeftTopX;
                rectangleLeftTopX = rectangleRightBottomX;
                rectangleRightBottomX = temp;
            }
            if (rectangleLeftTopY < rectangleRightBottomY) {
                let temp1 = rectangleLeftTopY;
                rectangleLeftTopY = rectangleRightBottomY;
                rectangleRightBottomY = temp1;
            }
            if ((linePointX1 < rectangleLeftTopX && linePointX2 < rectangleLeftTopX)
                    || (linePointX1 > rectangleRightBottomX && linePointX2 > rectangleRightBottomX)
                    || (linePointY1 > rectangleLeftTopY && linePointY2 > rectangleLeftTopY)
                    || (linePointY1 < rectangleRightBottomY && linePointY2 < rectangleRightBottomY)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    isIntersectedForRect(rect1, rect2) {
        if (this.containsInRectForXY(rect1, rect2.x, rect2.y) ||
            this.containsInRectForXY(rect1, rect2.x + rect2.width(), rect2.y) ||
            this.containsInRectForXY(rect1, rect2.x + rect2.width(), rect2.y + rect2.height()) ||
            this.containsInRectForXY(rect1, rect2.x, rect2.y + rect2.height())) {
            return true;
        }
        if (this.containsInRectForXY(rect2, rect1.x, rect1.y) ||
            this.containsInRectForXY(rect2, rect1.x + rect1.width(), rect1.y) ||
            this.containsInRectForXY(rect2, rect1.x + rect1.width(), rect1.y + rect1.height()) ||
            this.containsInRectForXY(rect2, rect1.x, rect1.y + rect1.height())) {
            return true;
        }
        return false;
    }

    getCrossArea(rect1, rect2) {
        let firstRect = rect1;
        let secondRect = rect2;

        if (this.containsInRectForXY(firstRect, secondRect.x, secondRect.y) &&
            this.containsInRectForXY(firstRect, secondRect.x + secondRect.width(), secondRect.y + secondRect.height())) {
            return this.getAreaByRect(firstRect);
        }

        if (this.containsInRectForXY(secondRect, firstRect.x, firstRect.y) &&
            this.containsInRectForXY(secondRect, firstRect.x + firstRect.width(), firstRect.y + firstRect.height())) {
            return this.getAreaByRect(secondRect);
        }

        let xa1 = rect1.x;
        let ya1 = rect1.y + rect1.height();
        let xa2 = rect1.x + rect1.width();
        let ya2 = rect1.y;

        let xb1 = rect2.x;
        let yb1 = rect2.y + rect2.height();
        let xb2 = rect2.x + rect2.width();
        let yb2 = rect2.y;

        let ox = (xa1 + xa2) / 2;
        let oy = (ya1 + ya2) / 2;
        let ex = (xb1 + xb2) / 2;
        let ey = (yb1 + yb2) / 2;

        let xoi = Math.abs(ex - ox) <= Math.abs((xa2 - xa1) / 2 + (xb2 - xb1) / 2);
        let yoi = Math.abs(ey - oy) <= Math.abs((ya2 - ya1) / 2 + (yb2 - yb1) / 2);

        if (xoi && yoi &&
            xa2 > xa1 && xb2 > xb1 && ya2 < ya1 && yb2 < yb1) {
            let cx = Math.max(xa1, xb1);///左下角的x坐标
            let cy = Math.min(ya1, yb1);///左下角y坐标
            let ecx = Math.min(xa2, xb2);///右上角x坐标
            let ecy = Math.max(ya2, yb2);///右上角y坐标
            return Math.abs(cx - ecx) * Math.abs(cy - ecy);
        }
        return 0;
    }

    getContentText(data, textLocal, styleList, fontSize, isTitle = false) {

        let selectFontSize = 18;
        let textShadow = -10000;
        let bgColor = -10000;
        let fontFamily = ""
        let text = "";
        let textStyles = [];

        if (data != null && data != "") {
            if (isTitle && data.titleContent != null) {
                text = data.titleContent.getContentText();
                textStyles = data.titleContent.styleCells;
                selectFontSize = data.titleContent.textFontSize;
                textShadow = data.titleContent.textShadow;
                bgColor = data.titleContent.bgColor;
                fontFamily = data.titleContent.fontFamily
            } else if (data.textContent != null) {
                text = data.textContent.getContentText();
                textStyles = data.textContent.styleCells;
                selectFontSize = data.textContent.textFontSize;
                textShadow = data.textContent.textShadow;
                bgColor = data.textContent.bgColor;
                fontFamily = data.textContent.fontFamily
            } else if (data.generalizationContent != null) {
                text = data.generalizationContent.getContentText();
                textStyles = data.generalizationContent.styleCells;
                selectFontSize = data.generalizationContent.textFontSize;
                textShadow = data.generalizationContent.textShadow;
                bgColor = data.generalizationContent.bgColor;
                fontFamily = data.generalizationContent.fontFamily
            }
        }
        if (fontFamily == null || fontFamily == "") {
            fontFamily = Config.textFontFamily
        }
        if (textLocal != null && textLocal != "") {
            text = textLocal;
        }
        if (styleList != null && styleList != "" && styleList.length > 0) {
            textStyles = styleList;
        }
        if (fontSize != null && fontSize != "") {
            selectFontSize = fontSize;
        }


        if (textStyles == null || textStyles.length == 0 ||
            text == null || text.length == 0) {
            let tag = "<span id='s0' style='font-size:" + selectFontSize +
                "px;letter-spacing: 1px;line-height: " + Config.textLineHeight + ";" +
                " fontFamily: " + fontFamily + ";" + Config.TextWritingModeVertical_LR;
            if (bgColor != -10000 && !Colors.isClear(bgColor)) {
                tag = tag + "background-color:" + Colors.getUiColor(bgColor) + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;";
            }

            if (textShadow != -10000 && !Colors.isClear(textShadow)) {
                let offset = 1
                if (selectFontSize <= 16) {
                    offset = 0.6;
                } else if (selectFontSize > 16 && selectFontSize <= 28) {
                    offset = 1;
                } else {
                    offset = 2;
                }

                tag = tag + "text-shadow: " + offset + "px " + offset + "px " + Colors.getUiColor(textShadow) + ";";
            }
            tag = tag + "' class='no_font_size' >" + text + "</span>";
            return tag;
        }
        let textStyleMap = new Array();
        let textCount = text.length
        let styleCount = textStyles.length
        let separator = "|"
        let textSeparator = separator
        let colorSeparator = "c——" + separator
        let fontSizeSeparator = "f——" + separator
        let boldSeparator = "b" + separator
        let italicsSeparator = "i" + separator
        let strikethroughSeparator = "s——" + separator
        let bgcolorSeparator = "g——" + separator
        let shadowColorSeparator = "d——" + separator
        var lastTextCellStyle = ""


        let indexSubscript = 1;
        for (let index = 0; index < textCount; index++) {
            let cellText = text.substring(index, index + 1)
            var value = cellText + textSeparator
            var cellStyle = ""
            for (let styleindex = 0; styleindex < styleCount; styleindex++) {
                let cell = textStyles[styleindex];
                if (cell.startIndex <= index && cell.endIndex > index) {
                    if (cell.isSetColor()) {
                        cellStyle += cell.textColor + colorSeparator;
                    }
                    if (cell.isSetFontSize()) {
                        cellStyle += cell.textFontSize + fontSizeSeparator;
                    }
                    if (cell.isBackgorunColor()) {
                        cellStyle += cell.bgColor + bgcolorSeparator;
                    }
                    if (cell.isTextShadowColor()) {
                        cellStyle += cell.textShadow + shadowColorSeparator;
                    }
                    if (cell.textBold.isSet()) {
                        cellStyle += cell.textBold.isValue() + boldSeparator;
                    }
                    if (cell.textItalics.isSet()) {
                        cellStyle += cell.textItalics.isValue() + italicsSeparator;
                    }
                    if (cell.textStrikethrough.isSet()) {
                        cellStyle += cell.textStrikethrough.isValue() + strikethroughSeparator;
                    }
                }
            }
            value += cellStyle

            if (cellStyle == lastTextCellStyle && index > 0) {
                let preCell = textStyleMap[index - indexSubscript]
                let arr = [];
                if (preCell != null && preCell != "") {
                    arr = preCell.split(textSeparator);
                }
                if (arr.length >= 2) {
                    textStyleMap.splice(index - indexSubscript, 1)
                    indexSubscript++;
                    textStyleMap.push(arr[0] + cellText + textSeparator + cellStyle)
                } else {
                    textStyleMap.push(value)
                }
            } else {
                textStyleMap.push(value)
            }
            lastTextCellStyle = cellStyle
        }
        let textStyleMapCount = textStyleMap.length
        if (textStyleMapCount == 1 &&
            textStyleMap[0].indexOf(colorSeparator) == -1 &&
            textStyleMap[0].indexOf(bgcolorSeparator) == -1 &&
            textStyleMap[0].indexOf(shadowColorSeparator) == -1 &&
            textStyleMap[0].indexOf(fontSizeSeparator) == -1 &&
            textStyleMap[0].indexOf(boldSeparator) == -1 &&
            textStyleMap[0].indexOf(italicsSeparator) == -1 &&
            textStyleMap[0].indexOf(strikethroughSeparator) == -1) {
            return "<span id='s0' style='font-size:" + selectFontSize + " fontFamily: " + fontFamily + ";" + Config.TextWritingModeVertical_LR +
                "px;letter-spacing: 1px;line-height: " + Config.textLineHeight +
                ";' class='no_font_size' >" + text + "</span>"
        }
        var pTagElement = ""
        for (let index = 0; index < textStyleMapCount; index++) {
            let value = textStyleMap[index]
            let values = value.split(separator)
            if (values.length == 2 && values[1] == "") {
                pTagElement = pTagElement + "<span id='s" + index + "' style='font-size:" + selectFontSize +
                    "px;letter-spacing: 1px;line-height: " + Config.textLineHeight + ";" + Config.TextWritingModeVertical_LR
                if (bgColor != -10000 && !Colors.isClear(bgColor)) {
                    pTagElement = pTagElement + "background-color:" + Colors.getUiColor(bgColor) + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;";
                }
                if (textShadow != -10000 && !Colors.isClear(textShadow)) {
                    let offset = 1
                    if (selectFontSize <= 16) {
                        offset = 0.6;
                    } else if (selectFontSize > 16 && selectFontSize <= 28) {
                        offset = 1;
                    } else {
                        offset = 2;
                    }
                    pTagElement = pTagElement + "text-shadow: " + offset + "px " + offset + "px " + Colors.getUiColor(textShadow) + ";";
                }
                pTagElement = pTagElement + " ' class='no_font_size' >" + values[0] + "</span>"
            } else {
                let styleText = this.getSplicingSpan(value, values, index, selectFontSize);
                pTagElement += styleText;
            }
        }
        pTagElement += "";
        return pTagElement;


    }

    getPixelRatio(context) {
        var backingStore = context.backingStorePixelRatio ||
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio || 1;

        return (window.devicePixelRatio || 1) / backingStore;
    }

    getSplicingSpan(val, vals, index, selectFontSize) {
        let separator = "|"
        let colorSeparator = "c——" + separator
        let bgcolorSeparator = "g——" + separator
        let fontSizeSeparator = "f——" + separator
        let boldSeparator = "b" + separator
        let italicsSeparator = "i" + separator
        let strikethroughSeparator = "s——" + separator
        let shadowColorSeparator = "d——" + separator

        let valsCount = vals.length;

        let pTagElement = "";
        // display: inline-block;
        pTagElement += "<span id='s" + index + "' style='letter-spacing: 1px;line-height: " + Config.textLineHeight + ";" + Config.TextWritingModeVertical_LR

        if (val.indexOf(colorSeparator) != -1) {

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valColor = vals[index]
                if (valColor.indexOf("c——") != -1) {
                    valStyle = valColor;
                }
            }
            let valStyles = valStyle.split("c——");
            let colorSpan = Colors.getUiColor(parseInt(valStyles[0]));
            if (parseInt(valStyles[0]) != -10000) {
                pTagElement += "color:" + colorSpan + ";"
            }
        }
        if (val.indexOf(bgcolorSeparator) != -1) {

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valColor = vals[index]
                if (valColor.indexOf("g——") != -1) {

                    valStyle = valColor;

                }
            }
            let valStyles = valStyle.split("g——");
            let colorSpan = Colors.getUiColor(parseInt(valStyles[0]));
            if (parseInt(valStyles[0]) != -10000) {
                pTagElement += "background-color:" + colorSpan + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;"
            }
        }

        if (val.indexOf(shadowColorSeparator) != -1) {

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valColor = vals[index]
                if (valColor.indexOf("d——") != -1) {

                    valStyle = valColor;

                }
            }
            let valStyles = valStyle.split("d——");
            let colorSpan = Colors.getUiColor(parseInt(valStyles[0]));
            if (parseInt(valStyles[0]) != -10000) {
                let offset = 1
                if (val.indexOf(fontSizeSeparator) != -1) {
                    let valStyleFont = [];
                    let valStyle = "";
                    for (let index = 0; index < valsCount; index++) {
                        let valFontSize = vals[index]
                        if (valFontSize.indexOf("f——") != -1) {
                            valStyle = valFontSize;
                        }
                    }

                    valStyleFont = valStyle.split('f——');
                    if (valStyleFont[0] <= 16) {
                        offset = 0.6;
                    } else if (valStyleFont[0] > 16 && valStyleFont[0] <= 28) {
                        offset = 1;
                    } else {
                        offset = 2;
                    }
                }
                pTagElement += "text-shadow: " + offset + "px " + offset + "px " + colorSpan + ";";
            }
        }

        if (val.indexOf(fontSizeSeparator) != -1) {

            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("f——") != -1) {

                    valStyle = valFontSize;

                }
            }

            valStyleFont = valStyle.split('f——');
            // let rowHeight = parseInt(valStyleFont[0])*Config.textLineHeight;
            pTagElement += "font-size:" + valStyleFont[0] + "px;"
            // pTagElement += "font-size:"+valStyleFont[0]+"px;line-height:"+rowHeight+"px;"
        }
        if (val.indexOf(boldSeparator) != -1) {

            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("b") != -1) {

                    valStyle = valFontSize;

                }
            }
            valStyleFont = valStyle.split('b');

            if (valStyleFont[0] == "true") {
                pTagElement += "font-weight:bold;"
            }
        }
        if (val.indexOf(italicsSeparator) != -1) {


            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("i") != -1) {

                    valStyle = valFontSize;

                }
            }
            valStyleFont = valStyle.split('i');

            if (valStyleFont[0] == "true") {
                pTagElement += "font-style: italic;"
            }
        }
        if (val.indexOf(strikethroughSeparator) != -1) {
            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("s——") != -1) {

                    valStyle = valFontSize;

                }
            }
            valStyleFont = valStyle.split('s——');

            if (valStyleFont[0] == "true") {
                pTagElement += "text-decoration: line-through;"
            }
        }
        if (val.indexOf(fontSizeSeparator) == -1) {
            pTagElement += "font-size:" + selectFontSize + "px;' class='no_font_size"
        }
        let valueText = vals[0]
        pTagElement += "'>" + valueText + "</span>";

        return pTagElement
    }

    getStaticgetLineIntersectionVerticalPoint(p1, p2, p3) {
        var foot = new CGPoint();

        let dx = p1.x - p2.x;
        let dy = p1.y - p2.y;
        if (dx == 0 && dy == 0) {
            return p1;
        } else if (dx == 0) {
            foot = new CGPoint(p1.x, p3.y);
            return foot;
        } else if (dy == 0) {
            foot = new CGPoint(p3.x, p1.y);
            return foot;
        }
        let k = dy / dx; //斜率

        let x = (p3.y - p2.y + k * p2.x + p3.x / k) / (k + 1.0 / k);
        let y = p2.y + k * (x - p2.x);
        foot = new CGPoint(x, y);
        return foot;
    }

    getStaticgetLineSegmentIntersectionVerticalPoint(p1, p2, p3) {
        let foot = this.getStaticgetLineIntersectionVerticalPoint(p1, p2, p3);
        let d = this.getPointSpacing(p1, p2);
        let d1 = this.getPointSpacing(p1, foot);
        let d2 = this.getPointSpacing(p2, foot);

        if (d1 > d || d2 > d) {
            if (d1 > d2) {
                return p2;
            } else {
                return p1;
            }
        }
        return foot;
    }

    /**
     * @param poss      贝塞尔曲线控制点坐标
     * @param precision 精度，需要计算的该条贝塞尔曲线上的点的数目
     * @return 该条贝塞尔曲线上的点（二维坐标）
     */
    bezierCalculate(poss, precision) {
        let result = new Array();
        //维度，坐标轴数（二维坐标，三维坐标...）
        let dimersion = 2;
        //贝塞尔曲线控制点数（阶数）
        let number = poss.length;
        //控制点数不小于 2 ，至少为二维坐标系
        if ((number < 3 || dimersion < 2)) {
            return result;
        }
        //计算杨辉三角
        let mi = new Array(number)
        mi[0] = mi[1] = 1;

        for (let i = 3; i <= number; i++) {

            let t = new Array(i - 1)
            for (let j = 0; j < t.length; j++) {
                t[j] = mi[j];
            }

            mi[0] = mi[i - 1] = 1;
            for (let j = 0; j < i - 2; j++) {
                mi[j + 1] = t[j] + t[j + 1];
            }
        }

        //计算坐标点
        for (let i = 0; i < precision; i++) {
            let t = i / precision;
            let p = new Point(0, 0);
            if (dimersion >= 1) {
                for (let j = 0; j < dimersion; j++) {
                    let temp = 0.0;
                    if (number >= 1) {
                        for (let k = 0; k < number; k++) {
                            temp += Math.pow((1 - (t)), (number - k) - 1) * (j == 0 ? poss[k].x : poss[k].y) * Math.pow((t), (k)) * (mi[k]);
                        }
                    }
                    if (j == 0) {
                        p.x = temp;
                    } else {
                        p.y = temp;
                    }
                }
            }
            result.push(p);
        }

        return result;
    }

    arrayDeleteValue(arr, value) {
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if (element == value) {
                arr.splice(index, 1)
                break
            }
        }
    }

    setPasteboard(text) {
        if (text == null) {
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            // console.log("Text copied to clipboard...")
        }).catch(err => {
            // console.log('Something went wrong', err);
        })

    }

    isMember() {
        const userData = MindMe.getMeMessage();;
        if (userData == null || userData == "") {
            return false;
        } else {
            return userData.isMember;
        }
    }

    getQueryId(vue) {
        return vue.$route.query.id
    }

    getQueryPath(vue) {
        if (vue.$route.query.path == null || vue.$route.query.path == "") {
            return "";
        }
        return decodeURIComponent(vue.$route.query.path)
    }

    getQueryLocalPath(vue) {
        if (vue.$route.query.localpath == null || vue.$route.query.localpath == "") {
            return "";
        }
        return decodeURIComponent(vue.$route.query.localpath)
    }

    getQueryNewParameter(vue) {
        if (vue.$route.query.parameter == null || vue.$route.query.parameter == "") {
            return "";
        }
        return decodeURIComponent(vue.$route.query.parameter)
    }

    getQuerySheetId(vue) {
        return vue.$route.query.sheetId
    }

    getUrlType(url) {
        if (url == null || url.length == 0 ||
            url.indexOf(".") < 0) {
            return "";
        }
        let list = url.split(".")
        if (list.length == 0) {
            return "";
        }
        return list[list.length - 1]
    }

    getNameByUrl(url, isCheckDot = true) {
        if (url == null || url.length == 0 || (url.indexOf(".") < 0 && isCheckDot) || url.indexOf(MindMapZipConstant.FileSeparator) < 0) {
            return "";
        }
        let list = url.split(MindMapZipConstant.FileSeparator)
        if (list.length == 0) {
            return "";
        }
        let fullName = list[list.length - 1]
        if (fullName.length == 0) {
            return "";
        }
        if (fullName.indexOf(".") < 0) {
            return fullName;
        }

        list = fullName.split(".")
        if (list.length < 2) {
            return fullName;
        }
        let name = "";
        for (let index = 0; index < list.length; index++) {
            const item = list[index];
            if (index == 0) {
                name = item
            } else {
                name = name + "." + item
            }

            if (index == list.length - 2) {
                break
            }
        }
        return name;
    }

    getNowFormatDay(nowDate) {
        var char = "-";
        if (nowDate == null) {
            nowDate = new Date();
        }
        var day = nowDate.getDate();
        var month = nowDate.getMonth() + 1;//注意月份需要+1
        var year = nowDate.getFullYear();
        //补全0，并拼接
        return year + char + this.completeDate(month) + char + this.completeDate(day);
    }

    getNowFormatTime() {
        var nowDate = new Date();
        var colon = ":";
        var h = nowDate.getHours();
        var m = nowDate.getMinutes();
        var s = nowDate.getSeconds();
        //补全0，并拼接
        return this.getNowFormatDay(nowDate) + " " + this.completeDate(h) + colon + this.completeDate(m) + colon + this.completeDate(s);
    }

    completeDate(value) {
        return value < 10 ? "0" + value : value;
    }

    isChinesel() {
        var language = (navigator.browserLanguage || navigator.language).toLowerCase();
        return language.indexOf("zh") > -1
    }

    md5(value) {
        return md5(value)
    }

    isSharePageView() { //分享页面
        return window.location.href.match(/\/s\//i) == "/s/";
    }
}
export default new Util()