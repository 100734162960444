
import Config from "../../../core/core/calcule/Config"
import UiUtil from "../../../utils/UiUtil"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import TimeLineLayout from "./TimeLineLayout"
import BaseLayout from "./BaseLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType"
import Util from "../../../utils/Util"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import CGPoint from "../base/basedata/CGPoint"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineSLayout extends TimeLineLayout {
    constructor() {
        super();
        this.UiUtil = new UiUtil();
        this.BaseLayout = new BaseLayout();
        this.baseLineHeight = this.UiUtil.dip2px(10);
    }

    initConfigInfo() {
        this.radius = this.getRadius();
        this.baseLineHeight = this.radius * 2 + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2;
        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);

        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
            this.baseLineHeight += this.UiUtil.dip2px(15);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineHeight += this.UiUtil.dip2px(17);
        } else {
            this.baseLineHeight += this.UiUtil.dip2px(10);
        }

        this.subjectAndLineSpace = this.addSubjectSpcaeVertical(0);
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);

        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                break;
            default:
        }
    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.title.height + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
            
        }
        this.initConfigInfo();

        let nodeList = this.getOrderNodes();
        let nodeListLength = nodeList.length;

        
        let contentX = this.title.x + this.title.width / 2;
        let contentY = this.title.y;
        let spaceV = 220
        let lineWidth = 520
        let startY = contentY - spaceV / 2;
        let startX = contentX + lineWidth/4
        
        let minX = contentX - lineWidth/2
        let maxX = contentX + lineWidth/2
        this.baseLine.x = contentX;        
        this.baseLine.y = this.title.y;
        this.space = (this.baseLineHeight - this.radius) / 2;

        let dots = new Array();
        let lastX = startX
        let lastY = startY
        let rowCount = 3
        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let mainMindElementDataDictLength = mainMindElementDataDict.length
        let spaceXIncrement = (lineWidth / 6) / 5
        let middleIndex = nodeListLength - 2 
        
        var left = 99999999
        var right = -99999999
        var top = 999999999
        var bottom = -999999999
        for (let index = 0; index < nodeListLength; index++) {
            let timeNode = nodeList[index];
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
            let x = 0
            let y = 0
            
            if (index == 0) {
                x = startX
                y = startY
            } else if (index == 1) {
                x = contentX - lineWidth/4 + + lineWidth / 10
                y = lastY - spaceV / 2
            } else if (index == nodeListLength - 1) {
                if (lastX > contentX + lineWidth / 4) { //最右边
                    x = contentX - lineWidth / 4
                    y = lastY - spaceV / 2
                } else if (lastX < contentX - lineWidth / 4) { //最左边
                    x = contentX + lineWidth / 4
                    y = lastY - spaceV / 2
                } else if (lastX < contentX && lastX > contentX - lineWidth / 4) { //左边中间
                    x = maxX// - lineWidth / 10
                    y = lastY - spaceV / 3                    
                } else {
                    x = minX// + lineWidth / 10
                    y = lastY - spaceV / 3
                }
            } else {                
                if (lastX < contentX && lastX > contentX - lineWidth / 4) {
                    x = maxX 
                    y = lastY - spaceV / 3 * 2
                } else if (lastX > contentX && lastX < maxX - lineWidth / 4) {
                    x = minX
                    y = lastY - spaceV / 3 * 2
                } else if (lastX > maxX - lineWidth / 4) {
                    x = contentX + lineWidth/4 - lineWidth / 10
                    y = lastY - spaceV / 3
                } else if (lastX < contentX - lineWidth/4) {
                    x = contentX - lineWidth/4 + lineWidth / 10
                    y = lastY - spaceV / 3
                } else {
                    y = lastY - spaceV
                }
            }

            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;
            dotElementContent.radius = this.radius;
            dotElementContent.x = x - this.baseLine.x;
            dotElementContent.y = y - this.baseLine.y;
            if (this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM) {
                dotElementContent.radius = head.width / 2;
            } else {
                dotElementContent.radius = this.radius;
            }
            
            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dots.push(dotElementContent);
            lastX = x
            lastY = y
            
            left = Math.min(left, dotElementContent.x)
            right = Math.max(right, dotElementContent.x)
            top = Math.min(top, dotElementContent.y)
            bottom = Math.max(bottom, dotElementContent.y)
        }
         
        this.baseLine.timeLineContent.dots = dots//this.getOrderDot(dots);
    
        this.baseLine.width = right - left;
        this.baseLine.height = this.title.y - (top + this.baseLine.y);

        this.baseLine.timeLineContent.lineContentWidth = this.baseLine.width;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLine.height;

        let moveX = left < 0 ? -left : 0
        let moveY = top < 0 ? -top : 0 
        this.baseLine.x = this.baseLine.x - moveX
        this.baseLine.y = this.baseLine.y - moveY
        for (let index = 0; index < dots.length; index++) {
            dots[index].x = dots[index].x + moveX
            dots[index].y = dots[index].y + moveY
        }

        for (let index = 0; index < dots.length; index++) {
            let x = dots[index].x + this.baseLine.x
            let y = dots[index].y + this.baseLine.y

            let timeNode = nodeList[index];
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
            head.isHidden = false;
            title.isHidden = false;
            desc.isHidden = false;
            let isCalculateLocation = false
            if (index > 0 && index < dots.length - 1 && 
                ((dots[index - 1].x < dots[index].x < dots[index + 1].x) || 
                (dots[index - 1].x > dots[index].x > dots[index + 1].x))) {
                isCalculateLocation = true
            }
            if (head.mindElementShape == MindElementShapeType.Ring2) {
                head.x = x - head.width/2;
                head.y = y - head.height;
            } else {
                head.x = x - head.width/2;
                head.y = y - head.height/2;
            }
            let prePoint = null
            if (index > 1) {
                prePoint = dots[index - 2]
            }
            let startPoint = null
            if (index == 0) {
                startPoint = new CGPoint(this.baseLine.width / 2, this.baseLine.height);
            } else {
                startPoint = dots[index - 1]
            }
            let endPoint = dots[index]
            
            let nextPoint = null
            if (index < dots.length - 1) {
                nextPoint = dots[index + 1]
            }
            let isMiddlePoint = false
            if (nextPoint != null && startPoint.x < endPoint.x && endPoint.x < nextPoint.x) {
                endPoint = dots[index + 1]
                startPoint = dots[index]
                prePoint = dots[index - 1]
                if (index < dots.length - 2) {
                    nextPoint = dots[index + 2]
                } else {
                    nextPoint = null
                }
                isMiddlePoint = true
            } else if (nextPoint != null && startPoint.x > endPoint.x && endPoint.x > nextPoint.x) {
                endPoint = dots[index + 1]
                startPoint = dots[index]
                prePoint = dots[index - 1]
                if (index < dots.length - 2) {
                    nextPoint = dots[index + 2]
                } else {
                    nextPoint = null
                }
                isMiddlePoint = true
            }

            let list = this.getTimeCubicLinePoints(prePoint, startPoint, endPoint, nextPoint)
            if (list.length == 4) {
                let precision = Math.abs(startPoint.x - endPoint.x);
                precision = Math.min(precision, 100);
                points = Util.bezierCalculate(list, precision);
                if (points.length > 0) {
                    let cx = -5000
                    let cy = 0
                    let space = 0
                    for (let j = 0; j < points.length; j++) {
                        const item = points[j];
                        if (index == dots.length - 1) {
                            let moveSpace = lineWidth/8
                            if (cx == -5000) {
                                cx = item.x
                                cy = item.y
                            } else if (dots[index].x > startPoint.x) { //右边
                                if (Math.abs(item.x - (dots[index].x - moveSpace)) < Math.abs(cx - (dots[index].x - moveSpace))) {
                                    cx = item.x
                                    cy = item.y
                                }
                            } else { //左边
                                if (Math.abs(item.x - (dots[index].x + moveSpace)) < Math.abs(cx - (dots[index].x + moveSpace))) {
                                    cx = item.x
                                    cy = item.y
                                }
                            }
                        } else if (isMiddlePoint) {
                            if (cx == -5000) {
                                cx = item.x
                                cy = item.y
                            } else if (Math.abs(item.x - dots[index].x) < Math.abs(cx - dots[index].x)) {
                                cx = item.x
                                cy = item.y
                            }
                        } else if (startPoint.x < endPoint.x) {
                            if (cx == -5000 || cx < item.x) {
                                cx = item.x
                                cy = item.y
                            }
                        } else {
                            if (cx == -5000 || cx > item.x) {
                                cx = item.x
                                cy = item.y
                            }
                        }
                    }
                    if (cx != -5000) {
                        if (isMiddlePoint) {
                            cx = cx + this.baseLine.x
                        } else if (startPoint.x < endPoint.x) {
                            cx = cx + this.baseLine.x - space
                        } else {
                            cx = cx + this.baseLine.x + space
                        }
                        cy = cy + this.baseLine.y
                        if (head.mindElementShape == MindElementShapeType.Ring2) {
                            head.x = cx - head.width/2;
                            head.y = cy - head.height;
                        } else {
                            head.x = cx - head.width/2;
                            head.y = cy - head.height/2;
                        }
                    }
                }
            } else {

            }
            if (index == dots.length - 1) {
                let titleEdgeInsets = this.getTextEdgeInsets(title);
                desc.x = head.x + this.getDesAndTitleHSpace(timeNode) - Math.max(title.width, desc.width) / 2 + head.width/2 - titleEdgeInsets.left;
                title.x = head.x - Math.max(title.width, desc.width) / 2 + head.width/2 - titleEdgeInsets.left;

                desc.y = head.y - this.BaseLayout.getNodeHeight(desc) - this.space;
                title.y = desc.y - this.BaseLayout.getNodeHeight(title) - this.titleDescMaxSpace - this.getDesAndTitleVSpace(timeNode);
            } else if (x > contentX) {
                desc.x = head.x + this.space + this.getDesAndTitleHSpace(timeNode) + head.width;
                title.x = head.x + this.space + head.width;
                if (isMiddlePoint) {
                    desc.y = head.y + this.BaseLayout.getNodeHeight(head) / 2 - this.BaseLayout.getNodeHeight(desc);                  
                    title.y = desc.y - this.BaseLayout.getNodeHeight(title) - this.titleDescMaxSpace - this.getDesAndTitleVSpace(timeNode);
                } else {
                    title.y = head.y + head.height / 2 - this.BaseLayout.getNodeHeight(title);
                    desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.getDesAndTitleVSpace(timeNode);
                }
            } else {
                desc.x = head.x - this.space * 2 + this.getDesAndTitleHSpace(timeNode) - nodeWidth;               
                title.x = head.x - this.space * 2 - nodeWidth;
                if (isMiddlePoint) {
                    desc.x = head.x + this.getDesAndTitleHSpace(timeNode) - Math.max(title.width, desc.width) / 2 + head.width/2;
                    title.x = head.x - Math.max(title.width, desc.width) / 2 + head.width/2;

                    desc.y = head.y - this.BaseLayout.getNodeHeight(desc) - this.space;
                    title.y = desc.y - this.BaseLayout.getNodeHeight(title) - this.titleDescMaxSpace - this.getDesAndTitleVSpace(timeNode);
                } else {
                    title.y = head.y + head.height / 2 - this.BaseLayout.getNodeHeight(title);
                    desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.getDesAndTitleVSpace(timeNode);
                }
            }
            
            if (this.isIncludesImage(desc)) {
                title.x = desc.x + (desc.width - title.width) / 2
            }
        }
    }

    isIncludesImage(data) {
        if (data == null || (data.imageContent == null && (data.iconElementContents == null || data.iconElementContents.length == 0))) {
            return false
        }
        if (data.textContent != null && data.textContent.text.length > 0) {
            //return false
        }
        if (data.generalizationContent != null && data.generalizationContent.text.length > 0) {
            //return false
        }
        return true
    }

    getTimeCubicLinePoints(prePoint, startPoint, endPoint, nextPoint) {
        let list = []
        let offsetDegrees = 20
        let offsetMinDegrees = 5
        let offsetRadius = 80
        let endIncreaseRadius = 100
        if (startPoint.x < endPoint.x) {            
            if (nextPoint == null || endPoint.x < nextPoint.x) {
                if (nextPoint != null && endPoint.x < nextPoint.x) {
                    return list
                }
                if (nextPoint == null) {
                    // if (prePoint.x < startPoint.x) {
                    //     nextPoint = new CGPoint(endPoint.x + 30, endPoint.y - 20)
                    // } else {
                    //     nextPoint = new CGPoint(endPoint.x + 30, endPoint.y - 20)
                    // }
                    nextPoint = new CGPoint(endPoint.x + 30, endPoint.y - 20)
                }
                if (endPoint.x > startPoint.x) {  
                    if (prePoint != null && prePoint.x < startPoint.x) {
                        startPoint = prePoint
                    }   
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    let c1 = Util.getCirclePoint(startPoint, degrees - offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    let c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                    list.push(startPoint, c1, c2, endPoint)
                }
            } else {
                let c1 = null
                let c2 = null
                if (prePoint == null) {
                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees, nextR + endIncreaseRadius)

                    let degrees = Util.getCircleDegreesInPoint(startPoint, c2)
                    let r = Util.getPointSpacing(startPoint, c2)
                    c1 = Util.getCirclePoint(startPoint, degrees, r/2)                     
                } else if (prePoint.x < startPoint.x) {
                    startPoint = prePoint
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees - offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                } else {
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees - offsetMinDegrees * 2, r * 0.25)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                }         
                if (c2 == null) {
                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees, nextR + endIncreaseRadius)
                }                
                list.push(startPoint, c1, c2, endPoint)
            }
        } else {
            
            if (nextPoint == null || endPoint.x > nextPoint.x) { 
                if (nextPoint != null && endPoint.x > nextPoint.x) {
                    return list
                }
                if (nextPoint == null) {
                    // if (prePoint.x > startPoint.x) {
                    //     nextPoint = new CGPoint(endPoint.x - 100, endPoint.y - 40)
                    // } else {
                    //     nextPoint = new CGPoint(endPoint.x - 100, endPoint.y - 40)
                    // } 
                    nextPoint = new CGPoint(endPoint.x - 100, endPoint.y - 40)               
                }
                
                if (endPoint.x < startPoint.x) {      
                    let c1 = null
                    let c2 = null                    
                    if (prePoint != null && prePoint.x > startPoint.x) {
                        startPoint = prePoint
                    } 
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees + offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                    list.push(startPoint, c1, c2, endPoint)
                }
            } else {
                let c1 = null
                let c2 = null
                if (prePoint == null) {
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees + offsetMinDegrees * 0, r/2)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees * 0.5, nextR + endIncreaseRadius)
                } else if (prePoint.x > startPoint.x) {
                    startPoint = prePoint
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees + offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                } else {
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees - offsetDegrees, offsetRadius)
                }
                if (c2 == null) {
                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees, nextR + endIncreaseRadius)
                }         
                list.push(startPoint, c1, c2, endPoint)
            }
        }
        return list
    }

    getOrderDot(dots) {
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1.y < data2.y) {
                return -1;
            } else if (data1.y == data2.y) {
                return 0;
            } else {
                return 1;
            }
        })
        return list;
    }



}
export default TimeLineSLayout
