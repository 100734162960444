import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType";

class ShapeTypeImag{
    constructor() {
        this.layoutStyleList= [
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_rectangle.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRectangleWhite.svg"),
            nodeType: MindElementShapeType.Corner,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_cornerShadow.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCornerShadowWhite.svg"),
            nodeType: MindElementShapeType.CornerShadow,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_radio.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRadioWhite.svg"),
            nodeType: MindElementShapeType.Semicircle,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_long_radio.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLongRadioWhite.svg"),
            nodeType: MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_edge_shape.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEdgeShapeWhite.svg"),
            nodeType: MindElementShapeType.Diamond,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_line.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLineWhite.svg"),
            nodeType: MindElementShapeType.Underline,
            },
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_two_line.svg"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/border_two_lineWhite.svg"),
              nodeType: MindElementShapeType.Two_Underline,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_garden.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderGardenWhite.svg"),
            nodeType: MindElementShapeType.Circular,
            },
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/book.svg"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/bookWhite.svg"),
              nodeType: MindElementShapeType.Book,
              },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRingWhite.svg"),
            nodeType: MindElementShapeType.Ring,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring2.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing2White.svg"),
            nodeType: MindElementShapeType.Ring2,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring2_UP.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing2_UPWhite.svg"),
            nodeType: MindElementShapeType.Ring2_UP,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring3.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing3White.svg"),
            nodeType: MindElementShapeType.Ring3,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_RightArrow2.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightArrow2White.svg"),
            nodeType: MindElementShapeType.RightArrow2,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_LeftArrow2.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftArrow2White.svg"),
            nodeType: MindElementShapeType.LeftArrow2,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Circular_Right_Top.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCircularRightTopWhite.svg"),
            nodeType: MindElementShapeType.Circular_Right_Top,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Three_Circular_Cloud.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderThreeCircularCloudWhite.svg"),
            nodeType: MindElementShapeType.Three_Circular_Cloud,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ellipse.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEllipseWhite.svg"),
            nodeType: MindElementShapeType.Ellipse,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ellipse_3D.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/border_Ellipse_3D_White.svg"),
            nodeType: MindElementShapeType.Ellipse_3D,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Cube.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCubeWhite.svg"),
            nodeType: MindElementShapeType.Cube,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Form.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderFormWhite.svg"),
            nodeType: MindElementShapeType.Form,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Corner_Right.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCornerRightWhite.svg"),
            nodeType: MindElementShapeType.Corner_Right,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_polygon.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderPolygonWhite.svg"),
            nodeType: MindElementShapeType.Hexagon,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_et_polygon.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEtPolygonWhite.svg"),
            nodeType: MindElementShapeType.Left_Right_Three_Side,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_right_long.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightLongWhite.svg"),
            nodeType: MindElementShapeType.Left_Straight_Right_Arrow,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_left_long.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftLongWhite.svg"),
            nodeType: MindElementShapeType.Right_Straight_Left_Arrow,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_right_arrow.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightArrowWhite.svg"),
            nodeType: MindElementShapeType.RightArrow,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_left_arrow.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftArrowWhite.svg"),
            nodeType: MindElementShapeType.LeftArrow,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_bubble.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderBubbleWhite.svg"),
            nodeType: MindElementShapeType.Cloud,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_triangle.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderTriangleWhite.svg"),
            nodeType: MindElementShapeType.Triangle,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_invertedTriangle.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderInvertedTriangleWhite.svg"),
            nodeType: MindElementShapeType.InvertedTriangle,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_rightTriangle.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightTriangleWhite.svg"),
            nodeType: MindElementShapeType.RightTriangle,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_leftTriangle.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftTriangleWhite.svg"),
            nodeType: MindElementShapeType.LeftTriangle,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_circularAndRight.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCircularAndRightWhite.svg"),
            nodeType: MindElementShapeType.CircularAndRight,
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_oblique_corner.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderObliqueCornerWhite.svg"),
            nodeType: MindElementShapeType.Oblique_corner,//斜角60矩形
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_large_braces.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLargeBracesWhite.svg"),
            nodeType: MindElementShapeType.Large_braces,//大括号
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_middle_brackets.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderMiddleBracketsWhite.svg"),
            nodeType: MindElementShapeType.Middle_brackets,//中括号
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_brackets.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderBracketsWhite.svg"),
            nodeType: MindElementShapeType.Brackets,//小括号
            },
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/flower_brackets.png"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/flower_brackets_white.png"),
              nodeType: MindElementShapeType.Flower_Brackets,//花括号
              },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_angle_braces.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderAngleBracesWhite.svg"),
            nodeType: MindElementShapeType.Angle_braces,//尖括号
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_heart.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderHeartWhite.svg"),
            nodeType: MindElementShapeType.Heart,//心
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_gemstone.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderGemstoneWhite.svg"),
            nodeType: MindElementShapeType.Gemstone,//宝石
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_star.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderStarWhite.svg"),
            nodeType: MindElementShapeType.Star,//五角星
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_octagonal.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderOctagonalWhite.svg"),
            nodeType: MindElementShapeType.Octagonal,//八边形
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_regular_hexagon.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRegularHexagonWhite.svg"),
            nodeType: MindElementShapeType.Regular_hexagon,//正六边形
            },
            {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_shield.svg"),
            layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderShieldWhite.svg"),
            nodeType: MindElementShapeType.Shield,//盾牌
            },
        ];
        this.explainLayoutStyleList =[
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_rectangle.svg"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_rectangle_white.svg"),
              nodeType: MindElementShapeType.Corner,
            },
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_radio.svg"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_radio_white.svg"),
              nodeType: MindElementShapeType.Semicircle,
            },
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_line.svg"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_line_white.svg"),
              nodeType: MindElementShapeType.Underline,
            },
            {
              layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_ellipse.svg"),
              layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_ellipse_white.svg"),
              nodeType: MindElementShapeType.Ellipse,
            },
          ];
    }
}

export default ShapeTypeImag