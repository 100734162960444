
const NodeLayoutType = {
    LAYOUT_UNKNOWN:0, //未知类型
    LAYOUT_RIGHT:1,
    LAYOUT_BOTTOM:2,
    LAYOUT_LEFT:3,
    LAYOUT_LEFT_RIGHT:4,
    LAYOUT_BUBBLE:5,
    LAYOUT_DOUBLE_BUBBLE:6,
    LAYOUT_VERTICAL:7, //时间轴竖向
    LAYOUT_HORIZONTAL:8, //时间轴横向
    LAYOUT_HORIZONTAL_S:9, //时间轴横向s型
    LAYOUT_FORM:10, //表格
    LAYOUT_TREE_RIGHT:11, //右树布局
    LAYOUT_TREE_LEFT:12, //左树布局
    LAYOUT_TREE_LEFT_RIGHT:13, //左右树布局
    LAYOUT_TRIANGLE:14, //三角形布局
    LAYOUT_BRACKETS_RIGHT:15,
    LAYOUT_LADDER:16, //梯形布局
    LAYOUT_HORIZONTAL_CRISSCROSS:17, //横向上线交错
    LAYOUT_RIGHT_LEFT:18, //右侧从顶部开始，左侧从顶部开始
    LAYOUT_RIGHT_LEFT_CLOCKWISE:19, //左右布局，从右侧顶部开始,顺时针
    LAYOUT_RADIATION_MAP:20, //左右布局，从右侧顶部开始,顺时针
    LAYOUT_TOP:21,
    LAYOUT_TOP_TREE_RIGHT:22, //顶部右树布局
    LAYOUT_TOP_TREE_LEFT:23, //顶部左树布局
    LAYOUT_TOP_TREE_LEFT_RIGHT:24, //顶部右树布局
    LAYOUT_HORIZONTAL_RIGHT:25, //横向布局
    LAYOUT_VERTICAL_RIGHT:26, //纵向布局
    LAYOUT_FISH_RIGHT:27, //横向布局
    LAYOUT_BRACKETS_LEFT:28, //括号布局
    LAYOUT_CIRCLE:29, //圆圈布局circle
    LAYOUT_TIME_ARC:30, //时间线孤型布局
    LAYOUT_FORM_HORIZONTAL:31, //表格横向
    LAYOUT_FREE_TREE:32, //四周自由树结构
    LAYOUT_BRIDGE:33, //桥型结构
    LAYOUT_RADIATE:34, //辐射图
    LAYOUT_FISH_LEFT:35, //鱼身在左边
    LAYOUT_TIME_S:36, //时间轴横向s型
    LAYOUT_HORIZONTAL_RING:37, //时间轴横向环型
}

export default NodeLayoutType