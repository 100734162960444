import Point from '../../../../viewmodel/core/base/Point'
import UiUtil from '../../../../utils/UiUtil'
import Util from '../../../../utils/Util'
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType"
import ImageShapeType from "../../../../viewmodel/datatype/ImageShapeType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import BaseMindElementDataCalculation from './BaseMindElementDataCalculation'
import MindElementShapeType from '../../../../viewmodel/datatype/MindElementShapeType'
import CGPoint from '../../../../viewmodel/core/base/basedata/CGPoint'
import Config from '../Config'
import Size from '../../../../viewmodel/core/base/Size'

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextIAndmageCalculation extends BaseMindElementDataCalculation {
    constructor (data,nodeLayoutType) {
        super(data,nodeLayoutType);
        this.UiUtil = new UiUtil();
        this.Util = Util;
    }

    getContentSize() {
        let width = 0;
        let height = 0;
        let imageContent = this.data.imageContent;
        let textMindElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        } else {
            return new Size(0, 0);
        }

        let textWidth = textMindElementContent.width;
        let textHeight = textMindElementContent.height;
        if (height < textHeight) {
            height = textHeight;
        }
        width += textWidth;

        if (imageContent != null) {
            if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
                width += imageContent.width;
                if (height < imageContent.height) {
                    height = imageContent.height;
                }
                imageContent.y = (height - imageContent.height) / 2;
                if (imageContent.y < this.imageSpace) {
                    imageContent.x = imageContent.y;
                } else {
                    imageContent.x = this.imageSpace;
                }
                width += imageContent.x;
                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                    width -= textWidth;
                } else {
                    textMindElementContent.x = imageContent.x + imageContent.width;
                    textMindElementContent.y = (height - textMindElementContent.height) / 2;
                }
            } else if (imageContent.layoutType == ImageLayoutType.LAYOUT_TOP) {
                height += imageContent.height;
                if (width < imageContent.width) {
                    width = imageContent.width;
                }
                imageContent.x = (width - imageContent.width) / 2;
                if (imageContent.x < this.imageSpace) {
                    imageContent.y = imageContent.x;
                } else {
                    imageContent.y = this.imageSpace;
                }
                height += imageContent.y;
                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                    height -= textHeight;
                } else {
                    textMindElementContent.x = (width - textMindElementContent.width) / 2;
                    textMindElementContent.y = imageContent.y + imageContent.height;
                }
            }
        } else {
            if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                let iconsRect = this.caluleIconElementRect(this.UiUtil.dip2px(8), 0);
                textMindElementContent.x = iconsRect.size.getWidth() - this.iconSpace;
                width +=iconsRect.size.getWidth() - this.iconSpace;
            } else {
                let iconsRect = this.caluleIconElementRect(this.UiUtil.dip2px(4), 0);
                textMindElementContent.x = (iconsRect.size.getWidth());
                width += (iconsRect.size.getWidth());
            }
            textMindElementContent.y = 0;

        }
        return new Size(width, height);
    }

    calculate() {
        let width = 0;
        let height = 0;
        let imageContent = this.data.imageContent;
        let textMindElementContent;
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        } else {
            return;
        }

        let textWidth = textMindElementContent.width;
        let textHeight = textMindElementContent.height;
        if (height < textHeight) {
            height = textHeight;
        }
        width += textWidth;

        if (imageContent != null) {
            if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
                width += imageContent.width;
                if (height < imageContent.height) {
                    height = imageContent.height;
                }
                imageContent.y = (height - imageContent.height) / 2;
                if (imageContent.y < this.imageSpace) {
                    imageContent.x = imageContent.y;
                } else {
                    imageContent.x = this.imageSpace;
                }
                width += imageContent.x;
                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                    width -= textWidth;
                } else {
                    textMindElementContent.x = imageContent.x + imageContent.width;
                    textMindElementContent.y = (height - textMindElementContent.height) / 2;
                }
            } else if (imageContent.layoutType == ImageLayoutType.LAYOUT_TOP) {
                height += imageContent.height;
                if (width < imageContent.width) {
                    width = imageContent.width;
                }
                imageContent.x = (width - imageContent.width) / 2;
                if (imageContent.x < this.imageSpace) {
                    imageContent.y = imageContent.x;
                } else {
                    imageContent.y = this.imageSpace;
                }
                height += imageContent.y;
                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                    height -= textHeight;
                } else {
                    textMindElementContent.x = (width - textMindElementContent.width) / 2;
                    textMindElementContent.y = imageContent.y + imageContent.height;
                }
            }
        } else {
            if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                let iconsRect = this.caluleIconElementRect((new UiUtil).dip2px(8), 0);
                textMindElementContent.x = iconsRect.size.getWidth() - iconSpace;
                width +=iconsRect.size.getWidth() - iconSpace;
            } else {
                let iconsRect = this.caluleIconElementRect((new UiUtil).dip2px(4), 0);
                textMindElementContent.x = (iconsRect.size.getWidth());
                width += (iconsRect.size.getWidth());
            }
            textMindElementContent.y = 0;

        }

        let left = 0;
        let top = 0;
        let right = 0;
        let bottom = 0;

        let textY = this.getTextTop();
        let textX = this.getTextLeft();


        //MindElementShapeType.Semicircle:
        let radius;
        let x;

        //MindElementShapeType.Diamond:
        let startPoint;
        let endPoint;
        let angle;

        //MindElementShapeType.LeftArrow2:
        let arrowAngle;
        let withoutPace;
        let arrowHeight;

        //MindElementShapeType.Ellipse_3D:
        let ellipseHeight;

        // MindElementShapeType.Cube:
        let cubeHeight;

        // MindElementShapeType.Form:
        let formHeight;

        //MindElementShapeType.Three_Circular_Cloud:
        let leftCloudAngle;
        let rightCloudAngle;
        let leftIntersectionPointX;
        let leftIntersectionPointY;
        let rightIntersectionPointX;
        let rightIntersectionPointY;
        let leftRadius;
        let rightRadius;
        let offset;

        //MindElementShapeType.Triangle:
        let triangleContentWidth;
        let triangleContentHeight;
        let topHeight;
        let triangleHeight;
        let sideWidth;

        //MindElementShapeType.InvertedTriangle:
        let bottomHeight

        // MindElementShapeType.RightTriangle:
        let rightWidth;
        let triangleWidth;

        // MindElementShapeType.LeftTriangle:
        let leftWidth;

        switch (this.data.mindElementShape) {
            case MindElementShapeType.Corner:
            case MindElementShapeType.CornerShadow:
            case MindElementShapeType.Underline:
                if (this.data.type == MindElementType.SON_SUBJECT) {
                    left = right = this.UiUtil.dip2px(2);
                    top = bottom = this.UiUtil.dip2px(2);
                } else {
                    left = right = this.UiUtil.dip2px(2);
                    top = bottom = this.UiUtil.dip2px(2);
                }
                break;
            case MindElementShapeType.Two_Underline:
                if (this.data.type == MindElementType.SON_SUBJECT) {
                    left = right = this.UiUtil.dip2px(2);
                    top = this.UiUtil.dip2px(2);
                    bottom = this.UiUtil.dip2px(4) + this.data.borderWidth;
                } else {
                    left = right = this.UiUtil.dip2px(2);
                    top = this.UiUtil.dip2px(2);
                    bottom = this.UiUtil.dip2px(4) + this.data.borderWidth;
                }

                break;
            case MindElementShapeType.Large_braces:
                left = right = this.UiUtil.dip2px(4);
                top = bottom = this.UiUtil.dip2px(2);
                break;
            case MindElementShapeType.Brackets:
                left = right = this.UiUtil.dip2px(6);
                top = bottom = this.UiUtil.dip2px(2);
                break;
            case MindElementShapeType.Flower_Brackets:
                left = right = this.UiUtil.dip2px(6);
                top = bottom = this.UiUtil.dip2px(2);
                break;
            case MindElementShapeType.Angle_braces:
                left = right = this.UiUtil.dip2px(10);
                top = bottom = this.UiUtil.dip2px(-3);
                break;
            case MindElementShapeType.Middle_brackets:
                left = right = this.UiUtil.dip2px(10);
                top = bottom = this.UiUtil.dip2px(3);
                break;
            case MindElementShapeType.Corner_Right:
                if (this.data.type == MindElementType.SON_SUBJECT) {
                    left = right = this.UiUtil.dip2px(4);
                    top = bottom = this.UiUtil.dip2px(2);
                } else {
                    left = right = this.UiUtil.dip2px(6);
                    top = bottom = this.UiUtil.dip2px(2);
                }
                break;
            case MindElementShapeType.Semicircle:
                if (text.length == 0 && !this.data.isEdit && imageContent.shapeType == ImageShapeType.Circular) {
                    left = right = 0;
                    top = bottom = 0;
                    break;
                }
                radius = height / 2;
                left = right = radius;
                top = bottom = this.UiUtil.dip2px(2);
                break;
            case MindElementShapeType.Diamond:

                if (text.length == 0 && !this.data.isEdit && imageContent.shapeType == ImageShapeType.Circular.Diamond) {
                    left = right = 0;
                    top = bottom = 0;
                    break;
                }

                startPoint = new CGPoint().init(0, (height / 2));
                endPoint = new CGPoint().init((width / 2), 0);
                angle = this.Util.getAnglesWithThreePoints(startPoint, endPoint, new Point(startPoint.x, endPoint.y));
                x = ((height / 2 - textY) / (Math.tan(angle)));

                if (textX <= x) {
                    left = right = x - textX;
                    top = bottom = left;
                }
                break;
            case MindElementShapeType.Cloud:
                left = right = this.UiUtil.dip2px(2);
                top = bottom = this.UiUtil.dip2px(2);
                break;
            case MindElementShapeType.CircularAndRight:
                left = right = this.UiUtil.dip2px(5);
                top = bottom = this.UiUtil.dip2px(2);
                break;
            case MindElementShapeType.Circular:
            case MindElementShapeType.Circular_Right_Top:
            case MindElementShapeType.Book:
                if ((text.length == 0 && !this.data.isEdit && imageContent.shapeType == ImageShapeType.Circular)) {
                    left = right = 0;
                    top = bottom = 0;
                    break;
                }
                radius = Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2;
                left = right = radius - width / 2;
                top = bottom = radius - height / 2;
            break;
            case MindElementShapeType.Ring:
            case MindElementShapeType.Ring3:
                if ((text.length == 0 && !this.data.isEdit && imageContent.shapeType == ImageShapeType.Circular)) {
                    left = right = 0;
                    top = bottom = 0;
                    break;
                }
                radius = Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2 + Config.Node_Ring_Space;
                left = right = radius - width / 2;
                top = bottom = radius - height / 2;
                break;
            case MindElementShapeType.Ring2:
                if ((text.length == 0 && !this.data.isEdit && imageContent.shapeType == ImageShapeType.Circular)) {
                    radius = width / 2 + Config.Node_Ring_Space;
                    left = right = radius - width / 2;
                    top = radius - height / 2;
                    bottom = Math.sqrt(Math.pow(radius, 2) + Math.pow(radius, 2)) - height / 2;
                    break;
                }
                radius = Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2 + Config.Node_Ring_Space;
                left = right = radius - width / 2;
                top = radius - height / 2;
                bottom = Math.sqrt(Math.pow(radius, 2) + Math.pow(radius, 2)) - height / 2;
                break;
            case MindElementShapeType.Ring2_UP:
                if ((text.length == 0 && !this.data.isEdit && imageContent.shapeType == ImageShapeType.Circular)) {
                    radius = width / 2 + Config.Node_Ring_Space;
                    left = right = radius - width / 2;
                    bottom = radius - height / 2;
                    top = Math.sqrt(Math.pow(radius, 2) + Math.pow(radius, 2)) - height / 2;
                    break;
                }
                radius = Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2 + Config.Node_Ring_Space;
                left = right = radius - width / 2;
                bottom = radius - height / 2;
                top = Math.sqrt(Math.pow(radius, 2) + Math.pow(radius, 2)) - height / 2;
                break;
            case MindElementShapeType.Hexagon:
            case MindElementShapeType.Left_Right_Three_Side:
            case MindElementShapeType.Left_Straight_Right_Arrow:
            case MindElementShapeType.Right_Straight_Left_Arrow:
            case MindElementShapeType.RightArrow:
            case MindElementShapeType.LeftArrow:
                if (this.data.type == MindElementType.SON_SUBJECT) {
                    top = bottom = this.UiUtil.dip2px(1);
                } else {
                    top = bottom = this.UiUtil.dip2px(2);
                }
                left = right = (height + top) / 2;
            break;
            case MindElementShapeType.LeftArrow2:
                arrowAngle = 80;
                withoutPace = height/2 / this.Util.tan(arrowAngle/2);
                arrowHeight = (this.Util.tan(arrowAngle/2) * withoutPace * 2 * 2);
                top = bottom = (height * 0.8 + 3);
                left = (withoutPace + withoutPace / 2);
                right = withoutPace;
                break;
            case MindElementShapeType.RightArrow2:
                arrowAngle = 80;
                withoutPace = height/2 / this.Util.tan(arrowAngle/2);
                arrowHeight = (this.Util.tan(arrowAngle/2) * withoutPace * 2 * 2);
                top = bottom = (height * 0.8 + 3);
                right = (withoutPace + withoutPace / 2);
                left = withoutPace;
                break;
            case MindElementShapeType.Ellipse:
                radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height,2)) / 2;

                if (Math.abs(width - height) < this.UiUtil.dip2px(18)) {
                    left = right = radius - width / 2;
                    top = bottom = radius - height / 2;
                } else if (width > height) {
                    left = right = radius - width / 2;
                    top = bottom = (radius - height/2) / 2;
                } else {
                    left = right = (radius - width/2) / 2;
                    top = bottom = radius - height / 2;
                }
                break;
            case MindElementShapeType.Ellipse_3D:
                ellipseHeight = Config.getEllipse3DHeight(this.data, width, height);
                left = right = this.UiUtil.dip2px(8);
                top = ellipseHeight + (height > this.UiUtil.dip2px(36) ? this.UiUtil.dip2px(10) : this.UiUtil.dip2px(6));
                bottom = ellipseHeight / 2 + (height > this.UiUtil.dip2px(36) ? this.UiUtil.dip2px(6) : this.UiUtil.dip2px(4));
                break;
            case MindElementShapeType.Cube:
                cubeHeight = Config.getCube3DHeight(this.data, width, height);
                left = this.UiUtil.dip2px(8);
                right = cubeHeight + this.UiUtil.dip2px(8);
                top = cubeHeight + this.UiUtil.dip2px(6);
                bottom = this.UiUtil.dip2px(6);
                break;
            case MindElementShapeType.Form:
                formHeight = Config.getFormHeight(this.data, width, height);
                left = right = this.UiUtil.dip2px(8);
                top = this.UiUtil.dip2px(8);
                bottom = formHeight + this.UiUtil.dip2px(6);
                break;
            case MindElementShapeType.Three_Circular_Cloud:
                radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2;
                leftCloudAngle = 16;
                rightCloudAngle = 8;
                leftIntersectionPointX = radius + ((radius) * this.Util.cosd(180 + leftCloudAngle));
                leftIntersectionPointY = radius + ((radius) *  this.Util.sind(180 + leftCloudAngle));
                rightIntersectionPointX = radius + ((radius) *  this.Util.cosd(360 - rightCloudAngle));
                rightIntersectionPointY = radius + ((radius) *  this.Util.sind(360 - rightCloudAngle));
                leftRadius = (radius * 2 - leftIntersectionPointY) / 2;
                rightRadius = (radius * 2 - rightIntersectionPointY) / 2;

                left = radius - width / 2 + leftRadius - leftIntersectionPointX;
                right = radius - width / 2 + rightRadius - (radius * 2 - rightIntersectionPointX);
                offset = ((radius - height / 2) * 0.4);
                top = radius - height / 2 + offset;
                bottom = radius - height / 2 - offset;
                break;
            case MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle:
                left = right = this.UiUtil.dip2px(2);
                top = bottom = this.UiUtil.dip2px(3);
                break;
            case MindElementShapeType.Triangle:
                triangleContentWidth = width + this.UiUtil.dip2px(2);
                triangleContentHeight = height;
                topHeight = (triangleContentWidth / 2) / Math.tan(Util.toRadians(30));
                triangleHeight = topHeight + triangleContentHeight + topHeight /2;
                sideWidth = triangleHeight / Math.sin(Util.toRadians(60));

                left = right = (sideWidth - width) / 2;
                top = topHeight;
                bottom = topHeight/2;
                break;
            case MindElementShapeType.InvertedTriangle:
                triangleContentWidth = width + this.UiUtil.dip2px(4);
                triangleContentHeight = height;
                bottomHeight = (triangleContentWidth / 2) / Math.tan(Util.toRadians(30));
                triangleHeight = bottomHeight + triangleContentHeight + bottomHeight / 2;
                sideWidth = triangleHeight / Math.sin(Util.toRadians(60));

                left = right = (sideWidth - width) / 2;
                top = bottomHeight / 2;
                bottom = bottomHeight;
                break;
            case MindElementShapeType.RightTriangle:
                triangleContentWidth = width;
                triangleContentHeight = height + this.UiUtil.dip2px(4);
                rightWidth = (triangleContentHeight / 2) / Math.tan(Util.toRadians(30));
                triangleWidth = rightWidth + triangleContentWidth + rightWidth / 2;
                sideWidth = triangleWidth / Math.sin(Util.toRadians(60));

                left =(rightWidth) / 2;
                right = (rightWidth);
                top = bottom = (sideWidth - height) / 2;
                break;
            case MindElementShapeType.LeftTriangle:
                triangleContentWidth = width;
                triangleContentHeight = height + this.UiUtil.dip2px(4);
                leftWidth = (triangleContentHeight / 2) / Math.tan(Util.toRadians(30));
                triangleWidth = leftWidth + triangleContentWidth + leftWidth / 2;
                sideWidth = triangleWidth / Math.sin(Util.toRadians(60));

                left = leftWidth;
                right = leftWidth/2;
                top = bottom = (sideWidth - height) / 2;
                break;
            case MindElementShapeType.Oblique_corner:
                let obliqueAngle = 60;
                top = bottom = this.UiUtil.dip2px(2);
                left = ((height + top*2) / this.Util.tan(obliqueAngle));
                right = left;
                break;
            case MindElementShapeType.Heart:
                radius = Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2;
                left = right = (radius * 1.0855) - width / 2;
                top = ((radius * 2 - height) * 0.45);
                bottom = ((radius * 2 - height) * 0.55);
                break;
            case MindElementShapeType.Gemstone:
                radius = Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2;
                left = right = (radius * 1.206) - width / 2;
                top = ((radius * 2 - height) * 0.200);
                bottom = ((radius * 2 - height) * 0.800);
                break;
            case MindElementShapeType.Star:
                radius = (Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2 * 1.58);
                left = right = (radius) - width / 2;
                top = ((radius * 2 - height))/2;
                bottom = ((radius * 2 - height))/2;
                break;
            case MindElementShapeType.Octagonal:
                radius = (Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2 * 1.18);
                left = right = (radius) - width / 2;
                top = ((radius * 2 - height))/2;
                bottom = ((radius * 2 - height))/2;
                break;
            case MindElementShapeType.Regular_hexagon:
                let margin = 10
                radius = (Math.sqrt(Math.pow(width,2) + Math.pow(height,2)) / 2) + margin;
                let hSpace = radius - Util.sind(60) * radius
                left = right = radius - width / 2;
                top = (radius * 2 - height)/2 - hSpace;
                bottom = (radius * 2 - height)/2 - hSpace;
                break;
            case MindElementShapeType.Shield:
                let scale = 0.81;
                var beforHeight = height / (0.6434 - 0.089);
                var beforWidth = width + this.UiUtil.dip2px(10) * 2;
                if (beforWidth/beforHeight > scale) {
                    left = right = this.UiUtil.dip2px(10);
                    beforHeight = beforWidth / scale;
                    let spaceV = (beforHeight * (0.6434 - 0.089) - height) / 2;
                    top = (beforHeight * 0.089) + spaceV;
                    bottom = (beforHeight * (1 - 0.6434)) + spaceV;
                } else {
                    top = (beforHeight * 0.089);
                    bottom =(beforHeight * (1 - 0.6434));
                    left = right = (beforHeight * scale - width) / 2;
                }
                break;
            case MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner:
                if (this.data.type == MindElementType.MAIN_SUBJECT) {
                    left = right = this.UiUtil.dip2px(8);
                    top = bottom = this.UiUtil.dip2px(12);
                } else if (this.data.type == MindElementType.SON_SUBJECT) {
                    left = right = this.UiUtil.dip2px(4);
                    top = bottom = this.UiUtil.dip2px(6);
                } else {
                    left = right = this.UiUtil.dip2px(6);
                    top = bottom = this.UiUtil.dip2px(10);
                }
                break;
            default:
                left = right = 0;
                top = bottom = 0;
        }
        width = width + left + right;
        height = height + top + bottom;
        textMindElementContent.x = textMindElementContent.x + left;
        textMindElementContent.y = textMindElementContent.y + top;
        imageContent.x = imageContent.x + left;
        imageContent.y = imageContent.y + top;
        this.data.width = width;
        this.data.height = height;
        this.setCustomSize(this.data.customWidth);


    }
}

export default TextIAndmageCalculation