const { default: strings } = require("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'Template';
stringMap [strings.Mind_Edit_Main_Topic] = 'Paksa ng Sangay';
stringMap [strings.Global_Add_Free_Node_Tips] = 'Mag-click sa screen upang piliin ang lokasyon na idaragdag';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'I-double click ang screen upang piliin ang lokasyon na idaragdag';
stringMap [strings.Global_Add_Relationship_Tips] = 'Piliin ang target na kailangang maiugnay';
stringMap [strings.Global_Add_Target_Tips] = 'Mangyaring pumili ng isang node upang magdagdag ng mga bagay';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'Ang bagay na ito ay naiugnay na sa iba pang mga mapa';
stringMap [strings.Mind_Edit_Main_Idea] = 'Central Theme';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'Ang nauugnay na mapa ay walang';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'Priority';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'Progress';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'Mga Bituin';
stringMap [strings.Mind_Style_Add_Icon_Week] = 'linggo';
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Portrait';
stringMap [strings.Establish] = 'Lumikha';
stringMap [strings.Create_From_Template] = 'Lumikha mula sa template';
stringMap [strings.Common] = 'Karaniwan';
stringMap [strings.Function_List] = 'Listahan ng Pag-andar';
stringMap [strings.Recent_Updates] = 'Kamakailang nai-update';
stringMap [strings.The_Latest_To_Delete] = 'Kamakailang tinanggal';
stringMap [strings.Setting_Up] = 'Makipag-ugnay sa amin';
stringMap [strings.Usinghelp] = 'Paggamit ng tulong';
stringMap [strings.The_Download_Client] = 'Mag-download ng client / mobile na bersyon';
stringMap [strings.Global_Purchase] = 'Bumili';
stringMap [strings.Global_Login] = 'Pag-login';
stringMap [strings.My_map] = 'Aking Mapa';
stringMap [strings.Create_Map] = 'Lumikha ng isang mapa';
stringMap [strings.Storm] = 'Bagyo';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'Karaniwan \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'Characteristic';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'Center ng Organisasyon';
stringMap [strings.Mind_Edit_Free_Topic] = 'Libreng Paksa';
stringMap [strings.Mind_Edit_Sub_Topic] = 'Sub Paksa';
stringMap [strings.Mind_Organization_Main_Topic] = 'Kagawaran';
stringMap [strings.Mind_Edit_Main_Topic] = 'Paksa ng Sangay';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'Pagpaplano';
stringMap [strings.Mind_Time_Edit_Head] = 'Oras';
stringMap [strings.Mind_Time_Edit_Title] = 'Plano';
stringMap [strings.Mind_Edit_Details] = 'Detalyadong paglalarawan';
stringMap [strings.Checking_Data] = 'Sinusuri ang data';
stringMap [strings.Setting_Information] = 'Pagtatakda ng Impormasyon';
stringMap [strings.Sign_Out] = 'Mag-sign out';

// listahan ng pag-andar
stringMap [strings.Create_a_Mind_Map] = 'Lumikha ng isang Mapa ng Pag-iisip';
stringMap [strings.Create_Mind_Map_1] = '[1] Lumikha ng isang mapa na may labing-apat na mga layout (two-way / kanan / kaliwa / organisasyon istraktura / solong tsart ng bubble / dobleng tsart ng bubble / kaliwang puno ng mapa / kanang puno ng mapa / Dalawang-daan na diagram ng puno / talahanayan / pahalang na timeline / patayong timeline / S timeline / pyramid) ';
stringMap [strings.Create_Mind_Map_2] = '[2] Maaaring magamit ang mga template upang mabilis na makalikha ng mga mapa (isang malaking bilang ng mga sanggunian ng nilalaman ng template)';
stringMap [strings.Create_Mind_Map_3] = '[3] I-edit ang mapa na may siyam na uri ng mga istilo ng linya';
stringMap [strings.Create_Mind_Map_4] = '[4] Gumawa ng siyam na pagbabago sa layout at mga pag-edit sa mapa';
stringMap [strings.Create_Mind_Map_5] = '[5] Magdagdag ng mga node ng bata sa target na node at magdagdag ng mga node sa parehong antas (itaas at ibaba)';
stringMap [strings.Create_Mind_Map_6] = '[6] Buod: Idagdag ang [Buod] upang ilarawan ang isang solong node / maraming mga node';
stringMap [strings.Create_Mind_Map_7] = '[7] I-block: idagdag ang [block] sa isang solong node / maraming mga node box ng mapa upang ma-modyulado';
stringMap [strings.Create_Mind_Map_8] = '[8] Magdagdag ng libreng node';
stringMap [strings.Create_Mind_Map_9] = '[9] Lumikha ng isang samahan ng apat na mga istilo ng linya sa pagitan ng anumang mga node';
stringMap [strings.Create_Mind_Map_10] = '[10] Ipasok ang mga larawan (lokal + na materyal na aklatan), mga icon, tala, mga link sa node';
stringMap [strings.Create_Mind_Map_11] = '[11] Kopyahin / i-paste ang node, kopyahin / i-paste ang istilo';
stringMap [strings.Create_Mind_Map_12] = '[12] Kanselahin, ibalik at kanselahin';
stringMap [strings.Create_Mind_Map_13] = '[13] Magagamit ang maraming pagpipilian, maraming mga node ang maaaring patakbuhin nang sabay-sabay';
stringMap [strings.Create_Mind_Map_14] = '[14] Maaaring maipasok ang graphic graph';
stringMap [strings.Create_Mind_Map_15] = '[15] Ang buong mapa ay maaaring maitala';
stringMap [strings.Create_Mind_Map_16] = '[16] Kopyahin ang mapa';
stringMap [strings.Create_Mind_Map_17] = '[17] Maaari mong kopyahin ang mapa o ang buong mapa sa mapa ng iba pang mga file';
stringMap [strings.Create_Mind_Map_18] = '[18] Ang isang mapa ay maaaring malikha pagkatapos ng buod';
stringMap [strings.Create_Mind_Map_19] = '[19] Maaaring mapalitan ang pag-edit ng mapa sa panahon ng pag-edit (computer side)';
stringMap [strings.Create_Mind_Map_20] = '[20] Mapa sa pag-edit ng keyboard ng Bluetooth: \ n \ t [Command] + [a] Piliin ang master node \ n \ t [Command] + [←] [↑] [→] [↓ ] Baguhin ang target na pagpipilian o ilipat ang mapa \ n \ t [Command] + [e] I-edit ang target \ n \ t [Command] + [w] Lumikha ng node ng bata \ n \ t [Command] + [s] Lumikha ng target na kapatid na node ( Nangungunang) \ n \ t [Command] + [d] Lumikha ng target na kapatid na node (ibaba) \ n \ t [Command] + [q] Lumabas sa napiling target \ n \ t [Command] + [-] [+] Mag-zoom mapa ';

stringMap [strings.Beautify_The_Mind_Map] = 'Pagandahin ang Mapa ng Isip';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] Itakda ang kulay ng background ng mind map';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] Itakda ang kapal at kulay ng buong linya ng mapa, kung ito ay naka-tapered o hindi';
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] Itakda ang kulay ng pagpuno ng teksto, kulay ng hangganan, hangganan 13 na hugis, lapad ng hangganan, epekto ng anino';
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] Itakda ang lahat o bahagi ng kulay ng linya at lapad ng mapa';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] Itakda ang laki ng font ng teksto, naka-bold, italic, tanggalin ang istilo';
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] Itakda ang laki ng larawan, posisyon, at istilo ng hugis';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] Magdagdag ng mga icon ng pitong kategorya (karaniwan / 3D / pag-aaral / trabaho / tao / hayop / pagkain) at itakda ang laki / lokasyon';
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] Magdagdag ng isang isang-click na sanggunian ng estilo sa buong mapa';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Map ng Pamamahala ng Grupo';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] Ang isang naisapersonal na background (materyal na aklatan) ay maaaring maidagdag sa buong mapa';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] Indibidwal na mga hangganan o background (materyal na aklatan) ay maaaring idagdag sa isang solong node';
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] Ang haba ng node ay maaaring ma-drag';


stringMap [strings.Map_Output] = 'Output ng mapa';
stringMap [strings.Map_Output_1] = '[1] I-save ang larawan';
stringMap [strings.Map_Output_2] = '[2] I-save ang PDF';
stringMap [strings.Map_Output_3] = '[3] Ibahagi ang link';
stringMap [strings.Map_Output_4] = '[4] Ibahagi sa bagyo';
stringMap [strings.Map_Output_4] = '[5] I-print';

stringMap [strings.Work_tought] = 'Magtrabaho sa pakikipagtulungan';
stringMap [strings.Work_tought_1] = '[1] Lumikha ng isang nakabahaging pangkat (label ng pangkat, anunsyo ng pangkat)';
stringMap [strings.Work_tought_2] = '[2] Mga bagong nakabahaging miyembro ng pangkat';
stringMap [strings.Work_tought_3] = '【3】 Maramihang mga miyembro sa pangkat ang nag-e-edit ng parehong mapa nang magkasama';
stringMap [strings.Work_tought_4] = '[4] Mag-upload ng mga nakabahaging file sa loob ng pangkat (salita / excle / pdf)';
stringMap [strings.Work_tought_5] = '[5] Maaari kang mag-iwan ng mensahe sa pangkat';
stringMap [strings.Work_tought_6] = '[6] Mga setting ng awtoridad ng pamamahala ng ibinahaging pangkat';
stringMap [strings.Work_tought_7] = '[7] Disband Group';

stringMap [strings.Work_tought] = 'Creative Sharing (Storm)';
stringMap [strings.Community_Sharing_1] = '[1] Maaari mong ibahagi ang iyong nilikha sa bagyo upang maraming tao ang makakakita nito';
stringMap [strings.Community_Sharing_1] = '[2] Maaari kang pumili ng mga tag upang matingnan ang nauugnay na nilalaman ng bagyo';
stringMap [strings.Community_Sharing_1] = '[3] Maaari kang magustuhan at magkomento sa mga gawa ng bagyo';

// Kamakailan-lamang na-update
stringMap [strings.Recently_Updated_Title_1] = 'Lumikha ng isang personal na account';
stringMap [strings.Recently_Updated_Title_2] = 'Massive Template Community Base';
stringMap [strings.Recently_Updated_Title_3] = 'Sumuporta sa maraming mga platform';
stringMap [strings.Recently_Updated_Title_4] = 'Ipasadya ang bawat detalye';
stringMap [strings.Recently_Updated_Title_5] = 'Personal na istilo ng library ng istilo';
stringMap [strings.Recently_Updated_Title_6] = 'Map output';
stringMap [strings.Recently_Updated_Title_7] = 'Cloud Storage';
stringMap [strings.Recently_Updated_Title_8] = 'Labinlimang uri ng mga mapa';


stringMap [strings.Recently_Updated_Content_1] = 'Lumikha ng isang personal na account at i-save ang nilikha na mapa kasama ang account';
stringMap [strings.Recently_Updated_Content_2] = 'Ang isang malaking bilang ng mga base ng kaalaman sa template ay direktang na-quote upang mapabuti ang kahusayan sa pag-aaral';
stringMap [strings.Recently_Updated_Content_3] = 'Suporta sa web bersyon online na paggawa, IOS mobile terminal, Mac terminal, Android mobile terminal, Android tablet multi-platform use';
stringMap [strings.Recently_Updated_Content_4] = 'Maaari mong pagandahin ang disenyo ng teksto, background, mga linya, mga icon ng larawan, atbp ayon sa mga personal na kagustuhan';
stringMap [strings.Recently_Updated_Content_5] = 'Personal na istilo ng library ng istilo ay direktang nasipi, inaalis ang problema ng pagtutugma ng kulay';
stringMap [strings.Recently_Updated_Content_6] = 'Maaari kang makatipid ng mga larawan, format na PDF, at direktang magbahagi sa mga kaibigan';
stringMap [strings.Recently_Updated_Content_7] = 'Malakas na suporta sa cloud storage, hindi na kailangang magalala tungkol sa pagkawala ng data';
stringMap [strings.Recently_Updated_Content_8] = 'Dalawang-daan, kanan, kaliwa, istrakturang pang-organisasyon, solong tsart ng bubble, doble na tsart ng bubble, kaliwang tsart ng puno, kanang tsart ng puno, tsart ng puno ng dalawang daan, mesa, pahalang na timeline, Vertical timeline, S timeline, walong mga layout ay maaaring mabago nang gusto ';

// Kamakailang natanggal
stringMap [strings.Mind_Mindmap_Deleted_Title] = 'Kamakailang tinanggal';
stringMap[strings.Mind_Mindmap_Deleted_Recovery] = 'Recovery';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'Tanggalin';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'Pangalan ng file';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'Pagkatapos ng pagtanggal dito, tatanggalin ito nang permanente';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'Operation';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'Paghahanap ng kamakailang tinanggal na mapa';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = 'Tanggalin ang Petsa';

//Makipag-ugnayan sa amin
stringMap [strings.Contact_Us_No_Public] = 'Public Account';
stringMap [strings.Contact_Us_QQ_Group] = 'QQ Group';
stringMap [strings.Contact_Us_Message_Board] = 'Lupon ng Mensahe';
stringMap [strings.Contact_Us_Username] = 'Pangalan';
stringMap [strings.Contact_Us_Email] = 'Email';
stringMap [strings.Contact_Us_Voicemail] = 'Mangyaring iwanan ang iyong mahahalagang mga puna';
stringMap [strings.Contact_Us_Send_Email] = 'Magpadala ng Email';

// verify message
stringMap [strings.Input_Username_isNull] = 'Ang pangalan ay hindi maaaring walang laman';
stringMap [strings.Input_Email_isNull] = 'Mangyaring ipasok ang tamang email address';
stringMap [strings.Input_Voicemail_isNull] = 'Mangyaring ipasok ang nilalaman na naipadala';
stringMap [strings.Input_Introduction_isNull] = 'Ang pagpapakilala ay hindi maaaring walang laman';
stringMap [strings.Input_Title_isNull] = 'Ang pamagat ay hindi maaaring walang laman';

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'Mangyaring ipasok ang tamang numero ng telepono';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'Mangyaring ipasok ang verification code! ';
stringMap [strings.Input_Please_Enter_The_Password] = 'Mangyaring ipasok ang password! ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'Ang format ng password ay hindi tama! ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'Mangyaring maglagay ng bagong password! ';
stringMap [strings.Input_Confirm_The_New_Password] = 'Mangyaring ipasok ang password sa pagkumpirma! ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'Hindi magkatugma ang mga password! ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'Mangyaring ipasok ang tamang verification code! ';
stringMap [strings.Input_The_Verification_Code_Error] = 'Error sa pag-verify ng code! ';

// Paggamit ng tulong
stringMap [strings.Mind_Usinghelp_1] = 'Lumikha ng isang mapa ng isip';
stringMap [strings.Mind_Usinghelp_2] = 'Paano magdagdag ng isang node';
stringMap [strings.Mind_Usinghelp_3] = 'Lumikha ng isang libreng node';
stringMap [strings.Mind_Usinghelp_4] = 'Itaguyod ang samahan';
stringMap [strings.Mind_Usinghelp_5] = 'pagandahin ang canvas';
stringMap [strings.Mind_Usinghelp_6] = 'pagandahin ang teksto';
stringMap [strings.Mind_Usinghelp_7] = 'Ipasok ang mga tala, mga link';
stringMap [strings.Mind_Usinghelp_8] = 'Ipasok ang icon';
stringMap [strings.Mind_Usinghelp_9] = 'Ipasok ang Larawan';
stringMap [strings.Mind_Usinghelp_10] = 'Paano ibalik ang pagtanggal';
stringMap [strings.Mind_Usinghelp_11] = 'I-export ang Mapa ng Pag-iisip';
stringMap [strings.Mind_Usinghelp_12] = 'Pag-upgrade ng pagiging miyembro';
stringMap [strings.Mind_Usinghelp_13] = 'Shortcut Key';
stringMap [strings.Mind_Usinghelp_Understand] = 'Nakuha ko';
stringMap [strings.Mind_Usinghelp_14] = 'Ipasok ang materyal na aklatan';
// Gumamit ng tulong-2
stringMap [strings.Mind_Usinghelp_15] = 'Bagong Mapa';
// stringMap [strings.Mind_Usinghelp_2] = 'Magdagdag ng node';
// stringMap [strings.Mind_Usinghelp_4] = 'Itaguyod ang samahan';
// stringMap [strings.Mind_Usinghelp_5] = 'Pagandahin ang canvas';
// stringMap [strings.Mind_Usinghelp_6] = 'Pagpapaganda ng teksto';
// stringMap [strings.Mind_Usinghelp_7] = 'Magdagdag ng mga tala, mga link';
stringMap [strings.Mind_Usinghelp_17] = 'I-undo ang operasyon, kanselahin';
stringMap [strings.Mind_Usinghelp_18] = 'I-drag ang node upang magpalit';
stringMap [strings.Mind_Usinghelp_19] = 'Node alignment';
stringMap [strings.Mind_Usinghelp_20] = 'Baguhin ang uri ng mapa';
stringMap [strings.Mind_Usinghelp_21] = 'Estilo ng linya ng mapa';
stringMap [strings.Mind_Usinghelp_22] = 'Kulay ng background ng mapa';
stringMap [strings.Mind_Usinghelp_23] = 'Itakda ang hugis ng node';
stringMap [strings.Mind_Usinghelp_24] = 'Estilo ng linya ng node';
stringMap [strings.Mind_Usinghelp_25] = 'Mga setting ng Larawan ng Node';
stringMap [strings.Mind_Usinghelp_26] ='Mga setting ng icon ng node';
stringMap [strings.Mind_Usinghelp_27] = 'Node Magdagdag ng Buod';
stringMap [strings.Mind_Usinghelp_28] = 'Pagpipili ng solong node box';
stringMap [strings.Mind_Usinghelp_29] = 'Multi-node into blocks';
stringMap [strings.Mind_Usinghelp_30] = 'Node magdagdag ng hangganan';
stringMap [strings.Mind_Usinghelp_31] = 'Magdagdag ng mapa ng istatistika';
stringMap [strings.Mind_Usinghelp_32] = 'Itago ang node at ibalik';
stringMap [strings.Mind_Usinghelp_33] = 'Node ng maraming pagpapatakbo';
stringMap [strings.Mind_Usinghelp_34] = 'Baguhin ang istilo';
stringMap [strings.Mind_Usinghelp_35] = 'Ipasok ang boses at paliwanag';
stringMap [strings.Mind_Usinghelp_36] = 'Mapa ng Gabay sa Larawan';
stringMap [strings.Mind_Usinghelp_37] = 'Map PDF na dokumento';
stringMap [strings.Mind_Usinghelp_38] = 'I-print ang mapa';
stringMap [strings.Mind_Usinghelp_39] = 'Pagbabahagi ng link at pagtingin';
stringMap [strings.Mind_Usinghelp_40] = 'Mapa upang ibahagi sa bagyo';
stringMap [strings.Mind_Usinghelp_41] = 'Paggamit ng folder';
stringMap [strings.Mind_Usinghelp_42] = 'Tanggalin at ibalik ang mapa';
// Gumamit ng tulong 3
stringMap [strings.Mind_Usinghelp_43] = 'Mag-apply ang mga kaibigan upang sumali sa pangkat';
stringMap [strings.Mind_Usinghelp_44] = 'Paano matunaw ang pangkat';
stringMap [strings.Mind_Usinghelp_45] = 'Mag-upload ng impormasyon ng file';
stringMap [strings.Mind_Usinghelp_46] = 'Sumang-ayon na sumali sa application';
stringMap [strings.Mind_Usinghelp_47] = 'Collaborative Editing';
stringMap [strings.Mind_Usinghelp_48] = 'Bagong Pangkat ng Pagbabahagi';
stringMap [strings.Mind_Usinghelp_49] = 'Imbitahan ang mga miyembro ng pangkat';

// bagyo
stringMap [strings.Mind_Storm_Recommend] = 'Rekomendasyon';
stringMap [strings.Mind_Storm_Boilingpoint] = 'Boiling point';
stringMap [strings.Mind_Storm_Unfold] = 'Unfold';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'Ang aking pag-uuri';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'Inirekumenda na Pag-uuri';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'I-click upang magdagdag ng kategorya';
stringMap [strings.Mind_Storm_No_Classification] = 'Wala pang pag-uuri';
stringMap [strings.Mind_Storm_No_Tinterested] = 'Hindi interesado';
stringMap [strings.Mind_Storm_Report] = 'Iulat';
stringMap [strings.Mind_Storm_Views] = 'Mga Pagtingin';

stringMap [strings.Mind_Storm_Pornographic] = 'Malaswang pornograpiya';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'Advertising Harassment';
stringMap [strings.Mind_Storm_Political_Religion] = 'Pulitika at Relihiyon';
stringMap [strings.Mind_Storm_Infringement] = 'Paglabag (larawan, paninirang-puri, pamamlahiya, mapanlinlang na paggamit)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'Ipinagbabawal na nilalaman';
stringMap[strings.Mind_Storm_Spread_Rumors] = 'Ikalat ang mga alingawngaw at kumalat tsismis';
stringMap [strings.Mind_Storm_Report_Successful] = 'Matagumpay na naiulat';

// edit
stringMap [strings.Global_Edit] = 'I-edit';
stringMap [strings.Global_Cancel] = 'Kanselahin';
stringMap [strings.Global_Ok] = 'OK';
stringMap [strings.Global_Name] = 'Palayaw';
stringMap [strings.Global_Introduction] = 'Panimula';
stringMap [strings.Global_Change_Avatar] = 'Baguhin ang Avatar';
stringMap [strings.Global_Local_Upload] = 'Lokal na pag-upload';

//Personal na impormasyon
stringMap [strings.User_Personal_information] = 'Personal na Impormasyon';
stringMap [strings.User_Member_Validity_Period] = 'Panahon ng Validity ng Miyembro';
stringMap [strings.User_My_Share] = 'Aking Ibahagi';
stringMap [strings.User_My_Collection] = 'Aking Koleksyon';
stringMap [strings.User_More_Message] = 'Higit Pa';
stringMap [strings.User_Deleted_Sotrm] = 'Tanggalin ang Bagyo';
stringMap [strings.User_Folder_Rename] = 'Palitan ang pangalan';
stringMap [strings.User_Folder_Rename_Root] = 'Baguhin ang mga pahintulot';
stringMap [strings.User_Folder_Rename_Root_Title] = 'Baguhin ang mga pahintulot';
stringMap [strings.User_Folder_Root_Public] = 'Ganap na pampubliko';
stringMap [strings.User_Folder_Root_Viewonly] = 'Pinapayagan lamang makita ang pampublikong folder na makita';
stringMap [strings.User_Folder_Root_Private] = 'Pribado';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'Maaaring makita ng ibang tao ang iyong mga paborito sa iyong pahina ng profile';
stringMap [strings.User_Message_Link] = 'Link';
stringMap [strings.User_New_Group] = 'Bagong pangkat';
stringMap [strings.User_New_Group_Title] = 'Lumikha ng isang bagong pangkat';
stringMap [strings.User_New_Group_Style_Title] = 'Uri ng File';
stringMap [strings.User_Share_Is_Null] = 'Wala pang pagbabahagi! ';
stringMap [strings.User_Deleted_Storm_Tips] = 'Sigurado ka bang nais mong tanggalin ang bagyo na ito? ';
stringMap [strings.User_Remove_From_List] = 'Alisin mula sa listahan',
    stringMap [strings.User_Move_To] = 'Lumipat sa',
    stringMap [strings.User_Deleted_Link] = 'Tanggalin ang link'
stringMap [strings.User_Collction_Is_Null] = 'Wala pang koleksyon'
stringMap [strings.User_Collection] = 'Koleksyon'

// Login box
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'Maligayang Pag-isip +'
stringMap [strings.User_Lolgin_Free_Regibution] = 'Libreng pagpaparehistro'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'Mangyaring ipasok ang iyong numero ng telepono'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'Mangyaring ipasok ang verification code'
stringMap [strings.User_Lolgin_Obtain] = 'Kumuha'
stringMap [strings.User_Lolgin_Password_Login] = 'Pag-login sa password'
stringMap [strings.User_Lolgin_Login] = 'Pag-login'
stringMap [strings.User_Lolgin_Register_An_Account] = 'Pagrehistro sa Account'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'Mayroon nang account'
stringMap [strings.User_Lolgin_To_Log_In] = 'Pumunta upang mag-log in'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'Mangyaring magpasok ng isang password'
stringMap [strings.User_Lolgin_Confirm_Password] = 'Kumpirmahin ang password'
stringMap [strings.User_Lolgin_Registro] = 'Nirehistro'
stringMap [strings.User_Lolgin_Account_Login] = 'Pag-login sa account'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'I-scan ang pag-login sa code'
stringMap [strings.User_Lolgin_Forgot_Password] = 'Nakalimutan ang password'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'Pag-login sa verification code'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'Buksan ang Mind + i-scan sa tuktok ng setting ng setting'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Pick_Click] = 'Mag-e-expire ang QR code na ito sa loob ng limang minuto, mangyaring mag-click kapag mag-expire ito'
stringMap [strings.User_Lolgin_Refresh] = 'Refresh'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'Matagumpay ang pag-scan, mangyaring kumpirmahin sa iyong telepono'
stringMap [strings.User_Lolgin_Wither_Yo_Authorize_Login] = 'Kung pahintulutan ang pag-login'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'Mangyaring maglagay ng bagong password'
stringMap [strings.User_Lolgin_Reset] = 'I-reset'

// Ang aking mapa
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'Uri ng template ng paghahanap o template'
stringMap [strings.My_Mind_Date] = 'Petsa'
stringMap [strings.My_Mind_Create_Folder] = 'Lumikha ng folder'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'Mangyaring ipasok ang pangalan ng folder'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'Mangyaring maglagay ng bagong pangalan ng folder'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'Wala pang mapa, pumunta kaagad'
stringMap [strings.My_Mind_Create] = 'Lumikha'
stringMap [strings.My_Mind_Right] = 'Bar!'
stringMap [strings.My_Mind_Open] = 'Buksan'
stringMap [strings.My_Mind_Type_Left_Right] = 'Dalawang mapa ng gabay'
stringMap [strings.My_Mind_Type_Unilateral] = 'Unilateral na mapa'
stringMap [strings.My_Mind_Type_Bottom] = 'Tsart ng Organisasyon'
stringMap [strings.My_Mind_Type_Bubble] = 'Bubble Chart'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'Iskedyul ng mapa'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'Blank Mind Map'
stringMap [strings.My_Mind_All] = 'Lahat'
stringMap [strings.My_Mind_Views] = 'Mga Pagtingin:'

// Paghahanap ng bagyo
stringMap [strings.Storm_Search_How_Title] = 'Mga Nangungunang Mga Tag'
stringMap [strings.Storm_History_Search] = 'Paghahanap sa kasaysayan'

// Public header
stringMap [strings.Public_Header_Mind] = 'Mapa'
stringMap [strings.Public_Header_Outline] = 'Balangkas'
stringMap [strings.Public_Header_Download_Apps] = 'I-download ang kliyente'
stringMap [strings.Public_Header_Mobile_Version] = 'Mobile na bersyon'
stringMap [strings.Public_Header_Creator] = 'Lumikha:'
stringMap [strings.Public_Header_View_Frequency] = 'Mga Pagtingin:'
stringMap [strings.Public_Header_Save] = 'I-save'
stringMap [strings.Header_Mind_Tips_Saved] = 'Nai-save'
stringMap [strings.Header_Mind_Tips_Edit] = 'Na-edit'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'Ang pag-update ay nai-save'

// Menu sa pag-edit ng mapa
stringMap [strings.Mind_Edit_Revoke] = 'Bawiin'
stringMap [strings.Mind_Edit_Restore] = 'Ibalik'
stringMap [strings.Mind_Edit_Subtopic] = 'Subtopic'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Nangungunang Subtopic'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'Bottom Subtopic'
stringMap [strings.Mind_Edit_Free_Node] = 'Libreng Node'
stringMap [strings.Mind_Edit_Summary] = 'Buod'
stringMap [strings.Mind_Edit_Block] = 'Sa isang bloke'
stringMap [strings.Mind_Edit_Insert] = 'Ipasok'
stringMap [strings.Mind_Edit_Related] = 'Kaugnay'
stringMap [strings.Mind_Edit_Style] = 'Estilo'
stringMap [strings.Mind_Edit_Recording] = 'Pagre-record'
stringMap [strings.Mind_Edit_Insert_Picture] = 'Maglagay ng larawan'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Ipasok ang icon'
stringMap [strings.Mind_Edit_Insert_Remark] = 'Ipasok ang Mga Pahayag'
stringMap [strings.Mind_Edit_Insert_Link] = 'Ipasok ang link'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'Ipasok ang statistical graph'
stringMap [strings.Mind_Edit_Upload] = 'Mag-upload'
stringMap [strings.Mind_Edit_Right_Icon] = 'Icon'
stringMap [strings.Mind_Edit_Right_Style] = 'Estilo'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'Pag-decode ...'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Tagal'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = 'Sigurado ka bang tatanggalin ang naitalang audio? '
stringMap [strings.Mind_Edit_Join_Membership] = 'Buksan ang pagiging miyembro'
stringMap [strings.Mind_Edit_Upgrade] = 'I-upgrade'
stringMap[strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = 'Ang mga miyembro ay maaaring magkaroon ng mas maraming oras sa pagrekord, ngayon ay mag-upgrade sa pagiging kasapi? '

stringMap [strings.Mind_Edit_Choose_Material] = 'Pumili ng materyal'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'I-drag at i-drop ang mga larawan dito'
stringMap [strings.Mind_Edit_Select_locally] = 'Pumili mula sa lokal'
stringMap [strings.Mind_Edit_Choose_From] = 'Pumili mula sa materyal na library'
stringMap [strings.Mind_Edit_Mine] = 'Aking'
stringMap [strings.Mind_Edit_My_Material] = 'Aking Materyal'
stringMap [strings.Mind_Edit_Recently_Used] = 'Kamakailang ginamit'
stringMap [strings.Mind_Edit_Use] = 'Gumamit'
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = 'Walang materyal sa ngayon, pumunta at idagdag ito! '
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'Piliin ang uri ng tsart'
stringMap [strings.Mind_Edit_Remark] = 'Mga Pahayag'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'Mangyaring ipasok ang mga pangungusap'
stringMap [strings.Mind_Edit_Add] = 'Idagdag'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'Mangyaring ipasok ang link'

// I-edit ang menu sa kaliwang bahagi ng pahina
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'I-save ang larawan'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'I-export ang PDF'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'Ibahagi ang mga link'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'Ibahagi sa bagyo'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'Print'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'I-unlock ang buong bersyon'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'Tulong'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'I-save ang format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'png format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'jpg format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'format ng webp'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'Ibahagi ang mapa'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'Ibahagi ang switch'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'Maaari mong kopyahin ang link at ipadala ito'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'Kopyahin ang link'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'Kung nais mong baguhin ang link, mangyaring i-click ang'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'Regenerate'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'I-link ang password:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='Payagan ang pag-save'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'Maximum na bilang ng mga view'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'Infinite'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'Limit'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'Pangalawa'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'Panahon ng pagpapatunay ng link'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '1 araw'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '3 araw'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '7 araw'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'Permanent'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'Kopyahin ang nilalaman at link'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = 'Sigurado ka bang nais mong muling makabuo? Ang dating link ay hindi wasto! '
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'Ibahagi'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'Pasadyang kategorya:'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'Papayag ba sa mga komento'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'I-edit upang magdagdag ng kategorya'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'Mga Karapatan sa Pagsapi'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'Suporta sa platform'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Buksan ang Pagsapi'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'Pagpapalit ng code ng pag-aktibo'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = 'Mga benepisyo sa pagiging miyembro pagkatapos ng pagbili'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'Piliin ang paraan ng pagbabayad'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'Presyo ng aktibidad'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'Halaga talagang binayaran'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'Pag-Renewal kaagad'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = '《Mga Tuntunin ng Serbisyo》'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《Patakaran sa Privacy》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'Code ng pag-activate:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'Mangyaring ipasok ang activation code'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'I-verify ang activation code'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'Pumili ng mga kategorya'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'Pangunahing pamagat ay hindi maaaring walang laman'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'Kupon:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'Mangyaring ipasok ang coupon code'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'Kumpirmahin ang paggamit ng mga kupon'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'Pag-verify'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'Mas gusto na halaga'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'Bayaran kaagad'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'Kumpirmahin ang impormasyon ng palitan'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'Exchange code:'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'Exchange'

// I-edit ang menu sa kanang bahagi ng pahina
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'Canvas'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'Kulay ng background'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Layout'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'Line style'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'Kulay ng linya'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'Linya ng linya'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = 'Ang mga linya ay naka-tapered'
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'Theme font'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'Mga bata font'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'Kulay ng teksto'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'Laki ng font'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'Font Style'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'Kulay ng hangganan'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'Punan ang kulay'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Border Shape'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'Lapad ng hangganan'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'Ipakita ang anino na epekto (pag-andar ng subscription)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'Kulay ng linya'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'Dotted line'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'Laki ng larawan'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'Lokasyon ng Larawan'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'Hugis ng Larawan'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'Laki ng icon'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'Icon na posisyon'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'Pangkalahatan'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'Priority'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'Progress'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'Mga Bituin'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'linggo'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Portrait'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'Pag-aaral'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'Trabaho'
stringMap [strings.Mind_Edit_Right_Menu_Character] = 'Mga Character'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'Mga Hayop'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'Pagkain'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'Kopyahin ang istilo'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'Sticking style'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'Baguhin ang hugis'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'Tingnan ang larawan'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'Tanggalin ang larawan'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'Kopyahin'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'I-paste'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'Tanggalin ang linya'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'Tanggalin'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'I-edit ang Mga Pahayag'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'Buksan ang link'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'Tanggalin ang Mga Remarka'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'Tanggalin ang link'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'I-delete ang icon'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'Icon style'

// Panel ng pag-edit ng graphic na istatistika
stringMap [strings.Echart_Edit_Statistical] = 'Mga Istatistika'
stringMap [strings.Echart_Edit_Value] = 'Halaga'
stringMap [strings.Echart_Edit_Color] = 'Kulay'
stringMap [strings.Echart_Edit_Title] = 'Pamagat'
stringMap [strings.Echart_Edit_Detailed_Description] = 'Detalyadong paglalarawan'
stringMap [strings.Echart_Edit_Value_Sort] = 'Pagbukud-bukurin'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'Display Type'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'Color scheme'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'Laki ng display'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'Laki ng pamagat'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'Ipaliwanag ang laki'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'Mag-subscribe'

// Tab na Bagyo
stringMap [strings.StormBookTag_Subscribe] = 'Naka-subscribe'
stringMap [strings.StormBookTag_List] = 'Listahan'

// pahina ng pagbabahagi ng bagyo
stringMap [strings.Storm_Share_Comment] = 'Komento'
stringMap [strings.Storm_Share_Reply] = 'Tumugon'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'Hayaan mo ring magkomento ako dito ~'
stringMap [strings.Storm_Share_Release] = 'Pakawalan'
stringMap [strings.Storm_Share_Main_Title] = 'Pangunahing Pamagat'
stringMap [strings.Storm_Share_Subtitle] = 'Subtitle'
stringMap [strings.Storm_Share_Author] = 'May-akda'
stringMap [strings.Storm_Share_Replies] = 'Tumugon'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'Wala pang mga puna. Maging una sa mauna.'
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = 'Mangyaring maglagay ng komento ~'
stringMap [strings.Storm_Share_Delete_Comment_Tips] = 'Sigurado ka bang nais mong tanggalin ang komentong ito? '

// Tulong para sa panloob na paggamit ng mapa
stringMap [strings.Mind_Usinghelp_Btn_1] = 'Mga shortcut key (windows)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'Shortcut key (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'Paglalarawan'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Command] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'Magdagdag ng bata node'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[Tanggalin]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'Tanggalin ang kasalukuyang node (tandaan na hindi ito maaaring matanggal kung nasa estado ng pag-edit)'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Command] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'Baguhin ang kasalukuyang node'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Command] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'Palakihin ang mapa'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Command] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'Isipin ang mapa'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Command] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Command] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Command] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'Exit editing'

// Prompt na teksto
stringMap [strings.Message_Tips_Login_Success] = 'Matagumpay ang pag-login'
stringMap [strings.Message_Tips_Dlt_Success] = 'Matagumpay na natanggal'
stringMap [strings.Message_Tips_Report_Success] = 'Matagumpay na naiulat'
stringMap [strings.Message_Tips_Copy_Success] = 'Matagumpay na makopya'
stringMap [strings.Message_Tips_Collction_Success] = 'Tagumpay sa koleksyon'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Tagumpay sa pagbabago ng Avatar'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'Tagumpay sa pagbabago ng lagda'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'matagumpay na nabago ang palayaw'
stringMap [strings.Message_Tips_Modification_Success] = 'Matagumpay na pagbabago'
stringMap [strings.Message_Tips_New_Profile_Success] = 'Bagong tagumpay sa folder'
stringMap [strings.Message_Tips_Mobile_Success] = 'Tagumpay sa mobile'
stringMap [strings.Message_Tips_Submit_Success] = 'Matagumpay na isumite'
stringMap [strings.Message_Tips_Exchange_Success] = 'Matagumpay na palitan'
stringMap [strings.Message_Tips_Please_Code] = 'Mangyaring ipasok ang activation code'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'Mangyaring sumang-ayon sa patakaran sa privacy'
stringMap [strings.Message_Tips_Please_Sign_In] = 'Mangyaring mag-sign in'
stringMap [strings.Message_Tips_Share_Success] = 'Matagumpay na pagbabahagi'
stringMap [strings.Message_Tips_Create_Success] = 'Matagumpay na nilikha'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'Ang pangalan ng folder ay hindi maaaring walang laman'
stringMap [strings.Message_Tips_Selecte_Success] = 'Nakumpleto ang paghahanap'
stringMap [strings.Message_Tips_Subscription_Success] = 'Matagumpay ang subscription'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'Matagumpay na mag-unsubscribe'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'Hindi nahanap ang nauugnay na nilalaman'
stringMap [strings.Message_Tips_Max_Add_Class] = 'Maaari lamang magdagdag ng higit sa'
stringMap [strings.Message_Tips_Registro_Success] = 'Matagumpay na nairehistro'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'Nabigo ang pag-login sa pag-scan ng code'
stringMap [strings.Message_Tips_Share_Is_Null] = 'Ang pagbabahagi ng link ay hindi mayroon'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'Ang na-upload na file ay hindi maaaring lumagpas sa 3MB'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'Matagumpay na makakuha ng pahintulot'
stringMap [strings.Message_Tips_Membership_Required] = 'Kailangan ng pagiging miyembro'
stringMap [strings.Message_Tips_Save_Success] = 'Matagumpay na nai-save'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'Matagumpay na pag-login sa pag-login sa code'
stringMap [strings.Message_Tips_Style] = 'Isang kategorya?'

// Outline mode
stringMap [strings.Outline_Menu_Indent] = 'Indent'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'Bawasan ang indentation'

// hint hint
stringMap [strings.Help_Background_Content] = 'May kaugnayan sa background ng linya, nauugnay ang background ng linya, may kaugnayan sa background ng linya, nauugnay sa linya ng background, nauugnay sa background ng linya, nauugnay sa linya ng linya, nauugnay sa background ng linya, nauugnay sa background ng linya,'
stringMap [strings.Help_Mind_Content] = "Ang mga mapa na may iba't ibang mga layout ay maaaring gawin ayon sa mga kagustuhan: dalawahang mapa ng gabay, kaliwang mapa, kanang mapa, tsart ng samahan, dalawang punong puno, kaliwang puno, kanang puno, mesa"
stringMap [strings.Help_Share_Link_Content] = 'Maibabahagi ang mga link sa WeChat, QQ, Weibo at iba pang mga channel sa anyo ng mga link, at maaaring matingnan ng mga kaibigan ang nilalaman'
stringMap [strings.Using_Templates] = 'Paggamit ng mga template'

// Code ng paanyaya
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'Mangyaring ipasok ang code ng imbitasyon'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'Mangyaring ipasok ang tamang code ng paanyaya'
stringMap [strings.User_Lolgin_Optional] = 'Opsyonal'

// Gumamit ng materyal

stringMap [strings.Cannot_Add_To_Node_Background] = 'Hindi nalalapat sa background ng node'
stringMap [strings.Cannot_Add_To_Img_Background] = 'Hindi nalalapat sa background ng teksto'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'Hindi nalalapat sa background ng linya ng parsela'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'Magdagdag ng larawan sa background'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'Tanggalin ang imahe sa background'
stringMap [strings.Mind_Add_Background_Image] = 'Ipasok ang imahe sa background'
stringMap [strings.Mind_Delete_Background_Image] = 'Tanggalin ang imahe sa background'

stringMap [strings.Permanent_Member] = 'Permanenteng Miyembro'
stringMap [strings.Insufficient_Storage_Space] = 'Hindi sapat na espasyo sa pag-iimbak, i-upgrade ang pagiging miyembro upang madagdagan ang espasyo. '
stringMap [strings.Used_Space] = 'Puwang ng imbakan'
stringMap [strings.Current_Storage] = 'Kasalukuyang imbakan:'
stringMap [strings.Used_Space_Upgrade] = 'Pag-upgrade ng espasyo sa imbakan'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mind + Mind Map & Notes Super mnemonics, magdagdag ng mga pakpak sa pag-iisip, lumikha, ipakita, plano, at tala'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = 'Ang espasyo sa pag-iimbak ay hindi sapat. Maaari mong gamitin ang template kapag na-upgrade mo ang iyong pagiging miyembro. '

stringMap [strings.Group_Management] = 'Pangkat'
stringMap [strings.Save_Img_Fill_Full] = 'Pave full'
stringMap [strings.Save_Img_Center_Tilt] = 'Center Tilt'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'Ganap (madilim)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'Mind + Mind Map Watermarking'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'Magdagdag ng watermark upang maprotektahan ang paglikha ng kaalaman:'
stringMap [strings.Save_Img_Watermark_Text] = 'Teksto ng watermark:'
stringMap [strings.Save_Img_Watermark_Logo] = 'Watermark LOGO:'
stringMap [strings.Save_Img_Watermark_Style] = 'Estilo ng watermark:'
stringMap [strings.Save_Img_Please_Input] = 'Mangyaring ipasok'
stringMap [strings.Save_Img_Chop_Tips] = 'Masisiyahan ka sa mga karapatan sa watermark kapag binuksan mo ang isang miyembro'
stringMap [strings.Public_Header_Mindmap_Mind] = 'Map mode'
stringMap [strings.Copy_Mind_Map] = 'Kopyahin ang Mapa ng Mapa'
stringMap [strings.Font_Style_Bold] = 'Bold'
stringMap [strings.Font_Style_Italics] = 'Italics'
stringMap [strings.Font_Style_Delete_Line] = 'Strikethrough'
stringMap [strings.Remove_Any] = 'Alisin'
stringMap[strings.Save_Img_Border_Style] = "Border style"
stringMap[strings.Save_Img_Border_Width] = "Border width"
stringMap[strings.Save_Img_Border_Color] = "Border color"
stringMap[strings.Save_Img_Border_Shadow] = "Shadow"
stringMap[strings.Save_Img_Border_Radius] = "Border radius"
stringMap[strings.Save_Img_Border_Big] = "Maximum"
stringMap[strings.Save_Img_Border_Middle] = "Middle"
stringMap[strings.Save_Img_Border_Small] = "Small"
stringMap[strings.Save_Img_Border_None] = "None"

stringMap [strings.Mindmap_Review] = 'Review ng mapa'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'Palalimin ang impression at pagbutihin ang memorya, pag-uri-uriin ang mga ideya at impormasyon nang mahusay'
stringMap [strings.Mindmap_Review_Content] = "Ang pagsusuri sa mapa ay tumutukoy sa pag-playback ng proseso ng pag-edit, at ang nilalaman ay nilalaro nang sunud-sunod na nagsisimula sa gitnang tema; makakatulong sa iyo ang pagsusuri sa mapa na isagawa ang imbentaryo ng system, pagsusuri, at maghanap ng mga ideya at kaluskos . Sa proseso ng pagsusuri, maaari kang makahanap ng iba't ibang mga inspirasyon "
stringMap [strings.Mindmap_Review_Speed] = 'Bilis'
stringMap [strings.Mindmap_Review_Word_Speed] = 'Bilis ng salita'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'Background ratio'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'Kasalukuyang pagpapalaki ng node ng pag-playback'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Node Playback Order'
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap [strings.Mind_Group_Picture_Upload] = 'Pag-upload ng larawan'
stringMap [strings.Mind_Group_Upload_From_Local] = 'Local upload'
stringMap [strings.Mind_Group_Mind_Mapping] = 'Mind Map'
stringMap [strings.Mind_Group_Information] = 'Data'

stringMap [strings.Mind_Group_Invite_New_Members] = 'Mag-imbita ng mga bagong kasapi'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'Mangyaring ipasok ang iyong numero ng telepono'
stringMap [strings.Mind_Group_Cancel] = 'Kanselahin'
stringMap [strings.Mind_Group_Confirm] = 'OK'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'Ang numero ng mobile phone ay hindi maaaring walang laman'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'Matagumpay ang imbitasyon'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "Ibinahagi ang mga miyembro ng pangkat"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "Maximum na bilang ng mga nakabahaging miyembro ng pangkat"
stringMap [strings.Mind_Group_Membership_Limit] = "Limitasyon sa Pagsapi"
stringMap [strings.Mind_Group_Current_Member_Limit] = "Kasalukuyang Miyembro sa Limitasyon"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "I-upgrade ang mas mataas na limitasyon ng miyembro"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "Maghanap para sa mga miyembro"
stringMap [strings.Mind_Group_Creator] = "Lumikha"
stringMap [strings.Mind_Group_Administrator] = "Administrator"
stringMap [strings.Mind_Group_Number_Of_People] = "Mga Tao"
stringMap [strings.Mind_Group_Selected] = "Napili"
stringMap [strings.Mind_Group_Member] = "Miyembro"
stringMap [strings.Mind_Group_Check_All] = "Piliin lahat"
stringMap [strings.Mind_Group_Remove] = "Alisin"
stringMap [strings.Mind_Group_Invite_New_Members] = "Mag-imbita ng mga bagong kasapi"
stringMap [strings.Mind_Group_Delete_Members] = "Tanggalin ang mga kasapi"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "Mangyaring piliin ang miyembro upang tanggalin"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "Sigurado ka bang nais mong alisin ang gumagamit mula sa pangkat na ito?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "Magdagdag ng Administrator"
stringMap [strings.Mind_Group_Accomplish] = "Nakumpleto"
stringMap [strings.Mind_Group_Compile] = "I-edit"
stringMap [strings.Mind_Group_Group_Owner] = "May-ari ng Grupo"
stringMap [strings.Mind_Group_Add_Admin] = "Magdagdag ng Administrator"
stringMap [strings.Mind_Group_Group_ID] = "Group id"
stringMap [strings.Mind_Group_Group_Number] = "Numero ng Grupo"
stringMap [strings.Mind_Group_Name] = "Pangalan"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Mangyaring ipasok ang pangalan ng nakabahaging pangkat"
stringMap [strings.Mind_Group_Introduce] = "Ipakilala"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "Mangyaring ipakilala sa madaling panahon ang mga ibinahaging pangkat"
stringMap [strings.Mind_Group_Announcement] = "Anunsyo"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Ibinahagi na Tag ng Grupo"
stringMap [strings.Mind_Group_Custom_Tag] = "Pasadyang Tag"
stringMap [strings.Mind_Group_Save] = "I-save"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "Pangalan ng pangkat ay hindi maaaring walang laman"
stringMap [strings.Mind_Group_Establish] = "Lumikha"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "Payagan na lumikha ng mga mapa / folder"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "Payagan ang pag-edit ng mga mapa"
stringMap [strings.Mind_Group_Holder] = "Lahat"
stringMap [strings.Mind_Group_Only_The_Administrator] = "Administrator lang"
stringMap [strings.Mind_Group_Not_Allow] = "Hindi pinapayagan"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "Payagan na tanggalin ang mapa"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "Payagan ang mga forum na mag-post ng mga mensahe"
stringMap [strings.Mind_Group_Export] = "I-export"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "Payagan na i-save ang mga imahe / PDF"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Buksan ang watermark ng pangkat"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "Pagkatapos ng pagbubukas, ang pangkat ng logo ay mai-print sa mind map"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "Payagan ang pag-save ng mga mapa"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "I-save sa Aking Mapa"
stringMap [strings.Mind_Group_Wither_Sharing_To_Storm_Is_Allowed] = "Kung papayagan ang pagbabahagi sa bagyo"
stringMap [strings.Mind_Group_Join_The_Way] = "Paano sumali"
stringMap [strings.Mind_Group_Wither_To_Invite_New_Members] = "Kung papayagan ang mga bagong kasapi na maimbitahan"
stringMap [strings.Mind_Group_Wither_To_Allow_Search_Group_Number_To_Be_Added] = "Kung papayagan na idagdag ang mga numero ng pangkat ng paghahanap"
stringMap [strings.Mind_Group_Wither_The_Message_Needs_To_Be_Validated_When_Joining] = "Kung ang mensahe ba ay kailangang patunayan kapag sumali"
stringMap [strings.Mind_Group_Shared_Group_Data] = "Ibinahaging data ng pangkat"
stringMap [strings.Mind_Group_Authority_Management] = "Pamamahala sa Awtoridad"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "Umalis sa pagbabahagi ng pangkat"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "Sigurado ka bang lalabas sa nakabahaging pangkat?"
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "Dissolution Group"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "Tatanggalin ang mga file pagkatapos na ma-disband ang pangkat. Kumpirmahing disband?"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "Bumalik sa home page"
stringMap [strings.Mind_Group_Join_The_Group] = "Sumali sa Pangkat"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "Mangyaring ipasok ang numero ng pangkat / pangalan ng grupo upang maghanap"
stringMap [strings.Mind_Group_Apply_To_Join] = "Mag-apply upang sumali"
stringMap [strings.Mind_Group_Join] = "Sumali"
stringMap [strings.Mind_Group_Verification] = "Pag-verify"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "Mangyaring ipasok ang mensahe ng pagpapatunay"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "Hindi maaaring walang laman ang nilalaman"
stringMap [strings.Mind_Group_Name] = "Ibinahaging pangalan ng pangkat"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "Mangyaring ipasok ang pangalan ng nakabahaging pangkat"
stringMap [strings.Mind_Group_Introduction] = "Ibahagi ang pagpapakilala ng pangkat"
stringMap [strings.Mind_Group_Group_Indication] = "Numero ng Grupo"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "Ang tagalikha ay tamad, hindi ko pa ito ipinakilala ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "Ibinahagi na anunsyo ng pangkat"
stringMap [strings.Mind_Group_No_Announcement] = "Wala pang anunsyo"
stringMap [strings.Mind_Group_Click_On] = "I-click upang palawakin"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "I-click upang itago"
stringMap [strings.Mind_Group_Altought] = "Total"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "Matagumpay na pagkopya ng numero ng pangkat"
stringMap [strings.Mind_Group_Stick] = "Dumikit sa tuktok"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "Mayroong bagong nilalaman"
stringMap [strings.Mind_Group_Cancel_The_Top] = "Kanselahin ang Nangungunang"
stringMap [strings.Mind_Group_Setting_Up] = "Mga Setting"
stringMap [strings.Mind_Group_Usinghelp] = "Gumagamit ng tulong"
stringMap [strings.Mind_Group_Set_The_Message] = "Pangkat na Mensahe"
stringMap [strings.Mind_Group_Apply_To_Join] = "Mag-apply upang sumali"
stringMap [strings.Mind_Group_Invite_To_Join] = "Imbitahan na sumali"
stringMap [strings.Mind_Group_Consent] = "Agree"
stringMap [strings.Mind_Group_Turn_Down] = "Tanggihan"
stringMap [strings.Mind_Group_Label] = "Label"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "Mangyaring ipasok ang pangalan ng nakabahaging label ng pangkat"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "Mag-imbita ng mga kaibigan at kasamahan na gumawa ng sama at magbahagi ng nilalaman ng kaalaman"
stringMap [strings.Mind_Group_Immediately_Create] = "Lumikha kaagad"
stringMap [strings.Mind_Group_Immediately_To_Join] = "Sumali Ngayon"

stringMap [strings.Currently_Selected] = "Kasalukuyang sumusunod"
stringMap [strings.LabelManagement] ="Pamamahala ng label"
stringMap [strings.NoAttention] = "Walang Pansin"
stringMap [strings.Mindmap_Review_Play] = 'Play'

stringMap [strings.Mind_Group_Joined] = "Sumali"
stringMap [strings.Mind_Group_Download_App] = "I-download ang APP"
stringMap [strings.Mind_Group_No_Content] = "Wala pang nilalaman"
stringMap [strings.Mind_Group_Individual] = "mga piraso"
stringMap [strings.Mind_Group_Message_Area] = "Lugar ng Mensahe"
stringMap [strings.Mind_Group_No_Message] = "Walang mensahe, pumunta ngayon"
stringMap [strings.Mind_Group_Leave_Message] = "Mag-iwan ng Mensahe"
stringMap [strings.Mind_Group_Bar] = "Bar"
stringMap [strings.Mind_Group_See_More] = "Tingnan ang higit pa"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "Ipasok ang code ng imbitasyon"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "Mag-apply upang sumali sa pangkat ng pagbabahagi"
stringMap [strings.Reasons_For_Application_0_30_Words] = "Mga dahilan para sa aplikasyon (0-30 salita)"
stringMap [strings.Mind_Group_Send_Out] = "Ipadala"
stringMap [strings.Mind_Group_Join_Successfully] = "Matagumpay na sumali"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "Matagumpay na naipadala ang application"

stringMap [strings.Mind_Group_Save_The_Mapping] = "I-save ang Mapa"
stringMap [strings.Mind_Group_Update_Record] = "Baguhin ang Record"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "Lumipat ng Mapa"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "Review ng Mapa"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "Ang isang mayroon nang gumagamit ay nag-e-edit ng mapa"
stringMap [strings.Mind_Edit_Page_Password] = "Password"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "Pangkat ng Pagbabahagi"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "My MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Alipay Payment"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "Mangyaring pumunta sa pahina ng pagbabayad upang magbayad"
stringMap [strings.HeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "Kabuuan"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "Kumpirmahin ang pagbabayad"
stringMap [strings.Mind_Shooping_Redeem_Now] = "I-red now"

stringMap [strings.Mind_Wither_Confirm_Payment_Purchase] = "Kung kumpirmahin ang pagbabayad para sa pagbili"
stringMap [strings.Mind_Tips] = "Mga Tip"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "Ang iyong M Beans ay hindi sapat, nais mo bang bumili ng M Beans"

stringMap [strings.Mind_share_scan_code] = "Mind + scan code"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "Kung ibabalik sa huling pinatugtog na lugar?"
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "Natitirang mga oras na maaaring i-play"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "Ang file ay ganap na tatanggalin, sigurado ka bang disband?"
stringMap [strings.Group_Mind_Up_One_Level] = "Up One Level"
stringMap [strings.Group_Mind_No_Documents] = "Wala pang mga file"
stringMap [strings.Group_Mind_PaymentSuccessful] = "Matagumpay ang pagbabayad"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "Ang mga taong nakikipagtulungan sa pag-edit, multi-terminal na real-time na pagsabay, pagbabahagi ng mga dokumento, pagpapabuti ng kahusayan sa pakikipagtulungan ng koponan"

stringMap [strings.Mind_Edit_Subscript] = "Subscript"
stringMap [strings.Mind_Edit_Text_Location] = "Lokasyon ng Teksto"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "I-import sa Ibinahaging Grupo"

stringMap [strings.Standard_Colors] = "Mga Karaniwang Kulay"
stringMap [strings.More_Colors] = "Marami pang mga kulay"
stringMap [strings.Reset_Password] = "I-reset ang Password"
stringMap [strings.Account_Password_Login] = "Pag-login sa Password ng Account"
stringMap [strings.Authentication_code_Login_regibution] = "Pag-login / pagrehistro ng code sa pag-verify"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "Isang pagtatapos sa paglikha, maraming pagtatapos ng pagsabay"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "Mayaman at magkakaibang istraktura ng mapa"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "Napakalaking magandang-maganda na library ng template"
stringMap [strings.Map_Review_Play] = "Map Review-Play"
stringMap [strings.CommunityLearningAndSharingPlatform] = "Learning Learning and Sharing Exchange Platform"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "I-upgrade ang espasyo sa imbakan"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "Hayaan mo ring magkomento ako dito"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "Wala pang notification sa mensahe"
stringMap [strings.Failed_To_Get_Recording_Device] = "Nabigong makakuha ng recording device"

stringMap [strings.Search_Pagination] = "Pagination Pagination"
stringMap [strings.Please_Enter_A_Paging_Name] = "Mangyaring ipasok ang pangalan ng pahina"
stringMap [strings.Operation_Settings] = "Mga Setting ng Operasyon"
stringMap [strings.Shortcut_key_Settings] = "Mga setting ng Shortcut Key"
stringMap [strings.Press_Key_Combination] = "Mangyaring pindutin ang kinakailangang key kombinasyon"
stringMap [strings.Mind_Reduction] = "Reduction"
stringMap [strings.Double_Click_Add_Free_Node] = "I-double click ang blangko na puwang upang magdagdag ng isang libreng node"
stringMap [strings.Shortcut_Conflict] = "Shortcut key conflict"
stringMap [strings.Select_Main_Node] = "Piliin ang Pangunahing Node"
stringMap [strings.Telephone] = "Telepono"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "Address"
stringMap [strings.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "Archive"
stringMap [strings.Mind_Bookshelf] = "Bookshelf"
stringMap [strings.Search_Archive] = "Search Bookshelf"
stringMap [strings.Modify_Archive_Order] = "Baguhin ang Order ng Bookshelf"
stringMap [strings.Modification_Order] = "Modification Order"
stringMap [strings.Please_Enter_Archive_Name] = "Mangyaring ipasok ang pangalan ng bookshelf"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "Ang pangalan ng book shelf ay hindi maaaring walang laman"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "Sigurado ka bang nais mong tanggalin ang librong ito?"
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "Sigurado ka bang nais mong tanggalin ang bookcase na ito?"
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "Sigurado ka bang nais mong tanggalin ang map na ito?"
stringMap [strings.Mind_Edit_Confirm] = "Kumpirmahin"
stringMap [strings.Mind_Import_Map] = "I-import ang Mapa"
stringMap [strings.Mind_Bookcase] = "Bookcase"
stringMap [strings.Added_Successfully] = "Matagumpay na naidagdag"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "Ang pangalan ng bookcase ay hindi maaaring walang laman"

stringMap [strings.Mind_Association_Map] = "Mapa ng Association"
stringMap [strings.Mind_confirm_Association_Map] = "Sigurado ka bang maiugnay ang mapa na ito?"
stringMap [strings.Mind_Association_Map_See] = "Tingnan ang Mapa ng Association"
stringMap [strings.Mind_Association_Map_Delete] = "Tanggalin ang Mapa ng Association"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "Structure Line ng Pag-uugnay"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "Start of Correlation Line"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "Ang pagtatapos ng linya ng ugnayan"

stringMap [strings.Share_Group_To_Storm] = "Kung ibabahagi ang pangkat sa bagyo"
stringMap [strings.Apply_To_Join_Storm_Platform] = "Sa platform ng bagyo, ang ibang mga tao ay nakikita at nag-a-apply upang sumali, nag-brainstorming sa mga mahilig sa karaniwang kaalaman"
stringMap [strings.Pay_To_Goin] = "Bayaran upang sumali"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "Kailangang bayaran ang mga M beans kapag sumali"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean Realised"

stringMap [strings.Mind_Visit] = "Bisitahin"
stringMap [strings.Mind_Details] = "Mga Detalye"
stringMap [strings.Mind_See] = "Tingnan"
stringMap [strings.Earn_Mbeans] = "Kumita ng mga Mbeans"
stringMap [strings.Mind_Explain] = "Paglalarawan"
stringMap [strings.Mind_Parenting] = "Pagiging Magulang"
stringMap [strings.Mind_Internet] = "Internet"
stringMap [strings.Mind_Psychology] = "Psychology"
stringMap [strings.Mind_literature] = "Panitikan"
stringMap [strings.Mind_Technology] = "Teknolohiya"
stringMap [strings.Mind_Workplace] = "Lugar ng Trabaho"
stringMap [strings.Mind_Life] = "Buhay"
stringMap [strings.Mind_Subject] = "Paksa"
stringMap [strings.Hot_Group] = "Mainit na Pangkat"
stringMap [strings.Open_Group] = "Buksan ang Pangkat"
stringMap [strings.Mind_Group_Used] = "Ginamit"

stringMap [strings.Mind_Click_Select] = "I-click upang piliin"
stringMap [strings.Mind_Double_Click_Enter] = "I-double click upang ipasok"
stringMap [strings.Search_Shared_Groups] = "Maghanap para sa mga nakabahaging pangkat"
stringMap [strings.Search_Storm] = "Search Storm"
stringMap [strings.Members_Save_Free] = "Mga Miyembro ay Makatipid ng Libre"
stringMap [strings.Bank_Card_Account_Name] = "Mangyaring ipasok ang pangalan ng iyong bank card account"
stringMap [strings.Bank_Card_No] = "Numero ng Card Card"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "Mangyaring ipasok ang numero ng iyong bank card"
stringMap [strings.Branch_Sub_Branch_Information] = "Impormasyon sa sangay ng sangay"
stringMap [strings.Branch_Sub_Branch_Example] = "Halimbawa: Hangzhou Branch Chengxi Sub-branch"
stringMap [strings.Application_For_Withdrawal] = "Application para sa pag-atras"
stringMap [strings.Withdrawal_Amount] = "Halaga ng pag-withdraw"
stringMap [strings.Withdrawal] = "Withdraw"
stringMap [strings.Current_Revenue] = "Kasalukuyang Kita"
stringMap [strings.Recharge] = "Recharge"
stringMap [strings.Current_Total_MBeans] = "Kasalukuyang kabuuang bilang ng mga MBeans"
stringMap [strings.Withdrawal_Status] = "Katayuan ng Pag-atras"
stringMap [strings.Insufficient_Withdrawal_Amount] = "Hindi sapat na halaga ng pag-atras"
stringMap [strings.Load_More] = "Mag-load pa"
stringMap [strings.Loading] = "Naglo-load"
stringMap [strings.It_Is_Over] = "Tapos na"
stringMap [strings.Default_Label] = "Default na label"
stringMap [strings.Selected_Label] = "Napiling label"
stringMap [strings.Current_Location] = "Kasalukuyang Lokasyon"
stringMap [strings.MGold_Introduction] = "M ginto ang nakukuha mo sa pamamagitan ng Mind +"
stringMap [strings.MGold_Details] = "Ang iyong bayad na pangkat ng ulap, may mga mapa ng kaalaman, mga materyales sa pag-aaral, atbp. sa pangkat. Kung ang mga gumagamit ay sumali sa pagbabayad ng M beans, 50% sa mga ito ay mai-convert sa M ginto, at mas M ginto mahigit sa 50 ang maaaring makuha "
stringMap [strings.Cloud_Group_Introduction] = "Ibahagi ang iyong pangkat, maaaring matingnan ito ng iba sa pahina ng bagyo"
stringMap [strings.Cloud_Group_Details] = "Ang iyong bayad na cloud group. Mayroong mga mapa ng kaalaman, mga materyales sa pag-aaral, atbp. sa pangkat. Kung ang mga gumagamit ay sumali sa pagbabayad ng M beans, 50% sa mga ito ay mai-convert sa M ginto, at mas ginto ang M mahigit sa 50 ang maaaring bawiin. "
stringMap [strings.Share_Storm_Introduction] = "Ibahagi ang iyong mapa sa komunidad ng bagyo, upang mas maraming tao ang maaaring matuto at mag-explore ng kaalaman mula sa iyo"
stringMap [strings.Share_Storm_Details] = "Kung pinagana mo ang paggamit ng M beans upang makatipid ng mga mapa, maaaring magamit ng ibang mga gumagamit ang iyong mapa ng kaalaman, at makakakuha ka rin ng 50% ng mga M beans. Ang M beans ay maaaring magamit upang bumili ng mga miyembro at sumali sa bayad mga pangkat, Maaari ka ring magbayad upang magamit ang mga mapa ng ibang tao. "

stringMap [strings.Linked_Successfully] = "Matagumpay na na-link"
stringMap [strings.Cancel_Archiving] = "Kanselahin ang Pag-archive"
stringMap [strings.Please_Select_Archive_Location] = "Mangyaring piliin ang lokasyon ng archive"
stringMap [strings.The_Search_Result_Is_Empty] = "Walang laman ang mga resulta sa paghahanap"

stringMap [strings.Customize_A_Style] = "Maaari lamang ipasadya ng mga hindi miyembro ang isang istilo"
stringMap [strings.Adds_Current_Guide_Style_Library] = "Idagdag ang kasalukuyang istilo ng mapa sa style library"
stringMap [strings.Trial_Version] = "Trial Version"
stringMap [strings.Not_Supported_At_The_Moment] = "Hindi kasalukuyang sinusuportahan"
stringMap [strings.Mind_File] = "File"
stringMap [strings.Mind_Import] = "I-import"
stringMap [strings.Importing] = "Pag-import"

stringMap [strings.Mind_Edit_Insert_Video] = "Ipasok ang Video"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "I-drag at i-drop ang video dito"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "Ang tagal ng upload ng video ay hindi maaaring lumagpas sa 60s"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "Tingnan ang Video"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "Tanggalin ang video"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "Ang na-upload na video ay hindi maaaring lumagpas sa 10MB"
stringMap [strings.Mind_Upload_Video_Restrictions] = "Sinusuportahan lamang ang pag-upload ng mga video sa format na MP4, hanggang sa 120s at 10M ang laki"
stringMap [strings.Mind_Video_Size] = "Laki ng video"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "I-export ang Salita"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "I-export ang TXT"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Export_Failed] = "Nabigo ang pag-export"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Add_File] = "Magdagdag ng file"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "Direktang i-drag sa file upang mag-upload"
stringMap [strings.Supported_Imported_File_Types] = "Mga uri ng file na kasalukuyang sinusuportahan para sa pag-import: Word (only .docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "Wala kang pahintulot upang maisagawa ang operasyong ito"

stringMap [strings.Upgrade_To_Professional] = "Mag-upgrade sa Professional Edition"
stringMap [strings.Non_Members_Insert_Video] = "Ang mga hindi miyembro ay maaari lamang maglagay ng mga video 24 na oras pagkatapos ng pagrehistro"
stringMap [strings.Upload_Failed] = "Nabigo ang pag-upload"
stringMap [strings.Upload_Failed_Title_Message] = "Nabigo ang pag-upload, paki-click upang kumpirmahin, tanggalin ang natitirang mga larawan na nabigong ma-upload"

stringMap [strings.Mind_Edit_Members_Use] = "Gumagamit ng Mga Miyembro"
stringMap [strings.Invitation_Code] = "Invitation Code"
stringMap [strings.Student_Certification] = "Certification ng Mag-aaral"
stringMap [strings.I_Get_It] = "Gusto ko, laktawan ang gabay"
stringMap [strings.Welcome_To_Mind] = "Malugod na pagbati sa batang master na sumali sa Mind +, simulan natin ang paglalakbay ng pag-iisip!"
stringMap [strings.Mind_Before] = "Una"
stringMap [strings.Try_A_Map] = "Subukan ang isang mapa"
stringMap [strings.Click_Create] = "I-click upang likhain"
stringMap [strings.Click_Create_A_Mind_Map] = "I-click upang lumikha ng isang mapa ng isip"
stringMap [strings.Mind_About] = "Tungkol sa"
stringMap [strings.See_Checking_Data] = "Tingnan ang personal na impormasyon"
stringMap [strings.Modify_Checking_Data] = "Baguhin ang personal na impormasyon"
stringMap [strings.Mind_Shear] = "Shear"

stringMap [strings.Mind_Style_Empty] = "Walang paborito o pasadyang istilo"
stringMap [strings.Mind_Branch_Free_Layout] = "Branch Free Layout"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "Ang two-way na istraktura at mga bubble map branch ay maaaring malayang mai-drag sa layout"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "Libreng Tema I-drag ang Snap"

stringMap [strings.Select_A_Node] = "Pumili ng isang paksa"
stringMap [strings.Create_Child_Node] = "Lumikha ng tema ng bata"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "Susunod ay magdagdag kami ng isang maganda sa tema ng bata"
stringMap [strings.Click_Icon_Panel] = "I-click ang panel ng icon"
stringMap [strings.Select_An_Icon_You_Like] = "Pumili ng isang icon na gusto mo"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "Susunod ay papalitan natin ang mind map ng bago"
stringMap [strings.Select_The_Central_Theme] = "Piliin ang gitnang tema"
stringMap [strings.Switch_To_Canvas_Panel] = "Lumipat sa Canvas Panel"
stringMap [strings.Click_Layout] = "Mag-click sa Layout"
stringMap [strings.Choose_A_Layout_You_Like] = "Pumili ng isang layout na gusto mo"
stringMap [strings.Mind_Map_Save_Share] = "Binabati kita, matagumpay kang nakagawa ng isang magandang mapa ng isip! I-save natin ito at ibahagi ito sa iyong mga kaibigan ~"
stringMap [strings.Click_Save_Picture] = "I-click upang i-save ang larawan"
stringMap [strings.Click_OK] = "Click OK"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "Binabati kita sa pagkumpleto ng gabay ng baguhan ~"
stringMap [strings.Upload_Successed] = "Matagumpay na na-upload"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "Mabilis na Estilo"
stringMap [strings.Mind_Extremely_Important] = "Lubhang mahalaga"
stringMap [strings.Mind_Important] = "Mahalaga"
stringMap [strings.Mind_Commonly] = "Pangkalahatan"
stringMap [strings.Mind_Ordinary] = "Ordinaryo"
stringMap [strings.Member_Exclusive] = "Ang tampok na ito ay eksklusibo sa mga miyembro. Nais mo bang i-upgrade ang iyong pagiging miyembro?"
stringMap [strings.Member_Exclusive_Color_Scheme] = "Ang color scheme na ito ay eksklusibo sa mga miyembro. Nais mo bang i-upgrade ang iyong pagiging miyembro?"
stringMap [strings.Mind_Enlarge] = "Palakihin"
stringMap [strings.Mind_Narrow] = "Makitid"
stringMap [strings.Mind_Center] = "Nakasentro"
stringMap [strings.Mind_Full_Screen] = "Buong Screen"

stringMap [strings.Mind_Association_Node] = "Association Node"

stringMap[strings.Mind_Resource_See] = "Tingnan ang kalakip"
stringMap[strings.Mind_Resource_Delete] = "Burahin ang mga kalakip"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "I-drag at i-drop ang mga file dito"
stringMap[strings.Mind_Edit_choose_explain] = "Pumili ng File"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "Ang na-upload na file ay hindi maaaring lumampas sa 20MB"

stringMap[strings.Please_Enter_Mind_Password] = "Pakiusap ipasok ang 4-8 digit password"
stringMap[strings.Please_Enter_Mind_Password_For_Unlock] = "Pakiusap ipasok ang password"
stringMap[strings.Please_Enter_Mind_Password_Member_Tips] = "Ang function na ito ay isang miyembro na fungsyon"
stringMap[strings.Please_Enter_Mind_Password_Remove_Tips] = "Ang password ng file ay inalis"
stringMap[strings.Please_Enter_Mind_Password_Length_Short] = "Ang bilang ng mga numero ng password ay hindi sapat. Paki-pasok ang password na may 4 na naibabalik na 8 na numero"
stringMap[strings.Please_Enter_Mind_Password_Length_Long] = "Masyadong mahaba ang password. Paki-enter ang password na may 4 na naibabalik na 8 na numero"
stringMap[strings.Please_Enter_Mind_Password_Success] = "Ang kontrasenyas ay matagumpay na itakda"
stringMap[strings.Please_Enter_Mind_Password_Format_Error] = "May pagkakamali sa format ng kontrasenyas"
stringMap[strings.Mind_Load_Data_Error] = "May problema sa paglagay ng mga datos"
stringMap[strings.Please_Enter_Mind_Password_Error] = "Pagkakamali ng kontrasenyas"
stringMap[strings.Please_Enter_Mind_Password_For_Owner] = "Ang gumagawa lamang ng mapa na ito ay maaaring itakda ang password"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

stringMap[strings.Mind_Edit_Node_Menu_Ai_Label] = "Ai Helper-GPT"
stringMap[strings.Mind_Edit_Ai_Title] = 'Ai Helper'
stringMap[strings.Mind_Ai_Empty_Tips_1_Label] = 'Enter the question you want to obtain'
stringMap[strings.Mind_Ai_Empty_Tips_2_Label] = 'Please use Ai assistant in a reasonable and civilized manner'
stringMap[strings.Mind_Ai_To_Map_Label] = 'Add MindMap'
stringMap[strings.Mind_Copy_Cuccess_Label] = 'Successfully copied'
stringMap[strings.Mind_Ai_Input_Hint_Label] = 'Please enter a question...'
stringMap[strings.Mind_Ai_Submit_Label] = 'Submit'
stringMap[strings.Mind_Ai_Exceeding_Limit_Label] = 'Sorry, it has been used too many times a day. Please use it again tomorrow'
stringMap[strings.Mind_Ai_To_Senior_Member_Label] = 'Today\'s usage has been exhausted, permanent members can receive more daily usage times'
stringMap[strings.Mind_Ai_To_Member_Label] = 'Free usage has been used up, members can receive more daily usage times'
stringMap[strings.Mind_Ai_Wait_0_Label] = 'AI Loading'
stringMap[strings.Mind_Ai_Wait_1_Label] = 'Ai is collecting information'
stringMap[strings.Mind_Ai_Wait_2_Label] = 'Ai is organizing optimization information'
stringMap[strings.Mind_Ai_Wait_3_Label] = 'Please be patient. Your question is quite professional'
stringMap[strings.Mind_Ai_Wait_4_Label] = 'Please be patient and wait'
stringMap[strings.Mind_Ai_Wait_5_Label] = 'Already on the way back'
stringMap[strings.Mind_Ai_Submit_Content_Psuffix_Label] = 'Please list the steps'
module.exports = stringMap;