import Point from "../../../viewmodel/core/base/Point"

import MindElementCalculation from '../../../core/core/calcule/elementCalculation/MindElementCalculation'
import Colors from "../../../utils/Colors"
import LinePath from "../../../viewmodel/core/base/LinePath"
import UiUtil from '../../../utils/UiUtil'
import CGPoint from '../../../viewmodel/core/base/basedata/CGPoint'
import OrientationType from '../../../viewmodel/datatype/OrientationType'

import TimeLineNodeType from '../../../viewmodel/datatype/TimeLineNodeType'
import TimeDotElementContent from '../../../viewmodel/mindelementdata/mindcontent/TimeDotElementContent'
import TimeLineContent from '../../../viewmodel/mindelementdata/mindcontent/TimeLineContent'
import BaseElementView from "./BaseElementView";
import Config from "../../../core/core/calcule/Config"
import Util from "../../../utils/Util"
import TimeHorizontalCrisscrossLayout from "../../../viewmodel/core/layout/TimeHorizontalCrisscrossLayout"
import TimeLadderLayout from "../../../viewmodel/core/layout/TimeLadderLayout"
import LinearGradient from "./LinearGradient"
import TimeNodeLayoutType from "../../../viewmodel/datatype/TimeNodeLayoutType"





/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 *
 * 下面是Canvas类常用的方法：
 *
 * drawRect(RectF rect, Paint paint) //绘制区域，参数一为RectF一个区域 
 *
 * drawPath(Path path, Paint paint) //绘制一个路径，参数一为Path路径对象
 *
 * drawBitmap(Bitmap bitmap, Rect src, Rect dst, Paint paint)  //贴图，参数一就是我们常规的Bitmap对象，参数二是源区域(这里是bitmap)，参数三是目标区域(应该在canvas的位置和大小)，参数四是Paint画刷对象，因为用到了缩放和拉伸的可能，当原始Rect不等于目标Rect时性能将会有大幅损失。
 *
 * drawLine(float startX, float startY, float stopX, float stopY, Paintpaint) //画线，参数一起始点的x轴位置，参数二起始点的y轴位置，参数三终点的x轴水平位置，参数四y轴垂直位置，最后一个参数为Paint 画刷对象。
 *
 * drawPoint(float x, float y, Paint paint) //画点，参数一水平x轴，参数二垂直y轴，第三个参数为Paint对象。
 *
 * drawText(String text, float x, floaty, Paint paint)  //渲染文本，Canvas类除了上面的还可以描绘文字，参数一是String类型的文本，参数二x轴，参数三y轴，参数四是Paint对象。
 *
 * drawOval(RectF oval, Paint paint)//画椭圆，参数一是扫描区域，参数二为paint对象；
 *
 * drawCircle(float cx, float cy, float radius,Paint paint)// 绘制圆，参数一是中心点的x轴，参数二是中心点的y轴，参数三是半径，参数四是paint对象；
 * drawArc(RectF oval, float startAngle, float sweepAngle, boolean useCenter, Paint paint)//画弧
 */
class TimeLineView extends BaseElementView {
    constructor(viewCanvs, data, vuethis) {
        super(viewCanvs, data, vuethis)
        this.Util = Util;
        this.UiUtil = new UiUtil();
        this.defsTag = null;
        this.lineFullWidth = 6;
        this.startPoint;
        this.endPoint;
        this.arrow; //箭头
        this.arrow2; //箭头
        this.lineElement = new Array();   //线条数组
        this.circleElement = new Array(); //圆形数组
        this.textElement = new Array();    //文字数组
        this.arrowElement = new Array();   //箭头数组
        this.lineElementFirst = new Array(); //每个节点的第一根线
        this.lineElementTwo = new Array(); //每个节点第二根线
        this.removeArray = new Array(); // 需要删除的所有元素。
        this.lastLine;  //最后一条线
        this.startG; //开始的圆圈
        this.dotDefsTag; //defs标签
        this.allPathParentG; //path的g标签
        this.countNumber = 0;
        this.lineWidth = 2; //线条宽度
        this.dotLineWidth = 2; // 画中间形状的线条宽度。

        this.DrawingBoardBackgroundColor; //画布的颜色
        this.TimeHorizontalCrisscrossLayout = new TimeHorizontalCrisscrossLayout(); //折线时间线
        this.TimeLadderLayout = new TimeLadderLayout();  //梯形时间线
        this.lineWidthStrokeCap; //线条的线帽样式

        this.lineDotted; //直线的虚线比例。
        this.sCircleDotted; //s型圆弧的虚线比例
        this.onDraw();
    }

    setViewRect(width, height, x, y) {
        this.context.style.width = width + 'px';
        this.context.style.height = height + 'px';
        this.context.style.transform = "translate(" + x + 'px,' + y + 'px)'
    }

    initView() {

        this.context = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        this.context.style.width = this.mindElementData.width + 'px';
        this.context.style.height = this.mindElementData.height + 'px';
        this.context.style.transform = "translate(" + this.mindElementData.x + 'px,' + this.mindElementData.y + 'px)'
        if (this.setRect == null) {
            this.setRect = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.setRect)
        }
        this.addChildIcon = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.addChildIcon.setAttribute('class', 'add-children-icon')
        this.addChildIconImg = document.createElement('img');
        this.addChildIcon.appendChild(this.addChildIconImg);
        this.context.appendChild(this.addChildIcon);

        this.root.appendChild(this.context)
        this.createBackgroundView();

        this.bundingContextMouse();
    }

    refresh() {
        if (!this.isRefresh) {
            return;
        }
        super.refresh();
        this.onDraw();
    }
    onDraw() {
        this.lineWidth = 2;
        this.drawLine();
    }

    clearHistoryDomAndCreatedDom() { //清除上一次的线条和中心形状。
        if (this.removeArray.length != 0) { //清空所有dom
            for (let i = 0; i < this.removeArray.length; i++) {
                if (this.removeArray[i] != null) {
                    this.removeArray[i].parentNode.removeChild(this.removeArray[i])
                }
            }
            this.removeArray = []
        }
        this.lineElement = new Array();   //线条数组
        this.circleElement = new Array(); //圆形数组
        this.textElement = new Array();    //文字数组
        this.arrowElement = new Array();   //箭头数组
        this.lineElementFirst = new Array(); //每个节点的第一根线
        this.lineElementTwo = new Array(); //每个节点第二根线

        if (this.arrow != null) {
            this.arrow = null;
        }
        if (this.arrow2 != null) {
            if (this.arrow2.parentNode != null) {
                this.arrow2.parentNode.removeChild(this.arrow2);
            }
            this.arrow2 = null;
        }

        if (this.startG != null) {
            this.startG.parentNode.removeChild(this.startG);
            this.startG = null;
        }

        if (this.dotDefsTag != null) {
            if (this.dotDefsTag.parentNode != null) {
                this.dotDefsTag.parentNode.removeChild(this.dotDefsTag);
            }
            this.dotDefsTag = null;
        }

        // if (this.defsTag != null) {
        //     if (this.defsTag.parentNode != null) {
        //         this.defsTag.parentNode.removeChild(this.defsTag);
        //     }
        //     this.defsTag = null
        // }

        if (this.allPathParentG != null) {
            this.allPathParentG.parentNode.removeChild(this.allPathParentG);
            this.allPathParentG = null
        }

        //包裹开始点小圆的g标签。
        this.startG = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        this.context.appendChild(this.startG);



        this.dotDefsTag = document.createElementNS('http://www.w3.org/2000/svg', "defs");
        this.context.appendChild(this.dotDefsTag);
        
        this.allPathParentG = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        this.context.appendChild(this.allPathParentG);


    };



    drawLine(startPoint, endPoint) {

        if (arguments.length == 0) {
            if (this.mindElementData == null || this.mindElementData.timeLineContent == null) {
                return;
            }

            let timeLineContent = this.mindElementData.timeLineContent;
            this.lineWidth = timeLineContent.lineWidth
            this.dotLineWidth = Math.min(this.UiUtil.dip2px(3), this.lineWidth);
            this.clearHistoryDomAndCreatedDom();
            if (this.lineWidth == 0 || this.mindElementData.timeLineContent.dots.length == 0) {
                return;
            }
            if ((timeLineContent.orientationType == OrientationType.HORIZONTAL_CRISSCROSS ||
                this.lineWidth < this.UiUtil.dip2px(11)) && timeLineContent.orientationType != OrientationType.HORIZONTAL_RING) {
                this.lineWidthStrokeCap = 'round'
            } else {
                this.lineWidthStrokeCap = 'square'
            }
            this.DrawingBoardBackgroundColor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)

            let startPoint = new CGPoint(0, 0);
            if (timeLineContent.orientationType == OrientationType.LADDER ||
                timeLineContent.orientationType == OrientationType.HORIZONTAL_CRISSCROSS) {
                startPoint = new CGPoint(0, this.mindElementData.height);
            } else if (timeLineContent.orientationType == OrientationType.BRIDGE) {
                startPoint = new CGPoint(0, this.mindElementData.height / 2);
            } else if (timeLineContent.orientationType == OrientationType.TIME_S) {
                startPoint = new CGPoint(this.mindElementData.width / 2, this.mindElementData.height);
            } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                startPoint = new CGPoint(this.mindElementData.timeLineContent.dots[0].x - this.mindElementData.timeLineContent.dots[0].left/2, 
                    this.mindElementData.timeLineContent.dots[0].y);
            }
            let endPoint = new CGPoint(0, 0);
            let radius = 6;
            let circleCenterPoint = Util.getCircleCenter(new Point(0, 0),
                new Point(this.mindElementData.timeLineContent.dots[0].x, this.mindElementData.timeLineContent.dots[0].y),
                new Point(0, this.mindElementData.height));
            let lastTimeDotElementContent = null;

            if (timeLineContent.dottedLine) {
                //直线
                this.lineDotted = (this.UiUtil.dip2px(2) + this.lineWidth) + ',' + (this.UiUtil.dip2px(4) + this.lineWidth)
                //圆弧
                this.sCircleDotted = (this.UiUtil.dip2px(2) + this.lineWidth) + ',' + (this.UiUtil.dip2px(2) + this.lineWidth)
            } else {
                this.lineDotted = null;
                this.sCircleDotted = null;
            }

            if (this.mindElementData.timeLineContent.dots.length > 0) {
                let count = (this.mindElementData.timeLineContent.dots).length;
                for (let index = 0; index < count; index++) {
                    if (!this.isDrawLine()) {
                        break
                    }
                    let dot = this.mindElementData.timeLineContent.dots[index];
                    lastTimeDotElementContent = dot;
                    radius = dot.radius;                    
                    if (timeLineContent.orientationType == OrientationType.HORIZONTAL_CRISSCROSS) {
                        if (index % 2 == 0) {
                            endPoint.x = dot.x + ((radius) * this.Util.cosd(90 - this.TimeHorizontalCrisscrossLayout.ladderAngle + 90));
                            endPoint.y = dot.y + ((radius) * this.Util.sind(90 - this.TimeHorizontalCrisscrossLayout.ladderAngle + 90));
                            let path = this.drawLine(startPoint, endPoint);
                            this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                            startPoint.x = dot.x + ((radius) * this.Util.cosd(this.TimeHorizontalCrisscrossLayout.ladderAngle));
                            startPoint.y = dot.y + ((radius) * this.Util.sind(this.TimeHorizontalCrisscrossLayout.ladderAngle));
                        } else {
                            endPoint.x = dot.x + ((radius) * this.Util.cosd(180 + this.TimeHorizontalCrisscrossLayout.ladderAngle));
                            endPoint.y = dot.y + ((radius) * this.Util.sind(180 + this.TimeHorizontalCrisscrossLayout.ladderAngle));
                            let path = this.drawLine(startPoint, endPoint);
                            this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                            startPoint.x = dot.x + ((radius) * this.Util.cosd(360 - this.TimeHorizontalCrisscrossLayout.ladderAngle));
                            startPoint.y = dot.y + ((radius) * this.Util.sind(360 - this.TimeHorizontalCrisscrossLayout.ladderAngle));
                        }
                    } else if (timeLineContent.orientationType == OrientationType.LADDER) {
                        endPoint.x = dot.x + ((radius) * this.Util.cosd(90 - this.TimeLadderLayout.ladderAngle + 90));
                        endPoint.y = dot.y + ((radius) * this.Util.sind(90 - this.TimeLadderLayout.ladderAngle + 90));
                        let path = this.drawLine(startPoint, endPoint);
                        this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                        startPoint.x = dot.x + ((radius) * this.Util.cosd(360 - this.TimeLadderLayout.ladderAngle));
                        startPoint.y = dot.y + ((radius) * this.Util.sind(360 - this.TimeLadderLayout.ladderAngle));
                    } else if (timeLineContent.orientationType == OrientationType.VERTICAL) {  //竖向排列时间线
                        startPoint.x = dot.x;
                        let path = this.drawLine(startPoint, new CGPoint(startPoint.x, dot.y - radius));
                        this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                        startPoint = new CGPoint(dot.x, dot.y + radius);

                    } else if (timeLineContent.orientationType == OrientationType.SEMICIRCLE) {  //竖向排列时间线
                        let path = this.drawArcLineByColor(startPoint, new CGPoint(dot.x , dot.y), dot.color, circleCenterPoint);
                        this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                        startPoint = new CGPoint(dot.x, dot.y);
                    } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) {    //横向排列时间线
                        startPoint.y = dot.y;
                        let path = this.drawLine(startPoint, new CGPoint((dot.x) - radius, (dot.y)));
                        this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                        startPoint = new CGPoint((dot.x) + radius, (dot.y));
                    } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S) {   //s排列时间线
                        let lindWidthSpace = this.lineWidth / 4;
                        if (index == 0) {
                            startPoint.y = (dot.y);
                            startPoint.x = (dot.x - dot.left);
                            endPoint = new CGPoint((dot.x) + radius + lindWidthSpace, (dot.y));
                            let path = this.drawLine(startPoint, endPoint);
                            this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                            startPoint = new CGPoint((dot.x) + radius + lindWidthSpace, (dot.y));

                        } else {

                            let preDot = this.mindElementData.timeLineContent.dots[index - 1];
                            let isLastDot = index == count - 1;
                            let nextDot = isLastDot ? dot : this.mindElementData.timeLineContent.dots[index + 1];
                            if (dot.lineClockwise) {
                                if (Math.abs(preDot.y - dot.y) > 15) {
                                    if (!isLastDot && Math.abs(dot.y - nextDot.y) > 15) {
                                        let right = this.getRightX()
                                        let path = this.drawLine(startPoint, new CGPoint(right + radius + lindWidthSpace, dot.y));
                                        this.renderCanvas(path, null, 'lineElement', nextDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
    
                                        let sCircleRadius = (nextDot.y - dot.y) / 2;
                                        let lineStartPoint = new CGPoint(right + radius + lindWidthSpace, dot.y);
                                        let circleStartPoint = new CGPoint(right + dot.right, dot.y);
                                        let circleEndPoint = new CGPoint(right + dot.right, (dot.y) + (nextDot.y - dot.y));
                                        let lineEndPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, nextDot.y);
                                        let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius,
                                            sCircleRadius, 0, 1, 1, circleEndPoint, lineEndPoint);
                                        this.renderCanvas(path1, null, 'lineElement', nextDot.color, this.lineWidth,
                                            this.lineWidthStrokeCap, null, this.lineDotted)
                                        startPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, (nextDot.y));
                                    }
                                    continue;
                                }
                                if (!isLastDot && Math.abs(dot.y - nextDot.y) > 15) {

                                    let path = this.drawLine(startPoint, new CGPoint(dot.x + radius + lindWidthSpace, dot.y));
                                    this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                                    let sCircleRadius = (nextDot.y - dot.y) / 2;
                                    let lineStartPoint = new CGPoint(dot.x + radius + lindWidthSpace, dot.y);
                                    let circleStartPoint = new CGPoint(dot.x + dot.right, dot.y);
                                    let circleEndPoint = new CGPoint(dot.x + dot.right, (dot.y) + (nextDot.y - dot.y));
                                    let lineEndPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, nextDot.y);
                                    let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius,
                                        sCircleRadius, 0, 1, 1, circleEndPoint, lineEndPoint);
                                    this.renderCanvas(path1, null, 'lineElement', nextDot.color, this.lineWidth,
                                        this.lineWidthStrokeCap, null, this.lineDotted)
                                    startPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, (nextDot.y));
                                } else {
                                    endPoint = new CGPoint(dot.x + radius + lindWidthSpace, dot.y);
                                    let path = this.drawLine(startPoint, endPoint);
                                    this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                                    startPoint = new CGPoint(dot.x + radius + lindWidthSpace, dot.y);
                                }
                            } else {
                                if (Math.abs(preDot.y - dot.y) > 15) {
                                    if (!isLastDot && Math.abs(dot.y - nextDot.y) > 15) {
                                        let left = this.getLeftX()
                                        let path = this.drawLine(startPoint, new CGPoint(left - radius - lindWidthSpace, dot.y));
                                        this.renderCanvas(path, null, 'lineElement', nextDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                                        let sCircleRadius = (nextDot.y - dot.y) / 2;
                                        let lineStartPoint = new CGPoint(left - radius - lindWidthSpace, dot.y);
                                        let circleStartPoint = new CGPoint(left - dot.left, dot.y);
                                        let circleEndPoint = new CGPoint(left - dot.left, (dot.y) + (nextDot.y - dot.y));
                                        let lineEndPoint = new CGPoint(nextDot.x + nextDot.radius + lindWidthSpace, nextDot.y);
                                        let sweep = lineEndPoint.y > lineStartPoint.y ? 0 : 1
                                        let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius, sCircleRadius, 0, 1, sweep, circleEndPoint, lineEndPoint);
                                        this.renderCanvas(path1, null, 'lineElement', nextDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                                        startPoint = new CGPoint(nextDot.x + nextDot.radius + lindWidthSpace, nextDot.y);
                                    }
                                    continue;
                                }

                                if (!isLastDot && Math.abs(dot.y - nextDot.y) > 15) {

                                    let path = this.drawLine(startPoint, new CGPoint(dot.x - radius - lindWidthSpace, dot.y));
                                    this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)



                                    let sCircleRadius = (nextDot.y - dot.y) / 2;
                                    let lineStartPoint = new CGPoint(dot.x - radius - lindWidthSpace, dot.y);
                                    let circleStartPoint = new CGPoint(dot.x - dot.left, dot.y);
                                    let circleEndPoint = new CGPoint(dot.x - dot.left, (dot.y) + (nextDot.y - dot.y));
                                    let lineEndPoint = new CGPoint(nextDot.x + nextDot.radius + lindWidthSpace, nextDot.y);
                                    let sweep = lineEndPoint.y > lineStartPoint.y ? 0 : 1
                                    let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius, sCircleRadius, 0, 1, sweep, circleEndPoint, lineEndPoint);
                                    this.renderCanvas(path1, null, 'lineElement', nextDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                                    startPoint = new CGPoint(nextDot.x + nextDot.radius + lindWidthSpace, nextDot.y);


                                } else {
                                    endPoint = new CGPoint((dot.x) - radius - lindWidthSpace, (dot.y));
                                    let path = this.drawLine(startPoint, endPoint);
                                    this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                                    startPoint = new CGPoint((dot.x) - radius - lindWidthSpace, (dot.y));
                                }
                            }
                        }
                    } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {   //Ring排列时间线
                        let lindWidthSpace = this.lineWidth / 4;
                        if (index == 0) {
                            startPoint.y = (dot.y);
                            startPoint.x = (dot.x - dot.left / 2);
                            endPoint = new CGPoint((dot.x) + radius + lindWidthSpace, (dot.y));
                            let path = this.drawLine(startPoint, endPoint);
                            this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                            startPoint = new CGPoint((dot.x) + radius + lindWidthSpace, (dot.y));

                            let isLastDot = index == count - 1;
                            let nextDot = isLastDot ? dot : this.mindElementData.timeLineContent.dots[index + 1];
                            if (!isLastDot && Math.abs(dot.y - nextDot.y) > 15) {
                                let right = dot.x + dot.right
                                let path = this.drawLine(startPoint, new CGPoint(right, dot.y));
                                this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)                                
                                
                                let sCircleRadius = (nextDot.y - dot.y) / 2;
                                let lineStartPoint = new CGPoint(right, dot.y);
                                let circleStartPoint = new CGPoint(right, dot.y);
                                let circleEndPoint = new CGPoint(right, (dot.y) + (nextDot.y - dot.y));
                                let lineEndPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, nextDot.y);
                                let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius,
                                    sCircleRadius, 0, 1, 1, circleEndPoint, lineEndPoint);
                                    
                                this.renderCanvas(path1, null, 'lineElement', nextDot.color, this.lineWidth,
                                    this.lineWidthStrokeCap, null, this.lineDotted)
                                startPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, (nextDot.y));
                                if (this.mindElementData.timeLineContent.isShowArrow) {
                                    if (this.arrow2 == null) {
                                        this.arrow2 = document.createElementNS('http://www.w3.org/2000/svg', "path")
                                        this.allPathParentG.appendChild(this.arrow2)
                                        this.removeArray.push(this.arrow2);
                                    }
                                    let arrowHeight = this.lineWidth + Math.min(this.lineWidth * 8, this.lineWidth < 6 ? 15 : 10)
                                    if (this.lineWidth > 22) {
                                        arrowHeight = this.lineWidth * 0.7
                                    }
                                    let arrowColor = dot.color
                                    if (dot.color == nextDot.color) {
                                        if (Colors.isDarkColor(arrowColor)) {
                                            arrowColor = Colors.getNumberToRgbDark(arrowColor, -0.3)
                                        } else {
                                            arrowColor = Colors.getNumberToRgbDark(arrowColor, 0.3)
                                        }
                                    }      
                                    this.drawArrowByColor(new CGPoint(dot.x - arrowHeight, dot.y), new CGPoint(right - arrowHeight, dot.y), arrowColor, arrowHeight, this.arrow2);
                                }
                            }
                        } else {

                            let isLastDot = index == count - 1;
                            let nextDot = isLastDot ? dot : this.mindElementData.timeLineContent.dots[index + 1];
                            if (!isLastDot && Math.abs(dot.y - nextDot.y) > 15) {
                                let right = dot.x + dot.right
                                let path = this.drawLine(startPoint, new CGPoint(right, dot.y));
                                this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted, nextDot.color, index)                                
                                
                                let sCircleRadius = (nextDot.y - dot.y) / 2;                                
                                let lineStartPoint = new CGPoint(right, dot.y);
                                if (!this.mindElementData.timeLineContent.isShowArrow) {
                                    lineStartPoint = new CGPoint(dot.x + radius + lindWidthSpace, dot.y);
                                }
                                let circleStartPoint = new CGPoint(right, dot.y);
                                let circleEndPoint = new CGPoint(right, (dot.y) + (nextDot.y - dot.y));
                                let lineEndPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, nextDot.y);
                                let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius,
                                    sCircleRadius, 0, 1, 1, circleEndPoint, lineEndPoint);
                                    
                                this.renderCanvas(path1, null, 'lineElement', nextDot.color, this.lineWidth,
                                    this.lineWidthStrokeCap, null, this.lineDotted, nextDot.color, index)
                                startPoint = new CGPoint(nextDot.x - nextDot.radius - lindWidthSpace, (nextDot.y));
                                
                                if (this.mindElementData.timeLineContent.isShowArrow) {
                                    if (this.arrow2 == null) {
                                        this.arrow2 = document.createElementNS('http://www.w3.org/2000/svg', "path")
                                        this.allPathParentG.appendChild(this.arrow2)
                                        this.removeArray.push(this.arrow2);
                                    }
                                    let arrowHeight = this.lineWidth + Math.min(this.lineWidth * 8, this.lineWidth < 6 ? 15 : 10)
                                    if (this.lineWidth > 22) {
                                        arrowHeight = this.lineWidth * 0.7
                                    }
                                    let arrowColor = dot.color
                                    if (dot.color == nextDot.color) {
                                        if (Colors.isDarkColor(arrowColor)) {
                                            arrowColor = Colors.getNumberToRgbDark(arrowColor, -0.3)
                                        } else {
                                            arrowColor = Colors.getNumberToRgbDark(arrowColor, 0.3)
                                        }
                                    }      
                                    this.drawArrowByColor(new CGPoint(dot.x - arrowHeight, dot.y), new CGPoint(right - arrowHeight, dot.y), arrowColor, arrowHeight, this.arrow2);
                                }
                            } else {
                                if (dot.lineClockwise) {
                                    endPoint = new CGPoint(dot.x + radius + lindWidthSpace, dot.y);
                                } else {
                                    endPoint = new CGPoint(dot.x - radius - lindWidthSpace, dot.y);
                                }
                                let path = this.drawLine(startPoint, endPoint);
                                this.renderCanvas(path, null, 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted, nextDot.color, index)
                                if (dot.lineClockwise) {
                                    startPoint = new CGPoint(dot.x + radius + lindWidthSpace, dot.y);
                                } else {
                                    startPoint = new CGPoint(dot.x - radius - lindWidthSpace, dot.y);
                                }
                                
                            }
                        }
                    } else if (timeLineContent.orientationType == OrientationType.BRIDGE) {  //桥型图
                        if (index == 0) {
                            startPoint.x = dot.x;
                            startPoint.y = dot.y;
                            continue;
                        }
                        let path = this.drawLine(startPoint, new CGPoint(dot.x, dot.y));
                        this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)
                        startPoint = new CGPoint(dot.x, dot.y);
                    } else if (timeLineContent.orientationType == OrientationType.TIME_S) {  //时间线
                        let nextDot = null
                        let prePoint = null
                        if (index > 1) {
                            prePoint = this.mindElementData.timeLineContent.dots[index - 2];
                        }
                        if (index == count - 1) {
                            nextDot = null
                        } else {
                            nextDot = this.mindElementData.timeLineContent.dots[index + 1];
                        }
                        let path = this.drawCubicLineByColor(prePoint, startPoint, new CGPoint(dot.x, dot.y), nextDot);
                        let lineWidth = Math.floor(this.lineWidth * 5) * 2
                        for (let i = -lineWidth/2; i < lineWidth/2; i++) {
                            let endSpace = index == count - 1 ? 0 : i
                            let startSpace = i
                            let pathItem = null;
                            if (prePoint == null && nextDot == null) {
                                pathItem = this.drawCubicLineByColor(prePoint, new CGPoint(startPoint.x - startSpace, startPoint.y), 
                                new CGPoint(dot.x - endSpace, dot.y), nextDot);
                            } else if (prePoint == null) {
                                pathItem = this.drawCubicLineByColor(prePoint, new CGPoint(startPoint.x - startSpace, startPoint.y), 
                                new CGPoint(dot.x - endSpace, dot.y), new CGPoint(nextDot.x + endSpace, nextDot.y));
                            } else if (nextDot == null) {
                                pathItem = this.drawCubicLineByColor(new CGPoint(prePoint.x - startSpace, prePoint.y), new CGPoint(startPoint.x - startSpace, startPoint.y), 
                                new CGPoint(dot.x - endSpace, dot.y), nextDot);
                            } else {
                                pathItem = this.drawCubicLineByColor(new CGPoint(prePoint.x - startSpace, prePoint.y), new CGPoint(startPoint.x - startSpace, startPoint.y), 
                                new CGPoint(dot.x - endSpace, dot.y), new CGPoint(nextDot.x - endSpace, nextDot.y));
                            }
                            this.renderCanvas(pathItem, this.lineElement[index], 'lineElement', dot.color, 2, this.lineWidthStrokeCap, null, this.lineDotted)
                        }
                        this.renderCanvas(path, this.lineElement[index], 'lineElement', dot.color, 2, this.lineWidthStrokeCap, null, this.lineDotted)
                        startPoint = new CGPoint(dot.x, dot.y);
                    }
                }

                let lastDot = this.mindElementData.timeLineContent.dots[this.mindElementData.timeLineContent.dots.length - 1];

                if (!this.isDrawLine()) {
                    
                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_CRISSCROSS) {

                } else if (timeLineContent.orientationType == OrientationType.LADDER) {
                    let endRadius = ((this.mindElementData.width - startPoint.x) / this.Util.cosd(this.TimeLadderLayout.ladderAngle));
                    endPoint.x = startPoint.x + ((endRadius) * this.Util.cosd(360 - this.TimeLadderLayout.ladderAngle));
                    endPoint.y = startPoint.y + ((endRadius) * this.Util.sind(360 - this.TimeLadderLayout.ladderAngle));
                    let path = this.drawLine(startPoint, endPoint);
                    this.renderCanvas(path, this.lastLine, 'lastLine', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                    if (this.mindElementData.timeLineContent.isShowArrow) {
                        this.drawArrowByColor(startPoint, endPoint, lastDot.color, this.lineWidth * 1.2);
                    }
                } else if (timeLineContent.orientationType == OrientationType.VERTICAL) {  //垂直最后一条线
                    let path = this.drawLine(startPoint, new CGPoint(startPoint.x, this.mindElementData.height));
                    this.renderCanvas(path, this.lastLine, 'lastLine', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                    if (this.mindElementData.timeLineContent.isShowArrow) {
                        this.drawArrowByColor(startPoint, new CGPoint(startPoint.x, (this.mindElementData.height)), lastDot.color, this.lineWidth * 1.2);
                    }
                } else if (timeLineContent.orientationType == OrientationType.SEMICIRCLE) {  //垂直最后一条线
                    let path = this.drawArcLineByColor(startPoint, new CGPoint(0, this.mindElementData.height), lastDot.color, circleCenterPoint);
                    this.renderCanvas(path, this.lastLine, 'lastLine', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                    if (this.mindElementData.timeLineContent.isShowArrow) {
                        let startTangentR = Util.getPointSpacing(circleCenterPoint, new CGPoint(0, this.mindElementData.height)) / Util.cosd(30)
                        let endAngle = Util.getCircleDegreesInPoint(circleCenterPoint, new CGPoint(0, this.mindElementData.height));
                        let tangentAngle = endAngle - 30;
                        if (endAngle < 30) {
                            tangentAngle = 360 - (30 - endAngle)
                        }
                        let tangentPoint = Util.getCirclePoint(circleCenterPoint, tangentAngle, startTangentR)
                        this.drawArrowByColor(tangentPoint, new CGPoint(0, this.mindElementData.height), lastDot.color, this.lineWidth * 1.2);
                    }
                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) { //水平最后一条线
                    endPoint = new CGPoint(this.mindElementData.width, startPoint.y);
                    let path = this.drawLine(startPoint, new CGPoint(this.mindElementData.width, startPoint.y));
                    this.renderCanvas(path, this.lastLine, 'lastLine', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                    if (this.mindElementData.timeLineContent.isShowArrow) {
                        this.drawArrowByColor(startPoint, endPoint, lastDot.color, this.lineWidth * 1.2);
                    }
                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S && lastTimeDotElementContent != null) {  //s形最后一条线
                    let arrowSize = 6;
                    if (lastTimeDotElementContent.lineClockwise) {
                        let path = this.drawLine(startPoint, new CGPoint((lastTimeDotElementContent.x + lastTimeDotElementContent.right), startPoint.y));
                        this.renderCanvas(path, this.lastLine, 'lastLine', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                        if (this.mindElementData.timeLineContent.isShowArrow) {
                            this.drawArrowByColor(new CGPoint((lastTimeDotElementContent.x + lastTimeDotElementContent.right - arrowSize), startPoint.y),
                                new CGPoint((lastTimeDotElementContent.x + lastTimeDotElementContent.right), startPoint.y), lastDot.color, this.lineWidth * 1.2);
                        }
                    } else {
                        let path = this.drawLine(startPoint, new CGPoint((lastTimeDotElementContent.x - lastTimeDotElementContent.left), startPoint.y));
                        this.renderCanvas(path, this.lastLine, 'lastLine', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                        if (this.mindElementData.timeLineContent.isShowArrow) {
                            this.drawArrowByColor(new CGPoint((lastTimeDotElementContent.x - lastTimeDotElementContent.left + arrowSize), startPoint.y),
                                new CGPoint((lastTimeDotElementContent.x - lastTimeDotElementContent.left), startPoint.y), lastDot.color, this.lineWidth * 1.2);
                        }
                    }
                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_RING && lastTimeDotElementContent != null) {  //Ring形最后一条线                    
                    let firstPoint = this.mindElementData.timeLineContent.dots[0];
                    let topLeft = firstPoint.x - firstPoint.left
                    let bottomLeft = lastDot.x - lastDot.left
                    
                    let path = this.drawLine(new CGPoint(lastDot.x - radius, lastDot.y), 
                                                new CGPoint(Math.min(bottomLeft, topLeft), lastDot.y));
                    this.renderCanvas(path, null, 'lineElement', lastDot.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                    let lindWidthSpace = this.lineWidth/4;
                    let sCircleRadius = (lastDot.y - firstPoint.y) / 2;
                    let lineStartPoint = new CGPoint(bottomLeft, lastDot.y);
                    if (!this.mindElementData.timeLineContent.isShowArrow) {
                        lineStartPoint = new CGPoint(lastDot.x - radius - lindWidthSpace, lastDot.y);
                    }
                    let circleStartPoint = new CGPoint(Math.min(topLeft, bottomLeft), lastDot.y);
                    let circleEndPoint = new CGPoint(Math.min(topLeft, bottomLeft), firstPoint.y);
                    let lineEndPoint = new CGPoint(firstPoint.x - radius - lindWidthSpace, firstPoint.y);
                    let sweep = lineEndPoint.y > lineStartPoint.y ? 0 : 1
                    let path1 = this.drawSCircle(lineStartPoint, circleStartPoint, sCircleRadius, sCircleRadius, 0, 1, sweep, circleEndPoint, lineEndPoint);
                    this.renderCanvas(path1, null, 'lineElement', firstPoint.color, this.lineWidth, this.lineWidthStrokeCap, null, this.lineDotted)

                    if (this.mindElementData.timeLineContent.isShowArrow) {
                        let arrowHeight = this.lineWidth + Math.min(this.lineWidth * 8, this.lineWidth < 6 ? 15 : 10)
                        if (this.lineWidth > 22) {
                            arrowHeight = this.lineWidth * 0.7
                        }
                        let arrowColor = lastDot.color
                        if (lastDot.color == firstPoint.color) {
                            if (Colors.isDarkColor(arrowColor)) {
                                arrowColor = Colors.getNumberToRgbDark(arrowColor, -0.3)
                            } else {
                                arrowColor = Colors.getNumberToRgbDark(arrowColor, 0.3)
                            }
                        }                    
                        this.drawArrowByColor(lastDot, new CGPoint(bottomLeft, circleStartPoint.y), arrowColor, arrowHeight);
                    }
                }
                if (this.mindElementData.timeLineContent.dots.length > 0 && 
                    timeLineContent.orientationType != OrientationType.BRIDGE &&
                    timeLineContent.orientationType != OrientationType.TIME_S && this.isDrawLine()) {
                    let dot = this.mindElementData.timeLineContent.dots[0];
                    radius = Math.min(dot.radius, this.UiUtil.dip2px(16));
                    if (this.lineWidth < this.UiUtil.dip2px(11) || this.lineWidth < radius / 2) {
                        if (timeLineContent.orientationType == OrientationType.LADDER ||
                            timeLineContent.orientationType == OrientationType.HORIZONTAL_CRISSCROSS) {
                            let alphaBigCircle = document.createElementNS('http://www.w3.org/2000/svg', "path");
                            let centerSmallCircle = document.createElementNS('http://www.w3.org/2000/svg', "path");
                            this.startG.appendChild(alphaBigCircle);
                            this.startG.appendChild(centerSmallCircle);
                            let path = this.drawYuanByColorAndText(0, this.mindElementData.height, radius)
                            this.renderCanvas(path[0], alphaBigCircle, 'circleElement', null, 0, null, Colors.getUiColorByAlpha(dot.color, 0.5));
                            let centerPath = this.drawYuanByColorAndText(0, this.mindElementData.height, radius / 2)
                            this.renderCanvas(centerPath[0], centerSmallCircle, 'circleElement', null, 0, null, Colors.getUiColor(dot.color));
                        } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S) {
                            let alphaBigCircle = document.createElementNS('http://www.w3.org/2000/svg', "path");
                            let centerSmallCircle = document.createElementNS('http://www.w3.org/2000/svg', "path");
                            this.startG.appendChild(alphaBigCircle);
                            this.startG.appendChild(centerSmallCircle);
                            let path = this.drawYuanByColorAndText(dot.x - dot.left, dot.y, radius)
                            this.renderCanvas(path[0], alphaBigCircle, 'circleElement', null, 0, null, Colors.getUiColorByAlpha(dot.color, 0.5));
                            let centerPath = this.drawYuanByColorAndText(dot.x - dot.left, dot.y, radius / 2)
                            this.renderCanvas(centerPath[0], centerSmallCircle, 'circleElement', null, 0, null, Colors.getUiColor(dot.color));
                        }
                    }
                }

                if (this.isDrawDot() && timeLineContent.orientationType != OrientationType.BRIDGE &&
                    timeLineContent.orientationType != OrientationType.TIME_S) {
                    for (let index = 0; index < count; index++) {
                        let dot = this.mindElementData.timeLineContent.dots[index];
                        radius = dot.radius;
                        let path = null;
                        switch (this.mindElementData.timeLineContent.nodeType) {
                            case TimeLineNodeType.TIME_LINE_CIRCULAR:
                                path = this.drawYuan((dot.x), (dot.y), radius, dot.isSelected);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                break;
                            case TimeLineNodeType.TIME_LINE_RING_ORDER:
                                let ringLineWidth = Math.min(2 + this.dotLineWidth, 8)
                                let outsideLineWidth = ringLineWidth / 2
                                let space = ringLineWidth/2 + outsideLineWidth / 2 + 4
                                if (timeLineContent.orientationType == OrientationType.VERTICAL) {
                                    let nextPoint = new CGPoint(dot.x,
                                        index == count - 1 ? this.mindElementData.height : this.mindElementData.timeLineContent.dots[index + 1].y - radius - space - 4 - space)
                                    
                                    let endPoint = new CGPoint(8, dot.y)
                                    if (endPoint.x > dot.x - radius - space) {
                                        endPoint.x = dot.x - radius - space
                                    }
                                    path = this.getRingLinePath(new CGPoint(dot.x, dot.y), 
                                                            new CGPoint(dot.x, dot.y - radius - space), 
                                                            endPoint, nextPoint, null, 
                                                            radius + space, 1);

                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, outsideLineWidth, this.lineWidthStrokeCap, 'rgba(255,255,255, 0.0)', null)

                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) {
                                    let nextPoint = new CGPoint(index == count - 1 ? this.mindElementData.width : this.mindElementData.timeLineContent.dots[index + 1].x - radius - space - 4 - space, 
                                        dot.y)
                                    let endPoint = new CGPoint((dot.x), 8)
                                    if (endPoint.y > dot.y - radius - space) {
                                        endPoint.y = dot.y - radius - space
                                    }
                                    path = this.getRingLinePath(new CGPoint(dot.x, dot.y), 
                                                                new CGPoint(dot.x - radius - space, dot.y), 
                                                                endPoint, nextPoint, null,
                                                                radius + space, 0);

                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, outsideLineWidth, this.lineWidthStrokeCap, 'rgba(255,255,255, 0.0)', null)
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S || timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                                    let nextPoint = null
                                    let boundaryPoint = null
                                    if (dot.lineClockwise) {
                                        if (index == count - 1) {
                                            nextPoint = new CGPoint(dot.x + radius + space + 4 + space + 50, dot.y)
                                        } else {
                                            let nextDot = this.mindElementData.timeLineContent.dots[index + 1]
                                            if (nextDot.y - dot.y > 10) {
                                                nextPoint = new CGPoint(nextDot.x + radius + space + 4 + space, nextDot.y)
                                                boundaryPoint = new CGPoint(Math.max(nextDot.x + nextDot.right, dot.x + dot.right), nextDot.y)
                                            } else {
                                                nextPoint = new CGPoint(nextDot.x - radius - space - 4 - space, nextDot.y)
                                            }
                                        }
                                        let endPoint = new CGPoint(dot.x, dot.y - (timeLineContent.lineContentHeight) / 2 + 8)
                                        if (endPoint.y > dot.y - radius - space) {
                                            endPoint.y = dot.y - radius - space
                                        }
                                        path = this.getRingLinePath(new CGPoint(dot.x, dot.y), 
                                        new CGPoint(dot.x - radius - space, dot.y), 
                                        endPoint, 
                                        nextPoint, boundaryPoint,
                                        radius + space, 0);
                                    } else {
                                        nextPoint = new CGPoint(index == count - 1 ? 0 : this.mindElementData.timeLineContent.dots[index + 1].x + radius + space + 4 + space, 
                                            dot.y)
                                        if (index == count - 1) {
                                            if (timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                                                let firstDot = this.mindElementData.timeLineContent.dots[0]
                                                nextPoint = new CGPoint(firstDot.x - radius - space - 4 - space, firstDot.y)
                                                boundaryPoint = new CGPoint(Math.max(firstDot.x - firstDot.left, dot.x - dot.left), nextPoint.y)
                                            } else {
                                                nextPoint = new CGPoint(dot.x - radius - space - 4 - space - 50, dot.y)
                                            }
                                        } else {
                                            let nextDot = this.mindElementData.timeLineContent.dots[index + 1]
                                            if (nextDot.y - dot.y > 10) {
                                                nextPoint = new CGPoint(nextDot.x - radius - space - 4 - space, nextDot.y)
                                                boundaryPoint = new CGPoint(Math.max(nextDot.x - nextDot.left, dot.x - dot.left), nextDot.y)
                                            } else {
                                                nextPoint = new CGPoint(nextDot.x + radius + space + 4 + space, nextDot.y)
                                            }
                                        }
                                        let endPoint = new CGPoint((dot.x), (dot.y) - (timeLineContent.lineContentHeight) / 2 + 8)
                                        if (endPoint.y > dot.y - radius - space) {
                                            endPoint.y = dot.y - radius - space
                                        }
                                        path = this.getRingLinePath(new CGPoint(dot.x, dot.y), 
                                        new CGPoint(dot.x + radius + space, dot.y), 
                                        endPoint, 
                                        nextPoint, boundaryPoint,
                                        radius + space, 1);
                                    }
                                    

                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, outsideLineWidth, this.lineWidthStrokeCap, 'rgba(255,255,255, 0.0)', null)

                                    // path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius), new CGPoint((dot.x), (dot.y) - (timeLineContent.lineContentHeight) / 2), dot.color);
                                    // this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                }
                                color = dot.color
                                if (Colors.isDarkColor(dot.color) && Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
                                    color = Colors.getNumberToRgbStringDark(color, -0.2)
                                } else if (!Colors.isDarkColor(dot.color) && !Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
                                    color = Colors.getNumberToRgbStringDark(color, 0.4)
                                }
                                path = this.drawYuanByColorAndText((dot.x), (dot.y), radius, dot.isSelected, color, "" + (index + 1), 14);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, ringLineWidth, this.lineWidthStrokeCap, 'rgba(255,255,255, 0.0)', null)
                                path[1].x = dot.x;
                                path[1].y = dot.y;
                                
                                this.renderTextCanvas(path[1], this.textElement[index], 'textElement', Colors.getUiColor(color))
                                break;
                            case TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER:
                                path = this.drawYuanByColorAndText((dot.x), (dot.y), radius, dot.isSelected, dot.color, "" + (index + 1), 14);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                path[1].x = dot.x;
                                path[1].y = dot.y;
                                let color = dot.color
                                if (Colors.isDarkColor(dot.color) && Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
                                    color = Colors.getNumberToRgbStringDark(color, -0.2)
                                } else if (!Colors.isDarkColor(dot.color) && !Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
                                    color = Colors.getNumberToRgbStringDark(color, 0.4)
                                }                                
                                this.renderTextCanvas(path[1], this.textElement[index], 'textElement', Colors.getUiColor(color))
                                break;

                            case TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER_1:
                                this.drawYuan3DByColorAndText((dot.x), (dot.y), radius, dot.isSelected, dot.color, "" + (index + 1), dot.targetId, index);
                                break;
                            case TimeLineNodeType.TIME_LINE_HEXAGON_ORDER:
                                this.drawHexagonColorAndText((dot.x), (dot.y), radius, dot.isSelected, dot.color, "" + (index + 1), dot.targetId);
                                break;
                            case TimeLineNodeType.TIME_LINE_DIAMOND:
                                path = this.drawDiamond((dot.x), (dot.y), radius);
                                this.renderCanvas(path, this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                break;


                            case TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD:
                                let arrowSize = (new UiUtil()).getArrowWidth(new Point(0, 0), new Point(20, 0), this.lineWidth);
                                let arrowSpace = 2
                                if (timeLineContent.orientationType == OrientationType.VERTICAL) {
                                    path = this.drawArrowList(new CGPoint(
                                        (((dot.x) - radius) - arrowSize) / 2 - arrowSize * 30,
                                        (dot.y)),
                                        new CGPoint((dot.x - radius) - arrowSpace - arrowSize,
                                            (dot.y)), dot.color);

                                    this.renderArrowCanvas(path, this.circleElement[index], dot.color);
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) {
                                    path = this.drawArrowList(new CGPoint((dot.x), ((dot.y) - radius - arrowSize) / 2 - arrowSize * 30),
                                        new CGPoint((dot.x), dot.y - radius - arrowSpace - arrowSize), dot.color);

                                    this.renderArrowCanvas(path, this.circleElement[index], dot.color);
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S ||
                                    timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                                    path = this.drawArrowList(new CGPoint((dot.x), (dot.y) - radius - arrowSize * 30),
                                        new CGPoint((dot.x), (dot.y) - radius - arrowSpace - arrowSize), dot.color)

                                    this.renderArrowCanvas(path, this.circleElement[index], dot.color);
                                }

                                path = this.drawYuanByColor((dot.x), (dot.y), radius, dot.isSelected, dot.color);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                break;

                            case TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD:
                                if (timeLineContent.orientationType == OrientationType.VERTICAL) {
                                    path = this.drawLineByColor(new CGPoint((dot.x) - radius, (dot.y)), new CGPoint(0, (dot.y)), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)

                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) {
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius), new CGPoint((dot.x), 0), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S || timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius),
                                        new CGPoint((dot.x), (dot.y) - (timeLineContent.lineContentHeight) / 2), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                }
                                path = this.drawYuanByColor((dot.x), (dot.y), radius, dot.isSelected, dot.color);
                                this.renderCanvas(path[0], this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                break;
                            //线条节点连接时间带序号
                            case TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER:
                                if (timeLineContent.orientationType == OrientationType.VERTICAL) {
                                    path = this.drawLineByColor(new CGPoint((dot.x) - radius, (dot.y)), new CGPoint(0, (dot.y)), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) {
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius), new CGPoint((dot.x), 0), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S || timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius), new CGPoint((dot.x), (dot.y) - (timeLineContent.lineContentHeight) / 2), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                }
                                color = dot.color
                                if (Colors.isDarkColor(dot.color) && Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
                                    color = Colors.getNumberToRgbStringDark(color, -0.2)
                                } else if (!Colors.isDarkColor(dot.color) && !Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
                                    color = Colors.getNumberToRgbStringDark(color, 0.4)
                                }
                                path = this.drawYuanByColorAndText((dot.x), (dot.y), radius, dot.isSelected, color, "" + (index + 1), 14);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                path[1].x = dot.x;
                                path[1].y = dot.y;
                                
                                this.renderTextCanvas(path[1], this.textElement[index], 'textElement', Colors.getUiColor(color))
                                break;
                            // 线条节点圆形连接Head和Title
                            case TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE:
                                if (timeLineContent.orientationType == OrientationType.VERTICAL) {
                                    path = this.drawLineByColor(new CGPoint((dot.x) - radius, (dot.y)), new CGPoint(0, (dot.y)), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                    path = this.drawLineByColor(new CGPoint((dot.x) + radius, (dot.y)), new CGPoint((timeLineContent.lineContentWidth), (dot.y)), dot.color);
                                    this.renderCanvas(path, this.lineElementTwo[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL) {
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius), new CGPoint((dot.x), 0), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) + radius), new CGPoint((dot.x), (timeLineContent.lineContentHeight)), dot.color);
                                    this.renderCanvas(path, this.lineElementTwo[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                } else if (timeLineContent.orientationType == OrientationType.HORIZONTAL_S || timeLineContent.orientationType == OrientationType.HORIZONTAL_RING) {
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) - radius), new CGPoint((dot.x), (dot.y) - (timeLineContent.lineContentHeight) / 2), dot.color);
                                    this.renderCanvas(path, this.lineElementFirst[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                    path = this.drawLineByColor(new CGPoint((dot.x), (dot.y) + radius), new CGPoint((dot.x), (dot.y) + (timeLineContent.lineContentHeight) / 2), dot.color);
                                    this.renderCanvas(path, this.lineElementTwo[index], null, dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                                }
                                path = this.drawYuanByColor((dot.x), (dot.y), radius, dot.isSelected, dot.color);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, this.DrawingBoardBackgroundColor, null)
                                break;
                            default:
                                path = this.drawYuan((dot.x), (dot.y), radius, dot.isSelected);
                                this.renderCanvas(path[0], this.circleElement[index], 'circleElement', dot.color, this.dotLineWidth, this.lineWidthStrokeCap, 'rgb(255,255,255)', null)
                        }
                    }
                }

            }

            if (this.mindElementData.isShadow) {
                this.context.setAttribute('filter', 'url(#linemapLineShadow)');
            } else {
                this.context.setAttribute('filter', '');
            }
        } else if (arguments.length == 2) {
            return this.drawLineByColor(startPoint, endPoint, this.mindElementData.timeLineContent.color);
        }

    }

    isDrawLine() {
        if (this.mindElementData.timeLineContent.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM ||
            this.mindElementData.timeLineContent.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM ||
            this.mindElementData.timeLineContent.orientationType == OrientationType.LADDER ||
            this.mindElementData.timeLineContent.orientationType == OrientationType.BRIDGE ||
            this.mindElementData.timeLineContent.orientationType == OrientationType.HORIZONTAL_CRISSCROSS ||
            this.mindElementData.timeLineContent.orientationType == OrientationType.SEMICIRCLE ||
            this.mindElementData.timeLineContent.orientationType == OrientationType.TIME_S
        ) {
            return true
        }
        return this.mindElementData.timeLineContent.nodeType != TimeLineNodeType.TIME_LINE_RING_ORDER
    }

    getLeftX() {
        let count = this.mindElementData.timeLineContent.dots.length;
        let left = 99999999
        for (let index = 0; index < count; index++) {
            left = Math.min(left, this.mindElementData.timeLineContent.dots[index].x)
        }
        return left            
    }

    getRightX() {
        let count = this.mindElementData.timeLineContent.dots.length;
        let right = -99999999
        for (let index = 0; index < count; index++) {
            right = Math.max(right, this.mindElementData.timeLineContent.dots[index].x)
        }
        return right            
    }

    drawArcLineByColor(startPoint, endPoint, color, centerPoint) {
        let linePath = new LinePath();
        let radius = Util.getPointSpacing(centerPoint, startPoint);

        linePath.moveTo(startPoint.x, startPoint.y);
        linePath.circle(radius, radius, 0, 0, 1, endPoint.x, endPoint.y);

        return linePath
    }

    //计算画圆的路径的函数
    drawCircle(startPoint, radius1, radius2, xAxisRotation = 0, largeArcFlag = 1, sweepFlag = 1, endPoint) {
        let linePath = new LinePath();
        linePath.moveTo(startPoint.x, startPoint.y);
        linePath.circle(radius1, radius2, xAxisRotation, largeArcFlag, sweepFlag, endPoint.x, endPoint.y);

        return linePath
    }
    //计算s形曲线转弯处的路径的函数
    drawSCircle(lineStartPoint, startPoint, radius1, radius2, xAxisRotation = 0, largeArcFlag = 1, sweepFlag = 1, endPoint, lineEndPoint) {
        let linePath = new LinePath();
        linePath.moveTo(lineStartPoint.x, lineStartPoint.y);
        linePath.lineTo(startPoint.x, startPoint.y);
        linePath.circle(radius1, radius2, xAxisRotation, largeArcFlag, sweepFlag, endPoint.x, endPoint.y);
        linePath.lineTo(lineEndPoint.x, lineEndPoint.y);
        return linePath
    }

    //计算s形曲线转弯处的路径的函数
    drawSrCorners(lineStartPoint, startPoint, radius1, radius2, xAxisRotation = 0, largeArcFlag = 1, sweepFlag = 1, endPoint, lineEndPoint) {
        let linePath = new LinePath();
        linePath.moveTo(lineStartPoint.x, lineStartPoint.y);
        linePath.lineTo(startPoint.x, startPoint.y);
        // linePath.circle(radius1, radius2, xAxisRotation, largeArcFlag, sweepFlag, endPoint.x, endPoint.y);
        linePath.lineTo(endPoint.x, endPoint.y);
        linePath.lineTo(lineEndPoint.x, lineEndPoint.y);
        return linePath
    }

    //自己添加的一个函数
    //path表示绘画的形状， element表示绘画的元素， name表示绘画的形状在时间线中间的类型， stroke表示线条颜色， 
    // strokeWidth表示线条长度，strokeLinecap表示线条开始结束点形状, fill表示形状的填充色, strokeDasharray自定义边框虚线样式
    renderCanvas(path, element, name, stroke, strokeWidth, strokeLinecap, fill, strokeDasharray, stroke2 = null, id = 0) {
        if (element == null) {
            element = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.allPathParentG.appendChild(element)
            this.removeArray.push(element);
        }
        let color = this.mindElementData.timeLineContent
        element.setAttribute("d", path.getLine())
        if (stroke != null) {
            if (stroke2 != null && id > -1 && 1 != 1) {                                
                if (this.defsTag == null && this.context != null) {                    
                    this.defsTag = document.getElementById("linemapLineDefs")                    
                    if (this.defsTag == null) {
                        if (this.dotDefsTag != null) {
                            this.defsTag = this.dotDefsTag
                        } else {
                            this.defsTag = document.createElementNS('http://www.w3.org/2000/svg', "defs");
                            this.context.appendChild(this.defsTag);
                        }
                    }
                }
                let setBackgroundFullShapeLinearGradient = this.defsTag.getElementsByClassName("timeLineStrokeLinearGradient" + id)
                
                if (setBackgroundFullShapeLinearGradient == null || setBackgroundFullShapeLinearGradient.length == 0) {
                    setBackgroundFullShapeLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', "linearGradient");
                    setBackgroundFullShapeLinearGradient.setAttribute("id", "timeLineStrokeLinearGradient" + id);
                    setBackgroundFullShapeLinearGradient.setAttribute("class", "timeLineStrokeLinearGradient" + id);
                    
                    this.defsTag.appendChild(setBackgroundFullShapeLinearGradient);
                } else {
                    setBackgroundFullShapeLinearGradient = setBackgroundFullShapeLinearGradient[0]
                }
                let setBackgroundFullShapeLinearGradientStartStop = setBackgroundFullShapeLinearGradient.getElementsByClassName("timeLineStrokeLinearGradientStart" + id)
                let setBackgroundFullShapeLinearGradientEndStop = setBackgroundFullShapeLinearGradient.getElementsByClassName("timeLineStrokeLinearGradientEnd" + id)
                if (setBackgroundFullShapeLinearGradientStartStop == null || setBackgroundFullShapeLinearGradientStartStop.length == 0) {
                    setBackgroundFullShapeLinearGradientStartStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");                    
                    setBackgroundFullShapeLinearGradientEndStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");

                    setBackgroundFullShapeLinearGradientStartStop.setAttribute("class", "timeLineStrokeLinearGradientStart" + id);
                    setBackgroundFullShapeLinearGradientEndStop.setAttribute("class", "timeLineStrokeLinearGradientEnd" + id);

                    setBackgroundFullShapeLinearGradient.appendChild(setBackgroundFullShapeLinearGradientStartStop);
                    setBackgroundFullShapeLinearGradient.appendChild(setBackgroundFullShapeLinearGradientEndStop);
                } else {
                    setBackgroundFullShapeLinearGradientStartStop = setBackgroundFullShapeLinearGradientStartStop[0]
                    setBackgroundFullShapeLinearGradientEndStop = setBackgroundFullShapeLinearGradientEndStop[0]
                }
                setBackgroundFullShapeLinearGradient.setAttribute("x1", "0%");
                setBackgroundFullShapeLinearGradient.setAttribute("y1", "0%");
                setBackgroundFullShapeLinearGradient.setAttribute("x2", "100%");
                setBackgroundFullShapeLinearGradient.setAttribute("y2", "0");
                setBackgroundFullShapeLinearGradientStartStop.setAttribute('offset', '0%');
                setBackgroundFullShapeLinearGradientEndStop.setAttribute('offset', '100%');
                setBackgroundFullShapeLinearGradientStartStop.setAttribute('stop-color', Colors.getUiColor(stroke));
                setBackgroundFullShapeLinearGradientEndStop.setAttribute('stop-color', Colors.getUiColor(stroke2));
                    
                element.setAttribute("stroke", "url(#" + "timeLineStrokeLinearGradient" + id + ")")
            } else {
                element.setAttribute("stroke", Colors.getUiColor(stroke))
            }
        } else { //改完所有的颜色要删除的。
        }

        if (fill != null) { //形状填充色
            element.setAttribute("fill", fill)
        } else {
            element.setAttribute("fill", "none")
        }

        if (strokeDasharray != null) {  //自定义边框虚线样式
            element.setAttribute("stroke-dasharray", strokeDasharray)
        }

        if (strokeWidth != null) {
            element.setAttribute("stroke-width", strokeWidth)
        } else {    //改完所有宽度要删除的。
        }

        if (strokeLinecap != null) { //设置线段开始与结束点形状
            element.setAttribute("stroke-linecap", strokeLinecap)
        } else {
            element.setAttribute("stroke-linecap", 'butt')
        }
        return element;
    }


    renderTextCanvas(path, element, name, color) {
        let childElement;
        if (element == null) {
            element = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject")
            childElement = document.createElement("div")
            element.appendChild(childElement);
            this.allPathParentG.appendChild(element)
            this.removeArray.push(element);
        } else {
            childElement = element.firstChild
        }
        //设置文本区域的宽高位置
        element.setAttribute('width', path.width)
        element.setAttribute('height', path.height)
        element.setAttribute('x', path.x - path.width / 2)        
        element.setAttribute('y', Math.floor(path.y - path.height / 2))
        //设置文本区域内容的样式

        childElement.style.lineHeight = Config.textLineHeight * path.fontSize + 'px'   //'20px'
        childElement.style.fontFamily = Config.textFontFamily //"SimSun"
        childElement.style.fontSize = path.fontSize + 'px'
        childElement.style.textAlign = 'center';
        childElement.style.whiteSpace = "pre-wrap"
        childElement.style.wordBreak = "break-word"
        childElement.style.fontWeight = 600
        childElement.innerHTML = path.text;
        childElement.style.color = color;
    }

    renderArrowCanvas(path, element, color) {
        if (element == null) {
            element = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.allPathParentG.appendChild(element)
            this.removeArray.push(element);
        }

        let timeLineContent = this.mindElementData.timeLineContent
        element.setAttribute("d", path.getLine())
        element.setAttribute("stroke", Colors.getUiColor(color))
        element.setAttribute("stroke-width", 0)
        element.setAttribute("fill", Colors.getUiColor(color))
    }

    drawDiamond(x, y, radius, isSelected) {
        let linePath = new LinePath();
        let onePoint = new CGPoint(x, y - radius)
        let twoPoint = new CGPoint(x - radius, y)
        let threePoint = new CGPoint(x, y + radius)
        let fourPoint = new CGPoint(x + radius, y)
        let fivePoint = new CGPoint(x, y - radius)

        linePath.moveTo(onePoint.x, onePoint.y);
        linePath.lineTo(twoPoint.x, twoPoint.y);
        linePath.lineTo(threePoint.x, threePoint.y);
        linePath.lineTo(fourPoint.x, fourPoint.y);
        linePath.lineTo(fivePoint.x, fivePoint.y);

        return linePath
    }

    drawYuan(x, y, radius, isSelected) {
        return this.drawYuanByColor(x, y, radius, isSelected, this.mindElementData.timeLineContent.color);
    }

    drawYuanByColor(x, y, radius, isSelected, color) {
        return this.drawYuanByColorAndText(x, y, radius, isSelected, color, "");
    }

    drawYuanByColorAndText(x, y, radius, isSelected, color, text = "", fontSize = 12) {
        let linePath = new LinePath();
        linePath.moveTo(x - radius, y);
        //不能直接画圆，得用两个半圆拼接。
        linePath.circle(radius, radius, 0, 1, 1, x + radius, y);
        linePath.circle(radius, radius, 0, 1, 1, x - radius, y);



        if (text.length > 0) {

            let obj = {}
            let textSize = MindElementCalculation.caluleText(text, fontSize, false);
            obj.width = Math.ceil(textSize.width);
            obj.height = Math.ceil(textSize.height);
            obj.text = text;
            obj.fontSize = fontSize

            return [linePath, obj]
        } else {
            return [linePath]
        }

    }

    drawHexagonColorAndText(x, y, radius, isSelected, color, text, targetId) {

        let shadowRadial = this.UiUtil.dip2px(1);

        let bigColorArray = [
            {
                offset: '0%',
                stopColor: Colors.getUiColor(Colors.black10)
            },
            {
                offset: '100%',
                stopColor: Colors.getUiColor(Colors.black25)
            }
        ]

        let bigDirection = {
            x1: '0%',
            y1: '0%',
            x2: '100%',
            y2: '100%'
        }
        let bigIdString = 'DOT_BIG_' + 'TIME_LINE_HEXAGON_ORDER' + '_' + targetId
        new LinearGradient(this.dotDefsTag, bigColorArray, bigDirection, bigIdString);
        let hexagonPath = this.getHexagonPath(x, y, radius);
        this.renderCanvas(hexagonPath, null, 'hexagon', color, 0, this.lineWidthStrokeCap, "url(#" + bigIdString + ")", null);




        shadowRadial = this.UiUtil.dip2px(2);

        let smallColorArray = [
            {
                offset: '0%',
                stopColor: Colors.getUiColor(Colors.black30)
            },
            {
                offset: '100%',
                stopColor: Colors.getUiColor(Colors.white)
            }
        ]

        let smallDirection = {
            x1: '0%',
            y1: '0%',
            x2: '100%',
            y2: '100%'
        }
        let smallIdString = 'DOT_SMALL_' + 'TIME_LINE_HEXAGON_ORDER' + '_' + targetId
        new LinearGradient(this.dotDefsTag, smallColorArray, smallDirection, smallIdString);
        let smallHexagonPath = this.getHexagonPath(x, y, radius - shadowRadial);
        let small = this.renderCanvas(smallHexagonPath, null, 'hexagon', color, 0, this.lineWidthStrokeCap, "url(#" + smallIdString + ")", null);

        let textColor;
        if (text.length > 0) {
            let obj = {}
            let textSize = MindElementCalculation.caluleText(text, 14, false);
            obj.width = Math.ceil(textSize.width);
            obj.height = Math.ceil(textSize.height);
            obj.text = text;
            obj.fontSize = 14

            obj.x = x;
            obj.y = y;
            if (Colors.isDarkColor(Colors.getNumberToRgbNumberDark(color, 0.2))) {
                textColor = Colors.getUiColor(color);
            } else {
                textColor = Colors.getUiColor(Colors.black80);
            }

            this.renderTextCanvas(obj, null, 'textElement', textColor)
        }

    }


    drawYuan3DByColorAndText(x, y, radius, isSelected, color, text, targetId, index) {

        let shadowRadial = this.UiUtil.dip2px(2);

        let bigColorArray = [
            {
                offset: '0%',
                stopColor: Colors.getUiColor(Colors.black10)
            },
            {
                offset: '100%',
                stopColor: Colors.getUiColor(Colors.black25)
            }
        ]

        let bigDirection = {
            x1: '0%',
            y1: '0%',
            x2: '100%',
            y2: '100%'
        }
        let bigIdString = 'DOT_BIG_' + 'TIME_LINE_CIRCULAR_ORDER_1' + '_' + targetId
        new LinearGradient(this.dotDefsTag, bigColorArray, bigDirection, bigIdString);
        let bigPathArray = this.drawYuanByColorAndText(x, y, radius, isSelected, color, '', 14);
        this.renderCanvas(bigPathArray[0], null, 'circleElement', color, 0, this.lineWidthStrokeCap, "url(#" + bigIdString + ")", null);

        shadowRadial = this.UiUtil.dip2px(2);
        let colorArray = [
            {
                offset: '0%',
                stopColor: Colors.getUiColor(Colors.black30)
            },
            {
                offset: '100%',
                stopColor: Colors.getUiColor(Colors.white)
            }
        ]

        let direction = {
            x1: '0%',
            y1: '0%',
            x2: '100%',
            y2: '100%'
        }
        let idString = 'DOT_CENTER_' + 'TIME_LINE_CIRCULAR_ORDER_1' + '_' + targetId
        new LinearGradient(this.dotDefsTag, colorArray, direction, idString);
        let smallCircle = radius - shadowRadial
        let pathArray = this.drawYuanByColorAndText(x, y, smallCircle, isSelected, color, text, 14);
        let textColor;
        if (Colors.isDarkColor(color)) {
            textColor = Colors.getUiColor(color);
        } else {
            textColor = Colors.getUiColor(Colors.black80);
        }
        this.renderCanvas(pathArray[0], null, 'circleElement', color, 0, this.lineWidthStrokeCap, "url(#" + idString + ")", null)
        pathArray[1].x = x;
        pathArray[1].y = y;
        pathArray[1].fontSize = 14
        this.renderTextCanvas(pathArray[1], null, 'textElement', textColor)

    }

    drawCubicLineByColor(prePoint, startPoint, endPoint, nextPoint) {
        let linePath = new LinePath();
        let list = this.getTimeCubicLinePoints(prePoint, startPoint, endPoint, nextPoint)
        if (list.length != 4) {
            return linePath
        }
        linePath.moveTo(list[0].x, list[0].y);
        linePath.cubicTo(
            list[1].x,
            list[1].y,
            list[2].x,
            list[2].y,
            list[3].x,
            list[3].y);
        return linePath

    }

    getTimeCubicLinePoints(prePoint, startPoint, endPoint, nextPoint) {
        let list = []
        let offsetDegrees = 20
        let offsetMinDegrees = 5
        let offsetRadius = 80
        let endIncreaseRadius = 100
        if (startPoint.x < endPoint.x) {            
            if (nextPoint == null || endPoint.x < nextPoint.x) {
                if (nextPoint != null && endPoint.x < nextPoint.x) {
                    return list
                }
                if (nextPoint == null) {
                    nextPoint = new CGPoint(endPoint.x + 30, endPoint.y - 20)
                }
                if (endPoint.x > startPoint.x) {  
                    if (prePoint != null && prePoint.x < startPoint.x) {
                        startPoint = prePoint
                    }   
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    let c1 = Util.getCirclePoint(startPoint, degrees - offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    let c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                    list.push(startPoint, c1, c2, endPoint)
                }
            } else {
                let c1 = null
                let c2 = null
                if (prePoint == null) {
                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees, nextR + endIncreaseRadius)

                    let degrees = Util.getCircleDegreesInPoint(startPoint, c2)
                    let r = Util.getPointSpacing(startPoint, c2)
                    c1 = Util.getCirclePoint(startPoint, degrees, r/2)                     
                } else if (prePoint.x < startPoint.x) {
                    startPoint = prePoint
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees - offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                } else {
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees - offsetMinDegrees * 2, r * 0.25)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                }         
                if (c2 == null) {
                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees + offsetMinDegrees, nextR + endIncreaseRadius)
                }                
                list.push(startPoint, c1, c2, endPoint)
            }
        } else {
            
            if (nextPoint == null || endPoint.x > nextPoint.x) { 
                if (nextPoint != null && endPoint.x > nextPoint.x) {
                    return list
                }
                if (nextPoint == null) {
                    nextPoint = new CGPoint(endPoint.x - 100, endPoint.y - 40)           
                }
                
                if (endPoint.x < startPoint.x) {      
                    let c1 = null
                    let c2 = null                    
                    if (prePoint != null && prePoint.x > startPoint.x) {
                        startPoint = prePoint
                    } 
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees + offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                    list.push(startPoint, c1, c2, endPoint)
                }
            } else {
                let c1 = null
                let c2 = null
                if (prePoint == null) {
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees + offsetMinDegrees * 0, r/2)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees * 0.5, nextR + endIncreaseRadius)
                } else if (prePoint.x > startPoint.x) {
                    startPoint = prePoint
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees + offsetMinDegrees, r * 0.45)

                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees * 0.5, nextR + endIncreaseRadius * 1.5)
                } else {
                    let degrees = Util.getCircleDegreesInPoint(startPoint, endPoint)
                    let r = Util.getPointSpacing(startPoint, endPoint)
                    c1 = Util.getCirclePoint(startPoint, degrees - offsetDegrees, offsetRadius)
                }
                if (c2 == null) {
                    let nextPointDegrees = Util.getCircleDegreesInPoint(nextPoint, endPoint)
                    let nextR = Util.getPointSpacing(nextPoint, endPoint)
                    c2 = Util.getCirclePoint(nextPoint, nextPointDegrees - offsetMinDegrees, nextR + endIncreaseRadius)
                }         
                list.push(startPoint, c1, c2, endPoint)
            }
        }
        return list
    }

    drawLineByColor(startPoint, endPoint, color) {
        let linePath = new LinePath();
        linePath.moveTo(startPoint.x, startPoint.y);
        linePath.lineTo(endPoint.x, endPoint.y);
        return linePath
    }

    getRingLinePath(centerPoint, startPoint, endPoint, middlePoint, boundaryPoint, radius, sweepFlag = 0) {
        let linePath = new LinePath();
        let circle1End = Util.getCirclePoint(centerPoint, Util.getCircleDegreesInPoint(centerPoint, startPoint)  + (sweepFlag == 0 ? -180 : 180), radius)
        let circle2End = Util.getCirclePoint(centerPoint, Util.getCircleDegreesInPoint(centerPoint, startPoint)  + (sweepFlag == 0 ? -270 : 270), radius)
        linePath.moveTo(startPoint.x, startPoint.y);
        linePath.lineTo(startPoint.x - 1, startPoint.y);
        linePath.circle(1, 1, 0, 0, 0, startPoint.x + 1, startPoint.y);
        linePath.circle(1, 1, 0, 0, 0, startPoint.x - 1, startPoint.y);
        linePath.lineTo(startPoint.x - 2, startPoint.y);
        linePath.circle(2, 2, 0, 0, 0, startPoint.x + 2, startPoint.y);
        linePath.circle(2, 2, 0, 0, 0, startPoint.x - 2, startPoint.y);

        linePath.lineTo(startPoint.x, startPoint.y);
        linePath.circle(radius, radius, 0, 0, sweepFlag, circle1End.x, circle1End.y);
        if (boundaryPoint != null && Math.abs(middlePoint.y - startPoint.y) > 10) {
            if (startPoint.x < centerPoint.x) {
                let right = boundaryPoint.x
                let circleRadius = Math.abs(middlePoint.y - startPoint.y)/2
                linePath.lineTo(right, circle1End.y);
                linePath.circle(circleRadius, circleRadius, 0, 0, 1, right, boundaryPoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(middlePoint.x - 1, middlePoint.y);
                linePath.circle(1, 1, 0, 0, 0, middlePoint.x + 1, middlePoint.y);
                linePath.circle(1, 1, 0, 0, 0, middlePoint.x - 1, middlePoint.y);
                linePath.lineTo(middlePoint.x - 2, middlePoint.y);
                linePath.circle(2, 2, 0, 0, 0, middlePoint.x + 2, middlePoint.y);
                linePath.circle(2, 2, 0, 0, 0, middlePoint.x - 2, middlePoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(right, boundaryPoint.y);
                linePath.circle(circleRadius, circleRadius, 0, 0, 0, right, circle1End.y);
                linePath.lineTo(circle1End.x, circle1End.y);
            } else {
                let left = boundaryPoint.x
                let circleRadius = Math.abs(middlePoint.y - startPoint.y)/2
                linePath.lineTo(left, circle1End.y);
                if (middlePoint.y < startPoint.y) {
                    linePath.circle(circleRadius, circleRadius, 0, 0, 1, left, boundaryPoint.y);
                } else {
                    linePath.circle(circleRadius, circleRadius, 0, 0, 0, left, boundaryPoint.y);
                }                
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(middlePoint.x - 1, middlePoint.y);
                linePath.circle(1, 1, 0, 0, 0, middlePoint.x + 1, middlePoint.y);
                linePath.circle(1, 1, 0, 0, 0, middlePoint.x - 1, middlePoint.y);
                linePath.lineTo(middlePoint.x - 2, middlePoint.y);
                linePath.circle(2, 2, 0, 0, 0, middlePoint.x + 2, middlePoint.y);
                linePath.circle(2, 2, 0, 0, 0, middlePoint.x - 2, middlePoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(left, boundaryPoint.y);
                if (middlePoint.y < startPoint.y) {
                    linePath.circle(circleRadius, circleRadius, 0, 0, 0, left, circle1End.y);
                } else {
                    linePath.circle(circleRadius, circleRadius, 0, 0, 1, left, circle1End.y);
                }                
                linePath.lineTo(circle1End.x, circle1End.y);
            }
        } else {
            linePath.lineTo(middlePoint.x, middlePoint.y);
            linePath.lineTo(middlePoint.x - 1, middlePoint.y);
            linePath.circle(1, 1, 0, 0, 0, middlePoint.x + 1, middlePoint.y);
            linePath.circle(1, 1, 0, 0, 0, middlePoint.x - 1, middlePoint.y);
            linePath.lineTo(middlePoint.x - 2, middlePoint.y);
            linePath.circle(2, 2, 0, 0, 0, middlePoint.x + 2, middlePoint.y);
            linePath.circle(2, 2, 0, 0, 0, middlePoint.x - 2, middlePoint.y);
            linePath.lineTo(middlePoint.x, middlePoint.y);
            linePath.lineTo(circle1End.x, circle1End.y);
        }

        linePath.circle(radius, radius, 0, 0, sweepFlag, circle2End.x, circle2End.y);

        linePath.lineTo(endPoint.x, endPoint.y);

        linePath.lineTo(endPoint.x - 1, endPoint.y);
        linePath.circle(1, 1, 0, 0, 0, endPoint.x + 1, endPoint.y);
        linePath.circle(1, 1, 0, 0, 0, endPoint.x - 1, endPoint.y);
        linePath.lineTo(endPoint.x - 2, endPoint.y);
        linePath.circle(2, 2, 0, 0, 0, endPoint.x + 2, endPoint.y);
        linePath.circle(2, 2, 0, 0, 0, endPoint.x - 2, endPoint.y);
        return linePath
    }

    drawSemicircleLine(centerPoint, radius, clockwise) {
        if (clockwise) {
            let linePath = new LinePath();
            linePath.moveTo(startPoint.x, startPoint.y);
            linePath.lineTo(endPoint.x, endPoint.y);
            return linePath

        } else {

        }
    }



    drawArrow(startPoint, endPoint, color,) {
        let timeLineContent = this.mindElementData.timeLineContent;
        this.drawArrowByColor(startPoint, endPoint, timeLineContent.color);
    }

    drawArrowByColor(startPoint, endPoint, color, height = this.lineHeight * 2, arrowView = null) {
        let arrowHeight = height;

        if (arrowHeight > 0 && arrowHeight < this.UiUtil.dip2px(8)) {
            arrowHeight = this.UiUtil.dip2px(8);
        }

        let linePath = new LinePath();
        let p = (new UiUtil()).getArrowPoint(startPoint, endPoint, this.lineWidth, arrowHeight);
        if (p.length != 3) {
            return;
        }
        linePath.moveTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);
        linePath.lineTo(p[2].x, p[2].y);
        linePath.lineTo(p[0].x, p[0].y);
        //为了解决封闭图形不规则，起始点有缺口的问题。
        linePath.lineTo(p[1].x, p[1].y);
        let arrowPath = arrowView
        if (arrowPath == null) {
            arrowPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.allPathParentG.appendChild(arrowPath)
            this.removeArray.push(arrowPath);
            this.arrow = arrowPath
        }

        arrowPath.setAttribute("d", linePath.getLine())
        arrowPath.setAttribute("stroke", Colors.getUiColor(color))
        arrowPath.setAttribute("stroke-width", this.lineWidth)
        arrowPath.setAttribute("fill", Colors.getUiColor(color))
    }

    getHexagonPath(x, y, radius) {
        let path = new LinePath();
        path.moveTo(x + radius, y);
        for (let index = 1; index < 6; index++) {
            let angle = 60 * index;
            let pointX = x + ((radius) * this.Util.cosd(angle));
            let pointY = y + ((radius) * this.Util.sind(angle));
            path.lineTo(pointX, pointY);
        }
        path.lineTo(x + radius, y);
        return path;
    }

    drawArrowList(startPoint, endPoint, color) {
        let linePath = new LinePath();
        let p = (new UiUtil()).getArrowPoint(startPoint, endPoint, this.lineWidth);
        if (p.length != 3) {
            return;
        }
        linePath.moveTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);
        linePath.lineTo(p[2].x, p[2].y);
        linePath.lineTo(p[0].x, p[0].y);
        //为了解决封闭图形不规则，起始点有缺口的问题。
        linePath.lineTo(p[1].x, p[1].y);
        return linePath

    }


    isDrawDot() {
        if (this.mindElementData == null ||
            this.mindElementData.timeLineContent == null) {
            return false;
        }
        return this.mindElementData.timeLineContent.timeNodeLayoutType == TimeNodeLayoutType.NORMAL ||
            this.mindElementData.timeLineContent.timeNodeLayoutType == TimeNodeLayoutType.TITLE_DES_BOTTOM ||
            this.mindElementData.timeLineContent.timeNodeLayoutType == TimeNodeLayoutType.TITLE_BOTTOM ||
            this.mindElementData.timeLineContent.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM;
    }

    createBackgroundView() {
    }
}

export default TimeLineView