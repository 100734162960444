// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */
let OrientationType =  {
    VERTICAL:0,
    HORIZONTAL:1,
    HORIZONTAL_S:2,
    LADDER:3,
    HORIZONTAL_CRISSCROSS:4,
    SEMICIRCLE:5,
    BRIDGE:6,
    TIME_S:7,
    HORIZONTAL_RING:8,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.VERTICAL;
    }
}

export default OrientationType
