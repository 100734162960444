
import Config from "../../../core/core/calcule/Config"
import UiUtil from "../../../utils/UiUtil"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import TimeLineLayout from "./TimeLineLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineHorizontalRingLayout extends TimeLineLayout {
    constructor() {
        super()
        this.UiUtil = new UiUtil();
        this.nodeSplitList = new Array(); //分割行数Datas
        this.lineSpacing = this.UiUtil.dip2px(50);
        this.baseLineHeight = this.UiUtil.dip2px(10);
    }

    initConfigInfo() {
        this.radius = this.getRadius();
        this.baseLineHeight = this.radius * 2 + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2;        
        this.baseLineHeight = Math.max(this.radius * 2, this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2) + 4;

        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);
        this.subjectAndLineSpace = 0;
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);
        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
            this.baseLineHeight += this.UiUtil.dip2px(13);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineHeight += this.UiUtil.dip2px(25);
        } else {
            this.baseLineHeight += this.UiUtil.dip2px(18);
        }
        this.baseLineHeight = this.addSubjectSpcaeVertical(this.baseLineHeight);
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(5);
                } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(30);
                }
                break;
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(5);
                } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(30);
                }
                break;
            default:
        }
        this.baseLineHeight += this.subjectAndLineSpace * 2
    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.title.height + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
        }
        this.initConfigInfo();
        let nodeList = this.getOrderNodes();  
        if (nodeList.length == 0) {
            this.baseLine.timeLineContent.dots = new Array();
            return
        }      
        this.space = (this.baseLineHeight - this.radius) / 2;
        this.spliteNode();
        let spliteLenght = this.nodeSplitList.length;
        if (spliteLenght == 0) {
            return;
        }
        
        let contentY = this.title.y + this.title.height + this.getTopDataHeight(3, nodeList, spliteLenght > 5 ? 80 : 50) + this.baseLineHeight / 2;
        let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(this.nodeSplitList[0][0]);
        let firstRowWidth = this.getRowWidth(this.nodeSplitList[0]) + this.subjectSpace * (this.nodeSplitList[0].length - 1)
        let startPointX = this.title.x + this.title.width/2 - firstRowWidth/2 - nodeWidth/2
        let nodesLeft = 500000;
        let nodesRight = -500000;
        let nodesTop = contentY - this.baseLineHeight / 2;
        let nodesBottom = 0;
        let preNodeCentreY = contentY;
        
        
        let timeHSpace = this.subjectSpace
        let left = 9999999
        let right = -9999999
        let top = contentY
        let bottom = contentY
        for (let index = 0; index < spliteLenght; index++) {
            let direction = index % 2 == 0;
            let nodes = this.nodeSplitList[index];
            let nodosTopHeight = this.getNodesTopHieght(nodes);
            let nodosBottomHeight = 0;
            if (index > 0) {
                nodosBottomHeight = this.getNodesBottomHieght(this.nodeSplitList[index - 1]);
                if (direction) {
                    nodosBottomHeight = this.getNodesBottomHieght(nodes);
                    
                    if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
                        contentY = top - nodosBottomHeight +- this.lineSpacing - this.baseLineHeight / 2 - this.subjectAndLineSpace;
                    } else {
                        contentY = top - nodosBottomHeight +- this.lineSpacing - this.baseLineHeight / 2;
                    }
                } else {
                    if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
                        contentY = bottom + nodosTopHeight + this.lineSpacing + this.baseLineHeight / 2 - this.subjectAndLineSpace;
                    } else {
                        contentY = bottom + nodosTopHeight + this.lineSpacing + this.baseLineHeight / 2;
                    }                    
                }
            }
            if (index < spliteLenght - 1) {
                timeHSpace = firstRowWidth > 0 ? (firstRowWidth - this.getRowWidth(nodes)) / (nodes.length - 1) : this.subjectSpace
            } else {
                let itemSpace = firstRowWidth > 0 ? (firstRowWidth - this.getRowWidth(nodes)) / (nodes.length - 1) : this.subjectSpace
                timeHSpace = itemSpace
            }
            
            if (direction) {
                if (index == 0) {
                    if (nodesLeft > startPointX) {
                        nodesLeft = startPointX;
                    }
                } else {
                    let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(nodes[0]);
                    startPointX = startPointX - nodeWidth/2
                    if (preNodeCentreY != contentY) {
                        if (nodesLeft > startPointX - (contentY - preNodeCentreY) / 2) {
                            nodesLeft = startPointX - (contentY - preNodeCentreY) / 2;
                        }
                    } else if (nodesLeft > startPointX) {
                        nodesLeft = startPointX;
                    }
                }
                let nodesLength = nodes.length;
                for (let i = 0; i < nodesLength; i++) {
                    let timeNode = nodes[i];
                    let head = timeNode.head;
                    let title = timeNode.title;
                    let desc = timeNode.desc;
                    let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
                    
                    switch (this.timeNodeLayoutType) {
                        case TimeNodeLayoutType.NORMAL:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head)// - this.subjectAndLineSpace;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = false;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.TITLE_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = true;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head);
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = head.y + head.height + this.space + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX + (nodeWidth - head.width) / 2;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX + (nodeWidth - title.width) / 2;
                            title.y = head.y + head.height + this.space + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        default:
                    }

                    if (!desc.isHidden) {
                        desc.x = startPointX + (nodeWidth - desc.width) / 2;
                        desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.sonSubjectAndLineSpace + this.getDesAndTitleVSpace(timeNode);
                    }
                    if (!head.isHidden) {
                        top = Math.min(top, head.y)
                        bottom = Math.max(bottom, head.y + head.height)
                    }
                    if (!title.isHidden) {
                        top = Math.min(top, title.y)
                        bottom = Math.max(bottom, title.y + title.height)
                    }
                    if (!desc.isHidden) {
                        top = Math.min(top, desc.y)
                        bottom = Math.max(bottom, desc.y + desc.height)
                    }
                    startPointX += nodeWidth + timeHSpace;
                }
            } else {
                if (index == 0) {
                    startPointX -= timeHSpace;
                } else {
                    let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(nodes[0]);
                    startPointX = startPointX + nodeWidth/2
                }
                
                if (nodesRight < startPointX + (nodosTopHeight + nodosBottomHeight + this.lineSpacing + this.baseLineHeight) / 2) {
                    nodesRight = startPointX + (nodosTopHeight + nodosBottomHeight + this.lineSpacing + this.baseLineHeight) / 2;
                }
                let nodesLength = nodes.length;
                for (let i = 0; i < nodesLength; i++) {
                    let timeNode = nodes[i];
                    let head = timeNode.head;
                    let title = timeNode.title;
                    let desc = timeNode.desc;
                    let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);

                    switch (this.timeNodeLayoutType) {
                        case TimeNodeLayoutType.NORMAL:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head) //- this.subjectAndLineSpace;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = false;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.TITLE_BOTTOM:
                            head.isHidden = true;
                            title.isHidden = false;
                            desc.isHidden = true;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - this.baseLineHeight / 2 - this.BaseLayout.getNodeHeight(head)// - this.subjectAndLineSpace;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = contentY + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = false;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = head.y + head.height + this.space + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                            head.isHidden = false;
                            title.isHidden = false;
                            desc.isHidden = true;
                            head.x = startPointX - (nodeWidth - head.width) / 2 - head.width;
                            head.y = contentY - head.height / 2;
                            title.x = startPointX - (nodeWidth - title.width) / 2 - title.width;
                            title.y = head.y + head.height + this.space + this.baseLineHeight / 3 + this.getTitleVSpace(timeNode);
                            break;
                        default:
                    }

                    if (!desc.isHidden) {
                        desc.x = startPointX - (nodeWidth - desc.width) / 2 - desc.width;
                        desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.sonSubjectAndLineSpace + this.getDesAndTitleVSpace(timeNode);
                    }
                    startPointX = startPointX - nodeWidth - timeHSpace;

                    if (!head.isHidden) {
                        top = Math.min(top, head.y)
                        bottom = Math.max(bottom, head.y + head.height)
                    }
                    if (!title.isHidden) {
                        top = Math.min(top, title.y)
                        bottom = Math.max(bottom, title.y + title.height)
                    }
                    if (!desc.isHidden) {
                        top = Math.min(top, desc.y)
                        bottom = Math.max(bottom, desc.y + desc.height)
                    }
                }
            }
            if (index == 0) {
                let timeNode = nodes[0];
                let head = timeNode.head;
                let title = timeNode.title;

                switch (this.timeNodeLayoutType) {
                    case TimeNodeLayoutType.NORMAL:
                        left = head.x + head.width / 2;
                        break;
                    case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                        left = title.x + title.width / 2;
                        break;
                    case TimeNodeLayoutType.TITLE_BOTTOM:
                        left = title.x + title.width / 2;
                        break;
                    case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                        left = head.x + head.width / 2;
                        break;
                    case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                        left = head.x + head.width / 2;
                        break;
                    case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                        left = head.x + head.width / 2;
                        break;
                    default:
                        left = head.x + head.width / 2;
                }

                timeNode = nodes[nodes.length - 1];
                head = timeNode.head;
                title = timeNode.title;

                switch (this.timeNodeLayoutType) {
                    case TimeNodeLayoutType.NORMAL:
                        right = head.x + head.width / 2;
                        break;
                    case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                        right = title.x + title.width / 2;
                        break;
                    case TimeNodeLayoutType.TITLE_BOTTOM:
                        right = title.x + title.width / 2;
                        break;
                    case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                        right = head.x + head.width / 2;
                        break;
                    case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                        right = head.x + head.width / 2;
                        break;
                    case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                        right = head.x + head.width / 2;
                        break;
                    default:
                        right = head.x + head.width / 2;
                }
            }
            if (direction) {
                startPointX = right
            } else {
                startPointX = left
            }            
            preNodeCentreY = contentY;
            nodesBottom = contentY + this.baseLineHeight / 2;
        }


        this.baseLine.width = nodesRight - nodesLeft;
        this.baseLine.height = nodesBottom - nodesTop;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLineHeight;
        this.baseLine.x = nodesLeft;
        this.baseLine.y = nodesTop;

        let dots = new Array();
        
        if (nodeList.length == 1 && this.nodeSplitList.length > 1) {
            nodeList = new Array();
            for (let index = 0; index < this.nodeSplitList.length; index++) {
                const itemList = this.nodeSplitList[index];
                for (let i = 0; i < itemList.length; i++) {
                    const item = itemList[i];
                    nodeList.push(item)
                }
            }
        }
        for (let index = 0; index < nodeList.length; index++) {
            let timeNode = nodeList[index];
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
            let head = timeNode.head;
            let title = timeNode.title;
            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;
            dotElementContent.radius = this.radius;
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    dotElementContent.x = head.x + head.width / 2 - this.baseLine.x;
                    dotElementContent.y = head.y + this.BaseLayout.getNodeHeight(head) + this.baseLineHeight / 2 - this.baseLine.y;
                    break;
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                case TimeNodeLayoutType.TITLE_BOTTOM:
                    dotElementContent.x = title.x + title.width / 2 - this.baseLine.x;
                    dotElementContent.y = title.y - this.baseLineHeight / 2 - this.baseLine.y;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    dotElementContent.radius = head.width / 2;
                    dotElementContent.x = head.x + head.width / 2 - this.baseLine.x;
                    dotElementContent.y = head.y + head.height / 2 - this.baseLine.y;
                    break;
                default:
            }

            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dotElementContent.left = nodeWidth / 2 + this.subjectSpace + this.radius;
            dotElementContent.right = nodeWidth / 2 + this.subjectSpace + this.radius;
            if (index == 0) {
                dotElementContent.lineClockwise = true;
            } else {
                let preDot = dots[index - 1];
                if (Math.abs(preDot.y - dotElementContent.y) > 15) {
                    dotElementContent.lineClockwise = !preDot.lineClockwise;
                } else {
                    dotElementContent.lineClockwise = preDot.lineClockwise;
                }
            }
            dots.push(dotElementContent);
        }
        
        this.baseLine.timeLineContent.dots = dots;
    }

    getOrderDot(dots) {
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1.y == data2.y) {
                return 0;
            } else if (data1.x < data2.x) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;
    }

    spliteNode() {
        this.nodeSplitList = [];
        let nodeList = this.getOrderNodes();
        let count = nodeList.length;
        if (count == 0) {
            return;
        }
        if (count == 1) {           
            let list = new Array();
            list.push(nodeList[0])
            this.nodeSplitList.push(list);

            let fillVacancyNode = nodeList[0].copy();
            let list1 = new Array();
            list1.push(fillVacancyNode)
            this.nodeSplitList.push(list1);
            return
        }
        var totalWidth = this.getRowWidth(nodeList)
        var width = 0
        for (let index = 0; index < count; index++) {
            let node = nodeList[index];
            let list = this.nodeSplitList.length == 0 ? new Array() : this.nodeSplitList[this.nodeSplitList.length - 1]
            if (this.nodeSplitList.length > 1) {
                list.push(node);
            } else {
                let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(node);
                if (index == 0) {
                    this.nodeSplitList.push(list);                
                    width = nodeWidth / 2
                } else {
                    width += nodeWidth
                }
                if (index == count - 1 && this.nodeSplitList.length == 1) {
                    list = new Array();
                    list.push(node);
                    this.nodeSplitList.push(list);
                } else {
                    list.push(node);
                    if (count == 2) {
                        list = new Array();
                        this.nodeSplitList.push(list);
                    } else if (width >= totalWidth / 2) {                    
                        let firstWidth = this.getRowWidth(list)
                        let enWidth = this.getRowWidth(this.nodeSplitList.slice(index + 1))
                        if (firstWidth > enWidth) {
                            list = new Array();
                            this.nodeSplitList.push(list);
                        }
                        
                    }
                }
            }
        }
    }

    getRowWidth(list) {
        let width = 0
        for (let index = 0; index < list.length; index++) {
            let timeNode = list[index];
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
            if (index == 0 || index == list.length - 1) {
                width = width + nodeWidth/2
            } else {
                width = width + nodeWidth
            }
        }
        return width
    }

    getTopDataHeight(count, nodeList, top = 80) {
        var height = 80
        for (let index = 0; index < nodeList.length; index++) {
            if (index >= count) {
                break
            }
            let timeNode = nodeList[index];
            let head = timeNode.head;
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                    height = Math.max(height, this.BaseLayout.getNodeHeight(head) + top)
                    break;
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    height = Math.max(height, top)
                    break;
                case TimeNodeLayoutType.TITLE_BOTTOM:
                    height = Math.max(height, top)
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    height = Math.max(height, this.BaseLayout.getNodeHeight(head) + top)
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                    height = Math.max(height, this.BaseLayout.getNodeHeight(head) / 2 + top)
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    height = Math.max(height, this.BaseLayout.getNodeHeight(head) / 2 + top)
                    break;
                default:
                    height = Math.max(height, this.BaseLayout.getNodeHeight(head) + top)
            }
        }
        return height
    }

    getNodesTopHieght(list) {
        if (this.timeNodeLayoutType == TimeNodeLayoutType.TITLE_DES_BOTTOM ||
            this.timeNodeLayoutType == TimeNodeLayoutType.TITLE_BOTTOM) {
            return 0;
        }
        let height = 0;
        let listLength = list.length;

        for (let index = 0; index < listLength; index++) {
            let node = list[index];
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    if (height < this.BaseLayout.getNodeHeight(node.head) + this.subjectAndLineSpace) {
                        height = this.BaseLayout.getNodeHeight(node.head) + this.subjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    if (height < node.head.height / 2 - this.lineSpacing - this.baseLineHeight) {
                        height = node.head.height / 2 - this.lineSpacing - this.baseLineHeight;
                    }
                    break;
                default:
            }
        }
        return height;
    }

    getNodesBottomHieght(list) {
        let height = 0;
        let listLength = list.length;
        for (let index = 0; index < listLength; index++) {
            let node = list[index];
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    if (height < this.BaseLayout.getNodeHeight(node.title) + this.BaseLayout.getNodeHeight(node.desc) + this.subjectAndLineSpace + this.sonSubjectAndLineSpace) {
                        height = this.BaseLayout.getNodeHeight(node.title) + this.BaseLayout.getNodeHeight(node.desc) + this.subjectAndLineSpace + this.sonSubjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.TITLE_BOTTOM:
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    if (height < this.BaseLayout.getNodeHeight(node.title) + this.subjectAndLineSpace) {
                        height = this.BaseLayout.getNodeHeight(node.title) + this.subjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                    let headTitleDesMiddleBottomHeight = this.BaseLayout.getNodeHeight(node.title) + this.BaseLayout.getNodeHeight(node.desc) + node.head.height / 2 + this.subjectAndLineSpace + this.sonSubjectAndLineSpace;
                    if (height < headTitleDesMiddleBottomHeight) {
                        height = headTitleDesMiddleBottomHeight;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    let headTitleMiddleBottomHeight = this.BaseLayout.getNodeHeight(node.title) + node.head.height / 2 + this.subjectAndLineSpace;
                    if (height < headTitleMiddleBottomHeight) {
                        height = headTitleMiddleBottomHeight;
                    }
                    break;
                default:
            }
        }
        return height;
    }

    getNodesWidth(list) {
        let width = this.subjectSpace;
        list.forEach(node => {
            width += this.getTimeMindTypeNodeWidthByHorizontal(node) + this.subjectSpace;
        })
        return width;
    }

    getTimeMindTypeNodeWidthByHorizontal(node) {
        let result = 0;

        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
                result = node.head.width;
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                result = node.head.width + this.UiUtil.dip2px(20);
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
                result = Math.max(result, node.title.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                result = node.head.width;
                result = Math.max(result, node.title.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                result = node.head.width + this.UiUtil.dip2px(20);
                result = Math.max(result, node.title.width);
                break;
            default:
        }

        return result;
    }

}
export default TimeLineHorizontalRingLayout