
import Config from "../../../core/core/calcule/Config"
import UiUtil from "../../../utils/UiUtil"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import TimeLineLayout from "./TimeLineLayout"
import BaseLayout from "./BaseLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineHorizontalLayout extends TimeLineLayout {
    constructor() {
        super();
        this.UiUtil = new UiUtil();
        this.BaseLayout = new BaseLayout();
        this.baseLineHeight = this.UiUtil.dip2px(10);
    }

    initConfigInfo() {
        this.radius = this.getRadius();
        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
            this.baseLineHeight = this.radius * 2 + this.UiUtil.dip2px(4);
        } else {
            this.baseLineHeight = Math.max(this.radius * 2, this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth)) + 10;
        }
        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);
        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
            this.baseLineHeight += this.UiUtil.dip2px(15);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineHeight += this.UiUtil.dip2px(17);
        } else {
            this.baseLineHeight += this.UiUtil.dip2px(10);
        }

        
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);
        this.subjectAndLineSpace = 0
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(5);
                } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(30);
                }
                break
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:            
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(5);
                } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_RING_ORDER) {
                    this.subjectAndLineSpace = this.addSubjectSpcaeVertical(30);
                }
                break
            default:
        }        
    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.title.height + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
        }
        this.initConfigInfo();

        let nodeList = this.getOrderNodes();
        let nodeListLength = nodeList.length;

        let left = this.title.x;
        let contentY = this.title.y + this.title.height + this.UiUtil.dip2px(60) + this.subjectAndLineSpace;
        if (nodeListLength > 0) {
            contentY += nodeList[0].head.height
        }

        this.baseLine.x = this.title.x;
        if (!this.isBackgroundAndBorder(this.title)) {            
            this.baseLine.x = this.title.x + this.getTextEdgeInsets(this.title).left;
            left = this.baseLine.x;
        }
        this.baseLine.y = contentY - this.baseLineHeight / 2;
        this.space = (this.baseLineHeight - this.radius) / 2;

        
        for (let index = 0; index < nodeListLength; index++) {
            let timeNode = nodeList[index];
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);

            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = left + (nodeWidth - head.width) / 2;
                    head.y = this.baseLine.y - this.BaseLayout.getNodeHeight(head) - this.subjectAndLineSpace;
                    title.x = left + (nodeWidth - title.width) / 2;
                    title.y = this.baseLine.y + this.baseLineHeight + this.subjectAndLineSpace/4;
                    desc.x = left + (nodeWidth - desc.width) / 2;
                    desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace;
                    break;
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    head.isHidden = true;
                    title.isHidden = false;
                    desc.isHidden = false;
                    title.x = left + (nodeWidth - title.width) / 2;
                    title.y = this.baseLine.y + this.baseLineHeight + this.subjectAndLineSpace/4;
                    desc.x = left + (nodeWidth - desc.width) / 2;
                    desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace;
                    break;
                case TimeNodeLayoutType.TITLE_BOTTOM:
                    head.isHidden = true;
                    title.isHidden = false;
                    desc.isHidden = true;
                    title.x = left + (nodeWidth - title.width) / 2;
                    title.y = this.baseLine.y + this.baseLineHeight + this.subjectAndLineSpace/4;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = true;
                    head.x = left + (nodeWidth - head.width) / 2;
                    head.y = this.baseLine.y - this.BaseLayout.getNodeHeight(head) - this.subjectAndLineSpace;
                    title.x = left + (nodeWidth - title.width) / 2;
                    title.y = this.baseLine.y + this.baseLineHeight + this.subjectAndLineSpace/4;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = left + (nodeWidth - head.width) / 2;
                    head.y = this.baseLine.y + (this.baseLineHeight - head.height) / 2;
                    title.x = left + (nodeWidth - title.width) / 2;
                    title.y = head.y + head.height + this.space;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = true;
                    head.x = left + (nodeWidth - head.width) / 2;
                    head.y = this.baseLine.y + (this.baseLineHeight - head.height) / 2;
                    title.x = left + (nodeWidth - title.width) / 2;
                    title.y = head.y + head.height + this.space;
                    break;
                default:
            }
            if (!desc.isHidden) {
                desc.x = left + (nodeWidth - desc.width) / 2;
                desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
            }
            left += nodeWidth + this.subjectSpace;
        }

        this.baseLine.width = left - this.baseLine.x;

        this.baseLineHeight += this.subjectAndLineSpace
        this.baseLine.height = this.baseLineHeight;

        this.baseLine.timeLineContent.lineContentWidth = this.baseLine.width;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLineHeight;

        let dots = new Array();

        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let mainMindElementDataDictLength = mainMindElementDataDict.length
        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let timeNode = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);

            let head = timeNode.head;
            let title = timeNode.title;
            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;
            dotElementContent.radius = this.radius;
            if (head.isHidden) {
                dotElementContent.x = title.x + title.width / 2 - this.baseLine.x;
            } else {
                dotElementContent.x = head.x + head.width / 2 - this.baseLine.x;
            }
            dotElementContent.y = (this.baseLineHeight - this.subjectAndLineSpace) / 2 + this.subjectAndLineSpace;
            if (this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM) {
                dotElementContent.radius = head.width / 2;
            } else {
                dotElementContent.radius = this.radius;
            }
            
            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dots.push(dotElementContent);
        }
        this.baseLine.y = this.baseLine.y - this.subjectAndLineSpace
        this.baseLine.timeLineContent.dots = this.getOrderDot(dots);
    }

    getOrderDot(dots) {
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1.x < data2.x) {
                return -1;
            } else if (data1.y == data2.y) {
                return 0;
            } else {
                return 1;
            }
        })
        return list;
    }



}
export default TimeLineHorizontalLayout
