const {default: strings} = require ("./strings");

var stringMap = {};
stringMap [strings.About_App_Name] = 'Mind + Mind Map';
stringMap [strings.TEMPLATE] = 'Template';
stringMap [strings.Mind_Edit_Main_Topic] = 'ဌာနခွဲခေါင်းစဉ်';
stringMap [strings.Global_Add_Free_Node_Tips] = 'ထည့်သွင်းမည့်တည်နေရာကိုရွေးချယ်ရန်မျက်နှာပြင်ပေါ်တွင်နှိပ်ပါ';
stringMap [strings.Global_Add_Free_Chart_Node_Tips] = 'ထည့်သွင်းမည့်တည်နေရာကိုရွေးချယ်ရန်မျက်နှာပြင်ကိုနှစ်ချက်နှိပ်ပါ။';
stringMap [strings.Global_Add_Relationship_Tips] = 'ဆက်နွယ်နေတဲ့ပစ်မှတ်ကိုရွေးပါ';
stringMap [strings.Global_Add_Target_Tips] = 'ကျေးဇူးပြုပြီးအရာဝတ္ထုများကိုထည့်သွင်းရန် node တစ်ခုကိုရွေးချယ်ပါ';
stringMap [strings.Mind_Mindmap_ConnectMap_Exist_Tips] = 'ဒီအရာဝတ္ထုသည်အခြားမြေပုံများနှင့်ဆက်စပ်နေပါတယ်';
stringMap [strings.Mind_Edit_Main_Idea] = 'ဗဟိုအကြောင်းအရာ';
stringMap [strings.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips] = 'ဆက်စပ်မြေပုံမရှိဘူး';
stringMap [strings.Mind_Style_Add_Icon_Priority] = 'ဦး စားပေး';
stringMap [strings.Mind_Style_Add_Icon_Progress] = 'တိုးတက်မှု';
stringMap [strings.Mind_Style_Add_Icon_Stars] = 'ကြယ်များ';
stringMap [strings.Mind_Style_Add_Icon_Week] = "ရက်သတ္တပတ်'၊"
stringMap [strings.Mind_Style_Add_Icon_Head_Portrait] = 'Portrait';
stringMap [strings.Establish] = 'ဖန်တီး';
stringMap [strings.Create_From_Template] = 'ပန်းချီကားကနေ ဖန်တီးထားတဲ့';
stringMap [strings.Common] = "အဖြစ်များ' ";
stringMap [strings.Function_List] = 'ရာထူးအမည်';
stringMap [strings.Recent_Updates] = 'မကြာသေးမီကမွမ်းမံထားတဲ့';
stringMap [strings.The_Latest_To_Delete] = "မကြာမီကပယ်ဖျက်' ခံရ၊";
stringMap [strings.Setting_Up] = 'ကျွန်ုပ်တို့ကိုဆက်သွယ်ပါ';
stringMap [strings.Usinghelp] = 'အကူအညီကိုအသုံးပြုခြင်း';
stringMap [strings.The_Download_Client] = 'ဂလိုင်း / မိုဘိုင်းဗားရှင်းကိုဒေါင်းလုပ်';
stringMap [strings.Global_Purchase] = 'ဝယ်ယူ';
stringMap [strings.Global_Login] = 'ဝင်မည်';
stringMap [strings.My_map] = 'ကျွန်ုပ်မြေပုံ';
stringMap [strings.Create_Map] = 'မြေပုံတခုဖန်တီးခြင်း';
stringMap [strings.Storm] = 'မုန်တိုင်း';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic] = 'အဖြစ်များသော \ nCharacteristic';
stringMap [strings.Mind_Double_Bubble_Edit_Main_Characteristic] = 'ဝိသေသ';
stringMap [strings.Mind_Organization_Edit_Main_Idea] = 'အဖွဲ့အစည်းစင်တာ';
stringMap [strings.Mind_Edit_Free_Topic] = 'အခမဲ့ခေါင်းစဉ်';
stringMap [strings.Mind_Edit_Sub_Topic] = 'ခေါင်းစဉ်ခွဲ';
stringMap [strings.Mind_Organization_Main_Topic] = "ဦး စီးဌာန'၊"
stringMap [strings.Mind_Edit_Main_Topic] = 'ဌာနခွဲခေါင်းစဉ်';
stringMap [strings.Mind_Time_Edit_Main_Idea] = 'စီစဉ်ခြင်း';
stringMap [strings.Mind_Time_Edit_Head] = 'အချိန်';
stringMap [strings.Mind_Time_Edit_Title] = 'အစီအစဉ်';
stringMap [strings.Mind_Edit_Details] = 'အသေးစိတ်ဖော်ပြချက်';
stringMap [strings.Checking_Data] = 'အချက်အလက်စစ်ဆေးခြင်း';
stringMap [strings.Setting_Information] = 'သတင်းအချက်အလက်များပြင်ဆင်ခြင်း';
stringMap [strings.Sign_Out] = 'ထွက်ရန်';

// function ကိုစာရင်း
stringMap [strings.Create_a_Mind_Map] = 'စိတ်မြေပုံတခုဖန်တီးပါ';
stringMap [strings.Create_Mind_Map_1] = '[1] အပြင်အဆင် (၁၄) ခုပါသောမြေပုံတစ်ခု (နှစ်ဖက်၊ ညာ၊ ဘယ် / အဖွဲ့အစည်းဖွဲ့စည်းပုံ / တစ်ခုတည်းပူဖောင်းဇယား / နှစ်ဖက်ပူဖောင်းဇယား / သစ်ပင်မြေပုံ / ညာသစ်ပင်မြေပုံ / နှစ်ဖက်သစ်ပင်ပုံ) / စားပွဲတင် / အလျားလိုက် timeline / ဒေါင်လိုက် timeline / S timeline / ပိရမစ်) ';
stringMap [strings.Create_Mind_Map_2] = '[2] မြေပုံများကို (တင်းပလိတ်အကြောင်းအရာအညွှန်းများအမြောက်အများ) မြေပုံများကိုလျင်မြန်စွာဖန်တီးရန်သုံးနိုင်သည်။'
stringMap [strings.Create_Mind_Map_3] = '[3] မျဉ်းကြောင်းပုံစံ ၉ မျိုးနှင့်မြေပုံကိုတည်းဖြတ်ပါ';
stringMap [strings.Create_Mind_Map_4] = '[4] မြေပုံသို့အပြင်အဆင်ကိုးခုကိုပြင်ဆင်ခြင်းနှင့်တည်းဖြတ်ခြင်း';
stringMap [strings.Create_Mind_Map_5] = '[5] ပစ်မှတ်ဆုံမှတ်သို့ကလေးများ node များထည့်ပြီးတူညီသောအဆင့် (အထက်နှင့်အောက်) တွင် node များကိုထည့်ပါ။'
stringMap [strings.Create_Mind_Map_6] = '[6] အနှစ်ချုပ် - တစ်ခုတည်းသောဆုံမှတ် / မျိုးစုံဆုံမှတ်များကိုဖော်ပြရန် [အကျဉ်းချုပ်] ကိုထည့်သွင်းခြင်း၊'
stringMap [strings.Create_Mind_Map_7] = '[7] ပိတ်ပင်တားဆီးမှု - modularized ခံရဖို့မြေပုံတစ်ခုတည်း node ကို / မျိုးစုံ node ကိုသေတ္တာများ [ပိတ်ပင်တားဆီးမှု] add';
stringMap [strings.Create_Mind_Map_8] = '[8] အခမဲ့ node ကိုထည့်သွင်း';
stringMap [strings.Create_Mind_Map_9] = '[9] မည်သည့်ဆုံမှတ်များအကြားမျဉ်းစတိုင် ၄ ခုနှင့်ဆက်နွယ်မှုတစ်ခုဖန်တီးရမည်';
stringMap [strings.Create_Mind_Map_10] = '[10] ရုပ်ပုံများကိုထည့်သွင်းခြင်း (local + material library)၊ icon များ၊ မှတ်စုများ၊ node သို့လင့်များ';
stringMap [strings.Create_Mind_Map_11] = '[11] ကူးယူခြင်း / ကူးထည့်ခြင်း node၊ မိတ္တူ / ကူးခြင်းစတိုင်';
stringMap [strings.Create_Mind_Map_12] = '[12] Cancel, restore and cancel';
stringMap [strings.Create_Mind_Map_13] = '[13] အကွိမျမြားစှာရှေးခယျြမှုမြားရရှိနိုငျပါသညျ။'
stringMap [strings.Create_Mind_Map_14] = '[14] စာရင်းအင်းဂရပ်ထည့်သွင်းနိုင်ပါတယ်';
stringMap [strings.Create_Mind_Map_15] = '[15] မြေပုံတစ်ခုလုံးမှတ်တမ်းတင်နိုင်ပါတယ်';
stringMap [strings.Create_Mind_Map_16] = '[16] မြေပုံကူးယူ';
stringMap [strings.Create_Mind_Map_17] = '[17] မြေပုံ (သို့) မြေပုံတခုလုံးကိုအခြားဖိုင်များ၏မြေပုံသို့ကူးယူနိုင်တယ်';
stringMap [strings.Create_Mind_Map_18] = '[18] အကျဉ်းချုပ်အပြီးမြေပုံကိုဖန်တီးနိုင်တယ်';
stringMap [strings.Create_Mind_Map_19] = '[19] (ကွန်ပျူတာဘက်) တည်းဖြတ်နေစဉ်မြေပုံတည်းဖြတ်မှုကိုပြောင်းလဲနိုင်သည်။'
stringMap [strings.Create_Mind_Map_20] = '[20] ဘလူးတုသ် keyboard keyboard editing map: \ n \ [Command] + [a] မာစတာ node ကို \ n ကိုရွေးချယ်ပါ။ ] ရွေးချယ်ထားသောပစ်မှတ်ကိုပြောင်းပါသို့မဟုတ်မြေပုံပြောင်းပါ။ n [[Command] + [e] ပစ်မှတ်အားတည်းဖြတ်ပါ။ n \ [Command] + [w] ကလေးသူငယ် node ကိုဖန်တီးပါ။ n [[Command] + [s] ပစ်မှတ်မွေးချင်း node ကိုဖန်တီးပါ။ အပေါ်သို့) n [Command] + [d] target sibling node (အောက်ခြေ) \ n \ [command> + [q] ရွေးချယ်ထားသည့် target မှထွက်ရန် \ n \ [Command] + [-] [+] Zoom မြေပုံ ';

stringMap [strings.Beautify_The_Mind_Map] = 'စိတ်မြေပုံကိုအလှဆင်';
stringMap [strings.Beautify_The_Mind_Map_1] = '[1] စိတ်မြေပုံ၏နောက်ခံအရောင်ကိုသတ်မှတ်ပါ';
stringMap [strings.Beautify_The_Mind_Map_2] = '[2] ကမြေပုံမျဉ်းတစ်ခုလုံး၏အထူနှင့်အရောင်ကို သတ်မှတ်၍ ၎င်းသည် tapered ဖြစ်စေမဖြစ်စေ၊'
stringMap [strings.Beautify_The_Mind_Map_3] = '[3] စာသားဖြည့်တဲ့အရောင်၊ border အရောင်၊ border ၁၃ ပုံစံ၊ border width, shadow effect ကိုသတ်မှတ်ပါ။'
stringMap [strings.Beautify_The_Mind_Map_4] = '[4] မျဉ်းကြောင်းရဲ့အရောင်နဲ့အကျယ်ရဲ့တစ်စိတ်တစ်ပိုင်းဒါမှမဟုတ်တစ်စိတ်တစ်ပိုင်းကိုသတ်မှတ်ရန်';
stringMap [strings.Beautify_The_Mind_Map_5] = '[5] စာသားဖောင့်အရွယ်အစား၊ စာလုံးစောင်း၊ စာလုံးစောင်း၊'
stringMap [strings.Beautify_The_Mind_Map_6] = '[6] ရုပ်ပုံအရွယ်အစား၊ အနေအထားနှင့်ပုံသဏ္styleာန်စတိုင်ကိုသတ်မှတ်ပါ';
stringMap [strings.Beautify_The_Mind_Map_7] = '[7] အမျိုးအစားခုနစ်ခု (ဘုံသုံးခု / လေ့လာမှု၊ အလုပ်၊ လူ၊ တိရစ္ဆာန်၊ အစားအစာ) အမျိုးအစား ၇ ခုမှ icon များကိုထည့်ပြီးအရွယ်အစား / တည်နေရာကိုသတ်မှတ်ပါ။'
stringMap [strings.Beautify_The_Mind_Map_8] = '[8] မြေပုံတစ်ခုလုံးကိုတစ်ချက်နှိပ်ရုံဖြင့်ရည်ညွှန်းချက်ထည့်ပါ';
stringMap [strings.Beautify_The_Mind_Map_9] = '[9] Group မှစီမံခန့်ခွဲမှုမြေပုံ';
stringMap [strings.Beautify_The_Mind_Map_10] = '[10] တစ် ဦး ချင်းစိတ်ကြိုက်နောက်ခံ (ပစ္စည်းစာကြည့်တိုက်) ကိုမြေပုံတစ်ခုလုံးသို့ပေါင်းထည့်နိုင်သည်';
stringMap [strings.Beautify_The_Mind_Map_9] = '[11] တစ် ဦး ချင်းနယ်နိမိတ်များသို့မဟုတ်နောက်ခံများ (ပစ္စည်းစာကြည့်တိုက်) ကိုတစ်ခုတည်းသောဆုံမှတ်တစ်ခုထဲသို့ထည့်နိုင်သည်။ '
stringMap [strings.Beautify_The_Mind_Map_9] = '[12] node ရဲ့အရှည်ကိုဆွဲယူနိုင်တယ်';


stringMap [strings.Map_Output] = 'မြေပုံထုတ်လုပ်မှု';
stringMap [strings.Map_Output_1] = '[1] ရုပ်ပုံသိမ်းဆည်း';
stringMap [strings.Map_Output_2] = '[2] PDF ကိုသိမ်းဆည်း';
stringMap [strings.Map_Output_3] = '[3] link ကိုမျှဝေ';
stringMap [strings.Map_Output_4] = '[4] မုန်တိုင်းသို့ဝေငှပါ';
stringMap [strings.Map_Output_4] = '[5] ပုံနှိပ်ရန်';

stringMap [strings.Work_together] = 'ပူးပေါင်းလုပ်ဆောင်ခြင်း';
stringMap [strings.Work_together_1] = '[1] မျှဝေထားသောအုပ်စုတစ်ခု (အုပ်စုလိုက်တံဆိပ်၊ အုပ်စုလိုက်ကြေငြာချက်) ဖန်တီးပါ။'
stringMap [strings.Work_together_2] = '[2] အုပ်စုလိုက်အဖွဲ့ဝင်အသစ်များ';
stringMap [strings.Work_together_3] = 'အုပ်စုအတွင်းရှိအဖွဲ့ ၀ င်များပြားမှုသည်အတူတူမြေပုံကိုအတူတူတည်းဖြတ်သည်';
stringMap [strings.Work_together_4] = '[4] အုပ်စုအတွင်းရှိဖိုင်များကိုတင်ပါ (word / excle / pdf)';
stringMap [strings.Work_together_5] = '[5] သင်အဖွဲ့လိုက်မက်ဆေ့ခ်ျကိုထားခဲ့နိုင်သည်';
stringMap [strings.Work_together_6] = '[6] အုပ်စုလိုက်စီမံခန့်ခွဲမှုအခွင့်အာဏာဆက်တင်များမျှဝေခြင်း';
stringMap [strings.Work_together_7] = '[7] အုပ်စုဖျက်သိမ်း;'

stringMap [strings.Work_together] = 'တီထွင်ဖန်တီးမှုမျှဝေခြင်း (မုန်တိုင်း)';
stringMap [strings.Community_Sharing_1] = '[1] မင်းရဲ့ဖန်တီးမှုကိုလူအများမြင်နိုင်ရန်မုန်တိုင်းသို့မျှဝေနိုင်သည်';
stringMap [strings.Community_Sharing_1] = '[2] ဆက်စပ်သောမုန်တိုင်းအကြောင်းအရာများကိုကြည့်ရှုရန် tags များကိုသင်ရွေးနိုင်သည်';
stringMap [strings.Community_Sharing_1] = '[3] မုန်တိုင်းအကျင့်ကိုသင်ကြိုက်နှစ်သက်။ မှတ်ချက်ပေးနိုင်သည်';

// လတ်တလော update လုပ်
stringMap [strings.Recently_Updated_Title_1] = 'ပုဂ္ဂိုလ်ရေးအကောင့်တခုဖန်တီးပါ';
stringMap [strings.Recently_Updated_Title_2] = 'Massive Template: အသိပညာအခြေစိုက်စခန်း';
stringMap [strings.Recently_Updated_Title_3] = 'မျိုးစုံပလက်ဖောင်းများကိုထောက်ပံ့';
stringMap [strings.Recently_Updated_Title_4] = 'အသေးစိတ်တိုင်းကိုစိတ်ကြိုက်';
stringMap [strings.Recently_Updated_Title_5] = 'ကိုယ်ပိုင်စတိုင်လ်စတိုင်စာကြည့်တိုက်';
stringMap [strings.Recently_Updated_Title_6] = 'မြေပုံ output ကို';
stringMap [strings.Recently_Updated_Title_7] = 'တိမ်တိုက်သိုလှောင်';
stringMap [strings.Recently_Updated_Title_8] = "မြေပုံ ၁၅ ခု'၊"


stringMap [strings.Recently_Updated_Content_1] = 'ကိုယ်ပိုင်အကောင့်တခုဖွင့်ပြီးအကောင့်နဲ့ဖန်တီးထားတဲ့မြေပုံကိုသိမ်းဆည်းပါ';
stringMap [strings.Recently_Updated_Content_2] = 'သင်ယူမှုထိရောက်မှုတိုးတက်စေရန်အတွက် template template ၏ကြီးမားသောအရေအတွက်ကိုတိုက်ရိုက်ကိုးကားထားသည်';
stringMap [strings.Recently_Updated_Content_3] = 'အွန်လိုင်းထုတ်လုပ်မှုကိုပံ့ပိုးပေးတယ်၊ IOS မိုဘိုင်း terminal၊ Mac terminal၊ Android မိုဘိုင်း terminal၊ Android တက်ဘလက်အမျိုးမျိုးသုံးတယ်။'
stringMap [strings.Recently_Updated_Content_4] = 'စာသား၊ နောက်ခံ၊ လိုင်း၊ ရုပ်ပုံအိုင်ကွန်စသည်တို့ကိုသင်နှစ်သက်ရာအတိုင်းအလှဆင်နိုင်သည်';
stringMap [strings.Recently_Updated_Content_5] = 'ကိုယ်ပိုင်စတိုင်လ်စတိုင်လ်စာကြည့်တိုက်ကိုတိုက်ရိုက်ကိုးကားထားပြီးအရောင်ကိုက်ညီခြင်းပြtheနာကိုဖယ်ရှားပေးသည်';
stringMap [strings.Recently_Updated_Content_6] = 'သင်သည်ဓါတ်ပုံများ၊ PDF ဖိုင်များသိမ်းနိုင်ပြီးသူငယ်ချင်းများနှင့်တိုက်ရိုက်မျှဝေနိုင်သည်';
stringMap [strings.Recently_Updated_Content_7] = 'ခိုင်မာသောတိမ်တိုက်သိုလှောင်ရေးပံ့ပိုးမှု၊ ဒေတာဆုံးရှုံးမှုနဲ့ပတ်သက်ပြီးစိတ်ပူစရာမလိုတော့ဘူး';
stringMap [strings.Recently_Updated_Content_8] = 'နှစ်လမ်း၊ ညာ၊ ဘယ်၊ အဖွဲ့အစည်းဖွဲ့စည်းမှု၊ တစ်ခုတည်းပူဖောင်းဇယား၊ နှစ်ဆပူဖောင်းဇယား၊ သစ်ပင်ဇယား၊ ညာသစ်ပင်ဇယား၊ နှစ်လမ်းသစ်ပင်ဇယား၊ ဇယား၊ အလျားလိုက် timeline၊ ဒေါင်လိုက် timeline၊ S အချိန်ဇယား၊ အလိုအလျောက်ပုံစံ (၈) ခုပြောင်းနိုင်သည်။'

// မကြာသေးမီကဖျက်လိုက်သည်
stringMap [strings.Mind_Mindmap_Deleted_Title] = "မကြာမီကပယ်ဖျက်' ခံရ၊"
stringMap [strings.Mind_Mindmap_Deleted_Recovery] = 'ပြန်လည်ထူထောင်ရေး';
stringMap [strings.Mind_Mindmap_Deleted_Forever] = 'ဖျက်ပစ်';
stringMap[strings.Mind_Mindmap_Only_Deleted_Forever] = 'Delete a single theme';
stringMap [strings.Mind_Mindmap_Deleted_Filename] = 'ဖိုင်အမည်';
stringMap [strings.Mind_Mindmap_Deleted_Tips] = 'ဒီနေရာကိုပယ်ဖျက်ပြီးတဲ့နောက်၊ အဲဒါကိုအမြဲတမ်းဖယ်ရှားလိမ့်မယ်';
stringMap [strings.Mind_Mindmap_Deleted_Operating] = 'စစ်ဆင်ရေး';
stringMap [strings.Mind_Mindmap_Deleted_Selected] = 'မကြာသေးမီကပယ်ဖျက်ခဲ့တဲ့မြေပုံကိုရှာပါ';
stringMap [strings.Mind_Mindmap_Deleted_Forever_Time] = "နေ့စွဲကိုပယ်ဖျက်' ပါ။"

//ကြှနျုပျတို့ကိုဆကျသှယျရနျ
stringMap [strings.Contact_Us_No_Public] = "Public Account'၊"
stringMap [strings.Contact_Us_QQ_Group] = "QQ Group'၊"
stringMap [strings.Contact_Us_Message_Board] = "သတင်းပေးဘုတ်'၊"
stringMap [strings.Contact_Us_Username] = 'အမည်';
stringMap [strings.Contact_Us_Email] = 'အီးမေးလ်';
stringMap [strings.Contact_Us_Voicemail] = 'ကျေးဇူးပြု၍ သင်၏အဖိုးတန်မှတ်ချက်များကိုချန်ထားပါ';
stringMap [strings.Contact_Us_Send_Email] = 'အီးမေးလ်ပို့ခြင်း';

// မက်ဆေ့ခ်ျကိုအတည်ပြုရန်
stringMap [strings.Input_Username_isNull] = 'အမည်မဖြည့်နိုင်ဘူး';
stringMap [strings.Input_Email_isNull] = 'ကျေးဇူးပြု၍ မှန်ကန်သောအီးမေးလ်လိပ်စာကိုရေးပါ';
stringMap [strings.Input_Voicemail_isNull] = 'ကျေးဇူးပြုပြီးပို့လိုက်တဲ့အကြောင်းအရာကိုရေးထည့်ပါ';
stringMap [strings.Input_Introduction_isNull] = 'နိဒါန်းဟာအချည်းနှီးမဖြစ်နိုင်ဘူး';
stringMap [strings.Input_Title_isNull] = 'ခေါင်းစဉ်ဟာအချည်းနှီးမဖြစ်နိုင်ဘူး၊'

stringMap [strings.Input_Please_Enter_A_Valid_Phone_Number] = 'ကျေးဇူးပြု၍ မှန်ကန်သောဖုန်းနံပါတ်ကိုရိုက်ထည့်';
stringMap [strings.Input_Please_Enter_Verification_Code] = 'ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကိုရိုက်ထည့်ပါ။ ';
stringMap [strings.Input_Please_Enter_The_Password] = 'ကျေးဇူးပြုပြီးစကားဝှက်ကိုရေးပါ။ ';
stringMap [strings.Input_The_Password_Format_Is_Incorrect] = 'စကားဝှက်ပုံစံကမမှန်ကန်ဘူး။ ';
stringMap [strings.Input_Please_Enter_A_New_Password] = 'ကျေးဇူးပြုပြီးစကားဝှက်အသစ်ထည့်ပါ။ ';
stringMap [strings.Input_Confirm_The_New_Password] = 'ကျေးဇူးပြု၍ အတည်ပြုစကားဝှက်ကိုရေးပါ။ ';
stringMap [strings.Input_The_Passwords_Are_Inconsistent] = 'စကားဝှက်များသည်ရှေ့နောက်မညီပါ။ ';
stringMap [strings.Input_The_Please_Enter_The_Correct_Verification_Code] = 'ကျေးဇူးပြု၍ မှန်ကန်သောအတည်ပြုနံပါတ်ကိုရေးထည့်ပါ။ ';
stringMap [strings.Input_The_Verification_Code_Error] = 'အတည်ပြုခြင်းကုဒ်အမှား! ';

// အကူအညီကိုအသုံးပြုခြင်း
stringMap [strings.Mind_Usinghelp_1] = 'စိတ်မြေပုံတခုဖန်တီးပါ';
stringMap [strings.Mind_Usinghelp_2] = "node တစ်ခုကိုထည့်သွင်းနည်း'၊"
stringMap [strings.Mind_Usinghelp_3] = 'အခမဲ့ node တခုဖန်တီးပါ';
stringMap [strings.Mind_Usinghelp_4] = "အသင်းအဖွဲ့ထူထောင်ရန်'၊"
stringMap [strings.Mind_Usinghelp_5] = 'ပတ္တူသွင်ပြင်';
stringMap [strings.Mind_Usinghelp_6] = 'beautify text';
stringMap [strings.Mind_Usinghelp_7] = "မှတ်စုများထည့်ခြင်း၊ လင့်များ'၊"
stringMap [strings.Mind_Usinghelp_8] = 'Insert icon';
stringMap [strings.Mind_Usinghelp_9] = 'ဓာတ်ပုံထည့်သွင်း';
stringMap [strings.Mind_Usinghelp_10] = "ပယ်ဖျက်ခြင်းကိုဘယ်လိုတင်ရမယ်'၊"
stringMap [strings.Mind_Usinghelp_11] = 'ပို့ကုန်စိတ်မြေပုံ';
stringMap [strings.Mind_Usinghelp_12] = 'အသင်းဝင်အဆင့်မြှင့်ခြင်း';
stringMap [strings.Mind_Usinghelp_13] = "ဖြတ်လမ်းကီး'၊"
stringMap [strings.Mind_Usinghelp_Understood] = 'ငါရတယ်';
stringMap [strings.Mind_Usinghelp_14] = 'စာကြည့်တိုက်ကိုထည့်ပါ';
// Help-2 ကိုသုံးပါ
stringMap [strings.Mind_Usinghelp_15] = 'မြေပုံသစ်';
// stringMap [strings.Mind_Usinghelp_2] = 'node ကိုထည့်သွင်း';
// stringMap [strings.Mind_Usinghelp_4] = 'အသင်းအဖွဲ့ထူထောင်ရန်';
// stringMap [strings.Mind_Usinghelp_5] = 'ပတ္တူစများကိုအလှဆင်';
// stringMap [strings.Mind_Usinghelp_6] = 'Beautify text';
// stringMap [strings.Mind_Usinghelp_7] = 'မှတ်စုများ၊ လင့်များထည့်ပါ';
stringMap [strings.Mind_Usinghelp_17] = "စစ်ဆင်ရေးကိုပယ်ဖျက်ပါ၊ ပယ်ဖျက်' ပါ။"
stringMap [strings.Mind_Usinghelp_18] = "swap to node ကိုဆွဲချ'၊"
stringMap [strings.Mind_Usinghelp_19] = 'Node alignment';
stringMap [strings.Mind_Usinghelp_20] = 'မြေပုံအမျိုးအစားကိုပြောင်းလဲ';
stringMap [strings.Mind_Usinghelp_21] = 'မြေပုံလိုင်းစတိုင်';
stringMap [strings.Mind_Usinghelp_22] = 'မြေပုံနောက်ခံအရောင်';
stringMap [strings.Mind_Usinghelp_23] = "သတ်မှတ်ထားသော node ပုံသဏ္'ာန်";
stringMap [strings.Mind_Usinghelp_24] = 'node လိုင်းစတိုင်';
stringMap [strings.Mind_Usinghelp_25] = 'Node ရုပ်ပုံချိန်ညှိချက်များ';
stringMap [strings.Mind_Usinghelp_26] ='Node အိုင်ကွန်ဆက်တင်များ';
stringMap [strings.Mind_Usinghelp_27] = 'Node Add Summary';
stringMap [strings.Mind_Usinghelp_28] = 'တစ်ခုတည်း node box box ကိုရွေးချယ်ခြင်း';
stringMap [strings.Mind_Usinghelp_29] = 'Multi-node များလုပ်ကွက်များ';
stringMap [strings.Mind_Usinghelp_30] = 'node add border';
stringMap [strings.Mind_Usinghelp_31] = 'စာရင်းအင်းမြေပုံထည့်ပါ';
stringMap [strings.Mind_Usinghelp_32] = 'node ကိုဝှက်ပြီး restore';
stringMap [strings.Mind_Usinghelp_33] = 'node multiple operations';
stringMap [strings.Mind_Usinghelp_34] = 'စတိုင်လ်ပြောင်းခြင်း';
stringMap [strings.Mind_Usinghelp_35] = 'အသံနဲ့ရှင်းပြချက်ကိုထည့်သွင်း';
stringMap [strings.Mind_Usinghelp_36] = 'မြေပုံသို့လမ်းညွှန်ပုံ';
stringMap [strings.Mind_Usinghelp_37] = 'မြေပုံ PDF စာရွက်စာတမ်း';
stringMap [strings.Mind_Usinghelp_38] = 'မြေပုံပုံနှိပ်ခြင်း';
stringMap [strings.Mind_Usinghelp_39] = 'ချိတ်ဆက်မှုဝေမျှခြင်းနှင့်ကြည့်ရှုခြင်း';
stringMap [strings.Mind_Usinghelp_40] = 'မုန်တိုင်းအားဝေငှရန်မြေပုံ';
stringMap [strings.Mind_Usinghelp_41] = 'ဖိုင်တွဲကိုအသုံးပြုခြင်း';
stringMap [strings.Mind_Usinghelp_42] = 'မြေပုံကိုပယ်ဖျက်ပြီး restore';
// အကူအညီကိုသုံးပါ
stringMap [strings.Mind_Usinghelp_43] = 'သူငယ်ချင်းများသည်အဖွဲ့ထဲဝင်ရန်လျှောက်ထားသည်';
stringMap [strings.Mind_Usinghelp_44] = 'အုပ်စုကိုဖျက်သိမ်းနည်း';
stringMap [strings.Mind_Usinghelp_45] = "ဖိုင်အချက်အလက်များကိုတင်ပို့ပါ'၊"
stringMap [strings.Mind_Usinghelp_46] = 'လျှောက်လွှာကိုဝင်ရန်သဘောတူ';
stringMap [strings.Mind_Usinghelp_47] = 'ပူးပေါင်းတည်းဖြတ်မှု';
stringMap [strings.Mind_Usinghelp_48] = "New Sharing Group'၊"
stringMap [strings.Mind_Usinghelp_49] = "အဖွဲ့ ၀ င်များကိုဖိတ်ကြား'၊"

// မုန်တိုင်း
stringMap [strings.Mind_Storm_Recommend] = 'ထောက်ခံချက်';
stringMap [strings.Mind_Storm_Boilingpoint] = 'ပြုတ်သောအချက်';
stringMap [strings.Mind_Storm_Unfold] = 'ဖှဲ့';
stringMap [strings.Mind_Storm_Putaway] = 'Putaway';
stringMap [strings.Mind_Storm_Myclassification] = 'ငါခွဲခြား';
stringMap [strings.Mind_Storm_Recommended_Classification] = 'အကြံပြုထောက်ခံချက်';
stringMap [strings.Mind_Storm_Click_Add_Category] = 'အမျိုးအစားထည့်ရန်နှိပ်ပါ';
stringMap [strings.Mind_Storm_No_Classification] = 'အဘယ်သူမျှမခွဲခြားရသေး';
stringMap [strings.Mind_Storm_No_Tinterested] = 'စိတ်မ ၀ င်စားဘူး';
stringMap [strings.Mind_Storm_Report] = 'အစီရင်ခံစာ';
stringMap [strings.Mind_Storm_Views] = 'ကြည့်ရှုခြင်း';

stringMap [strings.Mind_Storm_Pornographic] = 'အပြာရုပ်ပုံများ';
stringMap [strings.Mind_Storm_Advertising_Harassment] = 'ကြော်ငြာနှောက်ယှက်ခြင်း';
stringMap [strings.Mind_Storm_Political_Religion] = "နိုင်ငံရေးနှင့်ဘာသာရေး'၊"
stringMap [strings.Mind_Storm_Infringement] = 'ချိုးဖောက်မှု (ပုံတူ၊ အသရေဖျက်မှု၊ plagiarism၊ လိမ်လည်မှုအသုံးပြုမှု)';
stringMap [strings.Mind_Storm_Prohibited_Content] = 'တားမြစ်ထားသောအကြောင်းအရာ';
stringMap [strings.Mind_Storm_Spread_Rumors] = 'ကောလာဟလများပျံ့နှံ့စေ။ ကောလာဟလများပျံ့နှံ့စေ';
stringMap [strings.Mind_Storm_Report_Successful] = 'အောင်မြင်စွာအစီရင်ခံ';

// ပြင်ဆင်ရန်
stringMap [strings.Global_Edit] = 'တည်းဖြတ်';
stringMap [strings.Global_Cancel] = "Cancel'၊"
stringMap [strings.Global_Ok] = 'အိုကေ';
stringMap [strings.Global_Name] = 'Nickname';
stringMap [strings.Global_Introduction] = "နိဒါန်း'၊"
stringMap [strings.Global_Change_Avatar] = 'ရုပ်ပုံပြောင်းခြင်း';
stringMap [strings.Global_Local_Upload] = "'ဒေသတွင်းတင်ပို့ချက်'၊"

//ကိုယ်ပိုင်သတင်းအချက်အလက်များ
stringMap [strings.User_Personal_information] = 'ကိုယ်ရေးကိုယ်တာအချက်အလက်';
stringMap [strings.User_Member_Validity_Period] = 'အသင်းဝင်သက်တမ်းကာလ';
stringMap [strings.User_My_Share] = 'ကျွန်ုပ်၏ဝေစု';
stringMap [strings.User_My_Collection] = 'ကျွန်ုပ်၏စုဆောင်းမှု';
stringMap [strings.User_More_Message] = 'ပို';
stringMap [strings.User_Deleted_Sotrm] = 'မုန်တိုင်းကိုဖျက်ပစ်';
stringMap [strings.User_Folder_Rename] = 'အမည်ပြောင်း';
stringMap [strings.User_Folder_Rename_Root] = 'ပြုပြင်မွမ်းမံခွင့်ပြုချက်များ';
stringMap [strings.User_Folder_Rename_Root_Title] = 'ပြုပြင်မွမ်းမံခွင့်ပြုချက်';
stringMap [strings.User_Folder_Root_Public] = "အများပြည်သူအပြည့်အဝ'၊"
stringMap [strings.User_Folder_Root_Viewonly] = 'အများဆိုင်ဖိုဒါကိုကြည့်ရှုခွင့်သာရှိတယ်';
stringMap [strings.User_Folder_Root_Private] = 'ပုဂ္ဂလိက';
stringMap [strings.User_Folder_Root_Public_Introduction] = 'အခြားလူများကသင်နှစ်သက်သောအရာကိုသင်၏ပရိုဖိုင်စာမျက်နှာတွင်ကြည့်နိုင်သည်';
stringMap [strings.User_Message_Link] = "ချိတ်ဆက်မှု'၊"
stringMap [strings.User_New_Group] = 'အုပ်စုသစ်';
stringMap [strings.User_New_Group_Title] = 'အုပ်စုအသစ်တစ်ခုဖန်တီးပါ';
stringMap [strings.User_New_Group_Style_Title] = 'ဖိုင်အမျိုးအစား';
stringMap [strings.User_Share_Is_Null] = 'မျှဝေခြင်းမရှိသေးပါ။ ';
stringMap [strings.User_Deleted_Storm_Tips] = 'ဒီမုန်တိုင်းကိုသင်ဖျက်လိုပါသလား။ ';
stringMap [strings.User_Remove_From_List] = "စာရင်းမှဖယ်ရှားပါ'၊"
    stringMap [strings.User_Move_To] = "သို့ရွှေ့'၊"
    stringMap [strings.User_Deleted_Link] = 'link ကိုဖျက်ပစ်'
stringMap [strings.User_Collction_Is_Null] = 'စုဆောင်းခြင်းမရှိသေးပါ'
stringMap [strings.User_Collection] = 'စုဆောင်းခြင်း'

// ဝင်ရန်အကွက်
stringMap [strings.User_Lolgin_Welcome_To_Login] = 'စိတ်ထဲမှကြိုဆိုပါ၏'
stringMap [strings.User_Lolgin_Free_Registration] = 'အခမဲ့မှတ်ပုံတင်ခြင်း'
stringMap [strings.User_Lolgin_Please_Enter_Phone_Number] = 'ကျေးဇူးပြု၍ သင့်ဖုန်းနံပါတ်ကိုရိုက်ထည့်'
stringMap [strings.User_Lolgin_Please_Enter_Verification_Code] = 'ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကိုရိုက်ထည့်'
stringMap [strings.User_Lolgin_Obtain] = 'ရယူပါ'
stringMap [strings.User_Lolgin_Password_Login] = 'စကားဝှက်ရဲ့ login'
stringMap [strings.User_Lolgin_Login] = 'ဝင်မည်'
stringMap [strings.User_Lolgin_Register_An_Account] = 'အကောင့်မှတ်ပုံတင်ခြင်း'
stringMap [strings.User_Lolgin_Already_Have_An_Account] = 'အကောင့်ရှိပြီးသား'
stringMap [strings.User_Lolgin_To_Log_In] = 'လော့ဂ်အင်လုပ်ရန်'
stringMap [strings.User_Lolgin_Please_Enter_Password] = 'ကျေးဇူးပြု၍ လျှို့ဝှက်နံပါတ်ကိုရေးပါ'
stringMap [strings.User_Lolgin_Confirm_Password] = 'စကားဝှက်ကိုအတည်ပြုပါ'
stringMap [strings.User_Lolgin_Registered] = 'မှတ်ပုံတင်'
stringMap [strings.User_Lolgin_Account_Login] = 'အကောင့်ဝင်ပါ'
stringMap [strings.User_Lolgin_Scan_Code_Login] = 'ကုဒ်နံပါတ်ကိုလော့အင်'
stringMap [strings.User_Lolgin_Forgot_Password] = 'စကားဝှက်မေ့နေတယ်'
stringMap [strings.User_Lolgin_Verification_Code_Login] = 'စစ်ဆေးအတည်ပြုတဲ့ကုဒ်နံပါတ်'
stringMap [strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface] = 'ပွင့်လင်းစိတ် + စစ်ဆေးခြင်းမျက်နှာပြင်ရဲ့ထိပ်ဖျား'
stringMap [strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click] = 'ဒီ QR ကုဒ်ကငါးမိနစ်အတွင်းကုန်သွားတယ်၊ ကုန်သွားရင် ကျေးဇူးပြု၍ နှိပ်ပါ'
stringMap [strings.User_Lolgin_Refresh] = 'Refresh'
stringMap [strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone] = 'စကင်ဖတ်စစ်ဆေးမှုပြီးဆုံးပြီ၊ ကျေးဇူးပြုပြီးသင့်ဖုန်းကိုအတည်ပြုလိုက်ပါ'
stringMap [strings.User_Lolgin_Whether_Yo_Authorize_Login] = 'login လုပ်ပိုင်ခွင့်ရှိမရှိ'
stringMap [strings.User_Lolgin_Please_Enter_New_Password] = 'ကျေးဇူးပြု၍ စကားဝှက်အသစ်ရေးပါ'
stringMap [strings.User_Lolgin_Reset] = 'ပြန်လည်စတင်'

// ငါ့မြေပုံ
stringMap [strings.My_Mind_Search_Template_Type_Or_Template] = 'ရှာဖွေရန်ပုံစံအမျိုးအစားသို့မဟုတ်ပုံစံ'
stringMap [strings.My_Mind_Date] = 'နေ့စွဲ'
stringMap [strings.My_Mind_Create_Folder] = 'ဖိုင်တွဲဖန်တီးရန်'
stringMap [strings.My_Mind_Please_Enter_The_Folder_Name] = 'ကျေးဇူးပြု၍ ဖိုင်တွဲအမည်'
stringMap [strings.My_Mind_Please_Enter_A_New_Folder_Name] = 'ကျေးဇူးပြု၍ ဖိုင်တွဲအမည်အသစ်ထည့်ပါ'
stringMap [strings.My_Mind__No_Map_Yet_Go] = 'မြေပုံမရှိသေးပါ၊ အမြန်သွားပါ'
stringMap [strings.My_Mind_Create] = 'ဖန်တီး'
stringMap [strings.My_Mind_Right] = 'ဘား'
stringMap [strings.My_Mind_Open] = 'ဖွင့်'
stringMap [strings.My_Mind_Type_Left_Right] = 'လမ်းညွှန်မြေပုံနှစ်ခု'
stringMap [strings.My_Mind_Type_Unilateral] = 'တစ်ဖက်သတ်မြေပုံ'
stringMap [strings.My_Mind_Type_Bottom] = 'အဖွဲ့အစည်းဇယား'
stringMap [strings.My_Mind_Type_Bubble] = 'ပူဖောင်းဇယား'
stringMap [strings.My_Mind_Type_Time_Vertical] = 'အချိန်ဇယားမြေပုံ'
stringMap [strings.My_Mind_Blank_Mind_Map] = 'ဗလာစိတ်မြေပုံ'
stringMap [strings.My_Mind_All] = 'အားလုံး'
stringMap [strings.My_Mind_Views] = 'ကြည့်ရှုခြင်း'

// မုန်တိုင်းရှာဖွေမှု
stringMap [strings.Storm_Search_How_Title] = 'ထိပ်တန်းအမှတ်အသားများ'
stringMap [strings.Storm_History_Search] = 'သမိုင်းရှာဖွေမှု'

// လူထုခေါင်းစီး
stringMap [strings.Public_Header_Mind] = 'မြေပုံ'
stringMap [strings.Public_Header_Outline] = 'အကြမ်းဖျင်း'
stringMap [strings.Public_Header_Download_Apps] = 'client ကိုဒေါင်းလုပ်'
stringMap [strings.Public_Header_Mobile_Version] = 'Mobile version'
stringMap [strings.Public_Header_Creator] = 'ဖန်ဆင်းရှင်'
stringMap [strings.Public_Header_View_Frequency] = 'ကြည့်ရှုခြင်း'
stringMap [strings.Public_Header_Save] = 'သိမ်းဆည်း'
stringMap [strings.Header_Mind_Tips_Saved] = 'သိမ်းဆည်း'
stringMap [strings.Header_Mind_Tips_Edit] = 'တည်းဖြတ်သည်'
stringMap [strings.Header_Mind_Tips_Update_Saved] = 'အသစ်ပြောင်းခြင်းကိုသိမ်းပြီးပြီ'

// မြေပုံတည်းဖြတ်ရေးမီနူး
stringMap [strings.Mind_Edit_Revoke] = 'ပြန်လည်ရုပ်သိမ်းရန်'
stringMap [strings.Mind_Edit_Restore] = 'Restore'
stringMap [strings.Mind_Edit_Subtopic] = 'subttic'
stringMap [strings.Mind_Edit_Top_Subtopic] = 'Top Subtopic'
stringMap [strings.Mind_Edit_Bottom_Subtopic] = 'အောက်အဆင့်ခွဲ'
stringMap [strings.Mind_Edit_Free_Node] = 'အခမဲ့ဆုံမှတ်'
stringMap [strings.Mind_Edit_Summary] = 'အနှစ်ချုပ်'
stringMap [strings.Mind_Edit_Block] = 'ပိတ်ပင်တားဆီးမှုထဲသို့'
stringMap [strings.Mind_Edit_Insert] = 'ထည့်သွင်း'
stringMap [strings.Mind_Edit_Related] = 'ဆက်စပ်'
stringMap [strings.Mind_Edit_Style] = 'စတိုင်'
stringMap [strings.Mind_Edit_Recording] = 'အသံသွင်းခြင်း'
stringMap [strings.Mind_Edit_Insert_Picture] = 'ဓာတ်ပုံထည့်သွင်း'
stringMap [strings.Mind_Edit_Insert_Icon] = 'Insert icon'
stringMap [strings.Mind_Edit_Insert_Remarks] = 'မှတ်ချက်ထည့်ပါ'
stringMap [strings.Mind_Edit_Insert_Link] = 'ချိတ်ဆက်ထည့်ခြင်း'
stringMap [strings.Mind_Edit_Insert_Summary_Graph] = 'စာရင်းအင်းဂရပ်ကိုထည့်ပါ'
stringMap [strings.Mind_Edit_Upload] = 'တင်ယူ'
stringMap [strings.Mind_Edit_Right_Icon] = 'အိုင်ကွန်'
stringMap [strings.Mind_Edit_Right_Style] = 'စတိုင်'
stringMap [strings.Mind_Edit_Audio_Decoding] = 'ကုဒ်ဖြုတ်ခြင်း'
stringMap [strings.Mind_Edit_Audio_Duration] = 'Duration'
stringMap [strings.Mind_Edit_Delete_Audio_Tips] = "သင်အသံဖမ်းထားတဲ့အသံကိုပယ်ဖျက်ဖို့သေချာလား။ '"
stringMap [strings.Mind_Edit_Join_Membership] = 'ပွင့်လင်းသောအသင်း ၀ င်'
stringMap [strings.Mind_Edit_Upgrade] = 'အဆင့်မြှင့်'
stringMap [strings.Mind_Edit_Upgrade_Item] = "Upgrade to Pro"
stringMap [strings.Mind_Edit_Join_Membership_Tips] = "အသင်းဝင်များသည်မှတ်တမ်းတင်အချိန်ပိုရနိုင်သည်၊ ယခုအသင်းဝင်အဖြစ်သို့ပြောင်းရန်သွားမည်လော။ '"

stringMap [strings.Mind_Edit_Choose_Material] = 'ပစ္စည်းရွေးရန်'
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Picture_Here] = 'ဒီမှာပုံတွေကိုဆွဲချချလိုက်ပါ'
stringMap [strings.Mind_Edit_Select_locally] = 'ဒေသတွင်းမှရွေးပါ'
stringMap [strings.Mind_Edit_Choose_From] = 'ပစ္စည်းစာကြည့်တိုက်မှရွေးပါ'
stringMap [strings.Mind_Edit_Mine] = 'ကျွန်ုပ်၏'
stringMap [strings.Mind_Edit_My_Material] = 'ကျွန်ုပ်၏ပစ္စည်း'
stringMap [strings.Mind_Edit_Recently_Used] = 'မကြာသေးမီကအသုံးပြုခဲ့တဲ့'
stringMap [strings.Mind_Edit_Use] = 'အသုံးပြုခြင်း'
stringMap [strings.Mind_Edit_No_Material_Temporarily_Go_Add_It] = "ယခုအချိန်တွင်မည်သည့်ပစ္စည်းမျှသွား၊ ထပ်ထည့်ပါ။ '"
stringMap [strings.Mind_Edit_Select_Chart_Type] = 'ဇယားအမျိုးအစားရွေးရန်'
stringMap [strings.Mind_Edit_Remarks] = 'မှတ်ချက်များ'
stringMap [strings.Mind_Edit_Remarks_Tips] = 'ကျေးဇူးပြုပြီးမှတ်ချက်များကိုရေးပါ'
stringMap [strings.Mind_Edit_Add] = 'ထည့်ပါ'
stringMap [strings.Mind_Edit_Please_Enter_The_Link] = 'ကျေးဇူးပြု၍ link ကိုရိုက်ထည့်ပါ'

// စာမျက်နှာ၏ဘယ်ဘက်ခြမ်းရှိမီနူးကိုတည်းဖြတ်ပါ
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture] = 'ရုပ်ပုံသိမ်းဆည်း'
stringMap [strings.Mind_Edit_Left_Menu_Export_Pdf] = 'PDF တင်ပို့ခြင်း'
stringMap [strings.Mind_Edit_Left_Menu_Share_Links] = 'ချိတ်ဆက်မှုကိုမျှဝေ'
stringMap [strings.Mind_Edit_Left_Menu_Share_The_Storm] = 'မုန်တိုင်းသို့မျှဝေ'
stringMap [strings.Mind_Edit_Left_Menu_Printing] = 'ပုံနှိပ်ခြင်း'
stringMap [strings.Mind_Edit_Left_Menu_Unlock_Full_Version] = 'အပြည့်အဝဗားရှင်းကိုသော့ဖွင့်'
stringMap [strings.Mind_Edit_Left_Menu_Help] = 'အကူအညီ'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Format] = 'format သိမ်းဆည်း'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Png] = 'png format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Jpg] = 'jpg format'
stringMap [strings.Mind_Edit_Left_Menu_Save_Picture_Webp] = 'webp ပုံစံ'
stringMap [strings.Mind_Edit_Left_Menu_Share_Map] = 'မြေပုံကိုမျှဝေ'
stringMap [strings.Mind_Edit_Left_Menu_Share_Switch] = 'ဝေမျှရန်'
stringMap [strings.Mind_Edit_Left_Menu_The_Link_Can_Be_Copied_And_Sent] = 'ဒီလင့်ခ်ကိုကူးယူပြီးပေးပို့နိုင်သည်'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Link] = 'ချိတ်ဆက်မိတ္တူ'
stringMap [strings.Mind_Edit_Left_Menu_Follow_The_Link] = 'သင်လင့်ခ်ကိုပြောင်းလဲလိုပါက ကျေးဇူးပြု၍ နှိပ်ပါ'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate] = 'အသစ်ပြန်လည်မွေးဖွား'
stringMap [strings.Mind_Edit_Left_Menu_Link_Password] = 'ချိတ်ဆက်စကားဝှက် -'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Save] ='ချွေတာခွင့်ပြုသည်'
stringMap [strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views] = 'အကြည့်အများဆုံးအရေအတွက်'
stringMap [strings.Mind_Edit_Left_Menu_Infinite] = 'အဆုံးမဲ့'
stringMap [strings.Mind_Edit_Left_Menu_Limit] = 'ကန့်သတ်ချက်'
stringMap [strings.Mind_Edit_Left_Menu_Second] = 'ဒုတိယ'
stringMap [strings.Mind_Edit_Left_Menu_Link_Validity] = 'ချိတ်ဆက်မှုသက်တမ်းကာလ'
stringMap [strings.Mind_Edit_Left_Menu_1_Day] = '၁ ရက်'
stringMap [strings.Mind_Edit_Left_Menu_3_Days] = '၃ ရက်'
stringMap [strings.Mind_Edit_Left_Menu_7_Days] = '၇ ရက်'
stringMap [strings.Mind_Edit_Left_Menu_Permanent] = 'အမြဲတမ်း'
stringMap [strings.Mind_Edit_Left_Menu_Copy_Content_And_Link] = 'အကြောင်းအရာနှင့် link ကိုကူးယူပါ'
stringMap [strings.Mind_Edit_Left_Menu_Regenerate_Tips] = "သင် regenerate လုပ်ဖို့သေချာလား။ ယခင်လင့်ခ်သည်မမှန်ကန်ပါ! '"
stringMap [strings.Mind_Edit_Left_Menu_Share] = 'ဝေမျှ'
stringMap [strings.Mind_Edit_Left_Menu_Custom_Category] = 'စိတ်ကြိုက်အမျိုးအစား'
stringMap [strings.Mind_Edit_Left_Menu_Allow_Comments] = 'မှတ်ချက်ပေးရန်ခွင့်ပြုသလား'
stringMap [strings.Mind_Edit_Left_Menu_Edit_Add_Category] = 'အမျိုးအစားထည့်ရန်တည်းဖြတ်ခြင်း'
stringMap [strings.Mind_Edit_Left_Menu_Membership_Rights] = 'အသင်း ၀ င်အခွင့်အရေး'
stringMap [strings.Mind_Edit_Left_Menu_Support_Platform] = 'ပံ့ပိုးမှုစင်တာ'
stringMap [strings.Mind_Edit_Left_Menu_Open_Membership] = 'Open Membership'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code_Exchange] = 'အသက်သွင်းကုဒ်ဖလှယ်မှု'
stringMap [strings.Mind_Edit_Left_Menu_purchase_Tips] = '၀ ယ်ပြီးနောက်အသင်းဝင်အကျိုးကျေးဇူးများ'
stringMap [strings.Mind_Edit_Left_Menu_Payment_Type] = 'ငွေပေးချေမှုနည်းလမ်းကိုရွေး'
stringMap [strings.Mind_Edit_Left_Menu_Activity_Price] = 'လှုပ်ရှားမှုစျေးနှုန်း'
stringMap [strings.Mind_Edit_Left_Menu_Amount_Actually_Paid] = 'အမှန်တကယ်ငွေပမာဏ'
stringMap [strings.Mind_Edit_Left_Menu_Immediate_Renewal] = 'ချက်ချင်းသက်တမ်းတိုး'
stringMap [strings.Mind_Edit_Left_Menu_Terms_Of_Service] = 'Service ဝန်ဆောင်မှုစည်းကမ်းချက်များ《'
stringMap [strings.Mind_Edit_Left_Menu_Privacy_Policy] = '《 သီးသန့်တည်ရှိမှုမူဝါဒ》'
stringMap [strings.Mind_Edit_Left_Menu_Activation_Code] = 'အသက်သွင်းကုဒ်:'
stringMap [strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code] = 'ကျေးဇူးပြုပြီး activation ကုဒ်ကိုရေးပါ'
stringMap [strings.Mind_Edit_Left_Menu_Verify_Activation_Code] = 'activation code ကိုအတည်ပြု'
stringMap [strings.Mind_Edit_Left_Menu_Select_Categories] = 'အမျိုးအစားရွေးရန်'
stringMap [strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty] = 'အဓိကခေါင်းစဉ်ဟာအချည်းနှီးမဖြစ်နိုင်ဘူး'
stringMap [strings.Mind_Edit_Left_Menu_Coupon] = 'ကူပွန်'
stringMap [strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code] = 'ကျေးဇူးပြု၍ ကူပွန်ကုဒ်ကိုရိုက်ထည့်'
stringMap [strings.Mind_Confirm_Use_Coupons] = 'ကူပွန်များအသုံးပြုမှုကိုအတည်ပြုပါ'
stringMap [strings.Mind_Edit_Left_Menu_Verification] = 'အတည်ပြုခြင်း'
stringMap [strings.Mind_Edit_Left_Menu_Preferential_Amount] = 'ဦး စားပေးပမာဏ'
stringMap [strings.Mind_Edit_Left_Menu_Pay_Immediately] = 'ချက်ချင်းပေးဆောင်'
stringMap [strings.Mind_Edit_Left_Menu_Wx_Pay] = 'WeChat Pay'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information] = 'အချက်အလက်ဖလှယ်မှုကိုအတည်ပြုရန်'
stringMap [strings.Mind_Edit_Left_Menu_Exchange_Code] = 'လဲလှယ်ကုဒ်'
stringMap [strings.Mind_Edit_Left_Menu_Confirm_Exchange] = 'လဲလှယ်'

// စာမျက်နှာ၏ညာဘက်ခြမ်းရှိမီနူးကိုတည်းဖြတ်ပါ
stringMap [strings.Mind_Edit_Right_Menu_canvas] = 'ပတ္တူစ'
stringMap [strings.Mind_Edit_Right_Menu_background_Color] = 'နောက်ခံအရောင်'
stringMap [strings.Mind_Edit_Right_Menu_layout] = 'Layout'
stringMap [strings.Mind_Edit_Right_Menu_Line_Style] = 'လိုင်းစတိုင်'
stringMap [strings.Mind_Edit_Right_Menu_line_Color] = 'လိုင်းအရောင်'
stringMap [strings.Mind_Edit_Right_Menu_Line_Width] = 'လိုင်းအကျယ်'
stringMap [strings.Mind_Edit_Right_Menu_Line_Tapering] = "လိုင်းများကို tapered' တယ်။"
stringMap [strings.Mind_Edit_Right_Menu_Theme_Font] = 'အဓိကအကြောင်းအရာ'
stringMap [strings.Mind_Edit_Right_Menu_Child_Font] = 'ကလေးဖောင့်'
stringMap [strings.Mind_Edit_Right_Menu_text_Color] = 'စာသားအရောင်'
stringMap [strings.Mind_Edit_Right_Menu_font_Size] = 'စာလုံးအရွယ်အစား'
stringMap [strings.Mind_Edit_Right_Menu_Font_Style] = 'စာလုံးပုံစံ'
stringMap [strings.Mind_Edit_Right_Menu_Border_Color] = 'နယ်ခြားအရောင်'
stringMap [strings.Mind_Edit_Right_Menu_Fill_Color] = 'အရောင်ဖြည့်'
stringMap [strings.Mind_Edit_Right_Menu_Border_Shape] = 'Border Shape'
stringMap [strings.Mind_Edit_Right_Menu_Border_Width] = 'နယ်စပ်အကျယ်'
stringMap [strings.Mind_Edit_Right_Menu_Show_Shadow_Effect] = 'အရိပ်အကျိုးသက်ရောက်မှုကိုပြသ (subscription function)'
stringMap [strings.Mind_Edit_Right_Menu_Line_Color] = 'လိုင်းအရောင်'
stringMap [strings.Mind_Edit_Right_Menu_Dotted_Line] = 'အစက်အပြောက်'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Size] = 'ရုပ်ပုံအရွယ်အစား'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Location] = 'ရုပ်ပုံတည်နေရာ'
stringMap [strings.Mind_Edit_Right_Menu_Picture_Shape] = 'ရုပ်ပုံပုံ'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Size] = 'အိုင်ကွန်အရွယ်အစား'
stringMap [strings.Mind_Edit_Right_Menu_Icon_Position] = 'အိုင်ကွန်အနေအထား'
stringMap [strings.Mind_Edit_Right_Menu_routine] = 'အထွေထွေ'
stringMap [strings.Mind_Edit_Right_Menu_priority] = 'ဦး စားပေး'
stringMap [strings.Mind_Edit_Right_Menu_Progress] = 'တိုးတက်မှု'
stringMap [strings.Mind_Edit_Right_Menu_Stars] = 'ကြယ်'
stringMap [strings.Mind_Edit_Right_Menu_Week] = 'ရက်သတ္တပတ်'
stringMap [strings.Mind_Edit_Right_Menu_Head_Portrait] = 'Portrait'
stringMap [strings.Mind_Edit_Right_Menu_3D] = '3D'
stringMap [strings.Mind_Edit_Right_Menu_Learning] = 'သင်ယူခြင်း'
stringMap [strings.Mind_Edit_Right_Menu_Work] = 'အလုပ်'
stringMap [strings.Mind_Edit_Right_Menu_Characters] = 'ဇာတ်ကောင်'
stringMap [strings.Mind_Edit_Right_Menu_Animals] = 'တိရစ္ဆာန်များ'
stringMap [strings.Mind_Edit_Right_Menu_Food] = 'အစားအစာ'
stringMap [strings.Mind_Edit_Node_Menu_Copy_Style] = 'ကူးယူခြင်းစတိုင်'
stringMap [strings.Mind_Edit_Node_Menu_Sticking_Style] = 'ကပ်ကပ်စတိုင်'
stringMap [strings.Mind_Edit_Node_Menu_Modify_Shape] = 'ပုံစံကိုပြုပြင်မွမ်းမံ'
stringMap [strings.Mind_Edit_Node_Menu_View_Picture] = 'ရုပ်ပုံကိုကြည့်'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Picture] = 'ရုပ်ပုံကိုပယ်ဖျက်'
stringMap [strings.Mind_Edit_Node_Menu_Copy] = 'ကူးပါ'
stringMap [strings.Mind_Edit_Node_Menu_Paste] = 'Paste'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Line] = 'လိုင်းအားဖျက်ပစ်'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line] = 'ဖျက်ပစ်'
stringMap [strings.Mind_Edit_Node_Menu_Edit_Remarks] = 'မှတ်ချက်ပြင်ရန်'
stringMap [strings.Mind_Edit_Node_Menu_Open_Link] = 'ပွင့်လင်း link'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Remarks] = 'မှတ်ချက်များကိုပယ်ဖျက်'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Link] = 'link ကိုဖျက်ပစ်'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Icon] = 'အိုင်ကွန်ကိုဖျက်ပစ်'
stringMap [strings.Mind_Edit_Node_Menu_Icon_Style] = 'အိုင်ကွန်စတိုင်'

// စာရင်းအင်းဂရပ်တည်းဖြတ်ရေး panel ကို
stringMap [strings.Echart_Edit_Statistical] = 'စာရင်းအင်း'
stringMap [strings.Echart_Edit_Value] = 'တန်ဖိုး'
stringMap [strings.Echart_Edit_Color] = 'အရောင်'
stringMap [strings.Echart_Edit_Title] = 'ခေါင်းစဉ်'
stringMap [strings.Echart_Edit_Detailed_Description] = 'အသေးစိတ်ဖော်ပြချက်'
stringMap [strings.Echart_Edit_Value_Sort] = 'စီရန်'
stringMap [strings.Echart_Edit_Value_Display_Type] = 'ပြသရန်အမျိုးအစား'
stringMap [strings.Echart_Edit_Value_Color_Scheme] = 'အရောင်အစီအစဉ်'
stringMap [strings.Echart_Edit_Value_Display_Size] = 'ပြသမှုအရွယ်အစား'
stringMap [strings.Echart_Edit_Value_Title_Size] = 'ခေါင်းစဉ်အရွယ်အစား'
stringMap [strings.Echart_Edit_Value_Explain_Size] = 'အရွယ်အစားကိုရှင်းပြပါ'
stringMap [strings.Echart_Edit_Value_Subscribe] = 'နှစ်ကြိမ်စာကြေးယူခြင်း'

// Storm tab
stringMap [strings.StormBookTag_Subscribed] = 'နှစ်ကြိမ်ရေးသားထားသော'
stringMap [strings.StormBookTag_List] = 'စာရင်း'

// မုန်တိုင်းဝေငှခြင်းစာမျက်နှာ
stringMap [strings.Storm_Share_Comment] = 'မှတ်ချက်'
stringMap [strings.Storm_Share_Reply] = 'ပြန်ကြားချက်'
stringMap [strings.Storm_Share_Let_Me_Comment_Too] = 'ငါ့ကိုမှတ်ချက်ပေးပါရစေ ~'
stringMap [strings.Storm_Share_Release] = 'ထုတ်ပြန်'
stringMap [strings.Storm_Share_Main_Title] = 'ပင်မခေါင်းစဉ်'
stringMap [strings.Storm_Share_Subtitle] = 'စာတန်းထိုး'
stringMap [strings.Storm_Share_Author] = 'စာရေးသူ'
stringMap [strings.Storm_Share_Replies] = 'ပြန်ကြားချက်'
stringMap [strings.Storm_Share_Comment_Is_Null_Tips] = 'မှတ်ချက်များမရှိသေးပါ။ ပထမဆုံးဖြစ်အောင်အရင်လုပ်ပါ။ '
stringMap [strings.Storm_Share_Comment_Input_Is_Null_Tips] = "ကျေးဇူးပြု၍ မှတ်ချက်ရေးထည့်ပါ' "
stringMap [strings.Storm_Share_Delete_Comment_Tips] = "ဒီမှတ်ချက်ကိုသင်ဖျက်ချင်ပါသလား။ '"

// မြေပုံ၏အတွင်းပိုင်းအသုံးပြုမှုအတွက်အကူအညီ
stringMap [strings.Mind_Usinghelp_Btn_1] = 'ဖြတ်လမ်းသော့များ (ပြတင်းပေါက်များ)'
stringMap [strings.Mind_Usinghelp_Btn_2] = 'ဖြတ်လမ်းကီး (mac)'
stringMap [strings.Mind_Usinghelp_Btn_3] = 'ဖော်ပြချက်'
stringMap [strings.Mind_Usinghelp_Btn_4] = '[Ctrl] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_5] = '[Command] + [D]'
stringMap [strings.Mind_Usinghelp_Btn_6] = 'ကလေး node ထည့်ပါ'
stringMap [strings.Mind_Usinghelp_Btn_7] = '[ဖျက်ရန်]'
stringMap [strings.Mind_Usinghelp_Btn_8] = 'လက်ရှိဆုံမှတ်ကိုပယ်ဖျက်ပါ (တည်းဖြတ်တဲ့အခြေအနေမှာရှိနေရင်ဖျက်လို့မရဘူးဆိုတာသတိပြုပါ'
stringMap [strings.Mind_Usinghelp_Btn_9] = '[Ctrl] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_10] = '[Command] + [E]'
stringMap [strings.Mind_Usinghelp_Btn_11] = 'လက်ရှိဆုံမှတ်ပြုပြင်မွမ်းမံ'
stringMap [strings.Mind_Usinghelp_Btn_12] = '[Ctrl] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_13] = '[Command] + [↑] [↓] [←] [→]'
stringMap [strings.Mind_Usinghelp_Btn_14] = '[Ctrl] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_15] = '[Command] + [+]'
stringMap [strings.Mind_Usinghelp_Btn_16] = 'မြေပုံကိုချဲ့ပါ'
stringMap [strings.Mind_Usinghelp_Btn_17] = '[Ctrl] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_18] = '[Command] + [-]'
stringMap [strings.Mind_Usinghelp_Btn_19] = 'မြေပုံကိုမှတ်သားပါ'
stringMap [strings.Mind_Usinghelp_Btn_20] = '[Ctrl] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_21] = '[Command] + [S]'
stringMap [strings.Mind_Usinghelp_Btn_22] = '[Ctrl] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_23] = '[Command] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_24] = '[Ctrl] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_25] = '[Command] + [Shift] + [Z]'
stringMap [strings.Mind_Usinghelp_Btn_26] = '[Ctrl] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_27] = '[Command] + [B]'
stringMap [strings.Mind_Usinghelp_Btn_28] = 'တည်းဖြတ်တည်းဖြတ်ခြင်း'

// ချက်ချင်းစာသား
stringMap [strings.Message_Tips_Login_Success] = 'ဝင်ရောက်ရန်အောင်မြင်'
stringMap [strings.Message_Tips_Dlt_Success] = 'အောင်မြင်စွာဖျက်ပစ်'
stringMap [strings.Message_Tips_Report_Success] = 'အောင်မြင်စွာအစီရင်ခံပါ'
stringMap [strings.Message_Tips_Copy_Success] = 'ကူးယူပြီးပြီ'
stringMap [strings.Message_Tips_Collction_Success] = 'စုဆောင်းမှုအောင်မြင်မှု'
stringMap [strings.Message_Tips_Avatar_Modification_Success] = 'Avatar ပြုပြင်မွမ်းမံခြင်းအောင်မြင်မှု'
stringMap [strings.Message_Tips_Signature_Modification_Success] = 'လက်မှတ်ထိုးခြင်းပြုပြင်ခြင်းအောင်မြင်မှု'
stringMap [strings.Message_Tips_Name_Modification_Success] = 'အောင်မြင်စွာပြုပြင်ထားသောအမည်ဝှက်'
stringMap [strings.Message_Tips_Modification_Success] = 'ပြုပြင်မွမ်းမံခြင်းအောင်မြင်'
stringMap [strings.Message_Tips_New_Profile_Success] = 'ဖိုင်တွဲအသစ်ရဲ့အောင်မြင်မှု'
stringMap [strings.Message_Tips_Mobile_Success] = 'မိုဘိုင်းအောင်မြင်မှု'
stringMap [strings.Message_Tips_Submit_Success] = 'အောင်မြင်စွာတင်သွင်း'
stringMap [strings.Message_Tips_Exchange_Success] = 'အောင်မြင်စွာလဲလှယ်'
stringMap [strings.Message_Tips_Please_Code] = 'ကျေးဇူးပြုပြီး activation ကုဒ်ကိုရေးပါ'
stringMap [strings.Message_Tips_Agree_To_Terms] = 'ကျေးဇူးပြု၍ သီးသန့်တည်ရှိမှုမူဝါဒကိုသဘောတူပါ'
stringMap [strings.Message_Tips_Please_Sign_In] = 'ကျေးဇူးပြုပြီးဆိုင်းအင်လုပ်ပါ'
stringMap [strings.Message_Tips_Share_Success] = 'အောင်မြင်အောင်မျှဝေ'
stringMap [strings.Message_Tips_Create_Success] = 'အောင်မြင်စွာဖန်တီးခဲ့တယ်'
stringMap [strings.Message_Tips_Profile_Name_No_Null] = 'ဖိုလ်ဒါရဲ့နာမည်ကမလွတ်မြောက်ဘူး'
stringMap [strings.Message_Tips_Selecte_Success] = 'ရှာဖွေပြီးစီးသည်'
stringMap [strings.Message_Tips_Subscription_Success] = 'မှာယူမှုအောင်မြင်သည်'
stringMap [strings.Message_Tips_Unsubscribe_Success] = 'အောင်မြင်စွာပယ်ဖျက်ရန်'
stringMap [strings.Message_Tips_Related_Content_Not_Found] = 'ဆက်စပ်အကြောင်းအရာများကိုမတွေ့ရှိရ'
stringMap [strings.Message_Tips_Max_Add_Class] = 'အများဆုံးထည့်သွင်းနိုင်တယ်'
stringMap [strings.Message_Tips_Registered_Success] = 'အောင်မြင်စွာမှတ်ပုံတင်'
stringMap [strings.Message_Tips_Scan_Code_Login_Failed] = 'ကုဒ်စစ်ဆေးမှုရဲ့ login မအောင်မြင်'
stringMap [strings.Message_Tips_Share_Is_Null] = 'မျှဝေရန်ချိတ်ဆက်မှုမရှိပါ'
stringMap [strings.Message_Tips_Share_Link_Does_Not_Exist] = 'တင်ထားသောဖိုင်သည် ၃ MB ထက်မပိုနိူင်'
stringMap [strings.Message_Tips_Get_Permission_Successfully] = 'အောင်မြင်စွာခွင့်ပြုချက်ရယူပါ'
stringMap [strings.Message_Tips_Membership_Required] = 'အသင်းဝင်ရန်လိုအပ်သည်'
stringMap [strings.Message_Tips_Save_Success] = 'အောင်မြင်စွာသိမ်းဆည်း'
stringMap [strings.Message_Tips_Scan_Code_Login_Success] = 'ကုဒ်နံပါတ်ကိုအောင်မြင်စွာစစ်ဆေးရန်'
stringMap [strings.Message_Tips_Style] = 'အမျိုးအစားလား။ '

// အကြမ်းဖျင်း mode ကို
stringMap [strings.Outline_Menu_Indent] = 'စာချုပ်'
stringMap [strings.Outline_Menu_Decrease_Indent] = 'အ ၀ င်စာလျှော့ခြင်းကို'

// Help အရိပ်အမြွက်
stringMap [strings.Help_Background_Content] = 'လိုင်းနောက်ခံ၊ မျဉ်းနောက်ခံ၊ လိုင်းနောက်ခံ၊ လိုင်းနောက်ခံ၊ လိုင်းနောက်ခံ၊ လိုင်းနောက်ခံ၊ နောက်ခံ၊ လနောက်ခံ၊'
stringMap [strings.Help_Mind_Content] = 'ကွဲပြားသောအပြင်အဆင်များဖြင့်မြေပုံများကို ဦး စားပေးမှုများအရလုပ်နိုင်သည်။ လမ်းညွှန်မြေပုံ၊ လက်ဝဲမြေပုံ၊ ညာဘက်မြေပုံ၊ အဖွဲ့အစည်းဇယား၊ နှစ်လမ်းသွား၊ သစ်ပင်၊ ညာသစ်ပင်၊'
stringMap [strings.Help_Share_Link_Content] = 'ချိတ်ဆက်မှုပုံစံများကို WeChat, QQ, Weibo နှင့်အခြားလိုင်းများတွင်မျှဝေနိုင်သည်။ သူငယ်ချင်းများကအကြောင်းအရာကိုကြည့်နိုင်သည်'
stringMap [strings.Using_Templates] = 'တင်းပလိတ်များကိုအသုံးပြုခြင်း'

// ဖိတ်ကြားချက်ကုဒ်
stringMap [strings.User_Lolgin_Please_Enter_Invitation_Code] = 'ကျေးဇူးပြုပြီးဖိတ်ကြားချက်ကုဒ်ကိုထည့်ပါ'
stringMap [strings.User_Lolgin_Please_Correct_Enter_Invitation_Code] = 'ကျေးဇူးပြုပြီးမှန်ကန်တဲ့ဖိတ်ကြားမှုကုဒ်ကိုရိုက်ထည့်'
stringMap [strings.User_Lolgin_Optional] = 'optional'

// ပစ္စည်းကိုသုံးပါ

stringMap [strings.Cannot_Add_To_Node_Background] = 'node နောက်ခံနှင့်သက်ဆိုင်မှုမရှိ'
stringMap [strings.Cannot_Add_To_Img_Background] = 'စာသားနောက်ခံနဲ့မသက်ဆိုင်'
stringMap [strings.Cannot_Add_To_Mind_Background] = 'ကှကျလိုင်းနောက်ခံနှင့်မသက်ဆိုင်ပါ'
stringMap [strings.Mind_Edit_Node_Menu_Add_Encircle_Background] = 'နောက်ခံရုပ်ပုံထည့်ပါ'
stringMap [strings.Mind_Edit_Node_Menu_Delete_Encircle_Background] = 'နောက်ခံပုံပယ်ဖျက်'
stringMap [strings.Mind_Add_Background_Image] = 'နောက်ခံပုံထည့်သွင်း'
stringMap [strings.Mind_Delete_Background_Image] = 'နောက်ခံပုံပယ်ဖျက်'

stringMap [strings.Permanent_Member] = 'အမြဲတမ်းအဖွဲ့ဝင်'
stringMap [strings.Insufficient_Storage_Space] = "သိုလှောင်ရန်နေရာမလုံလောက်ခြင်း၊ အသင်းကိုနေရာတိုးမြှင့်ရန်အဆင့်မြှင့်တင်ခြင်း။ '"
stringMap [strings.Used_Space] = 'သိုလှောင်ရန်နေရာ'
stringMap [strings.Current_Storage] = 'လက်ရှိသိုလှောင်မှု'
stringMap [strings.Used_Space_Upgrade] = 'သိုလှောင်မှုနေရာအဆင့်မြှင့်ခြင်း'
stringMap [strings.My_Mind_Is_Null_Tips] = 'Mind + Mind မြေပုံနှင့်မှတ်စုများ Super mnemonics၊ စဉ်းစားတွေးခေါ်နိုင်မှု၊ ဖန်တီးရန်၊ ပြသရန်၊ စီစဉ်ရန်နှင့်မှတ်စုရေးရန်အတောင်ပံများကိုထည့်ပါ'
stringMap [strings.Insufficient_Storage_Space_Tempalte_Tips] = "သိုလှောင်ရန်နေရာမလုံလောက်။ သင်၏အသင်းဝင်ကိုအဆင့်မြှင့်တင်သောအခါ template ကိုသင်အသုံးပြုနိုင်သည်။ '"

stringMap [strings.Group_Management] = 'အုပ်စု'
stringMap [strings.Save_Img_Fill_Full] = 'အပြည့်ဖြည့်ပါ'
stringMap [strings.Save_Img_Center_Tilt] = 'Center Tilt'
stringMap [strings.Save_Img_File_Full_Dark_Color] = 'အပြည့်အဝ (အမှောင်)'
stringMap [strings.Save_Img_Mind_Map_Watermarking] = 'စိတ် + စိတ်မြေပုံရေတံဆိပ်'
stringMap [strings.Save_Img_Add_Watermark_To_Protect_Community_Creation] = 'အသိပညာဖန်တီးမှုကိုကာကွယ်ရန်ရေစာထည့်ခြင်း'
stringMap [strings.Save_Img_Watermark_Text] = 'ရေစာစာသား -'
stringMap [strings.Save_Img_Watermark_Logo] = 'ရေစာအမှတ်အသား -'
stringMap [strings.Save_Img_Watermark_Style] = 'ရေစာပုံစံ:'
stringMap [strings.Save_Img_Please_Input] = 'ကျေးဇူးပြု၍ ရိုက်ထည့်ပါ'
stringMap [strings.Save_Img_Chop_Tips] = 'အသင်းဝင်တခုကိုသင်ဖွင့်တဲ့အခါရေစာအခွင့်အရေးကိုခံစားနိုင်တယ်'
stringMap [strings.Public_Header_Mindmap_Mind] = 'မြေပုံမုဒ်'
stringMap [strings.Copy_Mind_Map] = 'Copy Mind Map'
stringMap [strings.Font_Style_Bold] = 'ရဲရင့်ခြင်း'
stringMap [strings.Font_Style_Italics] = 'စာလုံးစောင်း'
stringMap [strings.Font_Style_Delete_Line] = 'ထိုးနှက်ချက်'
stringMap [strings.Remove_Any] = 'ဖယ်ရှား'
stringMap[strings.Save_Img_Border_Style] = "Border style"
stringMap[strings.Save_Img_Border_Width] = "Border width"
stringMap[strings.Save_Img_Border_Color] = "Border color"
stringMap[strings.Save_Img_Border_Shadow] = "Shadow"
stringMap[strings.Save_Img_Border_Radius] = "Border radius"
stringMap[strings.Save_Img_Border_Big] = "Maximum"
stringMap[strings.Save_Img_Border_Middle] = "Middle"
stringMap[strings.Save_Img_Border_Small] = "Small"
stringMap[strings.Save_Img_Border_None] = "None"

stringMap [strings.Mindmap_Review] = 'မြေပုံသုံးသပ်ချက်'
stringMap [strings.Mindmap_Review_Recently_Updated_Content_10] = 'ထင်မြင်ချက်ကိုပိုမိုနက်ရှိုင်းစေပြီးမှတ်ဉာဏ်ကိုမြှင့်တင်ပေးသည်၊ အတွေးအခေါ်နှင့်သတင်းအချက်အလက်များကိုထိထိရောက်ရောက်ခွဲထုတ်'
stringMap [strings.Mindmap_Review_Content] = 'မြေပုံသုံးသပ်ချက်သည်တည်းဖြတ်ခြင်းလုပ်ငန်းစဉ်ကိုပြန်ဖွင့်သည်ကိုရည်ညွှန်းသည်။ အကြောင်းအရာကိုဗဟိုအခင်းအကျင်းမှစတင်စဉ်ဆက်တိုက်ဖွင့်သည်။ မြေပုံပြန်လည်သုံးသပ်ခြင်းသည်သင့်အားစနစ်စာရင်း၊ ခွဲခြမ်းစိတ်ဖြာမှုနှင့်အတွေးအခေါ်များနှင့်ကွက်ကွက်ကွင်းကွင်းကွက်ကွက်များကိုရှာဖွေရန်ကူညီနိုင်သည်။ ပြန်လည်သုံးသပ်တဲ့အချိန်မှာကွဲပြားခြားနားတဲ့လှုံ့ဆော်မှုတွေကိုသင်တွေ့ရလိမ့်မယ် '
stringMap [strings.Mindmap_Review_Speed] = 'မြန်နှုန်း'
stringMap [strings.Mindmap_Review_Word_Speed] = 'စကားလုံးအမြန်နှုန်း'
stringMap [strings.Mindmap_Review_Background_Ratio] = 'နောက်ခံအချိုး'
stringMap [strings.Mindmap_Review_Play_Order_Peer] = 'Priority peer content'
stringMap [strings.Mindmap_Review_Current_Playback_Node_Magnification] = 'လက်ရှိပြန်ဖွင့် node magnification'
stringMap [strings.Mindmap_Review_Node_Playback_Order] = 'Node ပြန်ဖွင့်အမိန့်'
stringMap[strings.Mindmap_Only_Play_Text] = 'Only play text'
stringMap[strings.Mindmap_Review_Tab_Lable] = "Thinking Review"
stringMap[strings.Mindmap_Speech_Tab_Lable] = "Demo mode"

stringMap [strings.Mind_Group_Picture_Upload] = 'ရုပ်ပုံတင်တင်'
stringMap [strings.Mind_Group_Upload_From_Local] = 'ဒေသခံတင်ပို့ချက်'
stringMap [strings.Mind_Group_Mind_Mapping] = 'စိတ်မြေပုံ'
stringMap [strings.Mind_Group_Information] = 'အချက်အလက်'

stringMap [strings.Mind_Group_Invite_New_Members] = 'အသင်းဝင်အသစ်ဖိတ်ခေါ်'
stringMap [strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number] = 'ကျေးဇူးပြု၍ သင့်ဖုန်းနံပါတ်ကိုရိုက်ထည့်'
stringMap [strings.Mind_Group_Cancel] = 'ပယ်ဖျက်'
stringMap [strings.Mind_Group_Confirm] = 'အိုကေ'
stringMap [strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank] = 'လက်ကိုင်ဖုန်းဖုန်းနံပါတ်ကအလွတ်မပေးနိုင်ဘူး'
stringMap [strings.Mind_Group_Invite_The_Successful] = 'ဖိတ်ကြားမှုအောင်မြင်'


stringMap [strings.Mind_Group_Shared_Group_Membership] = "အုပ်စုလိုက်အုပ်စု ၀ င်များ"
stringMap [strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members] = "အုပ်စုလိုက်အဖွဲ့ဝင်အရေအတွက်အများဆုံး"
stringMap [strings.Mind_Group_Membership_Limit] = "အသင်း ၀ င်ကန့်သတ်ချက်"
stringMap [strings.Mind_Group_Current_Member_Limit] = "လက်ရှိအသင်းဝင်အထက်ကန့်သတ်ချက်"
stringMap [strings.Mind_Group_Upgrade_Member_Limit] = "အသင်း ၀ င်အထက်အဆင့်ကိုအဆင့်မြှင့်တင်ပါ"
stringMap [strings.Mind_Group_Members_Of_The_Search] = "အသင်း ၀ င်ကိုရှာရန်"
stringMap [strings.Mind_Group_Creator] = "ဖန်ဆင်းရှင်"
stringMap [strings.Mind_Group_Administrator] = "အုပ်ချုပ်ရေးမှူး"
stringMap [strings.Mind_Group_Number_Of_People] = "လူများ"
stringMap [strings.Mind_Group_Selected] = "ရွေးချယ်ပြီး"
stringMap [strings.Mind_Group_Member] = "အသင်း ၀ င်"
stringMap [strings.Mind_Group_Check_All] = "အားလုံးရွေးရန်"
stringMap [strings.Mind_Group_Remove] = "ဖယ်ရှားရန်"
stringMap [strings.Mind_Group_Invite_New_Members] = "အသင်းဝင်အသစ်များဖိတ်ခေါ်ပါ"
stringMap [strings.Mind_Group_Delete_Members] = "အသင်းဝင်များကိုဖျက်ပါ"
stringMap [strings.Mind_Group_Please_Select_The_Member_To_Delete] = "ကျေးဇူးပြုပြီးပယ်ဖျက်မဲ့အသင်းကိုရွေးပါ"
stringMap [strings.Mind_Group_Make_Sure_You_Want_To_Remove_The_User_From_The_Group] = "သင်ဤအသုံးပြုသူကိုဤအုပ်စုမှဖယ်ရှားလိုသည်သေချာလား?"
stringMap [strings.Mind_Group_Add_As_Administrator] = "စီမံခန့်ခွဲသူကိုထည့်သွင်းပါ"
stringMap [strings.Mind_Group_Accomplish] = "ပြီးပြီ"
stringMap [strings.Mind_Group_Compile] = "တည်းဖြတ်"
stringMap [strings.Mind_Group_Group_Owner] = "အုပ်စုပိုင်ရှင်"
stringMap [strings.Mind_Group_Add_Admin] = "စီမံခန့်ခွဲသူကိုထည့်သွင်းပါ"
stringMap [strings.Mind_Group_Group_ID] = "အုပ်စုလိုက်အိုင်ဒီ"
stringMap [strings.Mind_Group_Group_Number] = "အုပ်စုနံပါတ်"
stringMap [strings.Mind_Group_Name] = "အမည်"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "ကျေးဇူးပြုပြီးမျှဝေထားသောအုပ်စု၏အမည်ကိုရေးပါ"
stringMap [strings.Mind_Group_Introduce] = "မိတ်ဆက်"
stringMap [strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups] = "ကျေးဇူးပြုပြီးမျှဝေထားသောအုပ်စုများကိုမိတ်ဆက်ပေးပါ။ "
stringMap [strings.Mind_Group_Announcement] = "ကြေငြာချက်"
stringMap [strings.Mind_Group_Shared_Group_Tag] = "Shared Group Tag"
stringMap [strings.Mind_Group_Custom_Tag] = "စိတ်ကြိုက် Tag"
stringMap [strings.Mind_Group_Save] = "သိမ်းဆည်းပါ"
stringMap [strings.Mind_Group_The_Group_Name_Cannot_Be_Empty] = "အုပ်စု၏အမည်သည်ဗလာမဖြစ်နိုင်ပါ"
stringMap [strings.Mind_Group_Establish] = "ဖန်တီးပါ"
stringMap [strings.Mind_Group_Allows_Mapfolder_Creation] = "မြေပုံ / ဖိုလ်ဒါများဖန်တီးရန်ခွင့်ပြုပါ"
stringMap [strings.Mind_Group_Allows_Editing_Of_Maps] = "မြေပုံများအားတည်းဖြတ်ခွင့်ပြုပါ"
stringMap [strings.Mind_Group_Holder] = "လူတိုင်း"
stringMap [strings.Mind_Group_Only_The_Administrator] = "အုပ်ချုပ်ရေးမှူးသာ"
stringMap [strings.Mind_Group_Not_Allow] = "ခွင့်မပြုပါ"
stringMap [strings.Mind_Group_Allows_Map_Deletion] = "မြေပုံအားဖျက်ရန်ခွင့်ပြုသည်"
stringMap [strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages] = "ဖိုရမ်များကိုစာတိုတင်ခွင့်ပြုရန်"
stringMap [strings.Mind_Group_Export] = "တင်ပို့မှု"
stringMap [strings.Mind_Group_Allows_You_To_Save_Images_PDF] = "ပုံများ / PDF ကိုသိမ်းရန်ခွင့်ပြုပါ"
stringMap [strings.Mind_Group_Open_Group_Watermark] = "Open group watermark"
stringMap [strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo] = "ဖွင့်ပြီးတဲ့အခါ၊ အုပ်စုအမှတ်တံဆိပ်ကိုစိတ်မြေပုံပေါ်တွင်ပုံနှိပ်လိမ့်မည်"
stringMap [strings.Mind_Group_Allow_Saving_Maps] = "မြေပုံသိမ်းဆည်းခြင်းကိုခွင့်ပြုသည်"
stringMap [strings.Mind_Group_Save_It_To_My_Map] = "ကျွန်ုပ်မြေပုံသို့သိမ်းဆည်းပါ"
stringMap [strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed] = "မုန်တိုင်းသို့မျှဝေခွင့်ပြုရမလား"
stringMap [strings.Mind_Group_Join_The_Way] = "ဘယ်လိုဆက်သွယ်ရမလဲ"
stringMap [strings.Mind_Group_Whether_To_Invite_New_Members] = "အသင်း ၀ င်သစ်တွေကိုဖိတ်ကြားခွင့်ပြုမလား။ "
stringMap [strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added] = "ရှာဖွေရေးအုပ်စုနံပါတ်များကိုထပ်ထည့်ခွင့်ပြု၊"
stringMap [strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining] = "ဆက်သွယ်မှုမှာသတင်းစကားကိုအတည်ပြုဖို့လိုသလား"
stringMap [strings.Mind_Group_Shared_Group_Data] = "အုပ်စုလိုက်ဒေတာများကိုမျှဝေပါ"
stringMap [strings.Mind_Group_Authority_Management] = "အခွင့်အာဏာစီမံခန့်ခွဲမှု"
stringMap [strings.Mind_Group_Quit_Sharing_Of_Group] = "အုပ်စုခွဲချန်ထားပါ"
stringMap [strings.Mind_Group_Confirm_To_Exit_The_Shared_Group] = "သင်မျှဝေထားသောအုပ်စုမှထွက်ရန်သေချာပါသလား။ "
stringMap [strings.Mind_Group_The_Dissolution_Of_Group] = "ဖျက်သိမ်းအုပ်စု"
stringMap [strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding] = "အုပ်စုဖျက်သိမ်းပြီးတဲ့နောက်ဖိုင်တွေကိုဖျက်ပစ်လိမ့်မယ်။ ဖျက်သိမ်းဖို့အတည်ပြုပါ့မလား"
stringMap [strings.Mind_Group_Return_To_Home_Page] = "ပင်မစာမျက်နှာသို့ပြန်သွား"
stringMap [strings.Mind_Group_Join_The_Group] = "အုပ်စုထဲကို Join"
stringMap [strings.Mind_Group_Please_Enter_The_Group_Number] = "ကျေးဇူးပြု၍ ရှာဖွေရန်အုပ်စုနံပါတ် / အုပ်စုအမည်ထည့်ပါ"
stringMap [strings.Mind_Group_Apply_To_Join] = "ပူးပေါင်းရန်လျှောက်ထား"
stringMap [strings.Mind_Group_Join] = "ဆက်သွယ်ပါ"
stringMap [strings.Mind_Group_Verification] = "အတည်ပြုခြင်း"
stringMap [strings.Mind_Group_Please_Enter_A_Validation_Message] = "ကျေးဇူးပြုပြီးအတည်ပြုခြင်းသတင်းကိုဖြည့်ပါ"
stringMap [strings.Mind_Group_The_Content_Cannot_Be_Empty] = "အကြောင်းအရာမရှင်းရ"
stringMap [strings.Mind_Group_Name] = "အုပ်စုလိုက်အုပ်စုနာမည်"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Name] = "ကျေးဇူးပြုပြီးမျှဝေထားသောအုပ်စုနာမည်ကိုရေးပါ"
stringMap [strings.Mind_Group_Introduction] = "အုပ်စုလိုက်မိတ်ဆက်ခြင်းကိုမျှဝေပါ"
stringMap [strings.Mind_Group_Group_Indication] = "အုပ်စုနံပါတ်"
stringMap [strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet] = "ဒီဖန်တီးသူဟာပျင်းရိတယ်၊ ငါမမိတ်ဆက်သေးဘူး ~"
stringMap [strings.Mind_Group_Shared_Group_Announcement] = "အုပ်စုလိုက်အုပ်စုကြေငြာချက်"
stringMap [strings.Mind_Group_No_Announcement] = "ကြေငြာခြင်းမရှိသေးပါ"
stringMap [strings.Mind_Group_Click_On] = "ချဲ့ရန်နှိပ်ပါ"
stringMap [strings.Mind_Group_Click_On_The_Hidden] = "ဖျောက်ရန်နှိပ်ပါ"
stringMap [strings.Mind_Group_Altogether] = "စုစုပေါင်း"
stringMap [strings.Mind_Group_The_Group_Number_Was_Copied_Successfully] = "အုပ်စုအရေအတွက်ကိုအောင်မြင်စွာကူးယူခြင်း"
stringMap [strings.Mind_Group_Stick] = "အပေါ်သို့ကပ်ပါ"
stringMap [strings.Mind_Group_There_Is_A_New_Content] = "အကြောင်းအရာအသစ်ရှိတယ်"
stringMap [strings.Mind_Group_Cancel_The_Top] = "ထိပ်ကိုပယ်ဖျက်ပါ"
stringMap [strings.Mind_Group_Setting_Up] = "ဆက်တင်များ"
stringMap [strings.Mind_Group_Usinghelp] = "အကူအညီကိုသုံးခြင်း"
stringMap [strings.Mind_Group_Set_The_Message] = "အုပ်စုလိုက်သတင်းစကား"
stringMap [strings.Mind_Group_Apply_To_Join] = "ပူးပေါင်းရန်လျှောက်ထား"
stringMap [strings.Mind_Group_Invite_To_Join] = "ပူးပေါင်းရန်ဖိတ်ခေါ်"
stringMap [strings.Mind_Group_Consent] = "သဘောတူ"
stringMap [strings.Mind_Group_Turn_Down] = "ပယ်ချ"
stringMap [strings.Mind_Group_Label] = "တံဆိပ်ကပ်"
stringMap [strings.Mind_Group_Please_Enter_A_Shared_Group_Label_Name] = "ကျေးဇူးပြုပြီးမျှဝေထားသောအုပ်စုတံဆိပ်အမည်ကိုရေးပါ"
stringMap [strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community] = "သူငယ်ချင်းများနှင့်လုပ်ဖော်ကိုင်ဖက်များကိုဗဟုသုတအကြောင်းအရာများကိုပူးတွဲဖန်တီးမျှဝေရန်ဖိတ်ခေါ်"
stringMap [strings.Mind_Group_Immediately_Create] = "ချက်ချင်းဖန်တီးပါ"
stringMap [strings.Mind_Group_Immediately_To_Join] = "အခုဆက်သွယ်ပါ"

stringMap [strings.Currently_Selected] = "လက်ရှိအောက်ပါ"
stringMap [strings.LabelManagement] ="Label စီမံခန့်ခွဲမှု"
stringMap [strings.NoAttention] = "သတိထားစရာမရှိ"
stringMap [strings.Mindmap_Review_Play] = 'ကစားခြင်း'

stringMap [strings.Mind_Group_Joined] = "ပူးပေါင်း"
stringMap [strings.Mind_Group_Download_App] = "APP ကိုဒေါင်းလုဒ်လုပ်ပါ"
stringMap [strings.Mind_Group_No_Content] = "အကြောင်းအရာမရှိသေးပါ"
stringMap [strings.Mind_Group_Individual] = "အပိုင်းပိုင်း"
stringMap [strings.Mind_Group_Message_Area] = "မက်ဆေ့ခ်ျAreaရိယာ"
stringMap [strings.Mind_Group_No_Message] = "သတင်းစကားမရှိပါ၊ ယခုသွားပါ"
stringMap [strings.Mind_Group_Leave_Message] = "မက်ဆေ့ခ်ျချန်ထားပါ"
stringMap [strings.Mind_Group_Bar] = "ဘား"
stringMap [strings.Mind_Group_See_More] = "ထပ်မံကြည့်ရှုရန်"
stringMap [strings.Mind_Group_Enter_Invitation_Code] = "ဖိတ်ကြားကုဒ်ကိုရိုက်ထည့်ပါ"
stringMap [strings.Mind_Group_Apply_To_Join_Sharing_Group] = "မျှဝေအုပ်စုထဲကိုဝင်ဖို့လျှောက်ထားပါ"
stringMap [strings.Reasons_For_Application_0_30_Words] = "လျှောက်လွှာအတွက်အကြောင်းရင်းများ (0-30 စကားလုံး)"
stringMap [strings.Mind_Group_Send_Out] = "ပေးပို့ပါ"
stringMap [strings.Mind_Group_Join_Successfully] = "အောင်မြင်စွာဆက်သွယ်ပါ"
stringMap [strings.Mind_Group_Application_Sent_Successfully] = "ဒီ application ကိုအောင်မြင်စွာပို့လိုက်ပြီ"

stringMap [strings.Mind_Group_Save_The_Mapping] = "မြေပုံသိမ်းဆည်းပါ"
stringMap [strings.Mind_Group_Update_Record] = "ပြုပြင်မွမ်းမံခြင်းမှတ်တမ်း"

stringMap [strings.Mind_Edit_Page_Switch_Mapping] = "မြေပုံပြောင်းခြင်း"
stringMap [strings.Mind_Edit_Page_Mind_Retrospect] = "မြေပုံပြန်လည်သုံးသပ်ခြင်း"
stringMap [strings.Mind_Edit_Page_A_User_Is_Already_Editing_The_Map] = "ရှိပြီးသားအသုံးပြုသူသည်မြေပုံကိုတည်းဖြတ်နေသည်"
stringMap [strings.Mind_Edit_Page_Password] = "စကားဝှက်"

stringMap [strings.Mind_Edit_Page_Sharing_Group] = "အုပ်စုမျှဝေခြင်း"
stringMap [strings.Mind_MBean] = "MBean"
stringMap [strings.Mind_My_MBean] = "My MBean"
stringMap [strings.Alipay] = "Alipay"
stringMap [strings.Alipay_Payment] = "Alipay ငွေပေးချေမှု"
stringMap [strings.Please_Pay_On_The_Payment_Page] = "ကျေးဇူးပြု၍ ငွေပေးချေရန်စာမျက်နှာသို့သွားပါ"
stringMap [strings.WeChat] = "WeChat"
stringMap [strings.Mind_Shooping_Total] = "စုစုပေါင်း"
stringMap [strings.Mind_Shooping_Confirm_Payment] = "ငွေပေးချေမှုကိုအတည်ပြုပါ"
stringMap [strings.Mind_Shooping_Redeem_Now] = "အခုရွေးပါ"

stringMap [strings.Mind_Whether_Confirm_Payment_Purchase] = "ဝယ်ယူမှုအတွက်ငွေပေးချေမှုကိုအတည်ပြုမလား။ "
stringMap [strings.Mind_Tips] = "သိကောင်းစရာများ"
stringMap [strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean] = "သင်၏ M Beans များမလုံလောက်ပါ။ သင် M Beans ကို ၀ ယ်လိုသည်"

stringMap [strings.Mind_share_scan_code] = "စိတ် + စကင်ဖတ်စစ်ဆေးတဲ့ကုဒ်"

stringMap [strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played] = "နောက်ဆုံးကစားခဲ့တဲ့နေရာကိုပြန်ထားဖို့လိုမလား။ "
stringMap [strings.Mind_Play_Number_Of_Playable_Times_Remaining] = "ကျန်ရှိနေသေးသောဖွင့်နိုင်သည့်အချိန်များ"

stringMap [strings.Group_Mind_Play_The_File_Will_Not_Exist] = "ဒီဖိုင်ကိုလုံးဝဖျက်ပစ်လိမ့်မယ်၊ သင်ဖျက်သိမ်းမှာသေချာလား?"
stringMap [strings.Group_Mind_Up_One_Level] = "အဆင့်တစ်ခုအထိ"
stringMap [strings.Group_Mind_No_Documents] = "ဖိုင်များမရှိသေးပါ"
stringMap [strings.Group_Mind_PaymentSuccessful] = "ငွေပေးချေမှုအောင်မြင်တယ်"

stringMap [strings.Mind_Edit_Page_Sharing_Group_introduce] = "လူများပူးပေါင်းတည်းဖြတ်ခြင်း၊ Multiminal Real-time ထပ်တူပြုခြင်း၊ စာရွက်စာတမ်းများမျှဝေခြင်း၊"

stringMap [strings.Mind_Edit_Subscript] = "နှစ်စဉ်ကြေး"
stringMap [strings.Mind_Edit_Text_Location] = "စာသားတည်နေရာ"
stringMap [strings.Mind_Mind_Import_Into_A_Shared_Group] = "မျှဝေအုပ်စုသို့တင်သွင်းခြင်း"

stringMap [strings.Standard_Colors] = "စံအရောင်များ"
stringMap [strings.More_Colors] = "အရောင်များစွာ"
stringMap [strings.Reset_Password] = "စကားဝှက်ကိုပြန်ပြင်ပါ"
stringMap [strings.Account_Password_Login] = "အကောင့်လျှို့ဝှက်နံပါတ် Login"
stringMap [strings.Authentication_code_Login_registration] = "စိစစ်အတည်ပြုကုဒ်ရဲ့ login / မှတ်ပုံတင်ခြင်း"
stringMap [strings.One_End_Creation_Multi_End_Synchronization] = "တစ် ဦး တည်းအဆုံးဖန်တီးမှု, မျိုးစုံအဆုံးထပ်တူပြုခြင်း"
stringMap [strings.Rich_And_Diverse_Mapping_Structure] = "ကြွယ်ဝပြီးမတူကွဲပြားသောမြေပုံဖွဲ့စည်းပုံ"
stringMap [strings.Massive_And_ExquisiteTemplateLibrary] = "iveရာရာလက်ရာမြောက်သော template template"
stringMap [strings.Map_Review_Play] = "မြေပုံသုံးသပ်ချက် - ကစားခြင်း"
stringMap [strings.CommunityLearningAndSharingPlatform] = "ဗဟုသုတလေ့လာခြင်းနှင့်လဲလှယ်ရေးစင်မြင့်ကိုဝေမျှခြင်း"

stringMap [strings.Mind_Mind_Upgrade_Storage_Space] = "သိုလှောင်မှုနေရာကိုအဆင့်မြှင့်ပါ"
stringMap [strings.Mind_Mind_Let_Me_Comment_Too] = "ငါ့ကိုမှတ်ချက်ပေးပါရစေ"
stringMap [strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time] = "စာမအကြောင်းကြားသေးပါ"

stringMap [strings.Failed_To_Get_Recording_Device] = "အသံဖမ်းစက်ကိုရယူရန်မအောင်မြင်ပါ"

stringMap [strings.Search_Pagination] = "စာမျက်နှာခွဲရှာဖွေခြင်း"
stringMap [strings.Please_Enter_A_Paging_Name] = "ကျေးဇူးပြု၍ စာမျက်နှာအမည်ထည့်ပါ"
stringMap [strings.Operation_Settings] = "လုပ်ဆောင်ချက်ဆက်တင်များ"
stringMap [strings.Shortcut_key_Settings] = "ဖြတ်လမ်းသော့ဆက်တင်များ"
stringMap [strings.Press_Key_Combination] = "ကျေးဇူးပြု၍ လိုအပ်သောသော့တွဲကိုနှိပ်ပါ"
stringMap [strings.Mind_Reduction] = "လျှော့ချခြင်း"
stringMap [strings.Double_Click_Add_Free_Node] = "လွတ်နေသောနေရာကိုထည့်ရန်ကွက်လပ်ကိုနှစ်ချက်နှိပ်ပါ။"
stringMap [strings.Shortcut_Conflict] = "ဖြတ်လမ်းသော့ပဋိပက္ခ"
stringMap [strings.Select_Main_Node] = "အဓိက Node ကိုရွေးပါ"
stringMap [strings.Telephone] = "ဖုန်း"
stringMap [strings.Microblog] = "Microblog"
stringMap [strings.Address] = "လိပ်စာ"
stringMap [strings.Company_Address] = "Block B, Building 21, No. 1158 Zhongxin Road, Songjiang District, Shanghai (Songjiang Lingang Caohejing)"
stringMap [strings.Mind_Archive] = "မှတ်တမ်းဟောင်း"
stringMap [strings.Mind_Bookshelf] = "စာအုပ်စင်"
stringMap [strings.Search_Archive] = "စာအုပ်စင်ရှာရန်"
stringMap [strings.Modify_Archive_Order] = "စာအုပ်စင်အမှာစာကိုမွမ်းမံပါ"
stringMap [strings.Modification_Order] = "ပြင်ဆင်ခြင်းအမိန့်"
stringMap [strings.Please_Enter_Archive_Name] = "ကျေးဇူးပြုပြီးစာအုပ်စင်နာမည်ကိုရိုက်ထည့်ပါ"
stringMap [strings.Message_Tips_Archive_Name_No_Null] = "စာအုပ်စင်အမည်ကိုကွက်လပ် ထား၍ မရပါ"
stringMap [strings.Mind_Edit_Delete_Archive_Tips] = "ဒီစာအုပ်စင်ကိုသင်ဖျက်ချင်တာသေချာလား။ "
stringMap [strings.Mind_Edit_Delete_Bookcase_Tips] = "ဤစာအုပ်သေတ္တာကိုသင်ဖျက်လိုသည်မှာသေချာသလား။ "
stringMap [strings.Mind_Edit_Delete_Map_Tips] = "ဒီမြေပုံကိုဖျက်ပစ်ဖို့သေချာလား။ "
stringMap [strings.Mind_Edit_Confirm] = "အတည်ပြုရန်"
stringMap [strings.Mind_Import_Map] = "မြေပုံတင်သွင်းရန်"
stringMap [strings.Mind_Bookcase] = "စာအုပ်စင်"
stringMap [strings.Added_Successfully] = "အောင်မြင်စွာထည့်ပြီးပါပြီ"
stringMap [strings.Message_Tips_Bookcase_Name_No_Null] = "စာအုပ်သေတ္တာအမည်ကိုကွက်လပ် ထား၍ မရပါ"

stringMap [strings.Mind_Association_Map] = "အသင်းမြေပုံ"
stringMap [strings.Mind_confirm_Association_Map] = "ဒီမြေပုံကိုသင်သေချာချိတ်ဆက်မိသလား။ "
stringMap [strings.Mind_Association_Map_See] = "အသင်းမြေပုံကြည့်ရန်"
stringMap [strings.Mind_Association_Map_Delete] = "အသင်းမြေပုံကိုဖျက်ပါ"

stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line] = "ဆက်စပ်မှုလိုင်းဖွဲ့စည်းပုံ"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_Start] = "ဆက်စပ်မှုလိုင်း၏အစ"
stringMap [strings.Mind_Edit_Right_Menu_Correlation_Line_End] = "ဆက်စပ်မှုမျဉ်း၏အဆုံး"

stringMap [strings.Share_Group_To_Storm] = "အဖွဲ့ကိုမုန်တိုင်းကိုမျှဝေမလား"
stringMap [strings.Apply_To_Join_Storm_Platform] = "မုန်တိုင်းပလက်ဖောင်းပေါ်တွင်အခြားလူများကမြင်။ လျှောက်ထားရန်ဘုံအသိပညာများကိုမြတ်နိုးသူများနှင့်ပူးပေါင်းရန်"
stringMap [strings.Pay_To_Goin] = "ပူးပေါင်းရန်ငွေပေးချေပါ"
stringMap [strings.Pay_To_Goin_Need_Mbeans] = "M စေ့များ ၀ င်လျှင်ငွေပေးချေရန်လိုသည်"
stringMap [strings.Mbean_Can_Be_Realized] = "Mbean သဘောပေါက်ပြီ"

stringMap [strings.Mind_Visit] = "လည်ပတ်ရန်"
stringMap [strings.Mind_Details] = "အသေးစိတ်"
stringMap [strings.Mind_See] = "ကြည့်ရန်"
stringMap [strings.Earn_Mbeans] = "Mbeans ကိုရယူပါ"
stringMap [strings.Mind_Explain] = "ဖော်ပြချက်"
stringMap [strings.Mind_Parenting] = "မိဘအုပ်ထိန်းမှု"
stringMap [strings.Mind_Internet] = "အင်တာနက်"
stringMap [strings.Mind_Psychology] = "စိတ်ပညာ"
stringMap [strings.Mind_literature] = "စာပေ"
stringMap [strings.Mind_Technology] = "နည်းပညာ"
stringMap [strings.Mind_Workplace] = "လုပ်ငန်းခွင်"
stringMap [strings.Mind_Life] = "ဘဝ"
stringMap [strings.Mind_Subject] = "အကြောင်းအရာ"
stringMap [strings.Hot_Group] = "Hot Group"
stringMap [strings.Open_Group] = "အုပ်စုဖွင့်ရန်"
stringMap [strings.Mind_Group_Used] = "အသုံးပြုထားသည်"

stringMap [strings.Mind_Click_Select] = "ရွေးရန်နှိပ်ပါ"
stringMap [strings.Mind_Double_Click_Enter] = "ရိုက်ထည့်ရန်နှစ်ချက်နှိပ်ပါ"
stringMap [strings.Search_Shared_Groups] = "မျှဝေထားသောအဖွဲ့များကိုရှာပါ"
stringMap [strings.Search_Storm] = "မုန်တိုင်းရှာရန်"
stringMap [strings.Members_Save_Free] = "အသင်းဝင်များအခမဲ့သိမ်းဆည်းပါ"
stringMap [strings.Bank_Card_Account_Name] = "ကျေးဇူးပြု၍ သင်၏ဘဏ်ကဒ်အကောင့်အမည်ကိုရိုက်ထည့်ပါ"
stringMap [strings.Bank_Card_No] = "ဘဏ်ကဒ်နံပါတ်"
stringMap [strings.Please_Enter_Your_Bank_Card_No] = "ကျေးဇူးပြု၍ သင်၏ဘဏ်ကဒ်နံပါတ်ကိုရိုက်ထည့်ပါ"
stringMap [strings.Branch_Sub_Branch_Information] = "ဌာနခွဲဌာနခွဲအချက်အလက်များ"
stringMap [strings.Branch_Sub_Branch_Example] = "ဥပမာ၊ Hangzhou ဌာနခွဲ Chengxi ဌာနခွဲ"
stringMap [strings.Application_For_Withdrawal] = "ငွေထုတ်ရန်လျှောက်လွှာ"
stringMap [strings.Withdrawal_Amount] = "ငွေထုတ်ပမာဏ"
stringMap [strings.Withdrawal] = "ငွေထုတ်ခြင်း"
stringMap [strings.Current_Revenue] = "လက်ရှိရငွေ"
stringMap [strings.Recharge] = "အားပြန်သွင်းရန်"
stringMap [strings.Current_Total_MBeans] = "လက်ရှိ MBeans စုစုပေါင်းအရေအတွက်"
stringMap [strings.Withdrawal_Status] = "ငွေထုတ်အခြေအနေ"
stringMap [strings.Insufficient_Withdrawal_Amount] = "ငွေထုတ်ပမာဏမလုံလောက်ပါ"
stringMap [strings.Load_More] = "နောက်ထပ်တင်ရန်"
stringMap [strings.Loading] = "တင်နေသည်"
stringMap [strings.It_Is_Over] = "ပြီးပါပြီ"
stringMap [strings.Default_Label] = "မူရင်းတံဆိပ်"
stringMap [strings.Selected_Label] = "ရွေးချယ်ထားသောတံဆိပ်"
stringMap [strings.Current_Location] = "လက်ရှိတည်နေရာ"
stringMap [strings.MGold_Introduction] = "M ကိုမင်းကတဆင့်ရွှေတွေရခဲ့တယ်"
stringMap [strings.MGold_Details] = "သင်၏အခပေး cloud အုပ်စုတွင်ဗဟုသုတမြေပုံများ၊ သင်ယူမှုပစ္စည်းများစသဖြင့်အဖွဲ့တွင်ရှိပါသည်။ သုံးစွဲသူများကငွေပေးချေမှု M M တွင်ပါ ၀ င်ပါက၎င်းတို့ ၅၀% ကို M gold သို့ M ရွှေအဖြစ်ပြောင်းပေးလိမ့်မည်။ ၅၀ ထက်ပိုထုတ်ယူနိုင်သည် "
stringMap [strings.Cloud_Group_Introduction] = "သင်၏အုပ်စုကိုဝေမျှပါ၊ အခြားသူများကမုန်တိုင်းစာမျက်နှာတွင်ကြည့်နိုင်သည်"
stringMap [strings.Cloud_Group_Details] = "သင်၏အခကြေးငွေပေးသော cloud group ။ ဗဟုသုတမြေပုံများ၊ သင်ယူမှုပစ္စည်းများစသည်ဖြင့်အဖွဲ့တွင်ရှိသည်။ သုံးစွဲသူများကငွေပေးချေမှု M M တွင်ပါ ၀ င်ပါက၎င်းတို့ ၅၀% ကို M gold သို့ပြောင်းလိမ့်မည်။ ၅၀ ကျော်ထုတ်ယူနိုင်သည် "
stringMap [strings.Share_Storm_Introduction] = "မင်းရဲ့မြေပုံကိုမုန်တိုင်းအသိုင်းအဝိုင်းကိုဝေမျှမယ်၊ မင်းလူတွေပိုသိအောင်စူးစမ်းလေ့လာနိုင်အောင်"
stringMap [strings.Share_Storm_Details] = "သင်မြေပုံများကိုသိမ်းဆည်းရန် M ถั่วများကိုသုံးခြင်းအားဖွင့်ပါကအခြားအသုံးပြုသူများသည်သင်၏ဗဟုသုတမြေပုံကိုသုံးနိုင်သည်၊ သင် M beans ၅၀% ကိုလည်းရနိုင်သည်။ M ถั่วများကို ၀ ယ် ယူ၍ အသင်း ၀ င်များနှင့်ပူးပေါင်းနိုင်သည်။ အုပ်စုများ၊ အခြားလူများ၏မြေပုံများကိုသုံးရန်သင်ငွေပေးနိုင်သည်။ "

stringMap [strings.Linked_Successfully] = "အောင်မြင်စွာချိတ်ဆက်ထားသည်"
stringMap [strings.Cancel_Archiving] = "သိမ်းဆည်းခြင်းပယ်ဖျက်ခြင်း"
stringMap [strings.Please_Select_Archive_Location] = "ကျေးဇူးပြု၍ သိမ်းဆည်းထားသောနေရာကိုရွေးပါ"
stringMap [strings.The_Search_Result_Is_Empty] = "ရှာဖွေမှုရလဒ်များလွတ်နေသည်"

stringMap [strings.Customize_A_Style] = "အဖွဲ့ဝင်မဟုတ်သူများသည်ပုံစံတစ်ခုသာစိတ်ကြိုက်ပြုလုပ်နိုင်သည်"
stringMap [strings.Adds_Current_Guide_Style_Library] = "လက်ရှိမြေပုံပုံစံကိုစတိုင်စာကြည့်တိုက်သို့ထည့်ပါ"
stringMap [strings.Trial_Version] = "စမ်းသပ်ဗားရှင်း"
stringMap [strings.Not_Supported_At_The_Moment] = "လောလောဆယ်ပံ့ပိုးမထားပါ"
stringMap [strings.Mind_File] = "ဖိုင်"
stringMap [strings.Mind_Import] = "သွင်းကုန်"
stringMap [strings.Importing] = "တင်သွင်းခြင်း"

stringMap [strings.Mind_Edit_Insert_Video] = "ဗီဒီယိုထည့်ပါ"
stringMap [strings.Mind_Edit_Drag_And_Drop_The_Video_Here] = "ဗီဒီယိုကိုဤနေရာသို့ဆွဲထည့်ပါ"
stringMap [strings.Message_Tips_Video_Upload_Time_Limit] = "အပ်လုဒ်တင်ထားသောဗီဒီယိုကြာချိန်သည် ၆၀ စက္ကန့်ထက်မပိုစေရ"
stringMap [strings.Mind_Edit_Node_Menu_View_Video] = "ဗီဒီယိုကြည့်ရန်"
stringMap [strings.Mind_Edit_Node_Delete_View_Video] = "ဗီဒီယိုကိုဖျက်ပါ"
stringMap [strings.Message_Tips_Share_Video_Does_Not_Exist] = "တင်ထားသောဗီဒီယိုသည် 10MB ထက်မပိုနိုင်ပါ"
stringMap [strings.Mind_Upload_Video_Restrictions] = "ဗွီဒီယိုများကို ၁၂၀ စက္ကန့်နှင့် ၁၀ မီလီမီတာအထိအရွယ်အစားတင်ခြင်းကိုသာထောက်ခံသည်"
stringMap [strings.Mind_Video_Size] = "ဗီဒီယိုအရွယ်အစား"
stringMap [strings.Mind_Edit_Left_Menu_Export_Word] = "စာကိုထုတ်ရန်"
stringMap [strings.Mind_Edit_Left_Menu_Export_TXT] = "TXT ပို့ရန်"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_Cloud] = "Cloud Save"
stringMap [strings.Mind_Edit_Left_Menu_Export_No_Save_Cloud] = "Not synchronized"
stringMap [strings.Mind_Edit_Left_Menu_Export_Save_As] = "Save as"
stringMap [strings.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As] = "The sharing group does not support saving as. You can save the file to [Recent Creation] first, and then to local"
stringMap [strings.Export_Failed] = "ထုတ်ယူမှုမအောင်မြင်ပါ"
stringMap [strings.Add_File] = "ဖိုင်ထည့်ပါ"
stringMap [strings.Drag_The_File_Directly_To_Upload] = "ဖိုင်သို့တိုက်ရိုက်တင်ရန်"
stringMap [strings.Supported_Imported_File_Types] = "လက်ရှိတင်သွင်းရန်အတွက်ပံ့ပိုးထားသောဖိုင်အမျိုးအစားများ: Word (.docx), XMind, .txt"
stringMap [strings.No_Permission_Do_This] = "မင်းဒီစစ်ဆင်ရေးလုပ်ဖို့ခွင့်ပြုချက်မရှိဘူး"

stringMap [strings.Upgrade_To_Professional] = "Professional Edition သို့အဆင့်မြှင့်တင်ခြင်း"
stringMap [strings.Non_Members_Insert_Videos] = "အဖွဲ့ဝင်မဟုတ်သူများသည်ဗီဒီယိုများတင်ပြီး ၂၄ နာရီအတွင်းသာထည့်နိုင်သည်"
stringMap [strings.Upload_Failed] = "တင်ခြင်းမအောင်မြင်ပါ"
stringMap [strings.Upload_Failed_Title_Message] = "တင်ခြင်းမအောင်မြင်ပါ၊ ကျေးဇူးပြု၍ အတည်ပြုရန်နှိပ်ပါ၊ အပ်လုဒ်တင်ရန်မအောင်မြင်သောကျန်ရှိသောပုံများကိုဖျက်ပါ"

stringMap [strings.Mind_Edit_Members_Use] = "အသင်းဝင်များအသုံးပြုခြင်း"
stringMap [strings.Invitation_Code] = "ဖိတ်ကြားလွှာကုဒ်"
stringMap [strings.Student_Certification] = "ကျောင်းသားအသိအမှတ်ပြု"
stringMap [strings.I_Get_It] = "ငါလမ်းညွှန်ကိုကျော်သွားမယ်"
stringMap [strings.Welcome_To_Mind] = "Mind+သို့ ၀ င်ရန်သခင်ငယ်အားကြိုဆိုပါသည်၊ တွေးခေါ်မှုခရီးကိုစကြစို့။ "
stringMap [strings.Mind_Before] = "ပထမ"
stringMap [strings.Try_A_Map] = "မြေပုံကိုစမ်းကြည့်ပါ"
stringMap [strings.Click_Create] = "ဖန်တီးရန်နှိပ်ပါ"
stringMap [strings.Click_Create_A_Mind_Map] = "စိတ်မြေပုံတစ်ခုဖန်တီးရန်နှိပ်ပါ"
stringMap [strings.Mind_About] = "အကြောင်း"
stringMap [strings.See_Checking_Data] = "ကိုယ်ရေးကိုယ်တာအချက်အလက်များကိုကြည့်ရန်"
stringMap [strings.Modify_Checking_Data] = "ကိုယ်ရေးကိုယ်တာအချက်အလတ်များကိုပြုပြင်ပါ"
stringMap [strings.Mind_Shear] = "ဖြတ်တောက်ခြင်း"

stringMap [strings.Mind_Style_Empty] = "အကြိုက်ဆုံး (သို့) စိတ်ကြိုက်ပုံစံမရှိပါ။"
stringMap [strings.Mind_Branch_Free_Layout] = "ဌာနခွဲအခမဲ့အပြင်အဆင်"
stringMap [strings.Mind_Branch_Free_Layout_explain] = "နှစ်လမ်းသွားပုံစံနှင့်ပူဖောင်းမြေပုံသစ်ကိုင်းများကိုအပြင်မှလွတ်လွတ်လပ်လပ်ဆွဲယူနိုင်သည်"
stringMap [strings.Mind_Free_Theme_Drag_Dnd_Snap] = "အခမဲ့ Theme Drag Snap"

stringMap [strings.Select_A_Node] = "ခေါင်းစဉ်တစ်ခုကိုရွေးပါ"
stringMap [strings.Create_Child_Node] = "ကလေးဆောင်ပုဒ်ဖန်တီးပါ"
stringMap [strings.Add_A_Nice_Image_To_The_Node] = "နောက်တစ်ခုကိုငါတို့ကလေးအတွက်ဆောင်ပုဒ်ကောင်းတစ်ပုဒ်ထည့်မယ်"
stringMap [strings.Click_Icon_Panel] = "သင်္ကေတ panel ကိုနှိပ်ပါ"
stringMap [strings.Select_An_Icon_You_Like] = "မင်းကြိုက်တဲ့သင်္ကေတတစ်ခုကိုရွေး"
stringMap [strings.Replace_The_Mind_Map_With_A_New_One] = "နောက်နောင်ငါတို့ mind map ကိုအသစ်တစ်ခုနဲ့အစားထိုးလိမ့်မယ်"
stringMap [strings.Select_The_Central_Theme] = "ဗဟိုဆောင်ပုဒ်ကိုရွေးပါ"
stringMap [strings.Switch_To_Canvas_Panel] = "Canvas Panel သို့ပြောင်းပါ"
stringMap [strings.Click_Layout] = "Layout သို့နှိပ်ပါ"
stringMap [strings.Choose_A_Layout_You_Like] = "သင်ကြိုက်နှစ်သက်သောအပြင်အဆင်ကိုရွေးပါ"
stringMap [strings.Mind_Map_Save_Share] = "ဂုဏ်ယူပါတယ်၊ သင်လှပသော mind map တစ်ခုကိုအောင်မြင်စွာပြုလုပ်နိုင်ပါပြီ၊ အဲဒါကိုသိမ်းပြီးသူငယ်ချင်းများကိုမျှဝေကြပါစို့ ~"
stringMap [strings.Click_Save_Picture] = "ပုံကိုသိမ်းရန်နှိပ်ပါ"
stringMap [strings.Click_OK] = "OK နှိပ်ပါ"
stringMap [strings.Congratulations_On_Completing_The_Novice_Guide] = "အတွေ့အကြုံမရှိသေးသောလမ်းညွှန်ကိုဖြည့်စွက်ခြင်းအတွက်ဂုဏ်ယူပါတယ် ~"
stringMap [strings.Upload_Succeeded] = "အပ်လုဒ်လုပ်ပြီးပါပြီ"

stringMap [strings.Mind_Edit_Node_Menu_Quick_Style] = "အမြန်ပုံစံ"
stringMap [strings.Mind_Extremely_Important] = "အလွန်အရေးကြီးသည်"
stringMap [strings.Mind_Important] = "အရေးကြီး"
stringMap [strings.Mind_Commonly] = "အထွေထွေ"
stringMap [strings.Mind_Ordinary] = "သာမန်"
stringMap [strings.Member_Exclusive] = "ဤအင်္ဂါရပ်သည်အဖွဲ့ဝင်များအတွက်သီးသန့်ဖြစ်သည်။ သင်၏အဖွဲ့ဝင်မှုကိုအဆင့်မြှင့်တင်လိုပါသလား။ "
stringMap [strings.Member_Exclusive_Color_Scheme] = "ဤအရောင်အစီအစဉ်သည်အဖွဲ့ဝင်များအတွက်သီးသန့်ဖြစ်သည်။ သင်၏အဖွဲ့ဝင်မှုကိုအဆင့်မြှင့်တင်လိုပါသလား။ "
stringMap [strings.Mind_Enlarge] = "ချဲ့ရန်"
stringMap [strings.Mind_Narrow] = "ကျဉ်းသည်"
stringMap [strings.Mind_Center] = "အလယ်ဗဟို"
stringMap [strings.Mind_Full_Screen] = "မျက်နှာပြင်အပြည့်"

stringMap [strings.Mind_Association_Node] = "အသင်းအဖွဲ့ဆုံမှတ်"

stringMap[strings.Mind_Resource_See] = "ပူးတွဲပါဖိုင်ကို ကြည့်ရှုရန်"
stringMap[strings.Mind_Resource_Delete] = "ပူးတွဲပါဖိုင်များကို ဖျက်ရန်"
stringMap[strings.Mind_Edit_Drag_And_Drop_The_explain_Here] = "ဖိုင်များကို ဤနေရာတွင် ဆွဲဆွဲချပါ"
stringMap[strings.Mind_Edit_choose_explain] = "ဖိုင်ကို ရွေးပါ"
stringMap[strings.Message_Tips_Share_Explain_Does_Not_Exist] = "အပ်လုဒ်လုပ်ထားသည့် ဖိုင်သည် 20MB ထက် မပိုရပါ။"
stringMap[strings.Mind_Style_Tight_Mode_Label] = "Tight layout"
stringMap[strings.Mind_Style_Same_Level_Alignment_Label] = "Sibling alignment"
stringMap[strings.Mind_Style_Node_Overlap] = "Theme overlap"
stringMap[strings.Mind_Style_To_Peer] = "Use to peer"
stringMap[strings.Mind_Style_To_All_Son_Subject] = "Use to all subtopics"
stringMap[strings.Mind_Edit_Node_Menu_Save_Picture] = "Save Picture As"
stringMap[strings.Mind_Edit_Order_Label] = 'Order'
stringMap[strings.Mind_Edit_Order_1_Label] = '1, 2, 3, ...'
stringMap[strings.Mind_Edit_Order_2_Label] = 'Ⅰ, Ⅱ, Ⅲ, ...'
stringMap[strings.Mind_Edit_Order_3_Label] = 'ⅰ, ⅱ, ⅲ, ...'
stringMap[strings.Mind_Edit_Order_4_Label] = 'A, B, C, ...'
stringMap[strings.Mind_Edit_Order_5_Label] = 'a, b, c, ...'
stringMap[strings.Mind_Edit_Order_6_Label] = '(1),(2),(3), ...'
stringMap[strings.Mind_Edit_Order_7_Label] = '①, ②, ③, ...'
stringMap[strings.Mind_Edit_Order_8_Label] = '一, 二, 三, ...'
stringMap[strings.Mind_Edit_Order_Level_1_Label] = '1 Level'
stringMap[strings.Mind_Edit_Order_Level_2_Label] = '2 Level'
stringMap[strings.Mind_Edit_Order_Level_3_Label] = '3 Level'
stringMap[strings.Mind_Edit_Order_Level_4_Label] = '4 Level'
stringMap[strings.Mind_Edit_Order_Remove_Label] = 'None'
stringMap[strings.Mind_Edit_Open_Hidde_Title] = 'Display Layers'
stringMap[strings.Mind_Edit_Open_Hidde_1] = '1 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_2] = '2 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_3] = '3 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_4] = '4 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_5] = '5 Layers'
stringMap[strings.Mind_Edit_Open_Hidde_All] = 'Show All Layers'

module.exports = stringMap;